<!--suppress JSUnresolvedVariable -->
<template>
  <main>
    <preloader-main v-if="contactsLoader"/>
    <header-button-utp1
        :header="header"
        @buttonClick="$router.push('/mailing/contacts/add')"
    />

    <form-search
        v-model:search="search"
        v-model:how="how"
        @newSearch="updateContactsAndNumber"
    />

    <div class="pt-3">
      <contacts-cards
          :contacts="contacts.contacts"
          @deleteCard="deleteCard"
      />
    </div>

    <!--suppress JSValidateTypes -->
    <pagination-utp1
        v-model:number="numbers"
        v-model:page="page"
        :total="number"
    />

  </main>
  <sidebar-right-utp1/>
</template>

<script>
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import HeaderButtonUtp1 from "../../../components/UI/headers/HeaderButtonUtp1";
import useContacts from "../../../hooks/rest/mailing/contacts/useContacts";
import {onMounted, ref, watch} from "vue";
import FormSearch from "../../../components/UI/forms/FormSearch.vue";
import ContactsCards from "../../../components/pages/mailing/contacts/ContactsCards";
import {useRoute, useRouter} from "vue-router";
import PaginationUtp1 from "../../../components/UI/paginations/PaginationUtp1";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";

export default {
  name: "Contacts",
  components: {
    SidebarRightUtp1,
    PaginationUtp1,
    ContactsCards,
    FormSearch,
    HeaderButtonUtp1,
    PreloaderMain
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const header = {
      title: "Контакты",
      buttonTitle: "Добавить контакт"
    }

    const page = ref(Number(route.query.page) ? Number(route.query.page) : 1)
    const numbers = ref(Number(route.query.number) ? Number(route.query.number) : 10)
    const search = ref(route.query.search ? route.query.search : "")
    const how = ref(route.query.how ? route.query.how : "")

    const myQuery = () => {
      return {
        search: search.value,
        how: how.value,
        page: page.value,
        number: numbers.value
      }
    }

    watch(page, async () => {
      await router.replace({
        query: myQuery()
      })
      await getContacts()
    })

    watch(numbers, async () => {
      if (page.value === 1) {
        await router.replace({
          query: myQuery()
        })
        await getContacts()
      } else page.value = 1
    })

    watch(how, async () => {
      if (page.value === 1) {
        await router.replace({
          query: myQuery()
        })
        await getContacts()
      } else page.value = 1
    })

    watch(search, () => {
      router.replace({
        query: myQuery()
      })
    })

    const updateContactsAndNumber = () => {
      if (page.value === 1) {
        getNumber()
        getContacts()
      } else {
        page.value = 1
        getNumber()
      }
    }

    const deleteCard = async (profileIf) => {
      await deleteContact(profileIf)
      await getContacts()
      await getNumber()
    }

    const {contactsLoader, number, contacts, getNumber, getContacts, deleteContact} = useContacts()

    onMounted(() => {
      getNumber()
      getContacts()
    })

    return {
      header,
      deleteCard,
      contactsLoader,
      number,
      contacts,
      updateContactsAndNumber,
      getContacts,
      page,
      numbers,
      search,
      how
    }
  }
}
</script>

<style scoped>

</style>