// noinspection JSCheckFunctionSignatures

import axios from "axios";
import {ref} from 'vue';
import useTokens from "../../useTokens";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

export default function useImages() {
    const store = useStore()
    const route = useRoute()
    const imagesLoader = ref(false)
    const imagesException = ref('')
    const letterLogo = ref(null)
    const thumbnailLetterLogo = ref(null)
    const letterHeader = ref(null)
    const thumbnailLetterHeader = ref(null)
    const lettersImagesCode = ref(null)
    const listLettersImages = ref({
        number: 0,
        lettersImagesMini: []
    })
    const productImage = ref(null)
    const productImageList = ref({
        productImagesMini: []
    })
    const paymentsDocumentsMini = ref({
        base64PassportMain: null,
        base64PassportRegistration: null,
        base64Inn: null,
        base64Grn: null,
        base64AccessionAgreement: null,
        base64Photo: null
    })
    const paymentDocument = ref({
        id: null,
        dateTime: null,
        check: false,
        base64Image: null
    })

    const updateSettingsAvatar = async (settingAvatar) => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.put(
            `https://src.utp1.com/images/settings/avatar`,
            settingAvatar,
            {headers: header}
        )
            .then(async () => {
                await getSettingsAvatar()
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await updateSettingsAvatar(settingAvatar)
                } else if (e.response.status === 413) {
                    imagesException.value = "Invalid file length"
                } else try {
                    imagesException.value = e.response.data.error
                } catch {
                    imagesException.value = "Exception"
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const deleteSettingsAvatar = async () => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.delete(
            `https://src.utp1.com/images/settings/avatar`,
            {headers: header}
        )
            .then(() => {
                store.commit("avatar/deleteAvatar")
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await deleteSettingsAvatar()
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const getSettingsAvatar = async () => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.get(
            `https://src.utp1.com/images/settings/avatar`,
            {headers: header}
        )
            .then(response => {
                store.commit("avatar/setAvatar", response.data)
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await getSettingsAvatar()
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const updateLettersLogo = async (lettersLogo) => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.put(
            `https://src.utp1.com/images/mailing/logo`,
            lettersLogo,
            {headers: header}
        )
            .then(async () => {
                await getLettersLogo()
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await updateLettersLogo(lettersLogo)
                } else if (e.response.status === 413) {
                    imagesException.value = "Invalid file length"
                } else try {
                    imagesException.value = e.response.data.error
                } catch {
                    imagesException.value = "Exception"
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const deleteLettersLogo = async () => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.delete(
            `https://src.utp1.com/images/mailing/logo`,
            {headers: header}
        )
            .then(() => {
                thumbnailLetterLogo.value = null
                letterLogo.value = null
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await deleteLettersLogo()
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const getLettersLogo = async () => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.get(
            `https://src.utp1.com/images/mailing/logo`,
            {headers: header}
        )
            .then(response => {
                letterLogo.value = response.data
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await getLettersLogo()
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const updateLettersHeader = async (lettersHeader) => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.put(
            `https://src.utp1.com/images/mailing/header`,
            lettersHeader,
            {headers: header}
        )
            .then(async () => {
                await getLettersHeader()
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await updateLettersHeader(lettersHeader)
                } else if (e.response.status === 413) {
                    imagesException.value = "Invalid file length"
                } else try {
                    imagesException.value = e.response.data.error
                } catch {
                    imagesException.value = "Exception"
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const deleteLettersHeader = async () => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.delete(
            `https://src.utp1.com/images/mailing/header`,
            {headers: header}
        )
            .then(() => {
                thumbnailLetterHeader.value = null
                letterHeader.value = null
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await deleteLettersHeader()
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const getLettersHeader = async () => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.get(
            `https://src.utp1.com/images/mailing/header`,
            {headers: header}
        )
            .then(response => {
                letterHeader.value = response.data
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await getLettersHeader()
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const addLettersImages = async (mailingImage) => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.post(
            `https://src.utp1.com/images/mailing`,
            mailingImage,
            {headers: header, params: route.query}
        )
            .then(response => {
                lettersImagesCode.value = response.data
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await addLettersImages(mailingImage)
                } else if (e.response.status === 413) {
                    imagesException.value = "Invalid file length"
                } else try {
                    imagesException.value = e.response.data.error
                } catch {
                    imagesException.value = "Exception"
                }
            })
            .finally(() => {
                imagesLoader.value = false
            })
    }

    const getLettersImages = async () => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.get(
            `https://src.utp1.com/images/mailing`,
            {headers: header, params: route.query}
        )
            .then(response => {
                listLettersImages.value = response.data
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await getLettersImages()
                }
            })
            .finally(() => {
                imagesLoader.value = false
            })
    }

    const deleteLettersImages = async (letterImageId) => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.delete(
            `https://src.utp1.com/images/mailing/` + letterImageId,
            {headers: header}
        )
            .then(getLettersImages)
            .catch(e => {
                if (e.response.status === 401) {
                    updateTokens()
                    deleteLettersImages(letterImageId)
                }
            })
            .finally(() => {
                imagesLoader.value = false
            })
    }

    const updateProductImage = async (productImage, id) => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.put(
            `https://src.utp1.com/images/shop/products/` + id,
            productImage,
            {headers: header}
        )
            .then(async () => {
                await getProductImageMini(id)
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await updateProductImage(productImage, id)
                } else if (e.response.status === 413) {
                    imagesException.value = "Invalid file length"
                } else try {
                    imagesException.value = e.response.data.error
                } catch {
                    imagesException.value = "Exception"
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const getProductImageMini = async (id) => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.get(
            `https://src.utp1.com/images/shop/products/` + id + `/mini`,
            {headers: header}
        )
            .then(response => {
                productImage.value = response.data
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await getProductImageMini(id)
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const getProductImagesMini = async (ids) => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.get(
            `https://src.utp1.com/images/shop/products/mini?ids=` + ids,
            {headers: header}
        )
            .then(response => {
                productImageList.value = response.data
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await getProductImagesMini(ids)
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const deleteProductImage = async (id) => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.delete(
            `https://src.utp1.com/images/shop/products/` + id,
            {headers: header}
        )
            .then(_ => {
                productImage.value = null
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await deleteProductImage(id)
                }
            })
            .finally(() => imagesLoader.value = false)
    }

    const getPaymentsDocument = async (id, type) => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.get(
            `https://src.utp1.com/images/payments/` + id + `/` + type,
            {headers: header}
        ).then(response => {
            paymentDocument.value = response.data
        }).catch(async e => {
            if (e.response.status === 401) {
                await updateTokens()
                await getPaymentsDocument(id, type)
            } else {
                imagesException.value = e.response.data.error ? e.response.data.error : "Exception"
            }
        }).finally(() => imagesLoader.value = false)
    }

    const addPaymentsDocument = async (productImage, id, type) => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.post(
            `https://src.utp1.com/images/payments/` + id + `/` + type,
            productImage,
            {headers: header}
        ).then(async () => {
            await getPaymentsDocumentsMini(id)
        }).catch(async e => {
            if (e.response.status === 401) {
                await updateTokens()
                await addPaymentsDocument(productImage, id, type)
            } else if (e.response.status === 413) {
                imagesException.value = "Invalid file length"
            } else {
                imagesException.value = e.response.data.error ? e.response.data.error : "Exception"
            }
        }).finally(() => imagesLoader.value = false)
    }

    const getPaymentsDocumentsMini = async (id) => {
        imagesException.value = null
        imagesLoader.value = true
        const header = await authHeader()
        await axios.get(
            `https://src.utp1.com/images/payments/` + id + `/mini`,
            {headers: header}
        ).then(response => {
            paymentsDocumentsMini.value = response.data
        }).catch(async e => {
            if (e.response.status === 401) {
                await updateTokens()
                await getPaymentsDocumentsMini(id)
            } else {
                imagesException.value = e.response.data.error ? e.response.data.error : "Exception"
            }
        }).finally(() => imagesLoader.value = false)
    }

    const {authHeader, updateTokens} = useTokens()

    return {
        imagesLoader,
        imagesException,
        letterLogo,
        thumbnailLetterLogo,
        letterHeader,
        thumbnailLetterHeader,
        lettersImagesCode,
        listLettersImages,
        productImage,
        productImageList,
        paymentsDocumentsMini,
        paymentDocument,
        updateSettingsAvatar,
        deleteSettingsAvatar,
        getSettingsAvatar,
        updateLettersLogo,
        deleteLettersLogo,
        getLettersLogo,
        updateLettersHeader,
        deleteLettersHeader,
        getLettersHeader,
        addLettersImages,
        getLettersImages,
        deleteLettersImages,
        updateProductImage,
        getProductImageMini,
        getProductImagesMini,
        deleteProductImage,
        getPaymentsDocument,
        addPaymentsDocument,
        getPaymentsDocumentsMini
    }

}
