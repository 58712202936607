<template>
  <div class="accordion-off">
    <!--suppress JSUnresolvedReference -->
    <div v-for="item in allProducts.productsList" class="accordion-item">
      <a class="accordion-button collapsed text-decoration-none" data-bs-toggle="collapse"
         :data-bs-target="'#panelsCategory' + (item.category ? item.category.id : 0) + uniqueCode"
         :aria-controls="'panelsCategory' + (item.category ? item.category.id : 0) + uniqueCode">
        {{ item.category ? item.category.name : 'Без категории' }}
      </a>
      <div :id="'panelsCategory' + (item.category ? item.category.id : 0) + uniqueCode"
           class="accordion-collapse collapse"
           :aria-labelledby="'panelsCategory' + item.id + uniqueCode">
        <div class="accordion-body">
          <div v-for="i in item.products">
            <div v-if="mainProduct" class="form-check">
              <!--suppress JSCheckFunctionSignatures -->
              <input class="form-check-input" type="radio" :id="'checkGroup' + i.id + uniqueCode"
                     :value="i.id" v-model="localProduct">
              <label class="form-check-label" :for="'checkGroup' + i.id + uniqueCode">
                {{ i.name }}
              </label>
            </div>
            <div v-else-if="!i.autoCost" class="form-check">
              <!--suppress JSCheckFunctionSignatures -->
              <input class="form-check-input" type="radio" :id="'checkGroup' + i.id + uniqueCode"
                     :value="i.id" v-model="localProduct">
              <label class="form-check-label" :for="'checkGroup' + i.id + uniqueCode">
                {{ i.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref, watch} from "vue";

export default {
  name: "products-accordion-radio",
  props: {
    allProducts: {},
    product: {},
    mainProduct: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.localProduct = this.product
  },
  watch: {
    product() {
      this.localProduct = this.product
    }
  },
  emits: ['update:product'],
  setup(props, {emit}) {
    const localProduct = ref()
    watch(localProduct, (newVal) => {
      emit('update:product', newVal)
    })

    const codeGenerate = () => {
      // noinspection SpellCheckingInspection
      const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
      let str = ''
      for (let i = 0; i < 3; i++) {
        const pos = Math.floor(Math.random() * chars.length)
        str += chars.substring(pos, pos + 1)
      }
      return str
    }

    const uniqueCode = ref("")

    onMounted(async () => {
      uniqueCode.value = codeGenerate()
    })

    return {uniqueCode, localProduct}
  }
}
</script>

<style scoped>
.accordion-button {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.form-check {
  margin-bottom: 0 !important;
}

.accordion-off {
  --bs-accordion-color: 0 !important;
  --bs-accordion-bg: 0 !important;
  --bs-accordion-transition: 0 !important;
  --bs-accordion-border-color: 0 !important;
  --bs-accordion-border-width: 0 !important;
  --bs-accordion-border-radius: 0 !important;
  --bs-accordion-inner-border-radius: 0 !important;
  --bs-accordion-btn-padding-x: 0 !important;
  --bs-accordion-btn-padding-y: 0 !important;
  --bs-accordion-btn-color: 0 !important;
  --bs-accordion-btn-bg: 0 !important;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: 0 !important;
  --bs-accordion-btn-focus-box-shadow: 0 !important;
  --bs-accordion-body-padding-x: 0 !important;
  --bs-accordion-body-padding-y: 0 !important;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: 0 !important;
}

</style>
