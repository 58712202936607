export const avatar = {
    state: () => ({
        avatar: null
    }),
    mutations: {
        setAvatar(state, avatar) {
            state.avatar = avatar
        },
        deleteAvatar(state) {
            state.avatar = null
        }
    },
    actions: {},
    namespaced: true
}
