<template>
  <header class="pt-1 pb-2 mb-4 border-bottom clearfix">
    <div class="float-start pe-3">
      <button type="button" class="btn btn-outline-primary rounded-circle" @click="routerBack">
        <i class="bi bi-chevron-compact-left mx--125"></i>
      </button>
    </div>
    <div class="float-start">
      <!--suppress JSUnresolvedReference -->
      <h1 class="h3 fw-light">
        {{ header.title }}
      </h1>
    </div>
    <div class="float-end">
      <div class="dropdown">
        <!--suppress JSUnresolvedReference -->
        <button class="btn btn-primary" data-bs-toggle="dropdown"
                :class="button.dropdownMenu.length !== 0 ? 'dropdown-toggle' : ''"
                :disabled="button.dropdownMenu.length === 0">{{ button.title }}
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <!--suppress JSUnresolvedReference -->
          <li v-for="item in button.dropdownMenu">
            <div v-if="item.dropdownDelete">
              <a class="dropdown-item" @click="deleteCard">
                {{ item.dropdownName }}
              </a>
            </div>
            <div v-else-if="item.dropdownHr">
              <hr class="dropdown-divider">
            </div>
            <div v-else>
              <a class="dropdown-item"
                 @click="item.dropdownEmit? dropdownEmit(item.dropdownEmit) :
                item.dropdownPush ? $router.push(item.dropdownPush) : ''">
                {{ item.dropdownName }}
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";
import {useRouter} from "vue-router";
import DeleteObject from "@/components/UI/modals/DeleteObject.vue";
import UpdateInvoiceModal from "@/components/UI/modals/UpdateInvoiceModal.vue";

export default {
  name: "header-back-button-settings-utp1",
  components: {UpdateInvoiceModal, DeleteObject, ButtonPrimaryUtp1},
  props: {
    header: {
      type: Object,
      default: null
    },
    status: {
      type: String,
      default: null
    },
    button: {
      type: Object,
      default: {
        title: '',
        dropdownMenu: []
      }
    }
  },
  setup(props, {emit}) {
    const router = useRouter()

    const routerBack = () => {
      if (router.options.history.state.back) {
        router.go(-1)
      } else router.push(props.header.buttonRedirect)
    }

    const deleteCard = () => {
      emit("deleteCard")
    }
    const dropdownEmit = (dropdownEmit) => {
      emit(dropdownEmit)
    }

    return {deleteCard, dropdownEmit, routerBack}
  }

}
</script>

<style scoped>
.mx--125 {
  margin-right: -0.125rem !important;
  margin-left: -0.125rem !important;
}
</style>