<template>
  <main>
    <preloader-main v-if="groupsLoader"/>
    <header-back-utp1
        :header="header"
    />
    <group
        v-model:group="group"
        :groups-categories="group.categories"
        :button="button"
        @buttonClick="updateGroup"
        @buttonDeleteClick="deleteGroup"
    />
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import HeaderBackUtp1 from "../../../components/UI/headers/HeaderBackUtp1";
import {onMounted} from "vue";
import useGroups from "../../../hooks/rest/mailing/contacts/useGroups";
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import router from "../../../router";
import Group from "../../../components/pages/mailing/contacts/Group";
import {useRoute} from "vue-router";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";

export default {
  name: "edit-groups",
  components: {SidebarRightUtp1, Group, PreloaderMain, HeaderBackUtp1},
  setup() {
    const route = useRoute()

    const header = {
      title: "Изменить группу контактов",
      buttonRedirect: "/mailing/contacts/groups"
    }
    const button = {
      buttonTitle: "Изменить",
      buttonDelete: true
    }

    const groupId = route.params.id

    const updateGroup = () => {
      updateGroups(groupId)
    }

    const deleteGroup = async () => {
      await deleteGroups(groupId)
      await router.push('/mailing/contacts/groups')
    }

    const {groupsLoader, group, getGroup, updateGroups, deleteGroups} = useGroups()

    onMounted(() => {
      getGroup(groupId)
    })

    return {groupsLoader, header, group, button, updateGroup, deleteGroup}
  }
}
</script>

<style scoped>

</style>
