<template>
  <div class="modal fade" id="updateInvoiceModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="
          action === 'PREPAYMENT' ? 'bg-green-dark' :
          action === 'PAYMENT' ? 'bg-green' :
          action === 'CANCEL' ? 'bg-danger' :
          action === 'RECOVERY' ? 'bg-purple' :
          action === 'RETURN' ? 'bg-black' : ''">
          <h5 v-if="action === 'PREPAYMENT'" class="modal-title text-white">Внесение задатка. Продолжить?</h5>
          <h5 v-else-if="action === 'PAYMENT'" class="modal-title text-white">Оплата счета. Продолжить?</h5>
          <h5 v-else-if="action === 'CANCEL'" class="modal-title text-white">Отмена заказа. Продолжить?</h5>
          <h5 v-else-if="action === 'RECOVERY'" class="modal-title text-white">Восстановление заказа. Продолжить?</h5>
          <h5 v-else-if="action === 'RETURN'" class="modal-title text-white">Возврат по заказу. Продолжить?</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
        </div>

        <form class="modal-body" id="formUpdateInvoiceModal" @submit.prevent novalidate>
          <div v-if="action === 'PREPAYMENT'" class="pb-3">
            <label class="form-label" for="localCost">Размер задатка:</label>
            <input-utp1
                id="localCost"
                v-model:model-value="localCost"
                placeholder="Введите сумму"
                type="number"
                step="0.01"
                required
                min="0.01"
                :max="maxCost ? maxCost - 0.01 : 0.00"
                autocomplete="off"
            />
          </div>
          <div v-if="action === 'RETURN'" class="pb-3">
            <label class="form-label" for="localCost">Сумма возврата:</label>
            <input-utp1
                id="localCost"
                v-model:model-value="localCost"
                placeholder="Введите сумму"
                type="number"
                step="0.01"
                required
                min="0.01"
                :max="maxReturnCost"
                autocomplete="off"
            />
          </div>
          <div>
            <label class="form-label" for="localComment">Комментарий:</label>
            <textarea
                id="localComment"
                class="form-control"
                v-model="localComment"
                placeholder="Введите текст"
                type="text"
                autocomplete="off"
            ></textarea>
          </div>
          <label v-if="action === 'PAYMENT'" class="form-text pt-2">
            Нажимая «Подтвердить» Вы соглашаетесь с тем, что клиенту будет
            выслан Продукт, а партнёрам начислены комиссионные!
          </label>
          <label v-else-if="action === 'RETURN'" class="form-text pt-2">
            Нажимая «Подтвердить» Вы соглашаетесь с тем, что данное
            действие не может быть отменено и счет будет заблокирован!
          </label>
          <label v-else class="form-text pt-2">
            Нажимая «Подтвердить» Вы соглашаетесь с тем, что информация
            о совершенном Вами действии может быть доступна клиенту!
          </label>
        </form>
        <div class="modal-footer">
          <button-secondary-utp1
              class="col col-sm-auto"
              :disabled="false"
              data-bs-dismiss="modal"
              @buttonClick="buttonSecondaryClick"
          >Отменить
          </button-secondary-utp1>
          <button-primary-utp1
              :class="action === 'PREPAYMENT' ? (localCost? 'col col-sm-auto' : 'col col-sm-auto disabled') : 'col col-sm-auto'"
              :disabled="action === 'PREPAYMENT' ? !localCost : false"
              @buttonClick="updateInvoice"
          >Подтвердить
          </button-primary-utp1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import ButtonSecondaryUtp1 from "../buttons/ButtonSecondaryUtp1";
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";
import InputUtp1 from "@/components/UI/inputs/InputUtp1.vue";

export default {
  name: "update-invoice-modal",
  components: {InputUtp1, ButtonPrimaryUtp1, ButtonSecondaryUtp1},
  props: {
    id: {
      type: Number,
      default: 0
    },
    action: {
      type: String
    },
    maxCost: {
      type: Number,
      default: 0
    },
    maxReturnCost: {
      type: Number,
      default: 0
    }
  },
  watch: {
    cost() {
      this.localCost = this.cost
    },
    comment() {
      this.localComment = this.comment
    }
  },
  emits: ['updateInvoice', 'update:cost', 'update:comment'],
  setup(props, {emit}) {
    const localCost = ref()
    const localComment = ref()

    const updateInvoiceData = ref({
      action: "",
      cost: null,
      comment: null
    })

    const updateInvoice = () => {
      const form = document.getElementById('formUpdateInvoiceModal')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        updateInvoiceData.value.action = props.action
        updateInvoiceData.value.cost = localCost.value
        updateInvoiceData.value.comment = localComment.value
        emit('updateInvoice', props.id, updateInvoiceData.value)
        localCost.value = null
        localComment.value = null
        form.classList.remove("was-validated")
      }
    }

    const buttonSecondaryClick = () => {
      const form = document.getElementById('formUpdateInvoiceModal')
      localCost.value = null
      localComment.value = null
      form.classList.remove("was-validated")
    }

    return {updateInvoice, buttonSecondaryClick, localCost, localComment}
  }
}
</script>

<style scoped>

</style>