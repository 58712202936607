<!--suppress JSCheckFunctionSignatures, JSUnresolvedReference -->
<template>
  <main>
    <preloader-main v-if="loaderShopSettings"/>
    <header-button-utp1
        :header="header"
        @buttonClick="$router.push('/shop/settings/add')"
    />
    <div v-if="errorMessage">
      <alert-danger>{{ errorMessage }}</alert-danger>
    </div>

    <div v-for="item in utpPays.utpPays">
      <div class="col px-0">
        <card-utp1
            :card-id="item.id"
            :dropdown-card-menu="item.default ? cardHeaderDefault : item.accessDelete ? cardHeaderDelete : cardHeader"
            @default="updateDefaultUtpPay(item.id)"
            @deleteCard="deleteCardModal(item.id)">

          <template v-slot:cardHeader>
            <div class="text-truncate">
              <a class="card-link text-decoration-none fw-semibold"
                 @click="$router.push('/shop/settings/' + item.id)">
              <span v-if="item.default" class="pe-1" title="По-умолчанию">
            <i class="bi bi-check-lg"></i>
          </span>
                {{ item.name ? item.name : "Без имени" }}
              </a>
            </div>
          </template>

          <div class="row">
            <div class="col p-0">
            <span v-if="item.utpPayStatus !== null" class="text-decoration-none text-dark pe-3">
              <i class="icon-utp-com"></i>
            </span>
              <span v-else class="text-decoration-none pe-3">
            <i class="icon-utp-com"></i>
          </span>
              <span v-if="item.yooMoney" class="text-decoration-none text-dark pe-3">
              <i class="icon-yoo-money"></i>
            </span>
              <span v-else class="text-decoration-none pe-3">
            <i class="icon-yoo-money"></i>
          </span>
            </div>
            <div class="col-auto p-0">
              <div v-if="item.default" class="text-truncate">По-умолчанию</div>
              <div v-else class="text-truncate">
                <a class="card-link text-decoration-none" @click="updateDefaultUtpPay(item.id)">
                  Назначить по-умолчанию</a>
              </div>
            </div>
          </div>

          <template v-slot:cardFooter>
            <div class="row-form">
              <div class="row">
                <div class="col-sm-auto col-12 text-info">
                  <i class="bi bi-card-checklist"></i>
                  Транзакций: {{ item.transactionNumber.toLocaleString() }} ({{
                    transactionNumber === 0 ? 0 : (item.transactionNumber * 100 / transactionNumber).toFixed(2).replace('.00', '')
                  }}%)
                </div>
                <div class="col-sm-auto d-none d-sm-block">|</div>
                <div class="col-sm-auto col-12 text-success">
                  <i class="bi bi-credit-card-2-front"></i>
                  Оборот: {{ item.transactionSum.toLocaleString() }} ₽ ({{
                    transactionSum === 0 ? 0 : (item.transactionSum * 100 / transactionSum).toFixed(2).replace('.00', '')
                  }}%)
                </div>
              </div>
            </div>
          </template>
        </card-utp1>
      </div>
    </div>

  </main>
  <sidebar-right-utp1>

  </sidebar-right-utp1>
  <delete-object
      :id="deleteCardId"
      :confirmation=false
      @deleteObject="deleteCard"
  />
</template>

<script>
import {onMounted, ref, watch} from "vue";
import PreloaderMain from "@/components/system/preloader/PreloaderMain.vue";
import HeaderButtonUtp1 from "@/components/UI/headers/HeaderButtonUtp1.vue";
import SidebarRightUtp1 from "@/components/system/SidebarRightUtp1.vue";
import useShopSettings from "@/hooks/rest/shop/useShopSettings";
import DeleteObject from "@/components/UI/modals/DeleteObject.vue";
import AlertDanger from "@/components/UI/alerts/AlertDanger.vue";
import useError from "@/hooks/error/useError";
import CardUtp1 from "@/components/UI/cards/CardUtp1.vue";

export default {
  name: "shop-settings",
  components: {CardUtp1, AlertDanger, DeleteObject, SidebarRightUtp1, HeaderButtonUtp1, PreloaderMain},
  setup() {
    const header = {
      title: "Магазины",
      buttonTitle: "Добавить магазин"
    }

    const cardHeader = [{
        dropdownName: "Открыть",
        dropdownPush: "/shop/settings/",
        dropdownHr: false,
        dropdownDelete: false
      }, {
        dropdownName: "По-умолчанию",
        dropdownEmit: "default",
        dropdownHr: false,
        dropdownDelete: false
      }]
    const cardHeaderDefault = [{
        dropdownName: "Открыть",
        dropdownPush: "/shop/settings/",
        dropdownHr: false,
        dropdownDelete: false
      }]
    const cardHeaderDelete = [{
        dropdownName: "Открыть",
        dropdownPush: "/shop/settings/",
        dropdownHr: false,
        dropdownDelete: false
      }, {
        dropdownName: "По-умолчанию",
        dropdownEmit: "default",
        dropdownHr: false,
        dropdownDelete: false
      }, {
        dropdownName: "",
        dropdownPush: "",
        dropdownHr: true,
        dropdownDelete: false
      }, {
        dropdownName: "Удалить",
        dropdownPush: "",
        dropdownHr: false,
        dropdownDelete: true
      }]

    const {
      loaderShopSettings,
      exceptionShopSettings,
      utpPays,
      getUtpPays,
      updateDefaultUtpPay,
      deleteUtpPay
    } = useShopSettings()

    const {
      error,
      errorMessage
    } = useError(exceptionShopSettings)

    const deleteCardId = ref(0)
    const deleteObjectModal = ref()
    const deleteCardModal = (profileId) => {
      deleteCardId.value = profileId
      deleteObjectModal.value.show()
    }

    const transactionNumber = ref(0)
    const transactionSum = ref(0)

    onMounted(async () => {
      await getUtpPays()
      for (let i = 0; i < utpPays.value.utpPays.length; i++) {
        transactionNumber.value += utpPays.value.utpPays[i].transactionNumber;
        transactionSum.value += utpPays.value.utpPays[i].transactionSum
      }
      deleteObjectModal.value = new bootstrap.Modal('#deleteObjectModal', {})
    })

    watch(exceptionShopSettings, () => {
      error(exceptionShopSettings.value)
    })

    const deleteCard = (cardId) => {
      const index = utpPays.value.utpPays.findIndex(element => element.id === cardId)
      if (index !== -1) utpPays.value.utpPays.splice(index, 1)
      deleteUtpPay(cardId)
    }

    return {
      header,
      errorMessage,
      loaderShopSettings,
      utpPays,
      cardHeader,
      cardHeaderDefault,
      cardHeaderDelete,
      deleteCardModal,
      deleteCardId,
      deleteCard,
      updateDefaultUtpPay,
      transactionNumber,
      transactionSum
    }
  }
}
</script>

<style scoped>

</style>
