<template>
  <main>
    <preloader-main v-if="mailingSettingsLoader"/>
    <header-tabs-utp1
        :header-nav="headerNav"
    />

    <div class="tab-content">

      <div class="tab-pane active" id="email">

        <header-nav-utp1 class="pt-4_5" :nav="emailNav"/>
        <div class="tab-content">
          <div class="tab-pane active" id="email-setting">
            <email-setting
                v-model:email-setting="mailingSettings.emailSetting"
            />
          </div>
          <div class="tab-pane" id="letter-settings">
            <letter-setting
                :letter-settings="mailingSettings.letterSettings"
            />
          </div>
          <div class="tab-pane" id="email-binding">
            <email-binding
                :error-message="errorMessage"
                :emailBindingSettings="fullEmailBinding"
                :email="email"
                :email-binding="emailBinding"
                @getEmailBindingConfigured="getEmailBindingConfigured"
                @addEmailBinding="addEmailBinding"
                @getNewEmailBindingDefault="getNewEmailBindingDefault"
                @updateEmailBinding="updateEmailBinding"
                @reAddEmailBinding="reAddEmailBinding"
                @deleteEmailBinding="deleteEmailBinding"
                @updateEmailBindings="updateEmailBindings"
                @cancelEmailBindings="cancelEmailBindings"
            />
          </div>
        </div>

      </div>

      <div class="tab-pane" id="social">

        <header-nav-utp1 class="pt-4_5" :nav="socialNav"/>
        <div class="tab-content">
          <div class="tab-pane active" id="vk-setting">
            <vk-binding
                :vk-setting="mailingSettings.vkSetting"
            />
          </div>
          <div class="tab-pane" id="telegram-settings">

          </div>
        </div>

      </div>

      <div class="tab-pane" id="smsPush">
        SMS / PUSH
      </div>

    </div>


  </main>
  <sidebar-right-utp1/>
</template>

<script>
import HeaderTabsUtp1 from "../../../components/UI/headers/HeaderTabsUtp1";
import HeaderNavUtp1 from "../../../components/UI/headers/HeaderNavUtp1";
import useMailingSettings from "../../../hooks/rest/mailing/useMailingSettings";
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import {onMounted, watch} from "vue";
import EmailSetting from "../../../components/pages/mailing/settings/EmailSetting";
import LetterSetting from "../../../components/pages/mailing/settings/LetterSetting";
import EmailBinding from "../../../components/pages/mailing/settings/EmailBinding";
import useError from "../../../hooks/error/useError";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";
import VkBinding from "../../../components/pages/mailing/settings/VkBinding";

export default {
  name: "mailing-email-settings",
  components: {
    VkBinding,
    SidebarRightUtp1,
    EmailBinding, LetterSetting, EmailSetting, PreloaderMain, HeaderNavUtp1, HeaderTabsUtp1
  },
  setup() {
    const headerNav = [{
      value: "email",
      name: "E-mail",
      active: true,
      disabled: false
    },
      {
        value: "social",
        name: "Социальные сети",
        active: false,
        disabled: false
      },
      {
        value: "smsPush",
        name: "SMS / PUSH",
        active: false,
        disabled: true
      }]
    const emailNav = [{
      value: "email-setting",
      name: "Основные настройки",
      active: true,
      disabled: false
    }, {
      value: "letter-settings",
      name: "Оформление",
      active: false,
      disabled: false
    }, {
      value: "email-binding",
      name: "Привязать e-mail для отправки",
      active: false,
      disabled: false
    }]
    const socialNav = [{
      value: "vk-setting",
      name: "ВКонтакте",
      active: true,
      disabled: false
    }, {
      value: "telegram-settings",
      name: "Telegram",
      active: false,
      disabled: true
    }]

    const {
      mailingSettingsLoader,
      mailingSettingsException,
      mailingSettings,
      fullEmailBinding,
      email,
      emailBinding,
      getMailingSettings,
      getEmailBindingConfigured,
      addEmailBinding,
      getNewEmailBindingDefault,
      updateEmailBinding,
      reAddEmailBinding,
      deleteEmailBinding,
      updateEmailBindings,
      cancelEmailBindings,
    } = useMailingSettings()
    const {error, errorMessage} = useError()

    watch(mailingSettingsException, () => {
      error(mailingSettingsException.value)
    })

    onMounted(() => {
      getMailingSettings()
      getEmailBindingConfigured()
    })

    return {
      headerNav,
      emailNav,
      socialNav,
      mailingSettingsLoader,
      mailingSettings,
      fullEmailBinding,
      email,
      emailBinding,
      getEmailBindingConfigured,
      addEmailBinding,
      getNewEmailBindingDefault,
      updateEmailBinding,
      reAddEmailBinding,
      deleteEmailBinding,
      updateEmailBindings,
      cancelEmailBindings,
      errorMessage
    }
  }
}
</script>

<style scoped>
.pt-4_5 {
  padding-top: 2.5rem
}
</style>