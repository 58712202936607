<template>

  <label class="form-label">Иконки социальных сетей</label>

  <draggable
      v-model="localSocialButton"
      direction="vertical"
      animation="150"
      item-key="draggable"
      handle=".draggable"
      @change="emitSocialButton"
  >
    <!--suppress VueUnrecognizedSlot -->
    <template #item="{element}">
      <div class="d-flex draggable-card-utp1">
        <div class="draggable-card">
          <div class="input-group mb-2">
            <div v-if="element.socialNetwork === 'VK'" class="input-group-text bg-social-button-input border-0">
              <i class="icon-vk-icon text-secondary ps-1 fs-3"/></div>
            <div v-else-if="element.socialNetwork === 'TELEGRAM'" class="input-group-text bg-social-button-input border-0">
              <i class="icon-telegram-icon icon-telegram-plane text-secondary ps-1 fs-3"/></div>
            <div v-else-if="element.socialNetwork === 'YOUTUBE'" class="input-group-text bg-social-button-input border-0">
              <i class="bi bi-youtube text-secondary ps-1 fs-3"/></div>
            <div v-else-if="element.socialNetwork === 'INSTAGRAM'" class="input-group-text bg-social-button-input border-0">
              <i class="bi bi-instagram text-secondary ps-1 fs-3"/></div>
            <div v-else-if="element.socialNetwork === 'FACEBOOK'" class="input-group-text bg-social-button-input border-0">
              <i class="icon-facebook-icon text-secondary ps-1 fs-4"/></div>
            <div v-else-if="element.socialNetwork === 'TWITTER'" class="input-group-text bg-social-button-input border-0">
              <i class="bi bi-twitter-x text-secondary ps-1 fs-4"/></div>
            <div v-else-if="element.socialNetwork === 'SITE'" class="input-group-text bg-social-button-input border-0">
              <i class="bi bi-globe text-secondary ps-1 fs-4"/></div>
            <div v-else class="input-group-text bg-social-button-input border-0">
              <i class="bi bi-globe text-secondary ps-1 fs-4"/></div>

            <div class="form-control bg-social-button-input border-0">
              <input :value="element.url" type="text" class="form-control bg-white" placeholder="URL" disabled>
            </div>

            <a id="dropdownCardMenu" class="input-group-text bg-social-button-input border-0" data-bs-toggle="dropdown">
              <i class="bi bi-three-dots fs-4"/>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item" @click="updateSocialButton(element.priority)">
                    Изменить
                  </a>
                  <hr class="dropdown-divider">
                  <a class="dropdown-item" @click="deleteSocialButton(element.priority)">
                    Удалить
                  </a>
                </li>
              </ul>
            </a>
          </div>
        </div>
        <div class="draggable pb-2">
          <i class="bi bi-grip-vertical"></i>
        </div>
      </div>
    </template>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'
import {ref} from "vue";

export default {
  name: "social-buttons-input",
  components: {
    draggable
  },
  props: {
    socialButtons: {
      type: Array,
      default: []
    }
  },
  mounted() {
    this.initSocialButton()
  },
  emits: ['update:socialButtons', 'updateSocialButton', 'deleteSocialButton'],
  setup(props, {emit}) {
    const localSocialButton = ref([])
    const initSocialButton = () => {
      localSocialButton.value = props.socialButtons
    }
    const emitSocialButton = () => {
      emit('update:socialButtons', localSocialButton.value.map((item, index) => {
        return {...item, priority: index}
      }))
    }
    const updateSocialButton = (id) => {
      emit('updateSocialButton', id)
    }
    const deleteSocialButton = (id) => {
      emit('deleteSocialButton', id)
    }
    return {updateSocialButton, deleteSocialButton, localSocialButton, initSocialButton, emitSocialButton}
  }
}
</script>

<style scoped>
.input-group-text {
  width: 3rem;
}

.bg-social-button-input {
  background-color: #e9ecef !important;
}

</style>
