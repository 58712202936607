<template>
  <div class="h-100 w-100 top-0 start-0 spinner-z-index position-absolute">
    <div class="h-100 w-100 bg-white opacity-75"/>
    <div class="position-fixed spinner-position">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "preloader-main"
}
</script>

<style scoped>

</style>