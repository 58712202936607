<template>
  <preloader-page/>
</template>

<script>
import PreloaderPage from "../../components/system/preloader/PreloaderPage";
import {useRoute} from "vue-router";
import {onMounted} from "vue";

export default {
  name: "external-auth",
  components: {PreloaderPage},
  setup() {
    const route = useRoute()

    const code = route.query.code ? route.query.code : ""
    const state = route.query.state ? route.query.state : ""

    onMounted(() => {
      if (state === 'user') {
        window.opener.authVk(code)
      } else if (state === 'groups') {
        window.opener.groupVk(code)
      } else {
        window.opener.closePopup()
      }
    })
  }
}
</script>

<style scoped>

</style>