<template>
  <main>
    <preloader-main v-if="groupsLoader"/>
    <header-button-utp1
        :header="header"
        @buttonClick="$router.push('/mailing/contacts/groups/add')"
    />

    <div v-for="item in getAllGroups">
      <!--suppress JSCheckFunctionSignatures -->
      <card-utp1
          :card-id="item.id"
          :dropdown-card-menu="cardHeader"
          @deleteCard="deleteCard(item.id)"
      >
        <template v-slot:cardHeader>
          <div class="text-truncate">
            <a class="card-link text-decoration-none fw-semibold"
               @click="$router.push('/mailing/contacts/groups/' + item.id)">
              {{ item.name }}
            </a>
          </div>
        </template>

        Контактов:&nbsp;<a class="card-link text-decoration-none">0</a>
        <div class="vr ms-2 me-1"></div>
        Подписчиков:&nbsp;<a class="card-link text-decoration-none">0</a>

        <template v-slot:cardFooter>
          <div v-if="item.categoryName">
            Категория: <a class="card-link text-decoration-none">{{ item.categoryName }}</a>
          </div>
          <div v-else>
            <a class="card-link text-decoration-none">Без категории</a>
          </div>
        </template>

      </card-utp1>
    </div>

  </main>
  <sidebar-right-utp1/>
</template>

<script>
import CardUtp1 from "../../../components/UI/cards/CardUtp1";
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import HeaderButtonUtp1 from "../../../components/UI/headers/HeaderButtonUtp1";
import {onMounted} from "vue";
import useGroups from "../../../hooks/rest/mailing/contacts/useGroups";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";

export default {
  name: "groups",
  components: {SidebarRightUtp1, CardUtp1, HeaderButtonUtp1, PreloaderMain},
  setup() {
    const header = {
      title: "Группы контактов",
      buttonTitle: "Добавить группу"
    }
    const cardHeader = [{
      dropdownName: "Изменить",
      dropdownPush: "/mailing/contacts/groups/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "",
      dropdownPush: "",
      dropdownHr: true,
      dropdownDelete: false
    }, {
      dropdownName: "Удалить",
      dropdownPush: "",
      dropdownHr: false,
      dropdownDelete: true
    }]

    const deleteCard = async (groupId) => {
      await deleteGroups(groupId)
      await getGroups()
    }

    const {groupsLoader, getAllGroups, getGroups, deleteGroups} = useGroups()

    onMounted(() => {
      getGroups()
    })

    return {header, cardHeader, deleteCard, groupsLoader, getAllGroups}
  }
}
</script>

<style scoped>

</style>