<!--suppress JSUnresolvedVariable -->
<template>
  <preloader-main v-if="contactsLoader"/>
  <div class="accordion pb-3" id="accordion">
    <div v-for="item in contacts" class="accordion-item">
      <a :class="contacts.length > 1 ?
      'accordion-button collapsed text-decoration-none' :
      item === contacts[0] ?
      'accordion-button text-decoration-none' :
      'accordion-button collapsed text-decoration-none'"
         data-bs-toggle="collapse"
         :data-bs-target="'#panelsContact' + item.id"
         :aria-controls="'panelsContact' + item.id">
        {{ item.type === 'VK' ? 'ВКонтакте' : item.type === 'TELEGRAM' ? 'Telegram' : item.channel }}
      </a>
      <div :class="contacts.length > 1 ?
      'accordion-collapse collapse' :
      item === contacts[0]?
      'accordion-collapse collapse show' :
      'accordion-collapse collapse'"
           data-bs-parent="#accordion"
           :id="'panelsContact' + item.id"
           :aria-labelledby="'panelsContact' + item.id">
        <div class="accordion-body">

          <groups-accordion
              v-model:groups="item.groupsId"
          />

          <div class="pt-3 text-sm-end">
            <button-primary-utp1 :disabled="false" @buttonClick="updateGroupsContact(item.id, item.groupsId)">
              Изменить
            </button-primary-utp1>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div v-if="contacts.length > 1">
    <hr-header-text :text="'Внести изменения сразу для всех каналов'"/>

    <div class="form-control mb-3 mt-2">
      <groups-accordion
          v-model:groups="allGroups"
      />
    </div>
    <div class="text-sm-end">
      <button-primary-utp1 :disabled="false" @buttonClick="updateGroupsContacts">
        Изменить
      </button-primary-utp1>
    </div>
    <p class="text-secondary pt-2">При внесении изменений происходит не только добавление в выбранные группы, но и
      удаление</p>
  </div>

</template>

<script>
import GroupsAccordion from "../../../UI/accordions/GroupsAccordion";
import {onMounted, ref} from "vue";
import ButtonPrimaryUtp1 from "../../../UI/buttons/ButtonPrimaryUtp1";
import PreloaderMain from "../../../system/preloader/PreloaderMain";
import useContacts from "../../../../hooks/rest/mailing/contacts/useContacts";
import {useRoute} from "vue-router";
import HrHeaderText from "../../../UI/universal/HrHeaderText";

export default {
  name: "contact-groups",
  components: {HrHeaderText, PreloaderMain, ButtonPrimaryUtp1, GroupsAccordion},
  emits: ['updateContact'],
  props: {
    contacts: {
      type: Array,
      default: []
    }
  },
  setup(props, {emit}) {
    const route = useRoute()
    const profileId = route.params.id

    const allGroups = ref([])

    const updateGroupsContact = (contactId, groupsId) => {
      const groupsContact = {
        contactId: contactId,
        profileId: null,
        groups: groupsId
      }
      updateContact(profileId, groupsContact)
    }

    const updateGroupsContacts = async () => {
      const groupsContact = {
        contactId: null,
        profileId: profileId,
        groups: allGroups.value
      }
      await updateContact(profileId, groupsContact)
      emit('updateContact')
    }

    onMounted(() => {
      props.contacts.forEach(item => allGroups.value.push(item))
    })

    const {contactsLoader, updateContact} = useContacts()

    return {allGroups, updateGroupsContact, updateGroupsContacts, contactsLoader}
  }
}
</script>

<style scoped>

</style>