<template>
  <form id="group" @submit.prevent novalidate>

    <div class="mb-3">
      <label class="form-label">
        Название группы
      </label>
      <!--suppress JSUnresolvedReference -->
      <input-utp1
          placeholder="Введите название группы"
          v-model:model-value="group.name"
          required
          minlength="2"
          maxlength="255"
      />
    </div>

    <div class="mb-3">
      <label class="form-label">
        Добавить категорию
      </label>
      <!--suppress JSUnresolvedReference -->
      <select class="form-select" v-model="group.categoryId">
        <option selected :value=null>Выберите...</option>
        <option v-for="item in groupsCategories" :value="item.id">{{ item.name }}</option>
      </select>
    </div>

    <!--suppress JSUnresolvedReference -->
    <div v-if="button.buttonDelete" class="row">
      <div class="col-6 col-sm-auto px-0 pe-2">
        <button-primary-utp1 :disabled="false" @buttonClick="buttonClick">
          {{ button.buttonTitle }}
        </button-primary-utp1>
      </div>
      <div class="col-6 col-sm-auto px-0 ps-2">
        <button-secondary-utp1 :disabled="false" @buttonClick="buttonDeleteClick">
          Удалить
        </button-secondary-utp1>
      </div>
    </div>
    <div v-else>
      <!--suppress JSUnresolvedReference -->
      <button-primary-utp1 :disabled="false" @buttonClick="buttonClick">
        {{ button.buttonTitle }}
      </button-primary-utp1>
    </div>

  </form>
</template>

<script>
import InputUtp1 from "../../../UI/inputs/InputUtp1";
import ButtonPrimaryUtp1 from "../../../UI/buttons/ButtonPrimaryUtp1";
import ButtonSecondaryUtp1 from "../../../UI/buttons/ButtonSecondaryUtp1";

export default {
  name: "group",
  components: {ButtonSecondaryUtp1, ButtonPrimaryUtp1, InputUtp1},
  props: {
    group: {
      type: Object,
      default: null
    },
    groupsCategories: {
      type: Array,
      default: []
    },
    button: {
      type: Object,
      default: null
    }
  },
  emits: ["buttonClick", "buttonDeleteClick"],
  setup(_, {emit}) {

    const buttonClick = () => {
      const form = document.querySelector('#group')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        emit("buttonClick")
      }
    }

    const buttonDeleteClick = () => {
      emit("buttonDeleteClick")
    }

    return {buttonClick, buttonDeleteClick}
  }
}
</script>

<style scoped>

</style>