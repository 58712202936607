<template>
  <main>
    <preloader-main v-if="loaderInvoices || loaderProducts"/>
    <!--suppress JSUnresolvedReference -->
    <header-back-button-settings-utp1
        :header="header"
        :status="getInvoice.invoice.status"
        :button="button"
        @prepayment="updateInvoiceModalShow('PREPAYMENT')"
        @payment="updateInvoiceModalShow('PAYMENT')"
        @cancel="updateInvoiceModalShow('CANCEL')"
        @recovery="updateInvoiceModalShow('RECOVERY')"
        @return="updateInvoiceModalShow('RETURN')"
        @deleteCard="deleteCardModal()"
    />
    <div v-if="invoiceErrorMessage">
      <alert-danger>{{ invoiceErrorMessage }}</alert-danger>
    </div>
    <basic-details :name="'Имя (ФИО):'">
      {{ getInvoice.invoice.name }}
    </basic-details>
    <basic-details :name="'Телефон:'">
      <a class="text-decoration-none"
         @click="$router.push('/mailing/contacts?search=' + getInvoice.invoice.phone.slice(1))">
        {{ getInvoice.invoice.phone }}
      </a>
    </basic-details>
    <basic-details :name="'E-mail:'">
      <a class="text-decoration-none"
         @click="$router.push('/mailing/contacts?search=' + getInvoice.invoice.email)">
        {{ getInvoice.invoice.email }}
      </a>
    </basic-details>
    <basic-details :name="'Сумма:'">
      <span v-if="getInvoice.invoice.cost">
        {{ getInvoice.invoice.cost.toLocaleString() }} ₽
      </span><span v-if="getInvoice.invoice.autoCost">
        + {{ getInvoice.invoice.autoCost.toLocaleString() }} ₽<i class="ps-1 bi bi-clock-history"></i>
      </span>
    </basic-details>
    <basic-details v-if="getInvoice.invoice.product" :name="'Основной продукт:'">
      <a v-if="getInvoice.invoice.productId" class="text-decoration-none"
         @click="$router.push('/shop/products/' + getInvoice.invoice.productId)">
        {{ getInvoice.invoice.product }}
      </a>
      <span v-else>{{ getInvoice.invoice.product }}</span><span
        v-if="getInvoice.products.length > 0"> - {{ getInvoice.invoice.productCost.toLocaleString() }} ₽
      <span v-if="getInvoice.invoice.autoCost">
        + {{ getInvoice.invoice.autoCost.toLocaleString() }} ₽<i class="ps-1 bi bi-clock-history"></i>
      </span></span>
      <a v-if="['WAITING', 'PREPAID'].includes(getInvoice.invoice.status)"
         class="ps-3 link-secondary bi bi-pencil-fill" @click="updateInvoiceProductsModalShow"></a>
    </basic-details>
    <basic-details v-if="getInvoice.products.length > 0"
                   :name="getInvoice.invoice.product? 'Дополнительные продукты:' : 'Продукты:'">
      <div v-for="item in getInvoice.products">
        <a v-if="item.productId" class="text-decoration-none"
           @click="$router.push('/shop/products/' + item.productId)">
          {{ item.product }}
        </a>
        <span v-else>{{ item.product }}</span><span
          v-if="getInvoice.products.length > 1 || getInvoice.invoice.product"> - {{
          item.cost.toLocaleString()
        }} ₽</span>
        <a v-if="['WAITING', 'PREPAID'].includes(getInvoice.invoice.status)"
           class="ps-3 link-secondary bi bi-pencil-fill" @click="updateInvoiceProductsModalShow"></a>
      </div>
    </basic-details>
    <!--suppress JSUnresolvedReference -->
    <basic-details v-if="getInvoice.invoice.coupon" :name="'Купон скидки:'">
      <!--suppress JSUnresolvedReference -->
      <a v-if="getInvoice.invoice.discountId" class="text-decoration-none"
         @click="$router.push('/shop/discounts/' + getInvoice.invoice.discountId)">
        {{ getInvoice.invoice.coupon }}
      </a>
      <!--suppress JSUnresolvedReference -->
      <span v-else>{{ getInvoice.invoice.coupon }}</span>
    </basic-details>
    <basic-details :name="'Создан:'">
      <!--suppress JSCheckFunctionSignatures -->
      <span v-if="getInvoice.invoice.create">
        {{ formattedDateTimeTz(getInvoice.invoice.create, 'UTC_P3', 'DD.MM.YYYY в HH:mm МСК') }}
      </span>
    </basic-details>
    <basic-details v-if="['WAITING', 'PREPAID'].includes(getInvoice.invoice.status)" :name="'Срок действия:'"
                   :form="editValidity">
      <div v-if="!editValidity">
        <!--suppress JSCheckFunctionSignatures -->
        <span v-if="getInvoice.invoice.validity">
        {{ formattedDateTimeTz(getInvoice.invoice.validity, 'UTC_P3', 'DD.MM.YYYY в HH:mm МСК') }}
      </span><span v-else>Бессрочно</span>
        <a class="ps-3 link-secondary bi bi-pencil-fill" @click="editValidity=true"></a>
      </div>
      <div v-else>
        <div class="row-form">
          <form class="row" id="validity-form">
            <div class="col-12 col-sm-4 pb-2">
              <input-utp1 type="date" v-model:model-value="cancellation.date" :disabled="cancellationDisabled"
                          :required="!cancellationDisabled"></input-utp1>
            </div>
            <div class="col-12 col-sm-4 d-flex align-items-center pb-2">
              <span class="me-2">в </span>
              <input-utp1 type="time" v-model:model-value="cancellation.time" :disabled="cancellationDisabled"
                          :required="!cancellationDisabled"></input-utp1>
            </div>
            <div class="col-12 col-sm-4 pb-2">
              <select class="form-select" v-model="cancellation.timezone" :disabled="cancellationDisabled">
                <option v-for="item in formats" :value="item.value">{{ item.name }}</option>
              </select>
            </div>

            <div class="col-auto">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="cancellationDisabled"
                       v-model="cancellationDisabled">
                <label class="form-check-label" for="cancellationDisabled">
                  Бессрочно
                </label>
              </div>
            </div>
            <div class="col-auto">
              <a class="link-secondary bi bi-floppy-fill pe-1" @click="updatesValidity"></a>
              <a class="link-secondary bi bi-x-circle-fill ps-1" @click="cancelValidity"></a>
            </div>
          </form>
        </div>
      </div>

    </basic-details>
    <!--suppress JSUnresolvedReference -->
    <basic-details v-if="getInvoice.invoice.transactionCost" :name="'Внесено:'">
      {{ getInvoice.invoice.transactionCost.toLocaleString() }} ₽
    </basic-details>
    <!--suppress JSCheckFunctionSignatures -->
    <basic-details v-if="getInvoice.invoice.transaction" :name="'Дата последней транзакции:'">
      {{ formattedDateTimeTz(getInvoice.invoice.transaction, 'UTC_P3', 'DD.MM.YYYY в HH:mm МСК') }}
    </basic-details>
    <!--suppress JSUnresolvedReference -->
    <basic-details
        v-if="getInvoice.invoice.returnCost && getInvoice.invoice.status === 'RETURNED'"
        :name="'Возвращено:'">
      {{ (getInvoice.invoice.returnCost).toLocaleString() }} ₽
    </basic-details>
    <!--suppress JSUnresolvedReference -->
    <basic-details
        v-else-if="getInvoice.invoice.status !== 'RETURNED' && getInvoice.invoice.transactionCost &&
        getInvoice.invoice.transactionCost < getInvoice.invoice.cost"
        :name="'Осталось оплатить:'">
      {{ (getInvoice.invoice.cost - getInvoice.invoice.transactionCost).toLocaleString() }} ₽
    </basic-details>
    <basic-details :name="editTimezone ? 'Часовой пояс клиента:' : 'Текущее время клиента:'" :form="editTimezone">
      <div v-if="!editTimezone">
        <span v-if="getInvoice.invoice.timezone">{{ formattedDateTimeTzNow }}</span>
        <span v-else>Не определено</span>
        <a class="link-secondary ps-3 bi bi-pencil-fill" @click="editTimezone=true"></a>
      </div>
      <div v-else class="row-form">
        <div class="row align-items-center">
          <div class="col-sm-auto col">
            <select class="form-select" v-model="newTimezone.timezone">
              <option v-for="item in formatsNullable" :value="item.value">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-auto">
            <a class="link-secondary bi bi-floppy-fill pe-1" @click="updatesTimezone"></a>
            <a class="link-secondary bi bi-x-circle-fill ps-1" @click="cancelTimezone"></a>
          </div>
        </div>
      </div>
    </basic-details>
    <basic-details v-if="['WAITING', 'PREPAID'].includes(getInvoice.invoice.status)" :name="'Письма - напоминания:'">
      <div class="form-check form-switch">
        <!--suppress JSUnresolvedReference -->
        <input class="form-check-input" type="checkbox" role="switch" id="status"
               v-model="getInvoice.invoice.reminders" @change="updateReminder">
        <!--suppress JSUnresolvedReference -->
        <label class="form-check-label" for="status">{{
            getInvoice.invoice.reminders ? 'Отправляются' : 'Отключены'
          }}</label>
      </div>
    </basic-details>

    <hr-header-text :text="'История действий'"/>

    <div class="overflow-x-auto text-center">
      <div class="btn-group btn-group-sm pb-3 text-nowrap" role="group">
        <button type="button" class="btn btn-green-dark" @click="newInvoiceEvent('SUCCESSFUL_TALK')">Успешный разговор
        </button>
        <button type="button" class="btn btn-info" @click="newInvoiceEvent('CALL_BACK')">Перезвонить</button>
        <button type="button" class="btn btn-orange" @click="newInvoiceEvent('DIDNT_CALL')">Не дозвонился</button>
        <button type="button" class="btn btn-yellow" @click="newInvoiceEvent('WRONG_PHONE')">Неверный телефон</button>
        <button type="button" class="btn btn-success" @click="newInvoiceEvent('ALREADY_PAID')">Уже оплачен</button>
        <button type="button" class="btn btn-red-light" @click="newInvoiceEvent('REFUSAL_PAYMENT')">Отказ</button>
        <button type="button" class="btn btn-secondary" @click="newInvoiceEvent('SECONDARY_ORDER')">Повторный заказ
        </button>
        <button type="button" class="btn btn-indigo" @click="newInvoiceEvent('COMMENT')">Комментарий</button>
      </div>
    </div>

    <div v-for="item in getInvoice.events.events" class="pb-2">
      <div class="card" :class="
        item.type === 'CREATION' ? 'border-2 border-secondary' :
        item.type === 'PREPAYMENT' ? 'border-2 border-green-dark' :
        item.type === 'PAYMENT' ? 'border-2 border-success' :
        item.type === 'AUTOPAYMENT' ? 'border-2 border-success' :
        item.type === 'FAILED' ? 'border-2 border-red' :
        item.type === 'CANCEL' ? 'border-2 border-danger' :
        item.type === 'RECOVERY' ? 'border-2 border-purple' :
        item.type === 'RETURN' ? 'border-2 border-black' :
        item.type === 'COMMENT' ? 'border-0 text-white bg-indigo' :
        item.type === 'SUCCESSFUL_TALK' ? 'border-0 text-white bg-green-dark' :
        item.type === 'CALL_BACK' ? 'border-0 text-white bg-info' :
        item.type === 'DIDNT_CALL' || item.type === 'DIDNT_CALL_BUSY' ? 'border-0 text-white bg-orange' :
        item.type === 'WRONG_PHONE' ? 'border-0 text-white bg-yellow' :
        item.type === 'ALREADY_PAID' ? 'border-0 text-white bg-success' :
        item.type === 'REFUSAL_PAYMENT' ? 'border-0 text-white bg-red-light' :
        item.type === 'SECONDARY_ORDER' ? 'border-0 text-white bg-secondary' :
      ''">
        <div class="row-form card-body">
          <div class="row">
            <!--suppress JSUnresolvedReference, JSCheckFunctionSignatures -->
            <div class="col-sm-auto d-none d-sm-block ">
              {{ formattedDateTimeTz(item.datetime, 'UTC_P3', 'DD.MM.YYYY в HH:mm МСК') }} |
              {{
                item.payMethod === 'YPMN_CARD' ? 'UTP Pay Card' : item.payMethod === 'YPMN_SBP' ? 'UTP Pay' : item.payMethod === 'YPMN_QIWI' ? 'UTP Pay QIWI' : item.payMethod === 'YPMN_YOOMONEY' ? 'UTP Pay YooMoney' : item.payMethod === 'YPMN_SVYAZNOY' ? 'UTP Pay Svyaznoy' :
                    item.payMethod === 'TINKOFF_SBP' ? 'UTP Pay 2' : item.payMethod === 'TINKOFF_PAY' ? 'UTP Pay Tinkoff' : item.payMethod === 'TINKOFF_INSTALLMENT' ? 'UTP Pay Рассрочка' : item.payMethod === 'TINKOFF_DOLYAME' ? 'UTP Pay Долями' : item.payMethod === 'TINKOFF_LOAN' ? 'UTP Pay Кредит' :
                        item.payMethod === 'UNITPAY' ? 'UTP Pay International' : item.payMethod === 'PAYPAL' ? 'PayPal' :
                            item.payMethod === 'YOOMONEY' ? 'YooMoney' : item.payMethod === 'QIWI' ? 'QIWI' :
                                item.payMethod === 'WEBMONEY_RUB' ? 'WebMoney RUB' : item.payMethod === 'WEBMONEY_USD' ? 'WebMoney USD' : item.payMethod === 'WEBMONEY_EUR' ? 'WebMoney EUR' : item.payMethod === 'WEBMONEY_BYN' ? 'WebMoney BYN' : item.payMethod === 'WEBMONEY_KZT' ? 'WebMoney KZT' :
                                    item.owner ? item.ownerName + ' (' + item.ownerLogin + ')' : 'Клиент'
              }}
            </div>
            <div class="col-sm d-none d-sm-block"></div>
            <div class="col-sm-auto">
              <span :class="item.type === 'CREATION' ? 'text-secondary' :
                item.type === 'PREPAYMENT' ? 'text-green-dark' :
                item.type === 'PAYMENT' ? 'text-green' :
                item.type === 'AUTOPAYMENT' ? 'text-green' :
                item.type === 'FAILED' ? 'text-red' :
                item.type === 'CANCEL' ? 'text-danger' :
                item.type === 'RECOVERY' ? 'text-purple' :
                item.type === 'RETURN' ? 'text-black' :
                ''">{{
                  item.type === 'CREATION' ? 'Создан' : item.type === 'PREPAYMENT' ? 'Задаток ' + (item.cost ? item.cost.toLocaleString() + ' ₽' : '') : item.type === 'PAYMENT' ? 'Оплата ' + (item.cost ? item.cost.toLocaleString() + ' ₽' : '') : item.type === 'AUTOPAYMENT' ? 'Автоплатеж' :
                      item.type === 'FAILED' ? 'Ошибка автоплатежа' : item.type === 'CANCEL' ? 'Отменен' : item.type === 'RECOVERY' ? 'Восстановлен' : item.type === 'RETURN' ? 'Возврат ' + (item.cost ? item.cost.toLocaleString() + ' ₽' : '') : item.type === 'COMMENT' ? 'Комментарий' :
                          item.type === 'SUCCESSFUL_TALK' ? 'Успешный разговор' : item.type === 'CALL_BACK' ? 'Перезвонить' : item.type === 'DIDNT_CALL' || item.type === 'DIDNT_CALL_BUSY' ? 'Не дозвонился' : item.type === 'WRONG_PHONE' ? 'Неверный контакт' :
                              item.type === 'ALREADY_PAID' ? 'Уже оплачен' : item.type === 'REFUSAL_PAYMENT' ? 'Отказ' : item.type === 'SECONDARY_ORDER' ? 'Повторный заказ' : ''
                }}</span>
            </div>
            <!--suppress JSUnresolvedReference, JSCheckFunctionSignatures -->
            <div class="col-sm-auto d-sm-none d-block">
              {{ formattedDateTimeTz(item.datetime, 'UTC_P3', 'DD.MM.YYYY в HH:mm МСК') }} |
              {{ item.owner ? item.ownerName + ' (' + item.ownerLogin + ')' : 'Клиент' }}
            </div>
          </div>
          <div v-if="item.comment" class="row">
            <div class="col-12 pt-1 small" v-html="item.comment.replaceAll('\n', '<br>')">
            </div>
          </div>
          <div class="row">
            <!--suppress JSUnresolvedReference, JSCheckFunctionSignatures -->
            <div v-if="item.callbackDatetime" class="col-sm-auto pt-1">
              Перезвонить {{ formattedDateTimeTz(item.callbackDatetime, 'UTC_P3', 'DD.MM.YYYY в HH:mm МСК') }}
            </div>
            <div class="col-sm d-none d-sm-block"></div>
            <div v-if="item.letterText" class="col-sm-auto pt-1">
              <a class="card-link link-silver text-decoration-none" @click="invoiceEventLetterModalShow(item)"><i
                  class="bi bi-envelope"></i> Отправлено письмо</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <pagination-light-utp1
        v-model:page="eventPage"
        :number="10"
        :total="getInvoice.events.number"
        style="margin-bottom: -0.75rem;"
    />

    <hr-header-text :text="'Дополнительная информация'"/>

    <basic-details :name="'IP адресс:'">
      {{ getInvoice.invoice.ip }}
    </basic-details>
    <basic-details v-if="getInvoice.affiliates.length > 0" :name="'Партнёры:'">
      <!--suppress JSUnresolvedReference -->
      <div v-for="item in getInvoice.affiliates">
        {{ item.affiliateName }} ({{ item.affiliateLogin }}) - {{ item.cost.toLocaleString() }} ₽
      </div>
    </basic-details>

  </main>
  <sidebar-right-utp1>
    <div class="sidebar-right-filter">
      <hr>
      <div class="pb-2">
        <label class="form-label" for="emailInformation"><i class="bi bi-info-lg"></i> Информация о клиенте:</label>
        <!--suppress JSUnresolvedReference -->
        <textarea
            id="emailInformation"
            class="form-control small"
            v-model="getInvoice.emailInformation.information"
            placeholder="Введите текст"
            type="text"
            autocomplete="off"
            rows="5"
            :disabled="!updateEmailInformationForm"
        ></textarea>
      </div>
      <div class="pb-3 row-form">
        <div class="row">
          <div class="col pe-1">
            <div class="d-grid gap-2">
              <button type="button" class="btn btn-primary" :disabled="!updateEmailInformationForm"
                      @click="updateEmailInformation">
                Сохранить
              </button>
            </div>
          </div>
          <div class="col-auto ps-1">
            <input type="checkbox" class="btn-check" id="updateEmailInformationForm" autocomplete="off"
                   v-model="updateEmailInformationForm">
            <label class="btn btn-secondary" for="updateEmailInformationForm">
              <i class="bi"
                 :class="updateEmailInformationForm ? 'bi-unlock-fill' : 'bi-lock-fill'"></i></label>
          </div>
        </div>
      </div>
      <div class="pb-2"><i class="bi bi-arrow-clockwise"></i> История счетов:</div>
      <div v-for="item in getInvoice.invoices.invoices" class="row container-status-card-utp1-less">
        <div class="col-auto ps-0 container-status-card-utp1">
          <a @click="$router.push('/shop/invoices/' + item.id)">
            <span class="status-card-utp1 text-white"
                  :class="item.status === 'WAITING' ? 'bg-secondary icon-status-card-utp1-little' :
                    item.status === 'PREPAID' ? 'bg-green-dark icon-status-card-utp1' :
                    item.status === 'PAID' ? 'bg-green icon-status-card-utp1' :
                    item.status === 'FAILED' ? 'bg-red icon-status-card-utp1-little' :
                    item.status === 'CANCELED' ? 'bg-danger icon-status-card-utp1' :
                    item.status === 'RETURNED' ? 'bg-black icon-status-card-utp1-little' :
                    'bg-warning icon-status-card-utp1'"
                  :title="item.status === 'WAITING' ? 'Ожидается' :
                    item.status === 'PREPAID' ? 'Предоплачен' :
                    item.status === 'PAID' ? 'Оплачен' :
                    item.status === 'FAILED' ? 'Ошибка' :
                    item.status === 'CANCELED' ? 'Отменен' :
                    item.status === 'RETURNED' ? 'Возврат' : 'Не определено'">
              <i :class="item.status === 'WAITING' ? 'bi bi-hourglass-split' :
                    item.status === 'PREPAID' ? 'bi bi-check-all' :
                    item.status === 'PAID' ? 'bi bi-plus' :
                    item.status === 'FAILED' ? 'bi bi-exclamation-diamond' :
                    item.status === 'CANCELED' ? 'bi bi-dash' :
                    item.status === 'RETURNED' ? 'bi bi-arrow-counterclockwise' : 'bi bi-question'"></i>
              <!--suppress JSUnresolvedReference -->
              <span class="badge-utp1 badge-icon"
                    v-if="['SUCCESSFUL_TALK', 'CALL_BACK', 'DIDNT_CALL_BUSY', 'WRONG_PHONE', 'ALREADY_PAID', 'REFUSAL_PAYMENT', 'SECONDARY_ORDER', 'COMMENT'].includes(item.callCenterStatus)"
                    :class="item.callCenterStatus === 'SUCCESSFUL_TALK' ? 'bg-green-dark' :
                      item.callCenterStatus === 'CALL_BACK' ? 'bg-info' :
                      (item.callCenterStatus === 'CALL_BACK' || item.callCenterStatus === 'DIDNT_CALL_BUSY') ?
                      'bg-orange' :
                      item.callCenterStatus === 'WRONG_PHONE' ? 'bg-yellow' :
                      item.callCenterStatus === 'ALREADY_PAID' ? 'bg-green' :
                      item.callCenterStatus === 'REFUSAL_PAYMENT' ? 'bg-red-light' :
                      item.callCenterStatus === 'SECONDARY_ORDER' ? 'bg-secondary' :
                      item.callCenterStatus === 'COMMENT' ? 'bg-indigo' : ''">
                <!--suppress JSUnresolvedReference -->
                <i :class="item.callCenterStatus === 'SUCCESSFUL_TALK' ? 'bi bi-check' :
                      item.callCenterStatus === 'CALL_BACK' ? 'bi bi-clock' :
                      (item.callCenterStatus === 'CALL_BACK' || item.callCenterStatus === 'DIDNT_CALL_BUSY') ?
                      'bi bi-exclamation' :
                      item.callCenterStatus === 'WRONG_PHONE' ? 'bi bi-bug' :
                      item.callCenterStatus === 'ALREADY_PAID' ? 'bi bi-plus' :
                      item.callCenterStatus === 'REFUSAL_PAYMENT' ? 'bi bi-x' :
                      item.callCenterStatus === 'SECONDARY_ORDER' ? 'bi bi-three-dots' :
                      item.callCenterStatus === 'COMMENT' ? 'bi bi-chat-text' : ''"></i>
              </span>
            </span>
          </a>
        </div>

        <div class="col px-0 container-card-utp1 less">
          <div class="text-truncate">
            <a class="card-link text-decoration-none"
               @click="$router.push('/shop/invoices/' + item.id)">
              {{ item.cost.toLocaleString() }} ₽
              <span v-if="item.autoCost"> + {{ item.autoCost.toLocaleString() }} ₽</span>
              <span v-if="item.product">{{ item.product }}</span>
            </a>
          </div>
          <!--suppress JSCheckFunctionSignatures -->
          <div class="text-truncate p-0"
               :class="(item.status === 'PAID') || (item.status === 'PREPAID') || (item.status === 'RETURNED') ? '' : 'pb-2'">
            <i class="bi bi-check-lg"></i> {{ formattedDateTimeTz(item.create, 'UTC_P3', 'DD.MM.YYYY в HH:mm') }}
          </div>
          <!--suppress JSCheckFunctionSignatures, JSUnresolvedReference -->
          <div v-if="(item.status === 'PAID') || (item.status === 'PREPAID')" class="text-truncate p-0 pb-1">
            <i class="bi bi-credit-card-2-front"></i> {{
              item.status === 'PAID' ? formattedDateTimeTz(item.transaction, 'UTC_P3', 'DD.MM.YYYY в HH:mm') :
                  item.status === 'PREPAID' ? item.transactionCost.toLocaleString() + ' ₽ ' + formattedDateTimeTz(item.transaction, 'UTC_P3', 'DD.MM.YYYY') : ''
            }}
          </div>
          <!--suppress JSUnresolvedReference, JSCheckFunctionSignatures -->
          <div v-if="(item.status === 'RETURNED')" class="text-truncate p-0 pb-1">
            <i class="bi bi-credit-card"></i> {{
              item.returnCost.toLocaleString() + ' ₽ ' + formattedDateTimeTz(item.transaction, 'UTC_P3', 'DD.MM.YYYY')
            }}
          </div>
        </div>
      </div>
      <div v-if="getInvoice.invoices.number > 5" style="margin-top: -0.3rem" class="row-form">
        <div class="row">
          <div v-if="otherInvoicesPage !== 1" class="text-start"
               :class="otherInvoicesPage < (getInvoice.invoices.number / 5) ? 'col-6' : 'col-12'">
            <a class="link-primary" @click="otherInvoicesPageMinus">
              <i class="bi bi-arrow-left-short"
                 :class="otherInvoicesPage < (getInvoice.invoices.number / 5) ? 'd-none' : 'd-inline'"></i>
              <i class="bi bi-arrow-left"
                 :class="otherInvoicesPage < (getInvoice.invoices.number / 5) ? 'd-inline' : 'd-none'"></i>
              <span class="small ps-1"
                    :class="otherInvoicesPage < (getInvoice.invoices.number / 5) ? 'd-none': 'd-inline'">предыдущие</span>
            </a>
          </div>
          <div v-if="otherInvoicesPage < (getInvoice.invoices.number / 5)" class="text-end"
               :class="otherInvoicesPage !== 1 ? 'col-6' : 'col-12'">
            <a class="link-primary" @click="otherInvoicesPagePlus">
              <span class="small pe-1"
                    :class="otherInvoicesPage !== 1 ? 'd-none' : 'd-inline'">следующие</span>
              <i class="bi bi-arrow-right-short pe-3"
                 :class="otherInvoicesPage !== 1 ? 'd-none' : 'd-inline'"></i>
              <i class="bi bi-arrow-right pe-3"
                 :class="otherInvoicesPage !== 1 ? 'd-inline' : 'd-none'"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </sidebar-right-utp1>
  <new-invoice-event v-model:type="newInvoiceEventType" @button-click="newEvent"/>
  <!--suppress JSCheckFunctionSignatures -->
  <delete-object
      :id="parseInt(invoiceId, 10)"
      @deleteObject="deleteCard"
  />
  <!--suppress JSCheckFunctionSignatures, JSUnresolvedReference -->
  <update-invoice-modal
      :id="parseInt(invoiceId, 10)"
      :action="updateInvoiceAction"
      :maxCost="getInvoice.invoice.cost - (getInvoice.invoice.transactionCost ? getInvoice.invoice.transactionCost : 0)"
      :maxReturnCost="(getInvoice.invoice.transactionCost ? getInvoice.invoice.transactionCost : 0) - (getInvoice.invoice.returnCost ? getInvoice.invoice.returnCost : 0)"
      @updateInvoice="updatesInvoice"
  />
  <invoice-event-letter-modal
      :invoice-event-letter="invoiceEventLetter"
  />
  <!--suppress JSUnresolvedReference -->
  <update-invoice-products-modal
      :invoiceProducts="invoiceProducts"
      :allProducts="productsList"
      :minCost="getInvoice.invoice.transactionCost ? getInvoice.invoice.transactionCost : 0.0"
      @cancel="initInvoiceProducts"
      @updatesInvoiceProducts="updatesInvoiceProducts"
  />
</template>

<!--suppress JSUnresolvedReference -->
<script>
import PreloaderMain from "@/components/system/preloader/PreloaderMain.vue";
import SidebarRightUtp1 from "@/components/system/SidebarRightUtp1.vue";
import {useRoute, useRouter} from "vue-router";
import useInvoices from "@/hooks/rest/shop/useInvoices";
import {onMounted, ref, watch} from "vue";
import useInvoiceError from "@/hooks/error/useInvoiceError";
import HeaderBackButtonSettingsUtp1 from "@/components/UI/headers/HeaderBackButtonSettingsUtp1.vue";
import BasicDetails from "@/components/UI/universal/BasicDetails.vue";
import useMoment from "@/hooks/useMoment";
import HrHeaderText from "@/components/UI/universal/HrHeaderText.vue";
import NewInvoiceEvent from "@/components/UI/modals/NewInvoiceEvent.vue";
import PaginationLightUtp1 from "@/components/UI/paginations/PaginationLightUtp1.vue";
import DeleteObject from "@/components/UI/modals/DeleteObject.vue";
import UpdateInvoiceModal from "@/components/UI/modals/UpdateInvoiceModal.vue";
import AlertDanger from "@/components/UI/alerts/AlertDanger.vue";
import InvoiceEventLetterModal from "@/components/UI/modals/InvoiceEventLetterModal.vue";
import InputUtp1 from "@/components/UI/inputs/InputUtp1.vue";
import ButtonSecondaryUtp1 from "@/components/UI/buttons/ButtonSecondaryUtp1.vue";
import DoubleButtonUtp1 from "@/components/UI/buttons/DoubleButtonUtp1.vue";
import ButtonPrimaryUtp1 from "@/components/UI/buttons/ButtonPrimaryUtp1.vue";
import UpdateInvoiceProductsModal from "@/components/UI/modals/UpdateInvoiceProductsModal.vue";
import useProducts from "@/hooks/rest/shop/useProducts";

export default {
  name: "invoice",
  components: {
    UpdateInvoiceProductsModal,
    ButtonPrimaryUtp1,
    DoubleButtonUtp1,
    ButtonSecondaryUtp1,
    InputUtp1,
    InvoiceEventLetterModal,
    AlertDanger,
    UpdateInvoiceModal,
    DeleteObject,
    PaginationLightUtp1,
    NewInvoiceEvent,
    HrHeaderText,
    BasicDetails,
    HeaderBackButtonSettingsUtp1,
    SidebarRightUtp1,
    PreloaderMain
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const invoiceId = ref(route.params.id)

    const header = ref({
      title: "Детали счета",
      buttonRedirect: "/shop/invoices"
    })

    const button = ref({
      title: '',
      dropdownMenu: []
    })

    const {
      loaderInvoices,
      exceptionInvoices,
      getInvoice,
      getInvoiceRest,
      addEvent,
      getEvents,
      getOtherInvoices,
      updateInvoice,
      updateReminders,
      updateInvoiceProducts,
      deleteInvoice,
      updateInvoiceEmailInformation,
      updateValidity,
      updateTimezone
    } = useInvoices()
    const {
      invoiceError,
      invoiceErrorMessage
    } = useInvoiceError()
    const {
      formattedDateTimeTz,
      formattedDateTimeTzNow,
      initTime,
      deInitTime,
      formatsNullable,
      formats,
      formatsString
    } = useMoment()
    const {loaderProducts, productsList, getAllProducts} = useProducts()

    const newInvoiceEventModal = ref()
    const deleteObjectModal = ref()
    const updateInvoiceModal = ref()
    const updateInvoiceProductsModal = ref()
    const invoiceEventLetterModal = ref()

    onMounted(async () => {
      await getInvoiceRest(invoiceId.value)
      await getAllProducts()
      try {
        if (getInvoice.value.invoice.timezone) {
          initTime(getInvoice.value.invoice.timezone)
        }
        newInvoiceEventModal.value = new bootstrap.Modal('#newInvoiceEventModal', {})
        deleteObjectModal.value = new bootstrap.Modal('#deleteObjectModal', {})
        updateInvoiceModal.value = new bootstrap.Modal('#updateInvoiceModal', {})
        updateInvoiceProductsModal.value = new bootstrap.Modal('#updateInvoiceProductsModal', {})
        invoiceEventLetterModal.value = new bootstrap.Modal('#invoiceEventLetterModal', {})
        header.value.title = 'Счет №' + invoiceId.value
        init()
      } finally {
      }
    })

    watch(exceptionInvoices, () => {
      invoiceError(exceptionInvoices.value)
    })

    watch(route, async () => {
      invoiceId.value = route.params.id
      eventPage.value = 1
      otherInvoicesPage.value = 1
      deInitTime()
      await getInvoiceRest(invoiceId.value)
      if (getInvoice.value.invoice.timezone) {
        initTime(getInvoice.value.invoice.timezone)
      }
      header.value.title = 'Счет №' + invoiceId.value
      init()
    })

    const newInvoiceEventType = ref()

    const newInvoiceEvent = (eventType) => {
      newInvoiceEventType.value = eventType
      newInvoiceEventModal.value.show()
    }

    const newEvent = async (newInvoiceEventData) => {
      newInvoiceEventModal.value.hide()
      eventPage.value = 1
      await addEvent(invoiceId.value, newInvoiceEventData)
      if (!exceptionInvoices.value) {
        await getEvents(invoiceId.value, eventPage.value)
      }
    }

    const eventPage = ref(1)

    watch(eventPage, () => getEvents(invoiceId.value, eventPage.value))

    const otherInvoicesPage = ref(1)

    watch(otherInvoicesPage, () => getOtherInvoices(invoiceId.value, otherInvoicesPage.value))

    const otherInvoicesPagePlus = () => {
      if (otherInvoicesPage.value < (getInvoice.value.invoices.number / 5)) {
        otherInvoicesPage.value = otherInvoicesPage.value + 1
      }
    }

    const otherInvoicesPageMinus = () => {
      if (otherInvoicesPage.value > 1) {
        otherInvoicesPage.value = otherInvoicesPage.value - 1
      }
    }

    const updateEmailInformationForm = ref(false)

    const updateEmailInformation = () => {
      updateInvoiceEmailInformation(invoiceId.value, getInvoice.value.emailInformation)
      updateEmailInformationForm.value = false
    }

    const updateInvoiceAction = ref("")

    const updateInvoiceModalShow = (action) => {
      updateInvoiceAction.value = action
      updateInvoiceModal.value.show()
    }

    const updatesInvoice = async (id, updateInvoiceData) => {
      updateInvoiceModal.value.hide()
      await updateInvoice(id, updateInvoiceData)
      if (!exceptionInvoices.value) {
        await getInvoiceRest(id)
        init()
      }
    }

    const deleteCardModal = () => {
      deleteObjectModal.value.show()
    }

    const deleteCard = async () => {
      await deleteInvoice(invoiceId.value)
      if (!exceptionInvoices) {
        await router.push('/shop/invoices')
      }
    }

    const updateInvoiceProductsModalShow = () => {
      updateInvoiceProductsModal.value.show()
    }

    const invoiceProducts = ref({
      product: null,
      productCost: 0.0,
      autoCost: null,
      currency: "RUB",
      products: []
    })

    const initInvoiceProducts = () => {
      invoiceProducts.value.product = getInvoice.value.invoice.product
      invoiceProducts.value.productCost = getInvoice.value.invoice.productCost
      invoiceProducts.value.autoCost = getInvoice.value.invoice.autoCost
      invoiceProducts.value.currency = getInvoice.value.invoice.currency
      invoiceProducts.value.products = []
      getInvoice.value.products.forEach(product => {
        if (product.productId) {
          invoiceProducts.value.products.push({
            id: invoiceProducts.value.products.length,
            productId: product.productId,
            cost: product.cost,
            product: product.product
          })
        }
      })
    }

    const dropdownMenu = [{
      dropdownName: "Внести задаток",
      dropdownEmit: "prepayment",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Оплатить",
      dropdownEmit: "payment",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Отменить",
      dropdownEmit: "cancel",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "",
      dropdownPush: "",
      dropdownHr: true,
      dropdownDelete: false
    }, {
      dropdownName: "Удалить",
      dropdownPush: "",
      dropdownHr: false,
      dropdownDelete: true
    }]

    const dropdownMenuPrepaid = [{
      dropdownName: "Внести задаток",
      dropdownEmit: "prepayment",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Оплатить",
      dropdownEmit: "payment",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Отменить",
      dropdownEmit: "cancel",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Сделать возврат",
      dropdownEmit: "return",
      dropdownHr: false,
      dropdownDelete: false
    }]

    const dropdownMenuReturn = [{
      dropdownName: "Сделать возврат",
      dropdownEmit: "return",
      dropdownHr: false,
      dropdownDelete: false
    }]

    const dropdownMenuPaidRecurrent = [{
      dropdownName: "Отменить",
      dropdownEmit: "cancel",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Сделать возврат",
      dropdownEmit: "return",
      dropdownHr: false,
      dropdownDelete: false
    }]

    const dropdownMenuCanceled = [{
      dropdownName: "Восстановить",
      dropdownEmit: "recovery",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "",
      dropdownPush: "",
      dropdownHr: true,
      dropdownDelete: false
    }, {
      dropdownName: "Удалить",
      dropdownPush: "",
      dropdownHr: false,
      dropdownDelete: true
    }]

    const dropdownMenuCanceledPrepaid = [{
      dropdownName: "Восстановить",
      dropdownEmit: "recovery",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Сделать возврат",
      dropdownEmit: "return",
      dropdownHr: false,
      dropdownDelete: false
    }]

    const updateReminder = (event) => {
      const reminders = {
        value: event.target.checked
      }
      updateReminders(invoiceId.value, reminders)
    }

    const invoiceEventLetterModalShow = (invoiceEvent) => {
      invoiceEventLetter.value.type = invoiceEvent.type
      invoiceEventLetter.value.datetime = invoiceEvent.datetime
      invoiceEventLetter.value.senderName = invoiceEvent.senderName
      invoiceEventLetter.value.senderEmail = invoiceEvent.senderEmail
      invoiceEventLetter.value.letterTitle = invoiceEvent.letterTitle
      invoiceEventLetter.value.letterText = invoiceEvent.letterText
      invoiceEventLetterModal.value.show()
    }

    const invoiceEventLetter = ref({
      type: "",
      datetime: "",
      senderName: "",
      senderEmail: "",
      letterTitle: "",
      letterText: ""
    })

    const editTimezone = ref(false)
    const newTimezone = ref({
      timezone: null
    })
    const initTimezone = () => {
      if (getInvoice.value.invoice.timezone) {
        newTimezone.value.timezone = getInvoice.value.invoice.timezone
      } else {
        newTimezone.value.timezone = null
      }
    }
    const updatesTimezone = async () => {
      editTimezone.value = false
      await updateTimezone(invoiceId.value, newTimezone.value)
      if (!exceptionInvoices.value) {
        getInvoice.value.invoice.timezone = newTimezone.value.timezone
        deInitTime()
        if (getInvoice.value.invoice.timezone) {
          initTime(getInvoice.value.invoice.timezone)
        }
      }
    }
    const cancelTimezone = () => {
      editTimezone.value = false
      initTimezone()
    }

    const editValidity = ref(false)
    const cancellation = ref({
      date: "",
      time: "",
      timezone: "UTC_P3"
    })
    const cancellationDisabled = ref(true)
    const initValidity = () => {
      if (getInvoice.value.invoice.validity) {
        cancellation.value.date =
            formattedDateTimeTz(getInvoice.value.invoice.validity, 'UTC_P3', 'YYYY-MM-DD')
        cancellation.value.time =
            formattedDateTimeTz(getInvoice.value.invoice.validity, 'UTC_P3', 'HH:mm')
        cancellation.value.timezone = 'UTC_P3'
        cancellationDisabled.value = false
      } else {
        cancellation.value.date = ""
        cancellation.value.time = ""
        cancellation.value.timezone = 'UTC_P3'
        cancellationDisabled.value = true
      }
    }
    const validity = ref({
      validity: null
    })
    const updatesValidity = async () => {
      const form = document.querySelector('#validity-form')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        editValidity.value = false
        if (cancellationDisabled.value) {
          validity.value.validity = null
        } else {
          // noinspection JSValidateTypes
          validity.value.validity =
              cancellation.value.date + 'T' + cancellation.value.time + ':00.000' + formatsString(cancellation.value.timezone)
        }
        await updateValidity(invoiceId.value, validity.value)
        if (!exceptionInvoices.value) {
          getInvoice.value.invoice.validity = validity.value.validity
        }
        form.classList.remove("was-validated")
      }
    }
    const cancelValidity = () => {
      editValidity.value = false
      initValidity()
    }

    const updatesInvoiceProducts = async () => {
      updateInvoiceProductsModal.value.hide()
      const invoicesProducts = ref({
        cost: invoiceProducts.value.productCost,
        currency: invoiceProducts.value.currency,
        products: []
      })
      invoiceProducts.value.products.forEach(product => {
        invoicesProducts.value.products.push({
          productId: product.productId,
          cost: product.cost,
          product: product.product
        })
      })
      await updateInvoiceProducts(invoiceId.value, invoicesProducts.value)
      if (!exceptionInvoices.value) {
        getInvoice.value.invoice.productCost = invoiceProducts.value.productCost
        getInvoice.value.invoice.currency = invoiceProducts.value.currency
        getInvoice.value.products = []
        invoicesProducts.value.products.forEach(product => {
          // noinspection JSCheckFunctionSignatures
          getInvoice.value.products.push({
            productId: product.productId,
            cost: product.cost,
            product: product.product
          })
        })
      }
    }

    const init = () => {
      initValidity()
      initTimezone()
      initInvoiceProducts()
      if (getInvoice.value.invoice.status === 'WAITING') {
        button.value.title = 'Ожидается'
        button.value.dropdownMenu = dropdownMenu
      } else if (getInvoice.value.invoice.status === 'PREPAID') {
        button.value.title = 'Предоплачен'
        button.value.dropdownMenu = dropdownMenuPrepaid
      } else if (getInvoice.value.invoice.status === 'PAID') {
        button.value.title = 'Оплачен'
        if (getInvoice.value.invoice.recurrent) {
          button.value.dropdownMenu = dropdownMenuPaidRecurrent
        } else {
          button.value.dropdownMenu = dropdownMenuReturn
        }
      } else if (getInvoice.value.invoice.status === 'FAILED') {
        button.value.title = 'Ошибка'
        button.value.dropdownMenu = dropdownMenuPaidRecurrent
      } else if (getInvoice.value.invoice.status === 'CANCELED') {
        button.value.title = 'Отменен'
        if (getInvoice.value.invoice.recurrent) {
          // noinspection JSUnresolvedReference
          if (getInvoice.value.invoice.transactionCost) {
            if (getInvoice.value.invoice.transactionCost >= getInvoice.value.invoice.cost) {
              button.value.dropdownMenu = dropdownMenuReturn
            } else {
              button.value.dropdownMenu = dropdownMenuCanceledPrepaid
            }
          } else {
            button.value.dropdownMenu = dropdownMenuCanceled
          }
        } else {
          // noinspection JSUnresolvedReference
          if (getInvoice.value.invoice.transactionCost) {
            button.value.dropdownMenu = dropdownMenuCanceledPrepaid
          } else {
            button.value.dropdownMenu = dropdownMenuCanceled
          }
        }
      } else if (getInvoice.value.invoice.status === 'RETURNED') {
        button.value.title = 'Возврат'
        // noinspection JSUnresolvedReference
        if (getInvoice.value.invoice.transactionCost > (getInvoice.value.invoice.returnCost ? getInvoice.value.invoice.returnCost : 0)) {
          button.value.dropdownMenu = dropdownMenuReturn
        } else {
          button.value.dropdownMenu = []
        }
      }
    }

    return {
      invoiceId,
      loaderInvoices,
      header,
      button,
      getInvoice,
      invoiceErrorMessage,
      formattedDateTimeTz,
      formattedDateTimeTzNow,
      newInvoiceEventType,
      newInvoiceEvent,
      newEvent,
      eventPage,
      updateEmailInformationForm,
      updateEmailInformation,
      otherInvoicesPage,
      otherInvoicesPagePlus,
      otherInvoicesPageMinus,
      updateInvoiceModalShow,
      updateInvoiceAction,
      updatesInvoice,
      deleteCardModal,
      deleteCard,
      updateReminder,
      invoiceEventLetter,
      invoiceEventLetterModalShow,
      formatsNullable,
      editTimezone,
      newTimezone,
      updatesTimezone,
      cancelTimezone,
      editValidity,
      cancellation,
      cancellationDisabled,
      formats,
      updatesValidity,
      cancelValidity,
      updateInvoiceProductsModalShow,
      invoiceProducts,
      productsList,
      loaderProducts,
      initInvoiceProducts,
      updatesInvoiceProducts
    }
  }
}
</script>

<style scoped>
.btn-info {
  color: white;
}

.btn-green-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #20c997;
  --bs-btn-border-color: #20c997;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4dd4ac;
  --bs-btn-hover-border-color: #4dd4ac;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #4dd4ac;
  --bs-btn-active-border-color: #4dd4ac;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #a6e9d5;
  --bs-btn-disabled-border-color: #a6e9d5;
}

.btn-orange {
  --bs-btn-color: #fff;
  --bs-btn-bg: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fd9843;
  --bs-btn-hover-border-color: #fd9843;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fd9843;
  --bs-btn-active-border-color: #fd9843;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fecba1;
  --bs-btn-disabled-border-color: #fecba1;
}

.btn-yellow {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ffcd39;
  --bs-btn-hover-border-color: #ffcd39;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffcd39;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffe69c;
  --bs-btn-disabled-border-color: #ffe69c;
}

.btn-red-light {
  --bs-btn-color: #fff;
  --bs-btn-bg: #d63384;
  --bs-btn-border-color: #d63384;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #de5c9d;
  --bs-btn-hover-border-color: #de5c9d;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #de5c9d;
  --bs-btn-active-border-color: #de5c9d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #efadce;
  --bs-btn-disabled-border-color: #efadce;
}

.btn-indigo {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6610f2;
  --bs-btn-border-color: #6610f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8540f5;
  --bs-btn-hover-border-color: #8540f5;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #8540f5;
  --bs-btn-active-border-color: #8540f5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c29ffa;
  --bs-btn-disabled-border-color: #c29ffa;
}

.btn-success {
  --bs-btn-hover-bg: #479f76;
  --bs-btn-hover-border-color: #479f76;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #479f76;
  --bs-btn-active-border-color: #479f76;
}

.btn-secondary {
  --bs-btn-hover-bg: #adb5bd;
  --bs-btn-hover-border-color: #adb5bd;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #adb5bd;
  --bs-btn-active-border-color: #adb5bd;
}

.link-silver {
  color: #e9ecef;
}

.link-silver:hover, .link-silver:focus {
  color: #ced4da;
}

.less {
  font-size: 0.75em !important;
}

.small {
  font-size: 0.875em !important;
}
</style>
