<template>
  <div class="modal fade" id="addProductToInvoiceModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Добавление продукта</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <products-accordion-radio
              :all-products="allProducts"
              :main-product="mainProduct"
              v-model:product="product"
          />
        </div>
        <div class="modal-footer">
          <button-secondary-utp1
              class="col col-sm-auto"
              :disabled="false"
              data-bs-dismiss="modal"
              @buttonClick="buttonSecondaryClick"
          >Отменить
          </button-secondary-utp1>
          <button-primary-utp1
              class="col col-sm-auto"
              data-bs-dismiss="modal"
              @buttonClick="buttonClick"
          >Подтвердить
          </button-primary-utp1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import ButtonSecondaryUtp1 from "../buttons/ButtonSecondaryUtp1";
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";
import ProductsAccordionRadio from "@/components/UI/accordions/ProductsAccordionRadio.vue";

export default {
  name: "add-product-to-invoice-modal",
  components: {ProductsAccordionRadio, ButtonPrimaryUtp1, ButtonSecondaryUtp1},
  props: {
    allProducts: {},
    mainProduct: {
      type: Boolean,
      default: true
    }
  },
  emits: ['addProductToInvoice'],
  setup(props, {emit}) {
    const product = ref()

    const buttonSecondaryClick = () => {
      product.value = null
    }

    const buttonClick = () => {
      emit('addProductToInvoice', product.value)
      product.value = null
    }

    return {buttonClick, buttonSecondaryClick, product}
  }
}
</script>

<style scoped>

</style>