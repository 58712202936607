<template>
  <main>
    <preloader-main v-if="singleMailingLoader"/>
    <header-back-no-line-utp1
        :header="header"
    />
    <single-mailing
        :newSingleMailing="newSingleMailing"
        :mailingErrorMessage="mailingErrorMessage"
        :sendingTypeDisabled="true"
        :readOnly="(newSingleMailing.singleMailing.status === 'SENT') ||
        (newSingleMailing.singleMailing.status === 'SENDING') ||
        (newSingleMailing.singleMailing.status === 'ERROR')"
        @buttonClick="updateSingleMailings"
        @duplicateSingleMailing="duplicateSingleMailing"
    />
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import HeaderBackUtp1 from "../../../components/UI/headers/HeaderBackUtp1";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";
import SingleMailing from "../../../components/pages/mailing/single/SingleMailing";
import HeaderBackStatusUtp1 from "../../../components/UI/headers/HeaderBackStatusUtp1";
import HeaderBackNoLineUtp1 from "../../../components/UI/headers/HeaderBackNoLineUtp1";
import ButtonPrimaryUtp1 from "../../../components/UI/buttons/ButtonPrimaryUtp1";
import useSingleMailing from "../../../hooks/rest/mailing/useSingleMailing";
import {onMounted, watch} from "vue";
import {useRoute} from "vue-router";
import useMailingError from "../../../hooks/error/useMailingError";
import AlertDanger from "../../../components/UI/alerts/AlertDanger";
import router from "../../../router";

export default {
  name: "edit-single-mailing",
  components: {
    AlertDanger,
    ButtonPrimaryUtp1,
    HeaderBackNoLineUtp1,
    HeaderBackStatusUtp1,
    SingleMailing,
    SidebarRightUtp1,
    HeaderBackUtp1,
    PreloaderMain
  },
  setup() {
    const route = useRoute()
    const singleMailingId = route.params.id

    const header = {
      title: "Изменить разовый выпуск",
      buttonRedirect: "/mailing"
    }

    const {
      singleMailingException,
      singleMailingLoader,
      getSingleMailing,
      updateSingleMailing,
      newSingleMailing
    } = useSingleMailing()

    const {mailingError, mailingErrorMessage} = useMailingError()

    watch(singleMailingException, () => {
      mailingError(singleMailingException.value)
    })

    const updateSingleMailings = (newSingleMailings) => {
      updateSingleMailing(newSingleMailings, singleMailingId)
    }

    const duplicateSingleMailing = () => {
      router.push('/mailing/single/add/' + singleMailingId)
    }

    onMounted(() => {
      getSingleMailing(singleMailingId)
    })

    return {
      header,
      mailingErrorMessage,
      singleMailingLoader,
      newSingleMailing,
      updateSingleMailings,
      duplicateSingleMailing
    }
  }
}
</script>

<style scoped>

</style>