import {ref} from "vue";
import {onBeforeRouteLeave} from "vue-router";

export default function useOnBeforeUnload() {

    const beforeRouteLeave = ref(false)

    const setOnBeforeUnload = () => {
        beforeRouteLeave.value = true
        onbeforeunload = () => {
            return true
        }
    }

    const unSetOnBeforeUnload = () => {
        beforeRouteLeave.value = false
        onbeforeunload = null
    }

    onBeforeRouteLeave(() => {
        if (beforeRouteLeave.value === true) {
            const answer = window.confirm(
                'Возможно, внесенные изменения не сохранятся.'
            )
            if (!answer) return false
        }
    })

    return {setOnBeforeUnload, unSetOnBeforeUnload}

}
