<template>
  <div class="modal fade" id="deleteObjectModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-danger">
          <h5 class="modal-title text-white">Удаление объекта. Продолжить?</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          Нажимая «Подтвердить» Вы соглашаетесь с тем, что объект
          и все связанные с ним данные будут безвозвратно удалены!
          <div v-if="confirmation" class="form-check pt-3">
            <input class="form-check-input" type="checkbox" id="deleteCheck" v-model="checked">
            <label class="form-check-label" for="deleteCheck">
              Я понимаю, что данное действие нельзя будет отменить
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button-secondary-utp1
              class="col col-sm-auto"
              :disabled="false"
              data-bs-dismiss="modal"
              @buttonClick="buttonSecondaryClick"
          >Отменить
          </button-secondary-utp1>
          <button-primary-utp1
              :class="confirmation? (checked? 'col col-sm-auto' : 'col col-sm-auto disabled') : 'col col-sm-auto'"
              :disabled="confirmation ? !checked : false"
              data-bs-dismiss="modal"
              @buttonClick="deleteObject"
          >Подтвердить
          </button-primary-utp1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import ButtonSecondaryUtp1 from "../buttons/ButtonSecondaryUtp1";
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";

export default {
  name: "delete-object",
  components: {ButtonPrimaryUtp1, ButtonSecondaryUtp1},
  props: {
    id: {
      type: Number,
      default: 0
    },
    confirmation: {
      type: Boolean,
      default: true
    }
  },
  setup(props, {emit}) {
    const checked = ref(false)
    const deleteObject = () => {
      emit('deleteObject', props.id)
      checked.value = false
    }
    const buttonSecondaryClick = () => {
      checked.value = false
    }
    return {checked, deleteObject, buttonSecondaryClick}
  }
}
</script>

<style scoped>

</style>