<template>
  <div id="vk_auth" class="py-3"></div>
  <div>{{ vkData }}</div>
</template>

<script>
import {onMounted, ref} from "vue";

export default {
  name: "vk-auth",
  setup() {
    let vkAuth = null

    const vkData = ref("")

    onMounted(() => {
      vkAuth = document.getElementById("vk_auth")
      // noinspection JSUnresolvedVariable,JSUnresolvedFunction
      VK.init({apiId: 51528363})
      // noinspection JSUnresolvedVariable,JSUnresolvedFunction,JSUnusedGlobalSymbols
      VK.Widgets.Auth("vk_auth", {
        scope: 4521984,
        onAuth: function (data) {
          vkData.value = data
        }
      })
    })

    return {vkData}
  }
}
</script>

<style scoped>

</style>
