<template>
  <main>
    <preloader-main v-if="userLoader || imagesLoader"/>
    <div class="delete-pb">
      <entry-header-utp1>Настройки</entry-header-utp1>
    </div>
    <div class="pt-3 delete-pb" v-if="errorMessage">
      <alert-danger>{{ errorMessage }}</alert-danger>
    </div>
    <div class="pt-3 delete-pb" v-if="hideEmail">
      <alert-warning>
        <p><strong>Для продолжения необходимо подтвердить свои действия!</strong></p>
        <p>Подробная информация и уникальная ссылка направлены Вам на e-mail: <strong>{{ hideEmail }}</strong></p>
        <p>Если письмо не пришло в течение нескольких минут, пожалуйста, проверьте папку <i>«Спам»</i>, и нажмите
          <strong><i>«НЕ спам»</i></strong></p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end" v-if="redirectButton">
          <button type="button" class="btn btn-outline-warning alert-link fw-normal" @click="redirect">
            {{ redirectButton }}
          </button>
        </div>
      </alert-warning>
    </div>

    <form id="grout-settings-avatar" class="mb-3">
      <div class="pt-2 mb-3">
        <!--suppress JSUnresolvedVariable -->
        <label v-if="!$store.state.avatar.avatar" for="formFileAvatar" class="form-label">Фото профиля</label>
        <div v-else class="text-center">
          <label for="formFileAvatar" class="mb-3">
            <!--suppress JSUnresolvedVariable -->
            <img class="rounded-circle border"
                 :src="'data:image;base64,' + $store.state.avatar.avatar"
                 style="width: 140px; height: 140px" alt="Avatar">
          </label>
        </div>
        <input class="form-control" type="file" id="formFileAvatar" ref="newSettingsAvatar"
               @change="avatarUpload" accept="image/*" required>
        <label class="form-text">
          Рекумендуемые ширина и высота 340px (или незначительно больше). Рекомендуемый формат JPG.
        </label>
      </div>
      <!--suppress JSUnresolvedVariable -->
      <double-button-utp1
          v-if="$store.state.avatar.avatar"
          :buttons="updateImageButton"
          :primary-disabled="!settingsAvatar"
          @buttonPrimaryClick="updateSettingsAvatars"
          @buttonSecondaryClick="deleteSettingsAvatars"/>
      <button-primary-utp1 v-else :disabled="!settingsAvatar" @buttonClick="updateSettingsAvatars">Добавить фото профиля
      </button-primary-utp1>
    </form>

    <form-in-line-fixed
        v-model="basic.name"
        :label="labelName"
        @clickButton="updateNames"
    >Ваше имя:
    </form-in-line-fixed>
    <form-in-line-fixed
        v-model="basic.email"
        :label="labelEmail"
        @clickButton="updateEmails"
    >Ваш e-mail:
    </form-in-line-fixed>
    <form-in-line-fixed
        v-model="basic.phone"
        :label="labelPhone"
        @clickButton="updatePhones"
    >Ваш телефон:
    </form-in-line-fixed>
  </main>
  <sidebar-right-utp1
      :items="sidebarRight"
  />
</template>

<script>
import useUser from "../../hooks/rest/settings/useUser";
import useError from "../../hooks/error/useError";
import useRedirect from "../../hooks/rest/useRedirect";
import {onMounted, ref, watch} from "vue";
import EntryHeaderUtp1 from "../../components/UI/headers/EntryHeaderUtp1";
import FormInLine from "../../components/UI/forms/FormInLine";
import SidebarRightUtp1 from "../../components/system/SidebarRightUtp1";
import FormInLineFixed from "../../components/UI/forms/FormInLineFixed";
import PreloaderMain from "../../components/system/preloader/PreloaderMain";
import AlertDanger from "../../components/UI/alerts/AlertDanger";
import AlertWarning from "../../components/UI/alerts/AlertWarning";
import DoubleButtonUtp1 from "../../components/UI/buttons/DoubleButtonUtp1";
import ButtonPrimaryUtp1 from "../../components/UI/buttons/ButtonPrimaryUtp1";
import useImages from "../../hooks/rest/files/useImages";

export default {
  name: "settings",
  components: {
    ButtonPrimaryUtp1,
    DoubleButtonUtp1,
    AlertWarning,
    AlertDanger,
    PreloaderMain,
    FormInLineFixed,
    SidebarRightUtp1,
    FormInLine,
    EntryHeaderUtp1
  },
  setup() {
    const sidebarRight = [
      {
        active: true,
        basic: true,
        disabled: false,
        route: '/settings',
        name: 'Настройки'
      },
      {
        active: false,
        basic: true,
        disabled: false,
        route: '/settings/security',
        name: 'Безопасность'
      },
    ]

    const updateImageButton = {
      primaryTitle: "Изменить",
      primaryDisabled: false,
      secondaryTitle: "Удалить",
      secondaryDisabled: false
    }

    const newSettingsAvatar = ref(null)
    const settingsAvatar = ref(null)

    const avatarUpload = () => {
      settingsAvatar.value = newSettingsAvatar.value.files[0]
    }

    const updateSettingAvatar = () => {
      let formData = new FormData()
      formData.append('file', settingsAvatar.value)
      const form = document.querySelector('#grout-settings-avatar')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        updateSettingsAvatar(formData)
        settingsAvatar.value = null
        newSettingsAvatar.value.value = ''
        form.classList.remove("was-validated")
      }
    }

    const labelName = {
      id: "name",
      placeholder: "Имя",
      button: "Изменить",
      invalidFeedback: "Некорректное имя"
    }
    const labelEmail = {
      id: "email",
      type: "email",
      placeholder: "E-mail",
      button: "Изменить",
      invalidFeedback: "Некорректный e-mail",
    }
    const labelPhone = {
      id: "phone",
      type: "phone",
      placeholder: "Телефон",
      button: "Изменить",
      invalidFeedback: "Некорректный телефон"
    }

    const redirectButton = ref("")

    const resetException = () => {
      userException.value = null
      imagesException.value = null
    }

    const updateNames = () => {
      resetException()
      updateName()
    }

    const updateEmails = () => {
      resetException()
      updateEmail()
    }

    const updatePhones = () => {
      resetException()
      updatePhone()
    }

    const updateSettingsAvatars = () => {
      resetException()
      updateSettingAvatar()
    }

    const deleteSettingsAvatars = () => {
      resetException()
      deleteSettingsAvatar()
    }

    const {
      imagesLoader,
      imagesException,
      updateSettingsAvatar,
      deleteSettingsAvatar,
    } = useImages()
    const {userLoader, getUser, basic, updateName, updateEmail, updatePhone, userException, hideEmail} = useUser()
    const {error, errorMessage} = useError()
    const {redirect, buttonRedirect, button} = useRedirect(hideEmail)

    onMounted(() => {
      getUser()
    })

    watch(userException, () => {
      error(userException.value)
    })

    watch(imagesException, () => {
      error(imagesException.value)
    })

    watch(hideEmail, () => {
      buttonRedirect()
      if (!button.value) {
        redirectButton.value = ""
      } else redirectButton.value = "Перейти в свой почтовый ящик"
    })

    return {
      userLoader,
      sidebarRight,

      imagesLoader,
      newSettingsAvatar,
      avatarUpload,
      settingsAvatar,
      updateSettingsAvatars,
      deleteSettingsAvatars,
      updateImageButton,

      basic,
      labelName,
      labelEmail,
      labelPhone,
      updateNames,
      updateEmails,
      updatePhones,
      errorMessage,
      redirectButton,
      hideEmail,
      redirect
    }
  }
}
</script>

<style scoped>

@media (min-width: 576px) {
  .delete-pb {
    margin-bottom: -1rem;
  }
}

@media (max-width: 575px) {
  .delete-pb {
    margin-bottom: -2rem;
  }
}

</style>