<template>
  <main>
    <preloader-main v-if="loaderShopSettings"/>
    <div :class="exceptionShopSettings? 'delete-pb' : ''">
      <header-back-utp1
          :header="header"
      />
    </div>

    <div class="pt-3" v-if="exceptionShopSettings">
      <alert-danger>{{ exceptionShopSettings }}</alert-danger>
    </div>

    <form id="addShopSettingForm" @submit.prevent novalidate>
      <div class="mb-3">
        <label class="form-label required-label" for="name">Название магазина:</label>
        <input-utp1
            id="name"
            placeholder="Введите название магазина"
            v-model:model-value="newUtpPays.name"
            type="text"
            required
            minlength="2"
            maxlength="255"
            autocomplete="off"
        />
      </div>
      <button-primary-utp1 @buttonClick="addUtpPay">Создать</button-primary-utp1>
    </form>

  </main>
  <sidebar-right-utp1>

  </sidebar-right-utp1>
</template>

<script>
import {ref} from "vue";
import SidebarRightUtp1 from "@/components/system/SidebarRightUtp1.vue";
import PreloaderMain from "@/components/system/preloader/PreloaderMain.vue";
import HeaderBackUtp1 from "@/components/UI/headers/HeaderBackUtp1.vue";
import AlertDanger from "@/components/UI/alerts/AlertDanger.vue";
import useShopSettings from "@/hooks/rest/shop/useShopSettings";
import InputUtp1 from "@/components/UI/inputs/InputUtp1.vue";
import ButtonPrimaryUtp1 from "@/components/UI/buttons/ButtonPrimaryUtp1.vue";
import {useRouter} from "vue-router";

export default {
  name: "add-shop-setting",
  components: {ButtonPrimaryUtp1, InputUtp1, AlertDanger, HeaderBackUtp1, PreloaderMain, SidebarRightUtp1},
  setup() {
    const router = useRouter()

    const header = {
      title: "Добавить магазин",
      buttonRedirect: "/shop/settings"
    }

    const newUtpPays = ref({
      name: ""
    })

    const {loaderShopSettings, exceptionShopSettings, newUtpPay, newUtpPayId} = useShopSettings()

    const addUtpPay = async () => {
      const form = document.querySelector('#addShopSettingForm')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        await newUtpPay(newUtpPays.value)
        if (!exceptionShopSettings.value) {
          await router.push("/shop/settings/" + newUtpPayId.value)
        }
      }
    }

    return {header, loaderShopSettings, exceptionShopSettings, newUtpPays, addUtpPay}
  }
}
</script>

<style scoped>

</style>
