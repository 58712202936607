<template>
  <div class="bg-white h-100 w-100 position-fixed" style="z-index: 1030"/>
  <div class="vh-100 vw-100 top-0 start-0 bg-black opacity-75 position-fixed full-spinner-z-index"/>
  <div class="position-fixed full-spinner-z-index full-spinner-position">
    <div class="spinner-border text-light full-spinner-size" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "preloader-full"
}
</script>

<style scoped>

</style>