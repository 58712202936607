<template>
  <div class="d-sm-inline d-grid gap-2">
    <button type="button" class="btn btn-primary" :class="loader ? 'disabled' : disabled ? 'disabled' : ''"
            @click="buttonClick">
      <span v-if="loader" class="spinner-border spinner-border-sm"></span>
      <span :class="loader ? 'ps-2' : ''" role="status"><slot></slot></span>
    </button>
  </div>
</template>

<script>
export default {
  name: "button-primary-utp1",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loader: {
      type: Boolean,
      default: false
    }
  },
  setup(_, {emit}) {
    const buttonClick = () => {
      emit("buttonClick")
    }
    return {buttonClick}
  }
}
</script>

<style scoped>

</style>