<template>
  <header class="pt-1 pb-2 mb-4 border-bottom">
    <h1 class="h3 fw-light">
      <slot></slot>
    </h1>
  </header>
</template>

<script>
export default {
  name: "entry-header-utp1",
}
</script>

<style scoped>

</style>