<template>
  <div class="row-form">
    <div class="row">

      <label class="form-label" for="singleMailingName">Название рассылки (отображается только для Вас):</label>
      <div class="pb-3">
        <input-utp1
            id="singleMailingName"
            v-model:model-value="singleMailing.name"
            placeholder="Введите название рассылки"
            type="text"
            required
            minlength="1"
            maxlength="255"
            autocomplete="off"
            :disabled="readOnly"
            :readonly="readOnly"
        />
      </div>

      <label class="form-label">Время отправки:</label>
      <div class="col-sm-3 pb-2">
        <!--suppress JSUnresolvedReference -->
        <input-utp1 type="date" v-model:model-value="sendingDateTimeTz.date" :disabled="readOnly" required></input-utp1>
      </div>
      <div class="col-sm-3 pb-2">
        <!--suppress JSUnresolvedReference -->
        <input-utp1 type="time" v-model:model-value="sendingDateTimeTz.time" :disabled="readOnly" required></input-utp1>
      </div>
      <div class="col-auto pb-3"><label for="timezone" class="col-form-label">Часовой пояс:</label></div>
      <div class="col">
        <!--suppress JSUnresolvedReference -->
        <select class="form-select" id="timezone" aria-label="Select timezone" v-model="sendingDateTimeTz.timezone"
                :disabled="readOnly">
          <option v-for="item in formats" :value="item.value">{{ item.name }}</option>
        </select>
      </div>

      <label class="form-label">Отправить по:</label>
      <div role="tablist" class="pb-3">
        <div class="form-check form-check-inline">
          <input class="form-check-input" id="inlineRadioGroups" name="inlineRadioOptions" data-bs-toggle="tab"
                 data-bs-target="#groups" v-model="singleMailing.sendingType"
                 type="radio" role="tab" aria-controls="groups" value="GROUPS" :disabled="sendingTypeDisabled"/>
          <label class="form-check-label" for="inlineRadioGroups">Группам и счетам</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" id="inlineRadioActivity" name="inlineRadioOptions" data-bs-toggle="tab"
                 data-bs-target="#activity" v-model="singleMailing.sendingType"
                 type="radio" role="tab" aria-controls="activity" value="ACTIVITY" disabled/>
          <label class="form-check-label" for="inlineRadioActivity">Активности и действиям</label>
        </div>
      </div>

      <div class="tab-content">
        <div :class="singleMailing.sendingType === 'GROUPS' ?
        'tab-pane pb-3 active' : 'tab-pane pb-3'" id="groups">
          <label class="form-label">Выберите группы:</label>
          <div class="form-control">
            <groups-accordion v-model:groups="localSingleMailingGroups" :disabled="readOnly"/>
          </div>
        </div>
        <div :class="singleMailing.sendingType === 'ACTIVITY' ?
        'tab-pane pb-3 active' : 'tab-pane pb-3'" id="activity">
          ACTIVITY
        </div>
      </div>

      <label class="form-label" for="singleMailingChannelsTypes">Каналы отправки:</label>
      <div class="pb-3">
        <div v-for="item in singleMailingChannelsTypes"
             class="form-check form-check-inline"
             id="singleMailingChannelsTypes">
          <input class="form-check-input" type="checkbox" v-model="localSingleMailingChannels"
                 :id="item.value" :value="item.value"
                 :disabled="item.disabled ? item.disabled : readOnly">
          <label class="form-check-label" :for="item.value">{{ item.name }}</label>
        </div>
      </div>

      <div class="pb-2">
        <button-primary-utp1
            data-bs-toggle="collapse" data-bs-target="#addExclusionsExample"
            aria-expanded="false" aria-controls="addExclusionsExample">
          Добавить исключения <i class="ps-1 bi bi-caret-down-fill"></i>
        </button-primary-utp1>
      </div>
      <div class="row collapse" id="addExclusionsExample">
        <label class="form-label">Только подписавшимся:</label>
        <div class="col-auto pb-3"><label for="sendAfter" class="col-form-label">с:</label></div>
        <div class="col col-sm-3">
          <input-utp1 id="sendAfter" type="date" v-model="singleMailing.signedAfter"
                      :disabled="readOnly"></input-utp1>
        </div>
        <div class="col-auto pb-3"><label for="sendBefore" class="col-form-label">по:</label></div>
        <div class="col col-sm-3">
          <input-utp1 id="sendBefore" type="date" v-model="singleMailing.signedBefore"
                      :disabled="readOnly"></input-utp1>
        </div>
        <label class="form-label">Исключить группы:</label>
        <div class="pb-1">
          <div class="form-control">
            <groups-accordion v-model:groups="localSingleMailingGroupsNoSending" :disabled="readOnly"/>
          </div>
        </div>
        <label class="form-text">
          Все подписчики, состоящие в выбранных группах, не получат текущее письмо.
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import InputUtp1 from "../../../UI/inputs/InputUtp1";
import GroupsAccordion from "../../../UI/accordions/GroupsAccordion";
import ButtonPrimaryUtp1 from "../../../UI/buttons/ButtonPrimaryUtp1";
import useMoment from "../../../../hooks/useMoment";
import {ref, watch} from "vue";

export default {
  name: "single-mailing-basic",
  components: {ButtonPrimaryUtp1, GroupsAccordion, InputUtp1},
  props: {
    singleMailing: {
      type: Object,
      default: null
    },
    singleMailingGroups: {
      type: Array,
      default: []
    },
    singleMailingInvoices: {
      type: Array,
      default: []
    },
    singleMailingChannels: {
      type: Array,
      default: []
    },
    singleMailingGroupsNoSending: {
      type: Array,
      default: []
    },
    singleMailingInvoicesNoSending: {
      type: Array,
      default: []
    },
    sendingDateTimeTz: {
      type: Object,
      default: null
    },
    sendingTypeDisabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.localSingleMailingGroups = this.singleMailingGroups
    this.localSingleMailingChannels = this.singleMailingChannels
    this.localSingleMailingGroupsNoSending = this.singleMailingGroupsNoSending
  },
  watch: {
    singleMailingGroups() {
      this.localSingleMailingGroups = this.singleMailingGroups
    },
    singleMailingChannels() {
      this.localSingleMailingChannels = this.singleMailingChannels
    },
    singleMailingGroupsNoSending() {
      this.localSingleMailingGroupsNoSending = this.singleMailingGroupsNoSending
    },

    singleMailingInvoices() {
      this.updateSingleMailingInvoices()
    },
    singleMailingInvoicesNoSending() {
      this.updateSingleMailingInvoicesNoSending()
    }

  },
  setup(props, {emit}) {
    const localSingleMailingGroups = ref()
    const localSingleMailingChannels = ref()
    const localSingleMailingGroupsNoSending = ref()

    const singleMailingChannelsTypes = [{
      name: "Умная отправка",
      value: "SMART",
      disabled: true
    }, {
      name: "E-mail",
      value: "EMAIL",
      disabled: false
    }, {
      name: "ВКонтакте",
      value: "VK",
      disabled: false
    }, {
      name: "Telegram",
      value: "TELEGRAM",
      disabled: true
    }]

    const {formats} = useMoment()

    watch(localSingleMailingGroups, (newVal) => {
      emit("update:singleMailingGroups", newVal)
    })

    const updateSingleMailingInvoices = () => {
      emit("update:singleMailingInvoices", props.singleMailingInvoices)
    }

    watch(localSingleMailingChannels, (newVal) => {
      emit("update:singleMailingChannels", newVal)
    })
    watch(localSingleMailingGroupsNoSending, (newVal) => {
      emit("update:singleMailingGroupsNoSending", newVal)
    })

    const updateSingleMailingInvoicesNoSending = () => {
      emit("update:singleMailingInvoicesNoSending", props.singleMailingInvoicesNoSending)
    }

    return {
      localSingleMailingGroups,
      localSingleMailingChannels,
      localSingleMailingGroupsNoSending,
      singleMailingChannelsTypes,
      formats,
      updateSingleMailingInvoices,
      updateSingleMailingInvoicesNoSending
    }
  }
}
</script>

<style scoped>

</style>