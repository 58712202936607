<template>
  <header-nav-utp1 :nav="nav"/>
  <div v-if="mailingErrorMessage">
    <alert-danger>{{ mailingErrorMessage }}</alert-danger>
  </div>
  <form id="single-mailing" @submit.prevent novalidate>
    <div class="tab-content">

      <div class="tab-pane active" id="basic">
        <single-mailing-basic
            v-model:singleMailing="newSingleMailings.singleMailing"
            v-model:singleMailingGroups="newSingleMailings.singleMailingGroups"
            v-model:singleMailingInvoices="newSingleMailings.singleMailingInvoices"
            v-model:singleMailingChannels="newSingleMailings.singleMailingChannels"
            v-model:singleMailingGroupsNoSending="newSingleMailings.singleMailingGroupsNoSending"
            v-model:singleMailingInvoicesNoSending="newSingleMailings.singleMailingInvoicesNoSending"
            v-model:sendingDateTimeTz="sendingDateTimeTz"
            :sendingTypeDisabled="sendingTypeDisabled"
            :readOnly="readOnly"
        />
      </div>

      <div class="tab-pane" id="text">
        <mailing-letter
            v-model:newMailingLetters="newSingleMailings.newMailingLetters"
            :readOnly="readOnly"
        />
      </div>

      <div class="tab-pane" id="social">
        <social-letters
            v-model:newMailingLetters="newSingleMailings.newMailingLetters"
            :readOnly="readOnly"
        />
      </div>

    </div>
  </form>
  <div class="pt-3">
    <double-button-utp1
        v-if="!readOnly"
        :buttons="buttons"
        @buttonPrimaryClick="buttonPrimaryClick"
        @buttonSecondaryClick="buttonSecondaryClick"
    />
    <button-primary-utp1
        v-else
        @buttonClick="duplicateSingleMailing"
    >Дублировать
    </button-primary-utp1>
  </div>
</template>

<!--suppress JSCheckFunctionSignatures -->
<script>
import HeaderNavUtp1 from "../../../UI/headers/HeaderNavUtp1";
import SingleMailingBasic from "./SingleMailingBasic";
import MailingLetter from "../MailingLetter";
import {onUnmounted, ref, watch} from "vue";
import useMoment from "../../../../hooks/useMoment";
import ButtonPrimaryUtp1 from "../../../UI/buttons/ButtonPrimaryUtp1";
import DoubleButtonUtp1 from "../../../UI/buttons/DoubleButtonUtp1";
import AlertDanger from "../../../UI/alerts/AlertDanger";
import {onBeforeRouteLeave} from "vue-router";
import SocialLetters from "../SocialLetters";

export default {
  name: "single-mailing",
  components: {
    SocialLetters, AlertDanger, DoubleButtonUtp1, ButtonPrimaryUtp1, MailingLetter, SingleMailingBasic, HeaderNavUtp1
  },
  props: {
    newSingleMailing: {
      type: Object,
      default: null
    },
    buttons: {
      type: Object,
      default: {
        primaryTitle: "Сохранить",
        secondaryTitle: "Отменить"
      }
    },
    mailingErrorMessage: {
      type: String.nullable,
      default: null
    },
    sendingTypeDisabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    newSingleMailing() {
      this.canceled()
      this.setDefaultBindings()
    }
  },
  emits: ["buttonClick", "duplicateSingleMailing"],
  setup(props, {emit}) {
    const nav = [{
      value: "basic",
      name: "Настройки письма",
      active: true,
      disabled: false
    }, {
      value: "text",
      name: "Текст",
      active: false,
      disabled: false
    }, {
      value: "social",
      name: "Социальные сети",
      active: false,
      disabled: false
    }, {
      value: "smsPush",
      name: "SMS / PUSH",
      active: false,
      disabled: true
    }]

    const newSingleMailings = ref({
      newMailingLetters: {
        mailingLetters: {
          letterTopic: null,
          emailBindingId: null,
          letterTitle: null,
          letterText: null,
          actionText: null,
          actionButtonUrl: null,
          actionButtonText: null,
          warningText: null,
          informationTitle: null,
          informationText: null,
          vkBindingId: null,
          vkText: null,
          vkActionButtonUrl: null,
          vkActionButtonText: null
        },
        mailingLetterLinks: [],
        mailingLetterImages: [],
        vkLetterLinks: [],
        vkLetterImages: [],
        emailBindings: [],
        vkBindings: []
      },
      singleMailing: {
        name: "",
        sendingDateTimeTz: "",
        sendingType: null,
        status: null,
        signedAfter: null,
        signedBefore: null
      },
      singleMailingGroups: [],
      singleMailingInvoices: [],
      singleMailingChannels: [],
      singleMailingGroupsNoSending: [],
      singleMailingInvoicesNoSending: []
    })

    const sendingDateTimeTz = ref({
      date: '',
      time: '',
      timezone: 'UTC_P3'
    })

    const reset = () => {
      newSingleMailings.value.singleMailing.name = props.newSingleMailing.singleMailing.name
      newSingleMailings.value.singleMailing.sendingDateTimeTz = props.newSingleMailing.singleMailing.sendingDateTimeTz
      newSingleMailings.value.singleMailing.sendingType = props.newSingleMailing.singleMailing.sendingType
      newSingleMailings.value.singleMailing.status = props.newSingleMailing.singleMailing.status
      newSingleMailings.value.singleMailing.signedAfter = props.newSingleMailing.singleMailing.signedAfter ?
          formattedDateTimeTz(props.newSingleMailing.singleMailing.signedAfter, 'UTC_P3', 'YYYY-MM-DD') : null
      newSingleMailings.value.singleMailing.signedBefore = props.newSingleMailing.singleMailing.signedBefore ?
          formattedDateTimeTz(props.newSingleMailing.singleMailing.signedBefore, 'UTC_P3', 'YYYY-MM-DD') : null
      newSingleMailings.value.singleMailingGroups = props.newSingleMailing.singleMailingGroups
      newSingleMailings.value.singleMailingInvoices = props.newSingleMailing.singleMailingInvoices
      newSingleMailings.value.singleMailingChannels = props.newSingleMailing.singleMailingChannels
      newSingleMailings.value.singleMailingGroupsNoSending = props.newSingleMailing.singleMailingGroupsNoSending
      newSingleMailings.value.singleMailingInvoicesNoSending = props.newSingleMailing.singleMailingInvoicesNoSending
      sendingDateTimeTz.value.date =
          formattedDateTimeTz(props.newSingleMailing.singleMailing.sendingDateTimeTz, 'UTC_P3', 'YYYY-MM-DD')
      sendingDateTimeTz.value.time =
          formattedDateTimeTz(props.newSingleMailing.singleMailing.sendingDateTimeTz, 'UTC_P3', 'HH:mm')
      sendingDateTimeTz.value.timezone = 'UTC_P3'
      newSingleMailings.value.newMailingLetters.mailingLetters.letterTopic = props.newSingleMailing.newMailingLetters.mailingLetters.letterTopic
      newSingleMailings.value.newMailingLetters.mailingLetters.emailBindingId = props.newSingleMailing.newMailingLetters.mailingLetters.emailBindingId
      newSingleMailings.value.newMailingLetters.mailingLetters.letterTitle = props.newSingleMailing.newMailingLetters.mailingLetters.letterTitle
      newSingleMailings.value.newMailingLetters.mailingLetters.letterText = props.newSingleMailing.newMailingLetters.mailingLetters.letterText
      newSingleMailings.value.newMailingLetters.mailingLetters.actionText = props.newSingleMailing.newMailingLetters.mailingLetters.actionText
      newSingleMailings.value.newMailingLetters.mailingLetters.actionButtonUrl = props.newSingleMailing.newMailingLetters.mailingLetters.actionButtonUrl
      newSingleMailings.value.newMailingLetters.mailingLetters.actionButtonText = props.newSingleMailing.newMailingLetters.mailingLetters.actionButtonText
      newSingleMailings.value.newMailingLetters.mailingLetters.warningText = props.newSingleMailing.newMailingLetters.mailingLetters.warningText
      newSingleMailings.value.newMailingLetters.mailingLetters.informationTitle = props.newSingleMailing.newMailingLetters.mailingLetters.informationTitle
      newSingleMailings.value.newMailingLetters.mailingLetters.informationText = props.newSingleMailing.newMailingLetters.mailingLetters.informationText
      newSingleMailings.value.newMailingLetters.mailingLetters.vkBindingId = props.newSingleMailing.newMailingLetters.mailingLetters.vkBindingId
      newSingleMailings.value.newMailingLetters.mailingLetters.vkText = props.newSingleMailing.newMailingLetters.mailingLetters.vkText
      newSingleMailings.value.newMailingLetters.mailingLetters.vkActionButtonUrl = props.newSingleMailing.newMailingLetters.mailingLetters.vkActionButtonUrl
      newSingleMailings.value.newMailingLetters.mailingLetters.vkActionButtonText = props.newSingleMailing.newMailingLetters.mailingLetters.vkActionButtonText
      newSingleMailings.value.newMailingLetters.mailingLetterLinks = props.newSingleMailing.newMailingLetters.mailingLetterLinks ?
          props.newSingleMailing.newMailingLetters.mailingLetterLinks.slice(0) : []
      newSingleMailings.value.newMailingLetters.mailingLetterImages = props.newSingleMailing.newMailingLetters.mailingLetterImages ?
          props.newSingleMailing.newMailingLetters.mailingLetterImages.slice(0) : []
      newSingleMailings.value.newMailingLetters.vkLetterLinks = props.newSingleMailing.newMailingLetters.vkLetterLinks ?
          props.newSingleMailing.newMailingLetters.vkLetterLinks.slice(0) : []
      newSingleMailings.value.newMailingLetters.vkLetterImages = props.newSingleMailing.newMailingLetters.vkLetterImages ?
          props.newSingleMailing.newMailingLetters.vkLetterImages.slice(0) : []
      newSingleMailings.value.newMailingLetters.emailBindings = props.newSingleMailing.newMailingLetters.emailBindings
      newSingleMailings.value.newMailingLetters.vkBindings = props.newSingleMailing.newMailingLetters.vkBindings
    }

    const canceled = async () => {
      await reset()
      unSetOnBeforeUnload()
    }

    const setDefaultBindings = async () => {
      if (!newSingleMailings.value.newMailingLetters.mailingLetters.emailBindingId) {
        await props.newSingleMailing.newMailingLetters.emailBindings.forEach(item => {
          if (item.default) newSingleMailings.value.newMailingLetters.mailingLetters.emailBindingId = item.id
        })
      } else {
        let valid = false
        await props.newSingleMailing.newMailingLetters.emailBindings.forEach(item => {
          if (item.id === newSingleMailings.value.newMailingLetters.mailingLetters.emailBindingId)
            valid = true
        })
        if (!valid) {
          newSingleMailings.value.newMailingLetters.mailingLetters.emailBindingId = null
        }
      }
      if (!newSingleMailings.value.newMailingLetters.mailingLetters.vkBindingId) {
        await props.newSingleMailing.newMailingLetters.vkBindings.forEach(item => {
          if (item.default) newSingleMailings.value.newMailingLetters.mailingLetters.vkBindingId = item.id
        })
      } else {
        let valid = false
        await props.newSingleMailing.newMailingLetters.vkBindings.forEach(item => {
          if (item.id === newSingleMailings.value.newMailingLetters.mailingLetters.vkBindingId)
            valid = true
        })
        if (!valid) {
          newSingleMailings.value.newMailingLetters.mailingLetters.vkBindingId = null
        }
      }
      unSetOnBeforeUnload()
    }

    watch(sendingDateTimeTz.value, () => {
      newSingleMailings.value.singleMailing.sendingDateTimeTz =
          sendingDateTimeTz.value.date + 'T' + sendingDateTimeTz.value.time + ':00.000' + formatsString(sendingDateTimeTz.value.timezone)
    })

    const buttonPrimaryClick = () => {
      const form = document.querySelector('#single-mailing')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        emit("buttonClick", newSingleMailings.value)
        unSetOnBeforeUnload()
      }
    }
    const buttonSecondaryClick = () => {
      canceled()
      setDefaultBindings()
    }
    const duplicateSingleMailing = () => {
      emit("duplicateSingleMailing")
    }

    const {formattedDateTimeTz, formatsString} = useMoment()

    watch(newSingleMailings.value, () => {
      setOnBeforeUnload()
    })

    const beforeRouteLeave = ref(false)

    const setOnBeforeUnload = () => {
      beforeRouteLeave.value = true
      onbeforeunload = () => {
        return true
      }
    }

    const unSetOnBeforeUnload = () => {
      beforeRouteLeave.value = false
      onbeforeunload = null
    }

    onUnmounted(() => {
      onbeforeunload = null
    })

    onBeforeRouteLeave(() => {
      if (beforeRouteLeave.value === true) {
        const answer = window.confirm(
            'Возможно, внесенные изменения не сохранятся.'
        )
        if (!answer) return false
      }
    })

    return {
      nav,
      newSingleMailings,
      sendingDateTimeTz,
      canceled,
      setDefaultBindings,
      buttonPrimaryClick,
      buttonSecondaryClick,
      duplicateSingleMailing
    }
  }
}
</script>

<style scoped>

</style>