<template>
  <main>
    <h1>Начать работу с UTP1</h1>
    <p class="fs-5 col-md-8">Быстро, легко и бесплатно переходите на ведение своего онлайн-бизнеса через систему UTP1.
      Перед началом обязательно посмотрите 2-х - минутное видео от создателя проекта!</p>

    <div class="mb-5">
      <button type="button" class="btn btn-primary btn-lg px-4" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Смотреть видеоприветствие
      </button>
    </div>

    <hr class="col-3 col-md-2 mb-5">

    <div class="row-form">
      <div class="row">
        <div class="col-md-6">
          <h2>Запущенные модули</h2>
          <p>Все анонсированные модули прошли тестирование и готовы к полноценному использованию.</p>
          <ul class="guide-list ps-0">
            <li class="d-flex align-items-start mb-1"><a @click="$router.push('/mailing')" class="link-primary">
              Рассылка на e-mail</a></li>
            <li class="d-flex align-items-start mb-1"><a @click="$router.push('/mailing')" class="link-primary">
              Рассылка ВКонтакте</a></li>
            <li class="d-flex align-items-start mb-1"><a @click="$router.push('/shop/products')" class="link-primary">
              Создание продуктов</a></li>
            <li class="d-flex align-items-start mb-1"><a @click="$router.push('/shop/invoices')" class="link-primary">
              Оформление заказов</a></li>
            <li class="text-muted d-flex align-items-start mb-1">Приём платежей (скоро!)</li>
            <li class="text-muted d-flex align-items-start mb-1">Публикация чеков по 54-ФЗ (скоро!)</li>
          </ul>
        </div>

        <div class="col-md-6">
          <h2>Первые шаги</h2>
          <p>Произведите элементарную настройку и начните отправлять письма. Для бесплатного переноса белой подписной
            базы обратитесь в службу поддержки!</p>
          <ul class="guide-list ps-0">
            <li class="d-flex align-items-start mb-1">
              <a @click="$router.push('/mailing/settings')" class="link-primary">
                Настройте рассылку</a></li>
            <li class="d-flex align-items-start mb-1">
              <a @click="$router.push('/mailing/settings')" class="link-primary">
                Привяжите e-mail и ВКонтакте</a></li>
            <li class="d-flex align-items-start mb-1">
              <a href="https://vk.com/utp_1" target="_blank" class="link-primary">
                Перенесите подписную базу</a></li>
            <li class="d-flex align-items-start mb-1">
              <a @click="$router.push('/mailing')" class="link-primary">
                Отправляйте письма</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0 pe-0">
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-0">
            <div class="ratio ratio-16x9">
              <iframe class="rounded-4"
                      src="https://www.youtube.com/embed/CSVv5dBfPTo?controls=0&rel=0&modestbranding=1&showinfo=0&fs=0&enablejsapi=1"
                      allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>

  <sidebar-right-utp1/>

</template>

<script>
import SidebarRightUtp1 from "../components/system/SidebarRightUtp1";
import EntryHeaderUtp1 from "../components/UI/headers/EntryHeaderUtp1";

export default {
  name: "home",
  components: {EntryHeaderUtp1, SidebarRightUtp1},
}
</script>

<style scoped>

.guide-list li::before {
  display: block;
  flex-shrink: 0;
  width: 1.5em;
  height: 1.5em;
  margin-right: .5rem;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23212529' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") no-repeat center center / 100% auto;
}

.modal-content {
  background-color: transparent;
  border: none;
}

</style>