<template>
  <!--suppress JSUnresolvedReference -->
  <header-nav-utp1
      :nav="!initProduct ? [] : newProduct.recurrent ? navRecurrent : newProduct.deposit ? navPrepayment : navNoPrepayment"
      :init-header-nav="true"
  />
  <div v-if="error">
    <alert-danger>{{ productErrorMessage }}</alert-danger>
  </div>
  <form id="product" @submit.prevent novalidate>
    <div class="tab-content">
      <div class="tab-pane active" id="basic">
        <product-basic
            :products-categories="productsCategories"
            v-model:product="newProduct"
            v-model:date-time-tz="dateTimeTzCancellation"
            :id="id"
        />
      </div>
      <div class="tab-pane" id="get">
        <product-get
            v-model:product="newProduct"
            :personal-commissions="personalCommissions"
            :disable-personal-commissions="disablePersonalCommissions"
            @addPersonalCommissions="addPersonalCommissions"
            @updatePersonalCommissions="updatePersonalCommissions"
            @deletePersonalCommissions="deletePersonalCommissions"
        />
      </div>
      <div v-if="!newProduct.recurrent" class="tab-pane" id="prepayment">
        <product-prepayment
            v-model:product="newProduct"
            v-model:date-time-tz="prepaymentDateTimeTzCancellation"
        />
      </div>
    </div>
  </form>
  <div class="pt-3">
    <double-button-utp1
        :buttons="buttons"
        @buttonPrimaryClick="buttonClick"
        @buttonSecondaryClick="canceled"
    />
  </div>
</template>

<!--suppress JSValidateTypes, JSIncompatibleTypesComparison, JSCheckFunctionSignatures -->
<script>
import HeaderNavUtp1 from "../../../UI/headers/HeaderNavUtp1";
import AlertDanger from "../../../UI/alerts/AlertDanger";
import DoubleButtonUtp1 from "../../../UI/buttons/DoubleButtonUtp1";
import ProductBasic from "./ProductBasic";
import {ref, watch, onUnmounted} from "vue";
import useMoment from "../../../../hooks/useMoment";
import ProductGet from "./ProductGet";
import ProductPrepayment from "./ProductPrepayment";
import useProductError from "../../../../hooks/error/useProductError";
import {onBeforeRouteLeave} from "vue-router";

export default {
  name: "product",
  components: {ProductPrepayment, ProductGet, ProductBasic, DoubleButtonUtp1, AlertDanger, HeaderNavUtp1},
  props: {
    buttons: {
      type: Object,
      default: {
        primaryTitle: "Сохранить",
        secondaryTitle: "Отменить"
      }
    },
    product: {
      type: Object
    },
    productsCategories: {
      type: Array,
      default: []
    },
    personalCommissions: {
      type: Object
    },
    disablePersonalCommissions: {
      type: Boolean
    },
    error: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  watch: {
    product() {
      this.product.affiliateCommissions.sort((a, b) => a.level - b.level);
      this.canceled()
    },
    error() {
      this.updateProductErrorMessage()
    }
  },
  emits: ["buttonClick", "addPersonalCommissions", "updatePersonalCommissions", "deletePersonalCommissions"],
  setup(props, {emit}) {
    const navPrepayment = [{
      value: 'basic',
      name: 'Основное',
      active: true,
      disabled: false
    }, {
      value: 'get',
      name: 'Получение продукта',
      active: false,
      disabled: false
    }, {
      value: 'prepayment',
      name: 'Предоплата',
      active: false,
      disabled: false
    }]

    const navNoPrepayment = [{
      value: 'basic',
      name: 'Основное',
      active: true,
      disabled: false
    }, {
      value: 'get',
      name: 'Получение продукта',
      active: false,
      disabled: false
    }, {
      value: 'prepayment',
      name: 'Предоплата',
      active: false,
      disabled: true
    }]

    const navRecurrent = [{
      value: 'basic',
      name: 'Основное',
      active: true,
      disabled: false
    }, {
      value: 'get',
      name: 'Получение продукта',
      active: false,
      disabled: false
    }]

    const dateTimeTzCancellation = ref({
      date: '',
      time: '',
      timezone: 'UTC_P3'
    })

    const prepaymentDateTimeTzCancellation = ref({
      date: '',
      time: '',
      timezone: 'UTC_P3'
    })

    const newProduct = ref({
      name: '',
      categoryId: null,
      identifier: '',
      status: true,
      recurrent: false,
      cost: 0.01,
      currency: 'RUB',
      deposit: false,
      depositCost: null,
      updateCost: false,
      minCost: null,
      maxCost: null,
      autoCost: null,
      firstProlong: null,
      periodProlong: null,
      typeCancellation: 'DISABLED',
      periodCancellationDays: null,
      periodCancellationHours: null,
      periodCancellationMinutes: null,
      datetimeCancellation: null,
      affiliateCommissions: [],
      url: null,
      description: null,
      instructions: null,
      getProductPage: null,
      groupsPayments: [],
      deleteGroupsPayments: [],
      accessCodes: null,
      typePrepaymentCancellation: 'DISABLED',
      prepaymentCancellationDays: null,
      prepaymentCancellationHours: null,
      prepaymentCancellationMinutes: null,
      prepaymentDatetimeCancellation: null,
      groupsPrepayments: [],
      deleteGroupsPrepayments: []
    })

    const initProduct = ref(false)

    const reset = () => {
      newProduct.value.name = props.product.name
      newProduct.value.categoryId = props.product.categoryId
      newProduct.value.identifier = props.product.identifier
      newProduct.value.status = props.product.status
      newProduct.value.recurrent = props.product.recurrent
      newProduct.value.cost = props.product.cost
      newProduct.value.currency = props.product.currency
      newProduct.value.deposit = props.product.deposit
      newProduct.value.depositCost = props.product.depositCost
      newProduct.value.updateCost = props.product.updateCost
      newProduct.value.minCost = props.product.minCost
      newProduct.value.maxCost = props.product.maxCost
      newProduct.value.autoCost = props.product.autoCost
      newProduct.value.firstProlong = props.product.firstProlong
      newProduct.value.periodProlong = props.product.periodProlong
      newProduct.value.typeCancellation = props.product.typeCancellation
      newProduct.value.periodCancellationDays = props.product.periodCancellationDays
      newProduct.value.periodCancellationHours = props.product.periodCancellationHours
      newProduct.value.periodCancellationMinutes = props.product.periodCancellationMinutes
      newProduct.value.datetimeCancellation = props.product.datetimeCancellation
      newProduct.value.affiliateCommissions = props.product.affiliateCommissions ? props.product.affiliateCommissions.map(item => JSON.parse(JSON.stringify(item))) : []
      newProduct.value.url = props.product.url
      newProduct.value.description = props.product.description
      newProduct.value.instructions = props.product.instructions
      newProduct.value.getProductPage = props.product.getProductPage
      newProduct.value.groupsPayments = props.product.groupsPayments ? props.product.groupsPayments.slice(0) : []
      newProduct.value.deleteGroupsPayments = props.product.deleteGroupsPayments ? props.product.deleteGroupsPayments.slice(0) : []
      newProduct.value.accessCodes = props.product.accessCodes
      newProduct.value.typePrepaymentCancellation = props.product.typePrepaymentCancellation
      newProduct.value.prepaymentCancellationDays = props.product.prepaymentCancellationDays
      newProduct.value.prepaymentCancellationHours = props.product.prepaymentCancellationHours
      newProduct.value.prepaymentCancellationMinutes = props.product.prepaymentCancellationMinutes
      newProduct.value.prepaymentDatetimeCancellation = props.product.prepaymentDatetimeCancellation
      newProduct.value.groupsPrepayments = props.product.groupsPrepayments ? props.product.groupsPrepayments.slice(0) : []
      newProduct.value.deleteGroupsPrepayments = props.product.deleteGroupsPrepayments ? props.product.deleteGroupsPrepayments.slice(0) : []
      if (props.product.datetimeCancellation !== null) {
        dateTimeTzCancellation.value.date =
            formattedDateTimeTz(props.product.datetimeCancellation, 'UTC_P3', 'YYYY-MM-DD')
        dateTimeTzCancellation.value.time =
            formattedDateTimeTz(props.product.datetimeCancellation, 'UTC_P3', 'HH:mm')
        dateTimeTzCancellation.value.timezone = 'UTC_P3'
      } else {
        dateTimeTzCancellation.value.date = ''
        dateTimeTzCancellation.value.time = ''
        dateTimeTzCancellation.value.timezone = 'UTC_P3'
      }
      if (props.product.prepaymentDatetimeCancellation !== null) {
        prepaymentDateTimeTzCancellation.value.date =
            formattedDateTimeTz(props.product.prepaymentDatetimeCancellation, 'UTC_P3', 'YYYY-MM-DD')
        prepaymentDateTimeTzCancellation.value.time =
            formattedDateTimeTz(props.product.prepaymentDatetimeCancellation, 'UTC_P3', 'HH:mm')
        prepaymentDateTimeTzCancellation.value.timezone = 'UTC_P3'
      } else {
        prepaymentDateTimeTzCancellation.value.date = ''
        prepaymentDateTimeTzCancellation.value.time = ''
        prepaymentDateTimeTzCancellation.value.timezone = 'UTC_P3'
      }
    }

    const {formattedDateTimeTz, formatsString} = useMoment()

    watch(dateTimeTzCancellation.value, () => {
      if ((dateTimeTzCancellation.value.date !== '') && (dateTimeTzCancellation.value.time !== '') && (dateTimeTzCancellation.value.date !== null) && (dateTimeTzCancellation.value.time !== null)) {
        newProduct.value.datetimeCancellation =
            dateTimeTzCancellation.value.date + 'T' + dateTimeTzCancellation.value.time + ':00.000' + formatsString(dateTimeTzCancellation.value.timezone)
      } else newProduct.value.datetimeCancellation = null
    })

    watch(prepaymentDateTimeTzCancellation.value, () => {
      if ((prepaymentDateTimeTzCancellation.value.date !== '') && (prepaymentDateTimeTzCancellation.value.time !== '') && (prepaymentDateTimeTzCancellation.value.date !== null) && (prepaymentDateTimeTzCancellation.value.time !== null)) {
        newProduct.value.prepaymentDatetimeCancellation =
            prepaymentDateTimeTzCancellation.value.date + 'T' + prepaymentDateTimeTzCancellation.value.time + ':00.000' + formatsString(prepaymentDateTimeTzCancellation.value.timezone)
      } else newProduct.value.prepaymentDatetimeCancellation = null
    })

    const canceled = async () => {
      await reset()
      initProduct.value = true
      unSetOnBeforeUnload()
    }

    const buttonClick = () => {
      const form = document.getElementById('product')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        emit("buttonClick", newProduct.value)
        form.classList.remove("was-validated")
        unSetOnBeforeUnload()
      }
    }

    const addPersonalCommissions = () => {
      emit("addPersonalCommissions")
    }

    const updatePersonalCommissions = (personalCommission) => {
      emit("updatePersonalCommissions", personalCommission)
    }

    const deletePersonalCommissions = (id) => {
      emit("deletePersonalCommissions", id)
    }

    const {productErrorMessage, productError} = useProductError()

    const updateProductErrorMessage = () => {
      productError(props.error)
    }

    watch(newProduct.value, () => {
      setOnBeforeUnload()
    })

    const beforeRouteLeave = ref(false)

    const setOnBeforeUnload = () => {
      beforeRouteLeave.value = true
      onbeforeunload = () => {
        return true
      }
    }

    const unSetOnBeforeUnload = () => {
      beforeRouteLeave.value = false
      onbeforeunload = null
    }

    onUnmounted(() => {
      onbeforeunload = null
    })

    onBeforeRouteLeave(() => {
      if (beforeRouteLeave.value === true) {
        const answer = window.confirm('Возможно, внесенные изменения не сохранятся.')
        if (!answer) return false
      }
    })

    return {
      navPrepayment,
      navNoPrepayment,
      navRecurrent,
      newProduct,
      canceled,
      dateTimeTzCancellation,
      prepaymentDateTimeTzCancellation,
      buttonClick,
      addPersonalCommissions,
      updatePersonalCommissions,
      deletePersonalCommissions,
      productErrorMessage,
      updateProductErrorMessage,
      initProduct
    }
  }
}
</script>

<style scoped>

</style>