import axios from "axios";
import {ref} from 'vue';
import useTokens from "../../../useTokens";
import router from "../../../../router";
import {useRoute} from "vue-router";

export default function useContacts() {
    const route = useRoute()
    const contactsLoader = ref(false)
    const contactsException = ref(null)
    const number = ref(0)
    const contacts = ref({
        contacts: []
    })
    const contact = ref({})

    const getNumber = async () => {
        contactsException.value = null
        contactsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/contacts/number`, {headers: header, params: route.query})
                .then(response => {
                    number.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getNumber()
                    }
                })
        }
        contactsLoader.value = false
    }

    const getContacts = async () => {
        contactsException.value = null
        contactsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/contacts`, {headers: header, params: route.query},)
                .then(response => {
                    contacts.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getContacts()
                    }
                })
                .finally(() => contactsLoader.value = false)
        }
    }


    const addContact = async (contact) => {
        contactsException.value = null
        contactsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.post(`https://api.utp1.com/contacts`,
                contact,
                {headers: header})
                .then(() => {
                    router.push('/mailing/contacts')
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await addContact(contact)
                    } else try {
                        contactsException.value = e.response.data.error
                    } catch {
                        contactsException.value = "Exception"
                    }
                })
                .finally(() => contactsLoader.value = false)
        }
    }

    const getContact = async (profileId) => {
        contactsException.value = null
        contactsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/contacts/` + profileId,
                {headers: header})
                .then(response => {
                    contact.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getContact(profileId)
                    } else (await router.push('/mailing/contacts'))
                })
                .finally(() => contactsLoader.value = false)
        }
    }

    const updateContact = async (profileId, updateContacts) => {
        contactsException.value = null
        contactsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(`https://api.utp1.com/contacts/` + profileId,
                updateContacts,
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateContact(profileId, updateContacts)
                    }
                })
                .finally(() => contactsLoader.value = false)
        }
    }

    const deleteContact = async (profileId) => {
        contactsException.value = null
        contactsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.delete(`https://api.utp1.com/contacts/` + profileId,
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await deleteContact(profileId)
                    } else (await router.push('/mailing/contacts'))
                })
                .finally(() => contactsLoader.value = false)
        }
    }

    const {authHeader, updateTokens} = useTokens()

    return {
        contactsLoader,
        contactsException,
        number,
        contacts,
        contact,
        getNumber,
        getContacts,
        addContact,
        getContact,
        updateContact,
        deleteContact
    }

}
