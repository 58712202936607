<template>
  <label class="form-label required-label" for="name">Название продукта:</label>
  <div class="pb-3">
    <!--suppress JSUnresolvedReference -->
    <input-utp1
        id="name"
        v-model:model-value="product.name"
        placeholder="Введите название"
        type="text"
        required
        minlength="1"
        maxlength="255"
        autocomplete="off"
    />
  </div>
  <label class="form-label" for="categoryId">Категория продукта:</label>
  <div class="pb-3">
    <!--suppress JSUnresolvedReference -->
    <select id="categoryId" class="form-select" v-model="product.categoryId">
      <option selected :value="null">Категория не выбрана</option>
      <option v-for="item in productsCategories" :value="item.id">{{ item.name }}</option>
    </select>
  </div>
  <label class="form-label required-label" for="identifier">Идентификатор продукта:</label>
  <div class="pb-3 row-form">
    <div class="row">
      <div class="col-auto">
        <!--suppress JSUnresolvedVariable -->
        <label for="identifier" class="col-form-label">https://{{ $store.state.auth.login }}.utp1.com/order/</label>
      </div>
      <div class="col">
        <!--suppress JSUnresolvedReference -->
        <input-utp1
            id="identifier"
            v-model:model-value="product.identifier"
            placeholder="Введите идентификатор"
            type="text"
            required
            minlength="1"
            maxlength="255"
            autocomplete="off"
        />
      </div>
      <label for="identifier" class="form-text">
        Уникальное значение. Допустимы прописные латинские буквы, цифры и символы «_» и «-».
        Начинаться должно с латинской буквы, заканчиваться латинской буквой или цифрой.
      </label>
    </div>
  </div>

  <div class="form-check form-switch">
    <!--suppress JSUnresolvedReference -->
    <input class="form-check-input" type="checkbox" role="switch" id="status" v-model="product.status" checked>
    <!--suppress JSUnresolvedReference -->
    <label class="form-check-label" for="status">{{ product.status ? 'Продукт включен' : 'Продукт отключен' }}</label>
  </div>

  <hr class="pb-3 text-secondary"/>

  <div class="pb-2">
    <div class="form-check form-check-inline">
      <!--suppress JSUnresolvedReference -->
      <input class="form-check-input" id="inlineRadioStandard" type="radio" checked
             v-model="product.recurrent" :disabled="id" :value="false"/>
      <label class="form-check-label" for="inlineRadioStandard">Обычный разовый платёж</label>
    </div>
    <div class="form-check form-check-inline">
      <!--suppress JSUnresolvedReference -->
      <input class="form-check-input" id="inlineRadioRecurrent" type="radio"
             v-model="product.recurrent" :disabled="id" :value="true"/>
      <label class="form-check-label" for="inlineRadioRecurrent">Реккурентный платёж</label>
    </div>
  </div>
  <!--suppress JSUnresolvedReference -->
  <div v-if="!product.recurrent">
    <div class="pb-3 row-form">
      <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-4 col-sm-5 col-auto">
          <label for="cost" class="col-form-label required-label">Стоимость:</label>
        </div>
        <div class="col-auto">
          <div class="input-group">
            <!--suppress JSUnresolvedReference -->
            <input-utp1
                id="cost"
                v-model:model-value="product.cost"
                placeholder="Введите стоимость"
                type="number"
                step="0.01"
                required
                min="0.01"
                autocomplete="off"
            />
            <span class="input-group-text">₽</span>
          </div>
        </div>
        <label for="cost" class="form-text">
          Целое или дробное число, с 1 или 2 цифрами после разделителя. Для разделения используйте «,» или «.».
        </label>
      </div>
    </div>
    <div class="pb-3">
      <div class="form-check form-check-inline">
        <!--suppress JSUnresolvedReference -->
        <input class="form-check-input" type="checkbox" id="deposit" v-model="product.deposit">
        <label class="form-check-label" for="deposit">Возможность оплаты несколькими платежами</label>
      </div>
      <!--suppress JSUnresolvedReference -->
      <div v-if="product.deposit" class="row-form">
        <div class="row">
          <div class="col-xl-4 col-lg-5 col-md-4 col-sm-5 col-auto">
            <label for="depositCost" class="col-form-label required-label">Минимальный задаток:</label>
          </div>
          <div class="col-auto">
            <div class="input-group">
              <input-utp1
                  id="depositCost"
                  v-model:model-value="product.depositCost"
                  placeholder="Введите стоимость"
                  type="number"
                  step="0.01"
                  required
                  :max="product.cost"
                  autocomplete="off"
              />
              <span class="input-group-text">₽</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-3">
      <div class="form-check form-check-inline">
        <!--suppress JSUnresolvedReference -->
        <input class="form-check-input" type="checkbox" id="updateCost" v-model="product.updateCost">
        <label class="form-check-label" for="updateCost">Возможность изменения цены в момент оформления заказа</label>
      </div>
      <!--suppress JSUnresolvedReference -->
      <div v-if="product.updateCost" class="row-form">
        <div class="pb-2">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-4 col-sm-5 col-auto">
              <label for="minCost" class="col-form-label required-label">Минимальная сумма:</label>
            </div>
            <div class="col-auto">
              <div class="input-group">
                <input-utp1
                    id="minCost"
                    v-model:model-value="product.minCost"
                    placeholder="Введите стоимость"
                    type="number"
                    step="0.01"
                    required
                    :max="product.cost"
                    autocomplete="off"
                />
                <span class="input-group-text">₽</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-5 col-md-4 col-sm-5 col-auto">
            <label for="maxCost" class="col-form-label required-label">Максимальная сумма:</label>
          </div>
          <div class="col-auto">
            <div class="input-group">
              <input-utp1
                  id="maxCost"
                  v-model:model-value="product.maxCost"
                  placeholder="Введите стоимость"
                  type="number"
                  step="0.01"
                  required
                  :min="product.cost"
                  autocomplete="off"
              />
              <span class="input-group-text">₽</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="pb-3 row-form">
      <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-4 col-sm-5 col-auto">
          <label for="firstAutoCost" class="col-form-label required-label">Первый платёж:</label>
        </div>
        <div class="col-auto">
          <div class="input-group">
            <!--suppress JSUnresolvedReference -->
            <input-utp1
                id="firstAutoCost"
                v-model:model-value="product.cost"
                placeholder="Введите стоимость"
                type="number"
                step="0.01"
                required
                min="0.01"
                autocomplete="off"
            />
            <span class="input-group-text">₽</span>
          </div>
        </div>
        <label for="cost" class="form-text">
          Целое или дробное число, с 1 или 2 цифрами после разделителя. Для разделения используйте «,» или «.».
        </label>
      </div>
    </div>
    <div class="pb-3 row-form">
      <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-4 col-sm-5 col-auto">
          <label for="autoCost" class="col-form-label required-label">Автоматические платежи:</label>
        </div>
        <div class="col-auto">
          <div class="input-group">
            <!--suppress JSUnresolvedReference -->
            <input-utp1
                id="autoCost"
                v-model:model-value="product.autoCost"
                placeholder="Введите стоимость"
                type="number"
                step="0.01"
                required
                min="0.01"
                autocomplete="off"
            />
            <span class="input-group-text">₽</span>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-3 row-form">
      <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-4 col-sm-5 col-auto">
          <label for="firstProlong" class="col-form-label required-label">Первый платёж спустя:</label>
        </div>
        <div class="col-auto">
          <div class="input-group">
            <!--suppress JSUnresolvedReference -->
            <input-utp1
                id="firstProlong"
                v-model:model-value="product.firstProlong"
                placeholder="Количество дней"
                type="number"
                required
                min="1"
                autocomplete="off"
            />
            <span class="input-group-text">дней</span>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-3 row-form">
      <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-4 col-sm-5 col-auto">
          <label for="periodProlong" class="col-form-label required-label">Интервал платежей:</label>
        </div>
        <div class="col-auto">
          <div class="input-group">
            <!--suppress JSUnresolvedReference -->
            <input-utp1
                id="periodProlong"
                v-model:model-value="product.periodProlong"
                placeholder="Количество дней"
                type="number"
                required
                min="1"
                autocomplete="off"
            />
            <span class="input-group-text">дней</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr class="pb-3 text-secondary"/>

  <div class="row-form">
    <div class="row">
      <form id="group-product-image" @submit.prevent novalidate class="col-12 no-validated">
        <div class="card p-3 mb-3">
          <div class="mb-3">
            <label for="formFileProductImage" class="form-label">Изображение продукта</label>
            <div v-if="productImage" class="mb-3">
              <img
                  :src="'data:image;base64,' + productImage"
                  style="max-width: 400px; max-height: 40px" alt="Logo">
            </div>
            <input class="form-control" type="file" id="formFileProductImage" ref="newProductImages"
                   @change="productImageUpload" accept="image/*" :disabled="!id" required>
            <label class="form-text">
              Рекомендуемая высота 360px, формат JPG. Отношение сторон не более чем 1:2.
            </label>
          </div>
          <!--suppress JSValidateTypes, JSCheckFunctionSignatures -->
          <double-button-utp1
              v-if="productImage"
              :buttons="updateProductImageButton"
              :primary-loader="imagesLoader"
              :primary-disabled="!productImages"
              @buttonPrimaryClick="updateProductImages"
              @buttonSecondaryClick="deleteProductImage(id)"/>
          <!--suppress JSValidateTypes -->
          <button-primary-utp1 v-else :loader="imagesLoader" :disabled="!productImages"
                               @buttonClick="updateProductImages">
            Добавить изображение продукта
          </button-primary-utp1>
        </div>
      </form>
    </div>
  </div>

  <label class="form-label required-label">Отмена счёта с этим продуктом:</label>
  <div class="form-check">
    <!--suppress JSUnresolvedReference -->
    <input class="form-check-input" type="radio" id="typeCancellationDisabled" checked
           v-model="product.typeCancellation" value="DISABLED">
    <label class="form-check-label pb-2" for="typeCancellationDisabled">
      Автоматически не отменяются
    </label>
  </div>
  <div class="form-check row-form">
    <div class="row">
      <div class="col-sm-3 col-6 col-form-label">
        <!--suppress JSUnresolvedReference -->
        <input class="form-check-input" type="radio" id="typeCancellationTimer"
               v-model="product.typeCancellation" value="TIMER">
        <label class="form-check-label" for="typeCancellationTimer">Через</label>
      </div>
      <div class="col-sm-3 col-6">
        <div class="input-group pb-2">
          <!--suppress JSUnresolvedReference -->
          <input-utp1
              id="periodCancellationDays"
              v-model:model-value="product.periodCancellationDays"
              placeholder="Дней"
              type="number"
              min="0"
              autocomplete="off"
          />
          <span class="input-group-text">дней</span>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="input-group pb-2">
          <!--suppress JSUnresolvedReference -->
          <input-utp1
              id="periodCancellationHours"
              v-model:model-value="product.periodCancellationHours"
              placeholder="Часов"
              type="number"
              min="0"
              max="23"
              autocomplete="off"
          />
          <span class="input-group-text">часов</span>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="input-group pb-2">
          <!--suppress JSUnresolvedReference -->
          <input-utp1
              id="periodCancellationMinutes"
              v-model:model-value="product.periodCancellationMinutes"
              placeholder="Минут"
              type="number"
              min="0"
              max="59"
              autocomplete="off"
          />
          <span class="input-group-text">минут</span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-check row-form">
    <div class="row">
      <div class="col-sm-3 col-6 col-form-label">
        <!--suppress JSUnresolvedReference -->
        <input class="form-check-input" type="radio" id="typeCancellationTime"
               v-model="product.typeCancellation" value="TIME">
        <label class="form-check-label" for="typeCancellationTime">
          Отменяются
        </label>
      </div>
      <div class="col-sm-3 col-6 pb-sm-3 pb-2">
        <!--suppress JSUnresolvedReference -->
        <input-utp1 type="date" v-model:model-value="dateTimeTz.date"
                    :required="(product.typeCancellation === 'TIME') || (dateTimeTz.time !== '')"></input-utp1>
      </div>
      <div class="col-sm-3 col-6 pb-3 d-flex align-items-center">
        <span class="me-2">в </span>
        <!--suppress JSUnresolvedReference -->
        <input-utp1 type="time" v-model:model-value="dateTimeTz.time"
                    :required="(product.typeCancellation === 'TIME') || (dateTimeTz.date !== '')"></input-utp1>
      </div>
      <div class="col-sm-3 col-6 pb-3">
        <!--suppress JSUnresolvedReference -->
        <select class="form-select" id="timezone" aria-label="Select timezone" v-model="dateTimeTz.timezone">
          <option v-for="item in formats" :value="item.value">{{ item.name }}</option>
        </select>
      </div>
    </div>
  </div>

  <!--suppress JSUnresolvedReference -->
  <div v-if="product.affiliateCommissions.length === 0" class="pb-3">
    <button-primary-utp1
        @buttonClick="addLevelAffiliateCommissions"
    >
      Добавить партнёрскую комиссию
    </button-primary-utp1>
  </div>
  <div class="pb-3" v-else>
    <div class="row-form">
      <!--suppress JSUnresolvedReference -->
      <div v-for="item in product.affiliateCommissions" class="row">
        <label class="form-label">Партнёрская комиссия {{ item.level }} уровня по-умолчанию:</label>
        <div class="col-sm-3 col-12">
          <div class="input-group pb-2">
            <!--suppress JSUnresolvedVariable -->
            <input-utp1
                v-model:model-value="item.percent"
                placeholder="Процентов"
                type="number"
                min="0"
                autocomplete="off"
            />
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div class="col-sm-auto d-none d-sm-flex">
          <label class="col-form-label">от стоимости с учётом скидок +</label>
        </div>
        <div class="col-12 d-flex d-sm-none">
          <label class="form-label">от стоимости с учётом скидок +</label>
        </div>
        <div class="col-sm-3 col-12">
          <div class="input-group pb-2">
            <input-utp1
                v-model:model-value="item.cost"
                placeholder="Рублей"
                type="number"
                step="0.01"
                min="0"
                autocomplete="off"
            />
            <span class="input-group-text">₽</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="pt-2 col-6 col-sm-auto px-0 pe-2">
        <button-primary-utp1 @buttonClick="addLevelAffiliateCommissions">
          Добавить уровень
        </button-primary-utp1>
      </div>
      <div class="pt-2 col-6 col-sm-auto px-0 ps-2">
        <button-danger-utp1 @buttonClick="deleteLevelAffiliateCommissions">
          Удалить уровень
        </button-danger-utp1>
      </div>
    </div>
  </div>

  <div class="pb-3">
    <label class="form-label" for="url">Ссылка на основную продающую страницу (если есть):</label>
    <!--suppress JSUnresolvedReference -->
    <input-url-utp1
        id="url"
        v-model:url="product.url"
    />
  </div>
  <div class="pb-3">
    <label class="form-label" for="description">Описание продукта:</label>
    <!--suppress JSUnresolvedReference -->
    <textarea
        class="form-control pb-3"
        id="description"
        v-model="product.description"
        placeholder="Введите описание"
        type="text"
        rows="3"
        autocomplete="off"></textarea>
  </div>
  <div>
    <label class="form-label" for="instructions">Инструкция для Call-центра:</label>
    <!--suppress JSUnresolvedReference -->
    <textarea
        class="form-control"
        id="instructions"
        v-model="product.instructions"
        placeholder="Введите инструкцию"
        type="text"
        rows="3"
        autocomplete="off"></textarea>
  </div>

</template>

<script>
import InputUtp1 from "../../../UI/inputs/InputUtp1";
import useMoment from "../../../../hooks/useMoment";
import ButtonPrimaryUtp1 from "../../../UI/buttons/ButtonPrimaryUtp1";
import ButtonDangerUtp1 from "../../../UI/buttons/ButtonDangerUtp1";
import InputUrlUtp1 from "../../../UI/inputs/InputUrlUtp1";
import DoubleButtonUtp1 from "../../../UI/buttons/DoubleButtonUtp1";
import {onMounted, ref} from "vue";
import useImages from "../../../../hooks/rest/files/useImages";

export default {
  name: "product-basic",
  components: {DoubleButtonUtp1, InputUrlUtp1, ButtonDangerUtp1, ButtonPrimaryUtp1, InputUtp1},
  props: {
    product: {
      type: Object
    },
    productsCategories: {
      type: Array,
      default: []
    },
    dateTimeTz: {
      type: Object,
      default: {}
    },
    id: null
  },
  setup(props) {
    const {formats} = useMoment()

    const newProductImages = ref(null)
    const productImages = ref(null)

    const updateProductImageButton = {
      primaryTitle: "Изменить",
      primaryDisabled: false,
      secondaryTitle: "Удалить",
      secondaryDisabled: false
    }

    const {imagesLoader, productImage, getProductImageMini, updateProductImage, deleteProductImage} = useImages()

    onMounted(() => {
      if (props.id !== null) {
        getProductImageMini(props.id)
      }
    })

    const addLevelAffiliateCommissions = () => {
      const newLevel = props.product.affiliateCommissions.length + 1
      props.product.affiliateCommissions.push({
        level: newLevel,
        percent: null,
        cost: null
      })
    }

    const deleteLevelAffiliateCommissions = () => {
      props.product.affiliateCommissions.splice(-1, 1)
    }

    const productImageUpload = () => {
      productImages.value = newProductImages.value.files[0]
    }

    const updateProductImages = () => {
      let formData = new FormData()
      formData.append('file', productImages.value)
      updateProductImage(formData, props.id)
      productImages.value = null
      newProductImages.value.value = ''
    }

    return {
      formats,
      addLevelAffiliateCommissions,
      deleteLevelAffiliateCommissions,
      newProductImages,
      productImages,
      productImage,
      imagesLoader,
      productImageUpload,
      updateProductImages,
      updateProductImageButton,
      getProductImageMini,
      deleteProductImage
    }
  }
}
</script>

<style scoped>

</style>