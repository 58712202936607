<template>
  <div class="d-block bg-light">
    <header class="header-nav-scroller bg-body border-bottom">
      <nav class="nav" aria-label="Header tabs navigation">
        <a v-for="item in headerNav"
           :id="'nav-link-' + item.value"
           :class="item.active? 'nav-link active' :
           item.disabled? 'nav-link disabled' : 'nav-link'"
           data-bs-toggle="tab" :data-bs-target="'#' + item.value" role="tab" :aria-controls="item.value">
          {{ item.name }}
        </a>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "header-tabs-utp1",
  props: {
    headerNav: {
      type: Array,
      default: []
    }
  }
}
</script>

<style scoped>

</style>