<template>
  <div class="modal fade" id="applySocialModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-warning">
          <h5 class="modal-title">Применить для социальных сетей?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          Если вносились дополнительные изменения в письмах
          социальных сетей, то они будут утеряны и заменены
          текущим текстом с форматированием по-умолчанию.
        </div>
        <div class="modal-footer">
          <button-secondary-utp1
              class="col col-sm-auto"
              :disabled="false"
              data-bs-dismiss="modal"
          >Отменить
          </button-secondary-utp1>
          <button-primary-utp1
              class="col col-sm-auto"
              data-bs-dismiss="modal"
              @buttonClick="applySocial"
          >Подтвердить
          </button-primary-utp1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonSecondaryUtp1 from "../buttons/ButtonSecondaryUtp1";
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";

export default {
  name: "apply-social",
  components: {ButtonPrimaryUtp1, ButtonSecondaryUtp1},
  setup(_, {emit}) {
    const applySocial = () => {
      emit('applySocial')
    }
    return {applySocial}
  }
}
</script>

<style scoped>

</style>