<template>
  <div class="modal modal-xl fade" id="newInvoiceEventModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="
          type === 'COMMENT' ? 'bg-blue-gray' :
          type === 'SUCCESSFUL_TALK' ? 'bg-green-dark' :
          type === 'CALL_BACK' ? 'bg-info' :
          type === 'DIDNT_CALL' ? 'bg-orange' :
          type === 'WRONG_PHONE' ? 'bg-yellow' :
          type === 'ALREADY_PAID' ? 'bg-success' :
          type === 'REFUSAL_PAYMENT' ? 'bg-red-light' :
          type === 'SECONDARY_ORDER' ? 'bg-secondary' : ''">
          <h5 v-if="type === 'COMMENT'" class="modal-title text-white">Комментарий</h5>
          <h5 v-else-if="type === 'SUCCESSFUL_TALK'" class="modal-title text-white">Успешный разговор</h5>
          <h5 v-else-if="type === 'CALL_BACK'" class="modal-title text-white">Перезвонить</h5>
          <h5 v-else-if="type === 'DIDNT_CALL'" class="modal-title text-white">Не дозвонился</h5>
          <h5 v-else-if="type === 'WRONG_PHONE'" class="modal-title text-white">Неверный телефон</h5>
          <h5 v-else-if="type === 'ALREADY_PAID'" class="modal-title text-white">Уже оплачен</h5>
          <h5 v-else-if="type === 'REFUSAL_PAYMENT'" class="modal-title text-white">Отказ</h5>
          <h5 v-else-if="type === 'SECONDARY_ORDER'" class="modal-title text-white">Повторный заказ</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"
                  @click="buttonSecondaryClick"></button>
        </div>

        <form class="modal-body" id="formNewInvoiceEventModal" @submit.prevent novalidate>

          <div v-if="type === 'DIDNT_CALL'" class="row-form pb-3 mb-sm--2">
            <div class="row">
              <div v-for="item in didntCallOptions"
                   class="col-xl-auto col-lg-4 col-sm-6 col-12 mb-xl-0 mb-2 d-grid gap-2">
                <input v-model="didntCallValue" type="radio" class="btn-check" name="options" :id="item.value"
                       :value="item.value" autocomplete="off" required>
                <label class="btn btn-outline-secondary" :for="item.value"> {{ item.name }} </label>
              </div>
            </div>
          </div>

          <div :class="type === 'DIDNT_CALL' && didntCallValue !== 'OTHER' ? 'd-none' : '' ">
            <div class="form-check pb-2">
              <input class="form-check-input" type="checkbox" id="deleteCheck" v-model="callback">
              <label class="form-check-label" for="deleteCheck">
                Перезвонить
              </label>
            </div>

            <div class="row-form pb-3" :class="type === 'DIDNT_CALL' && didntCallValue !== 'OTHER' ? 'd-none' : '' ">
              <div class="row">
                <label class="form-label" for="callbackDatetimeDate">Выбрать день:</label>
                <div class="col-xl-auto col-sm-4 col-6 mb-xl-0 mb-2 d-grid gap-2">
                  <button class="btn btn-outline-secondary" :disabled="!callback" @click="setDate(0)">Сегодня</button>
                </div>
                <div class="col-xl-auto col-sm-4 col-6 mb-xl-0 mb-2 d-grid gap-2">
                  <button class="btn btn-outline-secondary" :disabled="!callback" @click="setDate(1)">Завтра</button>
                </div>
                <div class="col-xl-auto col-sm-4 col-6 mb-xl-0 mb-2 d-grid gap-2">
                  <button class="btn btn-outline-secondary" :disabled="!callback" @click="setDate(2)">Послезавтра
                  </button>
                </div>
                <div class="col-xl-auto col-sm-4 col-6 mb-sm-0 mb-2 d-grid gap-2">
                  <button class="btn btn-outline-secondary" :disabled="!callback" @click="setDate(4)">Через 3 дня
                  </button>
                </div>
                <div class="col-xl-auto col-sm-4 col-6 d-grid gap-2">
                  <button class="btn btn-outline-secondary" :disabled="!callback" @click="setDate(7)">Через неделю
                  </button>
                </div>
                <div class="col-xl-auto col-sm-4 col-6">
                  <input-utp1 id="callbackDatetimeDate" type="date" v-model:model-value="callbackDatetime.date"
                              :disabled="!callback"
                              :required="callback"></input-utp1>
                </div>
              </div>
            </div>

            <div class="row-form pb-3">
              <div class="row">
                <label class="form-label" for="localCost">Выбрать время:</label>
                <div class="col-lg-auto col-sm-3 col-6 mb-sm-0 mb-2 d-grid gap-2">
                  <button class="btn btn-outline-secondary" :disabled="!callback" @click="setTime('11:00')">11:00
                  </button>
                </div>
                <div class="col-lg-auto col-sm-3 col-6 mb-sm-0 mb-2 d-grid gap-2">
                  <button class="btn btn-outline-secondary" :disabled="!callback" @click="setTime('18:00')">18:00
                  </button>
                </div>
                <div class="col-lg-auto col-sm-3 col-6">
                  <input-utp1 type="time" v-model:model-value="callbackDatetime.time" :disabled="!callback"
                              :required="callback"></input-utp1>
                </div>
                <div class="col-lg-auto col-sm-3 col-6">
                  <select class="form-select" v-model="callbackDatetime.timezone" :disabled="!callback">
                    <option v-for="item in formats" :value="item.value">{{ item.name }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="pb-3">
              <label class="form-label" for="localComment">Комментарий:</label>
              <textarea
                  id="localComment"
                  class="form-control"
                  v-model="newInvoiceEvent.comment"
                  placeholder="Введите текст"
                  type="text"
                  autocomplete="off"
              ></textarea>
            </div>
          </div>

          <div class="form-check" :class="newInvoiceEvent.sendMessage ? 'pb-2' : ''">
            <input class="form-check-input" type="checkbox" id="deleteCheck" v-model="newInvoiceEvent.sendMessage">
            <label class="form-check-label" for="deleteCheck">
              Отправить письмо
            </label>
          </div>

          <transition name="fade">
            <div v-if="newInvoiceEvent.sendMessage">
              <div class="pb-3">
                <label class="form-label" for="selectEmailBinding">Отправить от имени:</label>
                <select id="selectEmailBinding" class="form-select"
                        v-model="newInvoiceEvent.emailBindingId"
                        :disabled="!newInvoiceEvent.sendMessage" :required="newInvoiceEvent.sendMessage">
                  <option :value="null" disabled>
                    Выберите отправителя...
                  </option>
                  <option v-for="item in listEmailBindings.emailBindings" :value="item.id">
                    {{ item.name + ' <' + item.email + '>' }}
                  </option>
                </select>
              </div>

              <div class="pb-3">
                <label class="form-label" for="letterTitle">Тема письма:</label>
                <input-utp1
                    id="letterTitle"
                    v-model:model-value="newInvoiceEvent.letterTitle"
                    placeholder="Введите тему"
                    type="text"
                    minlength="1"
                    maxlength="255"
                    autocomplete="off"
                    :disabled="!newInvoiceEvent.sendMessage"
                    :required="newInvoiceEvent.sendMessage"
                />
              </div>

              <div class="pb-3">
                <!--suppress XmlInvalidId -->
                <label class="form-label" for="letterText">Текст письма:</label>
                <markdown-wysiwyg
                    :id="'letterText'"
                    :variables="variablesLetterText"
                    :markdown="false"
                    :emoticons="false"
                    :rows="5"
                    :overflow="false"
                    :disabled="!newInvoiceEvent.sendMessage"
                    :required="newInvoiceEvent.sendMessage"
                    v-model:model-value="newInvoiceEvent.letterText"
                />
              </div>
            </div>
          </transition>

        </form>
        <div class="modal-footer">
          <button-secondary-utp1
              class="col col-sm-auto "
              :disabled="false"
              data-bs-dismiss="modal"
              @buttonClick="buttonSecondaryClick"
          >Отменить
          </button-secondary-utp1>
          <button-primary-utp1
              class="col col-sm-auto"
              @buttonClick="buttonClick"
          >Подтвердить
          </button-primary-utp1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonSecondaryUtp1 from "@/components/UI/buttons/ButtonSecondaryUtp1.vue";
import ButtonPrimaryUtp1 from "@/components/UI/buttons/ButtonPrimaryUtp1.vue";
import InputUtp1 from "@/components/UI/inputs/InputUtp1.vue";
import {onMounted, ref} from "vue";
import useMoment from "@/hooks/useMoment";
import useMailingSettings from "@/hooks/rest/mailing/useMailingSettings";
import MarkdownWysiwyg from "@/components/UI/inputs/MarkdownWysiwyg.vue";

export default {
  name: "new-invoice-event",
  components: {MarkdownWysiwyg, InputUtp1, ButtonPrimaryUtp1, ButtonSecondaryUtp1},
  props: {
    type: String
  },
  watch: {
    type() {
      this.initCallback()
    }
  },
  emits: ['buttonClick', 'update:type'],
  setup(props, {emit}) {
    const newInvoiceEvent = ref({
      type: "",
      callbackDatetime: "",
      comment: null,
      sendMessage: false,
      emailBindingId: null,
      letterTitle: null,
      letterText: null
    })

    const {formatsString, formattedDateTimeTz, formats} = useMoment()
    const {listEmailBindings, getEmailBindingAccess} = useMailingSettings()

    const callback = ref(false)

    const date = new Date()

    const callbackDatetime = ref({
      date: date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate(),
      time: "11:00",
      timezone: "UTC_P3"
    })

    const initCallback = () => {
      didntCallValue.value = null
      newInvoiceEvent.value.type = props.type
      if (props.type === 'SUCCESSFUL_TALK') {
        callback.value = true
        newInvoiceEvent.value.sendMessage = true
        setDate(4)
        setTime('11:00')
      } else if (props.type === 'CALL_BACK') {
        callback.value = true
        newInvoiceEvent.value.sendMessage = true
        setDate(0)
        setTime('18:00')
      } else if (props.type === 'DIDNT_CALL') {
        callback.value = true
        newInvoiceEvent.value.sendMessage = true
        setDate(1)
        setTime('11:00')
      } else if (props.type === 'WRONG_PHONE') {
        callback.value = false
        newInvoiceEvent.value.sendMessage = true
        setDate(1)
        setTime('11:00')
      } else if (props.type === 'ALREADY_PAID') {
        callback.value = false
        newInvoiceEvent.value.sendMessage = false
        setDate(1)
        setTime('11:00')
      } else if (props.type === 'REFUSAL_PAYMENT') {
        callback.value = false
        newInvoiceEvent.value.sendMessage = false
        setDate(7)
        setTime('11:00')
      } else if (props.type === 'SECONDARY_ORDER') {
        callback.value = false
        newInvoiceEvent.value.sendMessage = false
        setDate(4)
        setTime('11:00')
      } else if (props.type === 'COMMENT') {
        callback.value = false
        newInvoiceEvent.value.sendMessage = false
        setDate(1)
        setTime('11:00')
      } else {
        callback.value = false
        newInvoiceEvent.value.sendMessage = false
        setDate(1)
        setTime('11:00')
      }
    }

    const setDate = (value) => {
      const newDate = ref(new Date())
      newDate.value.setUTCDate(date.getDate() + value)
      callbackDatetime.value.date = formattedDateTimeTz(newDate.value, 'UTC_P3', 'YYYY-MM-DD')
    }

    const setTime = (value) => {
      callbackDatetime.value.time = value
    }

    const resetNewInvoiceEvent = () => {
      newInvoiceEvent.value.type = ""
      newInvoiceEvent.value.callbackDatetime = null
      newInvoiceEvent.value.comment = null
      newInvoiceEvent.value.sendMessage = false
      newInvoiceEvent.value.letterTitle = null
      newInvoiceEvent.value.letterText = null
      didntCallValue.value = null
      listEmailBindings.value.emailBindings.forEach(item => {
        if (item.default) newInvoiceEvent.value.emailBindingId = item.id
      })
      emit('update:type', null)
    }

    const variablesLetterText = [{
      name: 'Номер заказа',
      value: '[#invoice()]'
    }, {
      name: 'Ссылка на оплату',
      value: '[#payment()]'
    }, {
      name: 'Имя',
      value: '[#name()]'
    }, {
      name: 'E-mail',
      value: '[#email()]'
    }, {
      name: 'Телефон',
      value: '[#phone()]'
    }, {
      name: 'Сумма',
      value: '[#cost()]'
    }, {
      name: 'Внесено',
      value: '[#transaction()]'
    }, {
      name: 'Остаток',
      value: '[#left()]'
    }, {
      name: 'Автоплатеж',
      value: '[#auto()]'
    }, {
      name: 'Продукты',
      value: '[#products()]'
    }, {
      name: 'Дата создания',
      value: '[#create()]'
    }, {
      name: 'Срок действия',
      value: '[#validity()]'
    }, {
      name: 'Таймер',
      value: '[#timer()]'
    }, {
      name: 'Ссылка на отмену',
      value: '[#cancel()]'
    },
      /*      {
            name: 'Имя менеджера',
            value: '[#manager_name()]'
          }, {
            name: 'Должность менеджера',
            value: '[#manager_post()]'
          }, {
            name: 'E-mail менеджера',
            value: '[#manager_email()]'
          }, {
            name: 'Телефон менеджера',
            value: '[#manager_phone()]'
          }*/
    ]

    onMounted(async () => {
      await getEmailBindingAccess()
      listEmailBindings.value.emailBindings.forEach(item => {
        if (item.default) newInvoiceEvent.value.emailBindingId = item.id
      })
    })

    const buttonSecondaryClick = () => {
      const form = document.getElementById('formNewInvoiceEventModal')
      form.classList.remove("was-validated")
      resetNewInvoiceEvent()
    }

    const buttonClick = async () => {
      const form = document.getElementById('formNewInvoiceEventModal')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        if (props.type === 'DIDNT_CALL' && (didntCallValue.value !== 'OTHER' || didntCallValue.value !== null)) {
          if (didntCallValue.value === 'NOT_RESPONDING') {
            callback.value = true
            setDate(1)
            setTime('11:00')
            newInvoiceEvent.value.comment = 'Не отвечает'
          } else if (didntCallValue.value === 'NOT_AVAILABLE') {
            callback.value = true
            setDate(1)
            setTime('11:00')
            newInvoiceEvent.value.comment = 'Не доступен'
          } else if (didntCallValue.value === 'RESET') {
            callback.value = true
            setDate(1)
            setTime('11:00')
            newInvoiceEvent.value.comment = 'Сбрасывают'
          } else if (didntCallValue.value === 'BUSY') {
            callback.value = true
            const nowDate = new Date()
            const newNewDate = ref(new Date())
            newNewDate.value.setUTCMinutes(nowDate.getMinutes() + 20)
            callbackDatetime.value.time = formattedDateTimeTz(newNewDate.value, 'UTC_P3', 'HH:mm')
            setDate(0)
            newInvoiceEvent.value.type = 'DIDNT_CALL_BUSY'
            newInvoiceEvent.value.comment = 'Занято'
          }
        }
        if (callback.value) {
          newInvoiceEvent.value.callbackDatetime = callbackDatetime.value.date + 'T' +
              callbackDatetime.value.time + ':00.000' + formatsString(callbackDatetime.value.timezone)
        } else newInvoiceEvent.value.callbackDatetime = null
        await emit('buttonClick', newInvoiceEvent.value)
        form.classList.remove("was-validated")
        resetNewInvoiceEvent()
      }
    }

    const didntCallValue = ref(null)

    const didntCallOptions = [{
      value: 'NOT_RESPONDING',
      name: 'Не отвечает'
    }, {
      value: 'NOT_AVAILABLE',
      name: 'Не доступн'
    }, {
      value: 'RESET',
      name: 'Сбрасывают'
    }, {
      value: 'NOT_SERVICED',
      name: 'Не обслуживается'
    }, {
      value: 'BUSY',
      name: 'Занято'
    }, {
      value: 'OTHER',
      name: 'Свой вариант'
    }]

    return {
      newInvoiceEvent,
      buttonClick,
      buttonSecondaryClick,
      callback,
      callbackDatetime,
      initCallback,
      formats,
      setDate,
      setTime,
      listEmailBindings,
      variablesLetterText,
      didntCallValue,
      didntCallOptions
    }
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

@media (max-width: 1199px) {
  .mb-sm--2 {
    margin-bottom: -0.5rem !important;
  }
}
</style>
