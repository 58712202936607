// noinspection JSCheckFunctionSignatures

import axios from "axios";
import {ref} from 'vue';
import useTokens from "../../useTokens";

export default function useMailingSettings() {
    const mailingSettingsLoader = ref(false)
    const mailingSettingsException = ref(null)
    const mailingSettings = ref({
        emailSetting: {
            name: "",
            projectName: "",
            projectUrl: "",
            legalForms: "OTHER",
            company: "",
            ogrn: "",
            inn: "",
            country: "",
            region: "",
            city: "",
            street: "",
            home: "",
            building: "",
            office: "",
            phone: "",
            author: "",
            authorContact: "",
            autoUnsubscribe: false
        },
        letterSettings: {
            logoUrl: "",
            color: "#23282d",
            backgroundColor: "#ffffff",
            contactNotes: "",
            contact: "",
            contactUrl: "",
            defaultTextTemplate: "",
            socialNetworksCall: "",
            socialButtonColor: true,
            socialButtonType: "ROUND",
            socialButtons: [{
                socialNetwork: "",
                url: "",
                priority: null
            }]
        },
        vkSetting: {
            author: "",
            authorContact: ""
        }
    })

    const fullEmailBinding = ref({
        emailBinding: [{
            id: null,
            email: "",
            name: "",
            confirmed: false,
            configured: false,
            default: false
        }],
        domainStatus: [{
            domainName: "",
            configured: false,
            dkimSelector: null,
            dkimKey: null,
            spf: false,
            dmarc: false,
            returnPath: null,
            returnPathConfirmation: false,
            fbl: null,
            fblConfirmation: false,
            fblMailUrl: null
        }]
    })

    const emailBinding = ref({
        id: null,
        email: "",
        name: ""
    })
    const email = ref({
        email: ""
    })
    const vkBinding = ref([])
    const emailLetterHtmlString = ref("")
    const listEmailBindings = ref({
        emailBindings: []
    })

    const getMailingSettings = async () => {
        mailingSettingsException.value = null
        mailingSettingsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/mailing/settings`, {headers: header})
                .then(response => {
                    mailingSettings.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getMailingSettings()
                    }
                })
                .finally(() => mailingSettingsLoader.value = false)
        }
    }

    const updateEmailSettings = async (emailSettings) => {
        mailingSettingsException.value = null
        mailingSettingsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(`https://api.utp1.com/mailing/settings/email/basic`,
                emailSettings,
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateEmailSettings(emailSettings)
                    } else try {
                        mailingSettingsException.value = e.response.data.error
                    } catch {
                        mailingSettingsException.value = "Exception"
                    }
                })
                .finally(() => mailingSettingsLoader.value = false)
        }
    }

    const updateLetterSettings = async (letterSettings) => {
        mailingSettingsException.value = null
        mailingSettingsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(`https://api.utp1.com/mailing/settings/email/letters`,
                letterSettings,
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateLetterSettings(letterSettings)
                    } else try {
                        mailingSettingsException.value = e.response.data.error
                    } catch {
                        mailingSettingsException.value = "Exception"
                    }
                })
                .finally(() => mailingSettingsLoader.value = false)
        }
    }

    const getEmailBindingConfigured = async () => {
        mailingSettingsException.value = null
        mailingSettingsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/mailing/settings/email/binding`, {headers: header})
                .then(response => {
                    fullEmailBinding.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getEmailBindingConfigured()
                    }
                })
                .finally(() => mailingSettingsLoader.value = false)
        }
    }

    const getEmailBindingAccess = async () => {
        mailingSettingsException.value = null
        mailingSettingsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/mailing/settings/email/binding/access`, {headers: header})
                .then(response => {
                    listEmailBindings.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getEmailBindingAccess()
                    } else e.response.data.error ?
                        (mailingSettingsException.value = e.response.data.error) : (mailingSettingsException.value = "Exception")
                })
                .finally(() => mailingSettingsLoader.value = false)
        }
    }

    const addEmailBinding = async () => {
        mailingSettingsException.value = null
        mailingSettingsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.post(`https://api.utp1.com/mailing/settings/email/binding`,
                emailBinding.value,
                {headers: header})
                .then(() => {
                    getEmailBindingConfigured()
                    temporaryEmail(emailBinding.value.email)
                    cancelEmailBindings()
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await addEmailBinding()
                    } else try {
                        mailingSettingsException.value = e.response.data.error
                    } catch {
                        mailingSettingsException.value = "Exception"
                    }
                })
                .finally(() => mailingSettingsLoader.value = false)
        }
    }

    const confirmedEmailBinding = async (code) => {
        mailingSettingsException.value = null
        mailingSettingsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/mailing/settings/email/` + code, {headers: header})
                .then(response => {
                    email.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await confirmedEmailBinding(code)
                    } else try {
                        mailingSettingsException.value = e.response.data.error
                    } catch {
                        mailingSettingsException.value = "Exception"
                    }
                })
                .finally(() => mailingSettingsLoader.value = false)
        }
    }

    const getNewEmailBindingDefault = async (id) => {
        mailingSettingsException.value = null
        mailingSettingsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/mailing/settings/email/binding/` + id, {headers: header})
                .then(() => {
                    fullEmailBinding.value.emailBinding.forEach(item => {
                        item.default = item.id === id;
                    })
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getNewEmailBindingDefault(id)
                    }
                })
                .finally(() => mailingSettingsLoader.value = false)
        }
    }

    const updateEmailBinding = async (id) => {
        mailingSettingsException.value = null
        mailingSettingsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(`https://api.utp1.com/mailing/settings/email/binding/` + id,
                emailBinding.value,
                {headers: header})
                .then(() => {
                    fullEmailBinding.value.emailBinding.forEach(item => {
                        if (item.id === id) item.name = emailBinding.value.name
                    })
                    cancelEmailBindings()
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateEmailBinding(id)
                    } else try {
                        mailingSettingsException.value = e.response.data.error
                    } catch {
                        mailingSettingsException.value = "Exception"
                    }
                })
                .finally(() => mailingSettingsLoader.value = false)
        }
    }

    const reAddEmailBinding = async (id) => {
        mailingSettingsException.value = null
        mailingSettingsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.patch(`https://api.utp1.com/mailing/settings/email/binding/` + id,
                null, {headers: header})
                .then(() => {
                    const item = fullEmailBinding.value.emailBinding.find(item => item.id === id)
                    temporaryEmail(item.email)
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await reAddEmailBinding(id)
                    }
                })
                .finally(() => mailingSettingsLoader.value = false)
        }
    }

    const deleteEmailBinding = async (id) => {
        mailingSettingsException.value = null
        mailingSettingsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.delete(`https://api.utp1.com/mailing/settings/email/binding/` + id, {headers: header})
                .then(() => {
                    const i = fullEmailBinding.value.emailBinding.findIndex(item => item.id === id)
                    if (i !== -1) {
                        fullEmailBinding.value.emailBinding.splice(i, 1)
                    }
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await deleteEmailBinding(id)
                    }
                })
                .finally(() => mailingSettingsLoader.value = false)
        }
    }

    const previewEmailLetters = async (newMailingLetters) => {
        mailingSettingsException.value = null
        mailingSettingsLoader.value = true
        emailLetterHtmlString.value = ""
        const header = await authHeader()
        if (header) {
            await axios.post(`https://api.utp1.com/mailing/settings/email/preview`,
                newMailingLetters,
                {headers: header})
                .then(response => {
                    emailLetterHtmlString.value = response.data
                })
                .catch(async e => {
                    console.log(e.response.data.error)
                    if (e.response.status === 401) {
                        await updateTokens()
                        await previewEmailLetters(newMailingLetters)
                    } else try {
                        mailingSettingsException.value = e.response.data.error
                    } catch {
                        mailingSettingsException.value = "Exception"
                    }
                })
                .finally(() => mailingSettingsLoader.value = false)
        }
    }

    const updateVkSettings = async (vkSettings) => {
        mailingSettingsException.value = null
        mailingSettingsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(`https://api.utp1.com/mailing/settings/social/vk/basic`,
                vkSettings,
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateVkSettings(vkSettings)
                    } else try {
                        mailingSettingsException.value = e.response.data.error
                    } catch {
                        mailingSettingsException.value = "Exception"
                    }
                })
                .finally(() => mailingSettingsLoader.value = false)
        }
    }

    const getVkBinding = async () => {
        const header = await authHeader()
        if (header) {
            mailingSettingsLoader.value = true
            axios.get(`https://api.utp1.com/mailing/settings/social/vk`,
                {headers: header})
                .then(response => {
                    vkBinding.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getVkBinding()
                    }
                })
                .finally(() => {
                    mailingSettingsLoader.value = false
                })
        }
    }

    const addVkBinding = async (vkCode) => {
        const header = await authHeader()
        if (header) {
            mailingSettingsLoader.value = true
            axios.post(`https://api.utp1.com/mailing/settings/social/vk`,
                vkCode,
                {headers: header})
                .then(async response => {
                    vkBinding.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await addVkBinding()
                    }
                })
                .finally(() => {
                    mailingSettingsLoader.value = false
                })
        }
    }

    const updateVkBindingDefault = async (id) => {
        const header = await authHeader()
        if (header) {
            mailingSettingsLoader.value = true
            axios.put(`https://api.utp1.com/mailing/settings/social/vk/` + id,
                null,
                {headers: header})
                .then(() => {
                    vkBinding.value.forEach(item => {
                        // noinspection JSUnresolvedVariable
                        item.default = item.id === id;
                    })
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await deleteVkBinding()
                    }
                })
                .finally(() => {
                    mailingSettingsLoader.value = false
                })
        }
    }

    const deleteVkBinding = async (id) => {
        const header = await authHeader()
        if (header) {
            mailingSettingsLoader.value = true
            axios.delete(`https://api.utp1.com/mailing/settings/social/vk/` + id,
                {headers: header})
                .then(() => {
                    // noinspection JSUnresolvedVariable
                    const i = vkBinding.value.findIndex(item => item.id === id)
                    if (i !== -1) {
                        vkBinding.value.splice(i, 1)
                    }
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await deleteVkBinding()
                    }
                })
                .finally(() => {
                    mailingSettingsLoader.value = false
                })
        }
    }

    const updateReturnPath = async (email, domain) => {
        const header = await authHeader()
        if (header) {
            mailingSettingsLoader.value = true
            mailingSettingsException.value = null
            axios.put(`https://api.utp1.com/mailing/settings/email/binding/return-path`,
                {
                    email: email,
                    domain: domain
                },
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateReturnPath()
                    } else try {
                        mailingSettingsException.value = e.response.data.error
                    } catch {
                        mailingSettingsException.value = "Exception"
                    }
                })
                .finally(() => {
                    mailingSettingsLoader.value = false
                })
        }
    }

    const updateFbl = async (email, domain) => {
        const header = await authHeader()
        if (header) {
            mailingSettingsLoader.value = true
            mailingSettingsException.value = null
            axios.put(`https://api.utp1.com/mailing/settings/email/binding/fbl`,
                {
                    email: email,
                    domain: domain
                },
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateFbl()
                    } else try {
                        mailingSettingsException.value = e.response.data.error
                    } catch {
                        mailingSettingsException.value = "Exception"
                    }
                })
                .finally(() => {
                    mailingSettingsLoader.value = false
                })
        }
    }

    const deleteFblMailUrl = async (domain) => {
        const header = await authHeader()
        if (header) {
            mailingSettingsLoader.value = true
            axios.get(`https://api.utp1.com/mailing/settings/email/binding/fbl/` + domain,
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await deleteFblMailUrl()
                    }
                })
                .finally(() => {
                    mailingSettingsLoader.value = false
                })
        }
    }

    const updateEmailBindings = (id) => {
        const emailBindings = fullEmailBinding.value.emailBinding.filter(obj => {
            return obj.id === id
        })
        emailBinding.value.id = id
        emailBinding.value.email = emailBindings[0].email
        emailBinding.value.name = emailBindings[0].name
    }

    const cancelEmailBindings = () => {
        emailBinding.value.id = null
        emailBinding.value.email = ""
        emailBinding.value.name = ""
    }

    const temporaryEmail = (temporaryEmail) => {
        email.value.email = temporaryEmail
        setTimeout(() => {
            email.value.email = null
        }, 30000)
    }

    const {authHeader, updateTokens} = useTokens()

    return {
        mailingSettingsLoader,
        mailingSettingsException,
        mailingSettings,
        fullEmailBinding,
        listEmailBindings,
        email,
        emailBinding,
        emailLetterHtmlString,
        getMailingSettings,
        updateEmailSettings,
        updateLetterSettings,
        getEmailBindingConfigured,
        getEmailBindingAccess,
        addEmailBinding,
        confirmedEmailBinding,
        getNewEmailBindingDefault,
        updateEmailBinding,
        reAddEmailBinding,
        deleteEmailBinding,
        previewEmailLetters,
        updateEmailBindings,
        cancelEmailBindings,
        updateVkSettings,
        getVkBinding,
        addVkBinding,
        updateVkBindingDefault,
        deleteVkBinding,
        updateReturnPath,
        updateFbl,
        vkBinding,
        deleteFblMailUrl
    }

}
