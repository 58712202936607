<template>
  <div class="modal fade 	modal-lg" id="updateInvoiceProductsModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Изменение продуктов и стоимости</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"
                  @click="cancel"></button>
        </div>
        <form id="updateInvoiceProductsForm" class="modal-body">
          <div v-if="error">
            <alert-danger :btn-close="false">{{ error }}</alert-danger>
          </div>
          <div v-if="localInvoiceProducts.product" class="pb-3">
            <label class="form-label">Основной продукт:</label>
            <div class="card bg-products-to-invoices-input border-0 mb-2">
              <div v-if="localInvoiceProducts.autoCost" class="card-body py-2 px-3 row-form">
                <div class="row">
                  <div class="pb-1 pb-sm-0 col-12 col-sm-5 col-md-7 col-lg-5 col-xl-6 my-auto">
                    {{ localInvoiceProducts.product }}
                  </div>
                  <div class="col my-auto">
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="Стоимость"
                             v-model="localInvoiceProducts.productCost">
                      <span class="input-group-text">₽</span>
                    </div>
                  </div>
                  <div class="col-auto px-0 my-auto">
                    +
                  </div>
                  <div class="col my-auto">
                    <div class="input-group">
                      <input type="text" class="form-control bg-light-gray-200" placeholder="Стоимость"
                             :value="localInvoiceProducts.autoCost" disabled>
                      <span class="input-group-text">₽<i class="ps-1 bi bi-clock-history"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="card-body py-2 px-3 row-form">
                <div class="row">
                  <div class="pb-1 pb-sm-0 col-12 col-sm-7 col-md-8 col-lg-7 col-xl-8 my-auto">
                    {{ localInvoiceProducts.product }}
                  </div>
                  <div class="col my-auto">
                    <div class="input-group">
                      <input-utp1
                          v-model:model-value="localInvoiceProducts.productCost"
                          placeholder="Cтоимость"
                          type="number"
                          step="0.01"
                          required
                          min="0.01"
                          autocomplete="off"/>
                      <span class="input-group-text">₽</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <label class="form-label">Дополнительные продукты:</label>
            <div v-for="item in localInvoiceProducts.products" class="card bg-products-to-invoices-input border-0 mb-2">
              <div class="card-body py-2 px-3 row-form">
                <div class="row">
                  <div class="pb-1 pb-sm-0 col-12 col-sm-7 col-md-8 col-lg-7 col-xl-8 my-auto">
                    {{ item.product }}
                  </div>
                  <div class="col my-auto">
                    <div class="input-group">
                      <input-utp1
                          id="cost"
                          v-model:model-value="item.cost"
                          placeholder="Cтоимость"
                          type="number"
                          step="0.01"
                          required
                          min="0.01"
                          autocomplete="off"/>
                      <span class="input-group-text">₽</span>
                    </div>
                  </div>
                  <div class="col-auto my-auto text-end">
                    <button type="button" class="btn btn-outline-danger rounded-circle"
                            @click="deleteProduct(item.id)">
                      <i class="bi bi-x-lg mx--125"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-control" :class="localInvoiceProducts.products.length > 0 ? 'mt-3' : ''">
            <div class="">
              <products-accordion-radio
                  :all-products="allProducts"
                  :main-product="false"
                  v-model:product="product"
              />
            </div>
            <a class="fs-5 link-secondary bi bi-cart-plus-fill pe-1" @click="addProductToInvoice"></a>
            <a class="fs-5 link-secondary bi bi-x-lg ps-1" @click="product=null"></a>


            <!--            <double-button-utp1
                            :buttons="buttons"
                            :primary-disabled="!product"
                            @buttonPrimaryClick="addProductToInvoice"
                            @buttonSecondaryClick="product=null"
                        />-->
          </div>
        </form>
        <div class="modal-footer">
          <button-secondary-utp1
              class="col col-sm-auto"
              data-bs-dismiss="modal"
              @buttonClick="cancel"
          >Отменить
          </button-secondary-utp1>
          <button-primary-utp1
              class="col col-sm-auto"
              @buttonClick="updatesInvoiceProducts"
          >Подтвердить
          </button-primary-utp1>
        </div>
      </div>
    </div>
  </div>
</template>

<!--suppress JSValidateTypes, JSUnresolvedReference -->
<script>
import {ref} from "vue";
import ButtonSecondaryUtp1 from "../buttons/ButtonSecondaryUtp1";
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";
import InputUtp1 from "@/components/UI/inputs/InputUtp1.vue";
import ProductsAccordionRadio from "@/components/UI/accordions/ProductsAccordionRadio.vue";
import DoubleButtonUtp1 from "@/components/UI/buttons/DoubleButtonUtp1.vue";
import AlertDanger from "@/components/UI/alerts/AlertDanger.vue";

export default {
  name: "update-invoice-products-modal",
  components: {
    AlertDanger,
    DoubleButtonUtp1,
    ProductsAccordionRadio,
    InputUtp1,
    ButtonPrimaryUtp1,
    ButtonSecondaryUtp1
  },
  props: {
    invoiceProducts: {
      type: Object,
      default: {
        product: null,
        productCost: 0.0,
        autoCost: null,
        currency: "RUB",
        products: []
      }
    },
    allProducts: {},
    minCost: {
      type: Number,
      default: 0.0
    }
  },
  mounted() {
    this.localInvoiceProducts = this.invoiceProducts
  },
  watch: {
    invoiceProducts() {
      this.localInvoiceProducts = this.invoiceProducts
    }
  },
  emits: ['updatesInvoiceProducts', 'cancel'],
  setup(props, {emit}) {
    const buttons = {
      primaryTitle: "Добавить",
      secondaryTitle: "Отменить"
    }

    const localInvoiceProducts = ref({
      product: null,
      productCost: 0.0,
      autoCost: null,
      currency: "RUB",
      products: []
    })

    const cancel = () => {
      emit('cancel')
    }

    const product = ref()

    const error = ref(null)

    const updatesInvoiceProducts = async () => {
      error.value = null
      const form = document.getElementById('updateInvoiceProductsForm')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        const cost = ref(localInvoiceProducts.value.productCost ? localInvoiceProducts.value.productCost : 0)
        cost.value = Number(cost.value) + localInvoiceProducts.value.products.reduce((accumulator, currentValue) => accumulator + Number(currentValue.cost), 0)
        console.log(cost.value)
        if (cost.value < props.minCost) {
          error.value = 'Стоимость заказа меньше внесённого задатка'
        } else {
          emit('updatesInvoiceProducts')
          form.classList.remove("was-validated")
          error.value = null
        }
      }
    }

    const addProductToInvoice = () => {
      if (product.value) {
        props.allProducts.productsList.forEach(list => {
          list.products.forEach(item => {
            if (item.id === product.value && !item.autoCost) {
              localInvoiceProducts.value.products.push({
                id: localInvoiceProducts.value.products.length,
                productId: item.id,
                cost: item.cost,
                product: item.name
              })
            }
          })
        })
        product.value = null
      }
    }

    const deleteProduct = (id) => {
      localInvoiceProducts.value.products.splice(id, 1)
      localInvoiceProducts.value.products.forEach(item => {
        item.id = localInvoiceProducts.value.products.indexOf(item)
      })
    }

    return {
      localInvoiceProducts,
      updatesInvoiceProducts,
      product,
      addProductToInvoice,
      buttons,
      cancel,
      deleteProduct,
      error
    }
  }
}
</script>

<style scoped>

</style>