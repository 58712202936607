<template>
  <header class="pt-1 pb-2 mb-4 clearfix">
    <div class="float-start pe-3">
      <button type="button" class="btn btn-outline-primary rounded-circle" @click="routerBack">
        <i class="bi bi-chevron-compact-left mx--125"></i>
      </button>
    </div>
    <div class="float-start">
      <!--suppress JSUnresolvedReference -->
      <h1 class="h3 fw-light">
        {{ header.title }}
      </h1>
    </div>
  </header>
</template>

<script>
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";
import {useRouter} from "vue-router";

export default {
  name: "header-back-no-line-utp1",
  components: {ButtonPrimaryUtp1},
  props: {
    header: Object
  },
  setup(props, {emit}) {
    const router = useRouter()

    const routerBack = () => {
      if (router.options.history.state.back) {
        router.go(-1)
      } else router.push(props.header.buttonRedirect)
    }

    const button = {
      buttonActive: true
    }
    const buttonClick = () => {
      emit("buttonClick")
    }
    return {button, buttonClick, routerBack}
  }

}
</script>

<style scoped>
.mx--125 {
  margin-right: -0.125rem !important;
  margin-left: -0.125rem !important;
}
</style>