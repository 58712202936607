<template>
  <main>
    <preloader-main v-if="groupsLoader"/>
    <header-back-utp1
        :header="header"
    />
    <!--suppress JSValidateTypes -->
    <group
        v-model:group="group"
        :groups-categories="groupsCategories"
        :button="button"
        @buttonClick="newGroups"
    />
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import HeaderBackUtp1 from "../../../components/UI/headers/HeaderBackUtp1";
import {onMounted, ref} from "vue";
import useGroups from "../../../hooks/rest/mailing/contacts/useGroups";
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import router from "../../../router";
import Group from "../../../components/pages/mailing/contacts/Group";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";

export default {
  name: "add-groups",
  components: {SidebarRightUtp1, Group, PreloaderMain, HeaderBackUtp1},
  setup() {
    const header = {
      title: "Добавить группу контактов",
      buttonRedirect: "/mailing/contacts/groups"
    }
    const button = {
      buttonTitle: "Создать",
      buttonDelete: false
    }
    const group = ref({
      name: "",
      categoryId: null
    })

    const newGroups = async () => {
      await addGroups(group.value)
      await router.push('/mailing/contacts/groups')
    }

    const {groupsLoader, addGroups, getGroupsCategories, groupsCategories} = useGroups()

    onMounted(() => {
      getGroupsCategories()
    })

    return {groupsLoader, header, group, button, newGroups, groupsCategories}
  }
}
</script>

<style scoped>

</style>
