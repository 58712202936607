<template>
  <main>
    <preloader-main v-if="loaderProducts"/>
    <header-back-utp1
        :header="header"
    />
    <products-category
        v-model:products-category="productsCategory"
        :button="button"
        @buttonClick="newProductsCategories"
    />
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import HeaderBackUtp1 from "../../../components/UI/headers/HeaderBackUtp1";
import ProductsCategory from "../../../components/pages/shop/products/ProductsCategory";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";
import {ref} from "vue";
import router from "../../../router";
import useProducts from "../../../hooks/rest/shop/useProducts";

export default {
  name: "add-products-category",
  components: {SidebarRightUtp1, ProductsCategory, HeaderBackUtp1, PreloaderMain},
  setup() {
    const header = {
      title: "Добавить категорию групп",
      buttonRedirect: "/shop/products/categories"
    }
    const button = {
      buttonTitle: "Создать",
      buttonDelete: false
    }
    const productsCategory = ref({
      name: ""
    })
    const newProductsCategories = async () => {
      await addProductsCategory(productsCategory.value)
      await router.push('/shop/products/categories')
    }
    const {loaderProducts, addProductsCategory} = useProducts()
    return {loaderProducts, header, button, productsCategory, newProductsCategories}
  }
}
</script>

<style scoped>

</style>
