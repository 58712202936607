<template>
  <div class="modal fade" :id="id" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Вставить ссылку</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <form :id="uniqueId" @submit.prevent novalidate>
            <div class="col-sm pb-2">
              <input-url-utp1
                  :placeholder="'URL ссылки'"
                  :required="true"
                  v-model:url="newLink.linkUrl"
              />
            </div>
            <div class="pb-2">
              <input-utp1
                  v-model:model-value="newLink.linkText"
                  placeholder="Текст ссылки"
                  type="text"
                  minlength="1"
                  maxlength="255"
                  autocomplete="off"
              />
            </div>
            <div :class="links.length > 0? 'pb-3' : ''">
              <button-primary-utp1 @click="addNewLink">
                Добавить ссылку
              </button-primary-utp1>
            </div>
          </form>
          <table class="table table-hover">
            <tbody>
            <tr v-for="item in links" class="cursor-pointer">
              <td style="max-width: 47px" @click="insertLink(item.letterLinkId)">
                <div class="text-truncate">
                  {{ item.linkText ? item.linkText : 'Без текста' }}
                </div>
                <div class="text-truncate">
                  <a class="link- text-decoration-none">
                    {{ item.linkUrl ? item.linkUrl : 'URL ссылки не указан' }}
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputUtp1 from "../inputs/InputUtp1";
import {ref} from "vue";
import InputUrlUtp1 from "../inputs/InputUrlUtp1";
import DoubleButtonUtp1 from "../buttons/DoubleButtonUtp1";
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";

export default {
  name: "add-links-from list",
  components: {ButtonPrimaryUtp1, DoubleButtonUtp1, InputUrlUtp1, InputUtp1},
  props: {
    id: {
      type: String,
      default: "addLinksFromList"
    },
    links: {
      type: Array,
      default: []
    }
  },
  emits: ['insertLink'],
  setup(props, {emit}) {
    const codeGenerate = () => {
      const chars = 'abcdefghijklmnopqrstuvwxyz'
      let str = ''
      for (let i = 0; i < 5; i++) {
        const pos = Math.floor(Math.random() * chars.length)
        str += chars.substring(pos, pos + 1)
      }
      return str
    }

    const uniqueId = codeGenerate()

    const insertLink = (id) => {
      emit('insertLink', id)
    }

    const newLink = ref({
      linkUrl: null,
      linkText: null
    })

    const addNewLink = () => {
      const form = document.querySelector('#' + uniqueId)
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        form.classList.remove("was-validated")
        const id = props.links.length
        props.links.push({
          letterLinkId: id,
          linkUrl: newLink.value.linkUrl,
          linkText: newLink.value.linkText
        })
        newLink.value.linkUrl = null
        newLink.value.linkText = null
        insertLink(id)
      }
    }

    return {insertLink, newLink, addNewLink, uniqueId}
  }
}
</script>

<style scoped>

</style>