<template>
  <main>
    <preloader-main v-if="loaderProducts"/>
    <header-back-no-line-utp1
        :header="header"
    />
    <!--suppress JSValidateTypes -->
    <product
        :products-categories="productsCategoryList.productsCategories"
        :product="product"
        :disable-personal-commissions="true"
        :error="exceptionProducts"
        v-model:personalCommissions="personalCommissions"
        @buttonClick="addProduct"
    />
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";
import Product from "../../../components/pages/shop/products/Product";
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import HeaderBackNoLineUtp1 from "../../../components/UI/headers/HeaderBackNoLineUtp1";
import useProducts from "../../../hooks/rest/shop/useProducts";
import {onMounted} from "vue";
import {useRoute} from "vue-router";

export default {
  name: "copy-product",
  components: {HeaderBackNoLineUtp1, PreloaderMain, Product, SidebarRightUtp1},
  setup() {
    const route = useRoute()
    const id = route.params.id

    const header = {
      title: "Копировать продукт",
      buttonRedirect: "/shop/products"
    }

    const {
      loaderProducts,
      exceptionProducts,
      product,
      productsCategoryList,
      personalCommissions,
      getAllProductsCategories,
      addProduct,
      getCopyProduct
    } = useProducts()

    onMounted(() => {
      getCopyProduct(id)
      getAllProductsCategories()
    })

    return {header, loaderProducts, product, productsCategoryList, addProduct, personalCommissions, exceptionProducts}
  }
}
</script>

<style scoped>

</style>