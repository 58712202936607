<template>
  <label class="form-label required-label">Отмена счёта с этим продуктом после внесения задатка:</label>
  <div class="form-check">
    <!--suppress JSUnresolvedReference -->
    <input class="form-check-input" type="radio" id="typePrepaymentCancellationDisabled" checked
           v-model="product.typePrepaymentCancellation" value="DISABLED">
    <label class="form-check-label pb-2" for="typePrepaymentCancellationDisabled">
      Автоматически не отменяются
    </label>
  </div>
  <div class="form-check row-form">
    <div class="row">
      <div class="col-sm-3 col-6 col-form-label">
        <!--suppress JSUnresolvedReference -->
        <input class="form-check-input" type="radio" id="typePrepaymentCancellationTimer"
               v-model="product.typePrepaymentCancellation" value="TIMER">
        <label class="form-check-label" for="typePrepaymentCancellationTimer">Через</label>
      </div>
      <div class="col-sm-3 col-6">
        <div class="input-group pb-2">
          <!--suppress JSUnresolvedReference -->
          <input-utp1
              id="periodCancellationDays"
              v-model:model-value="product.prepaymentCancellationDays"
              placeholder="Дней"
              type="number"
              min="0"
              autocomplete="off"
          />
          <span class="input-group-text">дней</span>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="input-group pb-2">
          <!--suppress JSUnresolvedReference -->
          <input-utp1
              id="periodCancellationHours"
              v-model:model-value="product.prepaymentCancellationHours"
              placeholder="Часов"
              type="number"
              min="0"
              max="23"
              autocomplete="off"
          />
          <span class="input-group-text">часов</span>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="input-group pb-2">
          <!--suppress JSUnresolvedReference -->
          <input-utp1
              id="periodCancellationMinutes"
              v-model:model-value="product.prepaymentCancellationMinutes"
              placeholder="Минут"
              type="number"
              min="0"
              max="59"
              autocomplete="off"
          />
          <span class="input-group-text">минут</span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-check row-form">
    <div class="row">
      <div class="col-sm-3 col-6 col-form-label">
        <!--suppress JSUnresolvedReference -->
        <input class="form-check-input" type="radio" id="typePrepaymentCancellationTime"
               v-model="product.typePrepaymentCancellation" value="TIME">
        <label class="form-check-label" for="typePrepaymentCancellationTime">
          Отменяются
        </label>
      </div>
      <div class="col-sm-3 col-6 pb-2 pb-sm-0">
        <!--suppress JSUnresolvedReference -->
        <input-utp1 type="date" v-model:model-value="dateTimeTz.date"
                    :required="(product.typePrepaymentCancellation === 'TIME') || (dateTimeTz.time !== '')"></input-utp1>
      </div>
      <div class="col-sm-3 col-6 d-flex align-items-center">
        <span class="me-2">в </span>
        <!--suppress JSUnresolvedReference -->
        <input-utp1 type="time" v-model:model-value="dateTimeTz.time"
                    :required="(product.typePrepaymentCancellation === 'TIME') || (dateTimeTz.date !== '')"></input-utp1>
      </div>
      <div class="col-sm-3 col-6 ">
        <!--suppress JSUnresolvedReference -->
        <select class="form-select" id="timezone" aria-label="Select timezone" v-model="dateTimeTz.timezone">
          <option v-for="item in formats" :value="item.value">{{ item.name }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="py-3">
    <label class="form-label">Добавлять в группы после внесения задатка:</label>
    <div class="form-control">
      <!--suppress JSUnresolvedReference -->
      <groups-accordion v-model:groups="product.groupsPrepayments"/>
    </div>
  </div>
  <div>
    <label class="form-label">Удалять из групп после внесения задатка:</label>
    <div class="form-control">
      <!--suppress JSUnresolvedReference -->
      <groups-accordion v-model:groups="product.deleteGroupsPrepayments"/>
    </div>
  </div>
</template>

<script>
import InputUtp1 from "../../../UI/inputs/InputUtp1";
import useMoment from "../../../../hooks/useMoment";
import GroupsAccordion from "../../../UI/accordions/GroupsAccordion";

export default {
  name: "product-prepayment",
  components: {GroupsAccordion, InputUtp1},
  props: {
    product: {
      type: Object
    },
    dateTimeTz: {
      type: Object
    }
  },
  setup() {
    const {formats} = useMoment()
    return {formats}
  }
}
</script>

<style scoped>

</style>
