<template>
  <main>
    <preloader-main v-if="loaderProducts || imagesLoader"/>
    <header-button-utp1
        :header="header"
        @buttonClick="$router.push('/shop/products/add')"
    />
    <form-search
        v-model:search="filter.search"
        v-model:how="how"
        :placeholder="'Поиск по названию и идентификатору...'"
        :sorting="sorting"
        @newSearch="updateProducts"
    />
    <div class="pt-3">
      <products-card
          :products="productList.products"
          :products-categories="productList.productsCategories"
          :product-image-list="productImageList"
          @queryCategory="queryCategory"
          @deleteCard="deleteProducts"
      />
    </div>
    <pagination-utp1
        v-model:number="number"
        v-model:page="page"
        :total="productList.number"
    />
  </main>
  <sidebar-right-utp1>
    <sidebar-right-filter
        class="sidebar-right-filter"
        @filterApply="updateProducts"
    >
      <div class="pb-3">
        <label class="pb-2">Категория:</label>
        <select class="form-select" v-model="filter.category">
          <option selected value="">Любая</option>
          <option v-for="item in productList.productsCategories" :value="item.id">{{ item.name }}</option>
        </select>
      </div>
      <div class="pb-3">
        <label class="pb-2">Статус:</label>
        <select class="form-select" v-model="filter.status">
          <option selected value="">Любой</option>
          <option v-for="item in status" :value="item.value">{{ item.name }}</option>
        </select>
      </div>
      <label class="pb-2">Тип:</label>
      <select class="form-select" v-model="filter.type">
        <option selected value="">Все продукты</option>
        <option v-for="item in type" :value="item.value">{{ item.name }}</option>
      </select>
    </sidebar-right-filter>
  </sidebar-right-utp1>
</template>

<script>
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import HeaderButtonUtp1 from "../../../components/UI/headers/HeaderButtonUtp1";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";
import {useRoute, useRouter} from "vue-router";
import {onMounted, ref, watch} from "vue";
import SidebarRightFilter from "../../../components/system/SidebarRightFilter";
import InputUtp1 from "../../../components/UI/inputs/InputUtp1";
import FormSearch from "../../../components/UI/forms/FormSearch.vue";
import useProducts from "../../../hooks/rest/shop/useProducts";
import PaginationUtp1 from "../../../components/UI/paginations/PaginationUtp1";
import ProductsCard from "../../../components/pages/shop/products/ProductsCard";
import useImages from "../../../hooks/rest/files/useImages";

export default {
  name: "products",
  components: {
    ProductsCard,
    PaginationUtp1,
    FormSearch, InputUtp1, SidebarRightFilter, SidebarRightUtp1, HeaderButtonUtp1, PreloaderMain
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const header = {
      title: "Продукты",
      buttonTitle: "Добавить продукт"
    }

    const sorting = [{
      value: 'DDATE',
      label: 'по дате',
      down: true
    }, {
      value: 'ADATE',
      label: 'по дате',
      down: false
    }, {
      value: 'ANAME',
      label: 'по имени',
      down: true
    }, {
      value: 'DNAME',
      label: 'по имени',
      down: false
    }]

    const {loaderProducts, productList, getProducts, deleteProduct} = useProducts()
    const {imagesLoader, productImageList, getProductImagesMini} = useImages()

    const page = ref(Number(route.query.page) ? Number(route.query.page) : 1)
    const number = ref(Number(route.query.number) ? Number(route.query.number) : 10)
    const how = ref(route.query.how ? route.query.how : "")

    const filter = ref({
      search: route.query.search ? route.query.search : "",
      category: route.query.category ? route.query.category : "",
      status: route.query.status ? route.query.status : "",
      type: route.query.type ? route.query.type : "",
    })

    const myQuery = () => {
      return {
        how: how.value,
        search: filter.value.search,
        category: filter.value.category,
        status: filter.value.status,
        type: filter.value.type,
        page: page.value,
        number: number.value
      }
    }

    watch(page, async () => {
      await router.replace({
        query: myQuery()
      })
      await getProduct()
    })

    watch(number, async () => {
      if (page.value === 1) {
        await router.replace({
          query: myQuery()
        })
        await getProduct()
      } else page.value = 1
    })

    watch(how, async () => {
      if (page.value === 1) {
        await router.replace({
          query: myQuery()
        })
        await getProduct()
      } else page.value = 1
    })

    watch(filter.value, async () => {
      await router.replace({
        query: myQuery()
      })
      if (shouldUpdateProducts.value === true) {
        await updateProducts()
        shouldUpdateProducts.value = false
      }
    })

    const shouldUpdateProducts = ref(false)

    const queryCategory = async (categoryId) => {
      shouldUpdateProducts.value = true
      filter.value.category = categoryId
    }

    const updateProducts = () => {
      if (page.value === 1) {
        getProduct()
      } else page.value = 1
    }

    const status = [{
      value: true,
      name: "Включен"
    }, {
      value: false,
      name: "Отключен"
    }]

    const type = [{
      value: "STANDARD",
      name: "Стандартный"
    }, {
      value: "RECURRENT",
      name: "Реккурентный платеж"
    }, {
      value: "WITH_DEPOSIT",
      name: "С возможностью внести задаток"
    }, {
      value: "WITH_UPDATE_COST",
      name: "С плавающей ценой"
    }]

    const getProduct = async () => {
      await getProducts()
      const ids = ref('')
      productList.value.products.forEach(product => {
        ids.value = ids.value + product.id + ','
      })
      ids.value = ids.value.slice(0, -1)
      await getProductImagesMini(ids.value)
    }

    onMounted(() => {
      getProduct()
    })

    const deleteProducts = async (id) => {
      await deleteProduct(id)
      await getProduct()
    }

    return {
      loaderProducts,
      imagesLoader,
      header,
      filter,
      how,
      updateProducts,
      status,
      type,
      productList,
      number,
      page,
      queryCategory,
      deleteProducts,
      productImageList,
      sorting
    }
  }
}
</script>

<style scoped>

</style>