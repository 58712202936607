// noinspection JSCheckFunctionSignatures

import axios from "axios";
import {ref} from 'vue';
import useTokens from "../../useTokens";
import {useRoute} from "vue-router";
import router from "../../../router";

export default function useProducts() {
    const route = useRoute()
    const loaderProducts = ref(false)
    const exceptionProducts = ref("")
    const productList = ref({
        products: [],
        number: 0,
        productsCategories: []
    })
    const productsCategoryList = ref([])
    const product = ref({
        name: '',
        categoryId: null,
        identifier: '',
        status: true,
        recurrent: false,
        cost: 0.01,
        currency: 'RUB',
        deposit: false,
        depositCost: null,
        updateCost: false,
        minCost: null,
        maxCost: null,
        autoCost: null,
        firstProlong: null,
        periodProlong: null,
        typeCancellation: 'DISABLED',
        periodCancellationDays: null,
        periodCancellationHours: null,
        periodCancellationMinutes: null,
        datetimeCancellation: null,
        affiliateCommissions: [],
        url: null,
        description: null,
        instructions: null,
        getProductPage: null,
        groupsPayments: [],
        deleteGroupsPayments: [],
        accessCodes: null,
        typePrepaymentCancellation: 'DISABLED',
        prepaymentCancellationDays: null,
        prepaymentCancellationHours: null,
        prepaymentCancellationMinutes: null,
        prepaymentDatetimeCancellation: null,
        groupsPrepayments: [],
        deleteGroupsPrepayments: []
    })
    const productsCategories = ref([])
    const productsCategory = ref({
        name: ""
    })
    const productsList = ref([])
    const personalCommissions = ref({
        personalCommissions: []
    })

    const getProducts = async () => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/shop/products`, {headers: header, params: route.query})
                .then(response => {
                    productList.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getProducts()
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const getProduct = async (id) => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/shop/products/` + id, {headers: header})
                .then(response => {
                    product.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getProduct(id)
                    } else {
                        await router.push('/shop/products')
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const getAllProductsCategories = async () => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/shop/products/categories/all`, {headers: header})
                .then(response => {
                    productsCategoryList.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getAllProductsCategories()
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const getProductsCategories = async () => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/shop/products/categories`, {headers: header})
                .then(response => {
                    productsCategories.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getProductsCategories()
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const addProduct = async (product) => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.post(
                `https://api.utp1.com/shop/products`,
                product,
                {headers: header}
            )
                .then(response => {
                    router.push("/shop/products/" + response.data)
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await addProduct(product)
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const updateProduct = async (product, id) => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(
                `https://api.utp1.com/shop/products/` + id,
                product,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateProduct(product, id)
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const deleteProduct = async (id) => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.delete(
                `https://api.utp1.com/shop/products/` + id,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await deleteProduct(id)
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const getCopyProduct = async (id) => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/shop/products/` + id + `/copy`, {headers: header})
                .then(response => {
                    product.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getCopyProduct(id)
                    } else {
                        await router.push('/shop/products')
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const getAllProducts = async () => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(
                `https://api.utp1.com/shop/products/all`,
                {headers: header}
            )
                .then(response => {
                    productsList.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getAllProducts()
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const getProductsCategory = async (id) => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(
                `https://api.utp1.com/shop/products/categories/` + id,
                {headers: header}
            )
                .then(response => {
                    productsCategory.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getProductsCategory(id)
                    } else {
                        await router.push('/shop/products/categories')
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const addProductsCategory = async (productsCategory) => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.post(
                `https://api.utp1.com/shop/products/categories`,
                productsCategory,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await addProductsCategory(productsCategory)
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const updateProductsCategory = async (productsCategory, id) => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(
                `https://api.utp1.com/shop/products/categories/` + id,
                productsCategory,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateProductsCategory(productsCategory, id)
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const deleteProductsCategory = async (id) => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.delete(
                `https://api.utp1.com/shop/products/categories/` + id,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await deleteProductsCategory(id)
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const getPersonalCommissions = async (id) => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(
                `https://api.utp1.com/shop/products/` + id + `/personal-commissions`,
                {headers: header}
            )
                .then(response => {
                    personalCommissions.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getPersonalCommissions(id)
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const addPersonalCommissions = async (id) => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.post(
                `https://api.utp1.com/shop/products/` + id + `/personal-commissions`,
                null,
                {headers: header}
            )
                .then(async () => {
                    await getPersonalCommissions(id)
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await addPersonalCommissions(id)
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const updatePersonalCommissions = async (personalCommission, id) => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(
                `https://api.utp1.com/shop/products/` + id + `/personal-commissions`,
                personalCommission,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updatePersonalCommissions(personalCommission, id)
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const deletePersonalCommissions = async (id, personalCommissionId) => {
        exceptionProducts.value = ""
        loaderProducts.value = true
        const header = await authHeader()
        if (header) {
            await axios.delete(
                `https://api.utp1.com/shop/products/` + id + `/personal-commissions/` + personalCommissionId,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await deletePersonalCommissions(id, personalCommissionId)
                    } else {
                        e.response.data.error ?
                            (exceptionProducts.value = e.response.data.error) : (exceptionProducts.value = "Exception")
                    }
                })
                .finally(() => loaderProducts.value = false)
        }
    }

    const {authHeader, updateTokens} = useTokens()

    return {
        loaderProducts,
        exceptionProducts,
        productList,
        productsCategoryList,
        product,
        productsCategories,
        productsCategory,
        productsList,
        personalCommissions,
        getProducts,
        getProduct,
        getAllProductsCategories,
        addProduct,
        updateProduct,
        deleteProduct,
        getCopyProduct,
        getAllProducts,
        getProductsCategories,
        getProductsCategory,
        addProductsCategory,
        updateProductsCategory,
        deleteProductsCategory,
        getPersonalCommissions,
        addPersonalCommissions,
        updatePersonalCommissions,
        deletePersonalCommissions
    }

}
