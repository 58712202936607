<template>
  <div class="d-block col-lg-3 sidebar-right-utp1 navbar-expand-lg">
    <aside class="navbar sidebar-utp1-height sticky-lg-top overflow-auto">

      <div class="offcanvas offcanvas-start bg-light-gray-200" tabindex="-1"
           id="sidebarRightUtp1" aria-labelledby="sidebarRightUtp1Label">

        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="sidebarRightUtp1Label"></h5>
          <button type="button" class="btn-close btn-close align-items-end" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">
          <ul class="navbar-nav flex-column w-90">
            <!--suppress HtmlUnknownTag -->
            <div v-for="item in items">
              <div v-if="item.basic">
                <!--suppress HtmlUnknownTag -->
                <li class="nav-item">
                  <a :class="(sectionBasic === item.section || (sectionBasic === '' && item.basicSection === true))
                     && !noBasic? 'nav-link active' :  item.disabled? 'nav-link disabled' : 'nav-link'"
                     @click="redirect(item.route)">{{ item.name }}</a>
                </li>
              </div>
              <div v-else-if="!item.basic">
                <ul>
                  <li class="nav-item">
                    <a :class="sectionNoBasic === item.section? noBasicActive() : item.disabled? 'nav-link disabled' : 'nav-link'"
                       @click="redirect(item.route)">{{ item.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <slot></slot>
          </ul>
        </div>
        <vk-community-messages/>
      </div>
    </aside>
  </div>
</template>

<script>
import VkCommunityMessages from "./widgets/VkCommunityMessages";
import {useRoute, useRouter} from "vue-router";
import {onMounted, ref} from "vue";

export default {
  name: "Sidebar-right-utp1",
  components: {VkCommunityMessages},
  setup() {
    const route = useRoute()
    const router = useRouter()
    const path = () => {
      return route.path.substring(1).split('/')[0].toLowerCase()
    }
    const pathBasic = () => {
      return route.path.substring(1).split('/')[1] ? route.path.substring(1).split('/')[1]?.toLowerCase() : ''
    }
    const pathNoBasic = () => {
      return route.path.substring(1).split('/')[1]?.toLowerCase() + '/' + route.path.substring(1).split('/')[2]?.toLowerCase()
    }
    const sectionBasic = ref(pathBasic())
    const sectionNoBasic = ref(pathNoBasic())
    const noBasic = ref(false)
    const noBasicActive = () => {
      noBasic.value = true
      return 'nav-link active'
    }

    const settings = [{
      basic: true,
      disabled: false,
      route: '/settings',
      name: 'Настройки',
      basicSection: true,
      section: 'settings'
    }, {
      basic: true,
      disabled: false,
      route: '/settings/security',
      name: 'Безпасность',
      basicSection: false,
      section: 'security'
    }]

    const shop = [{
      basic: true,
      disabled: true,
      route: '/shop',
      name: 'Статистика',
      basicSection: false, // TODO: Update this variable
      section: 'shop'
    }, {
      basic: true,
      disabled: false,
      route: '/shop/invoices',
      name: 'Счета',
      basicSection: false,
      section: 'invoices'
    }, {
      basic: false,
      disabled: true,
      route: '/shop/payment-reminders',
      name: 'Напоминания оплаты',
      basicSection: false,
      section: 'payment-reminders'
    }, {
      basic: false,
      disabled: true,
      route: '/shop/surcharge-reminders',
      name: 'Напоминания о доплате',
      basicSection: false,
      section: 'surcharge-reminders'
    }, {
      basic: true,
      disabled: true,
      route: '/shop/call-center',
      name: 'Call-центр',
      basicSection: false,
      section: 'call-center'
    }, {
      basic: true,
      disabled: false,
      route: '/shop/products',
      name: 'Продукты',
      basicSection: false,
      section: 'products'
    }, {
      basic: false,
      disabled: false,
      route: '/shop/products/categories',
      name: 'Категории продуктов',
      basicSection: false,
      section: 'products/categories'
    }, {
      basic: true,
      disabled: true,
      route: '/shop/coupons',
      name: 'Купоны',
      basicSection: false,
      section: 'coupons'
    }, {
      basic: true,
      disabled: false,
      route: '/shop/settings',
      name: 'Настройки',
      basicSection: false,
      section: 'settings'
    }]

    const mailing = [{
      basic: true,
      disabled: false,
      route: '/mailing',
      name: 'Разовая',
      basicSection: true,
      section: 'single'
    }, {
      basic: true,
      disabled: true,
      route: '/mailing/auto',
      name: 'Автоматическая',
      basicSection: false,
      section: 'auto'
    }, {
      basic: true,
      disabled: true,
      route: '/mailin/launch',
      name: 'Запуск',
      basicSection: false,
      section: 'launch'
    }, {
      basic: true,
      disabled: false,
      route: '/mailing/contacts',
      name: 'Контакты',
      basicSection: false,
      section: 'contacts'
    }, {
      basic: false,
      disabled: false,
      route: '/mailing/contacts/groups',
      name: 'Группы контактов',
      basicSection: false,
      section: 'contacts/groups'
    }, {
      basic: false,
      disabled: false,
      route: '/mailing/contacts/groups-categories',
      name: 'Категории групп',
      basicSection: false,
      section: 'contacts/groups-categories'
    }, {
      basic: true,
      disabled: true,
      route: '/mailing/subscription',
      name: 'Подписка',
      basicSection: false,
      section: 'subscription'
    }, {
      basic: true,
      disabled: false,
      route: '/mailing/settings',
      name: 'Настройка',
      basicSection: false,
      section: 'settings'
    }]

    const sidebarRightUtp1 = ref()

    const items = ref([])
    onMounted(() => {
      sidebarRightUtp1.value = new bootstrap.Offcanvas('#sidebarRightUtp1')
      if ((path() === 'shop') || (path() === '')) {
        items.value = shop
      } else if (path() === 'mailing') {
        items.value = mailing
      } else if (path() === 'settings') {
        items.value = settings
      }
    })

    const redirect = async (page) => {
      await router.push(page)
      sidebarRightUtp1.value.hide()
    }

    return {items, sectionBasic, sectionNoBasic, noBasicActive, noBasic, redirect}
  }
}
</script>

<style scoped>

</style>