import {ref} from 'vue';

export default function useError(primaryException = null) {

    const errorMessage = ref(null)
    const exception = ref(null)

    const error = (secondaryException) => {
        if (secondaryException !== null) {
            exception.value = secondaryException
        } else if (primaryException  !== null) {
            exception.value = primaryException
        } else exception.value = null

        if (exception.value === "" || exception.value === null) {
            errorMessage.value = null
        } else {
            if (exception.value === "No ...") errorMessage.value = "Отсутствует ..."

            else if (exception.value === "Use email") errorMessage.value = "Пользователь с таким e-mail уже есть"
            else if (exception.value === "Use phone") errorMessage.value = "Пользователь с таким телефоном уже есть"
            else if (exception.value === "Use ...") errorMessage.value = "Пользователь с таким ... уже есть"

            else if (exception.value === "Invalid email") errorMessage.value = "Некорректный e-mail"
            else if (exception.value === "Invalid phone") errorMessage.value = "Некорректный телефон"
            else if (exception.value === "Invalid vk") errorMessage.value = "Некорректный VK ID"
            else if (exception.value === "Invalid telegram") errorMessage.value = "Некорректный логин Telegram"
            else if (exception.value === "Invalid code") errorMessage.value = "Некорректный код"
            else if (exception.value === "Invalid file") errorMessage.value = "Некорректный файл"
            else if (exception.value === "Invalid domain") errorMessage.value = "Некорректный домен"
            else if (exception.value === "Invalid ...") errorMessage.value = "Некорректный ..."

            else if (exception.value === "Invalid name") errorMessage.value = "Некорректное имя"
            else if (exception.value === "Invalid password") errorMessage.value = "Некорректный пароль"
            else if (exception.value === "Invalid data") errorMessage.value = "Некорректные данные"

            else if (exception.value === "Invalid file length") errorMessage.value = "Слишком большой размер файла (максимум 20МБ)"

            else if (exception.value === "No access") errorMessage.value = "К сожалению у Вас нет доступа"
            else if (exception.value === "Timeout") errorMessage.value = "Мы уже исправляем эту ошибку"
            else if (exception.value === "Exception") errorMessage.value = "Проверьте данные и попробуйте ещё раз"
            else errorMessage.value = "Неизвестная ошибка, попробуйте позже"
        }
    }

    return {
        errorMessage,
        error
    }

}
