<template>
  <main>
    <preloader-main v-if="loaderInvoices || loaderProducts"/>
    <div :class="invoiceErrorMessage? 'delete-pb' : ''">
      <header-back-utp1
          :header="header"
      />
    </div>

    <div class="pt-3" v-if="invoiceErrorMessage">
      <alert-danger>{{ invoiceErrorMessage }}</alert-danger>
    </div>

    <form id="addInvoiceForm" @submit.prevent novalidate>
      <div class="mb-3">
        <label class="form-label required-label" for="name">Имя
          <span class="text-secondary">(можно Фамилия Имя Отчество, при наличии)</span>:</label>
        <input-utp1
            id="name"
            placeholder="Введите имя"
            v-model:model-value="newInvoice.name"
            type="text"
            required
            minlength="2"
            maxlength="255"
            autocomplete="off"
        />
      </div>

      <div class="mb-3">
        <label class="form-label required-label" for="email">E-mail:</label>
        <input-utp1
            id="email"
            placeholder="Введите e-mail"
            v-model:model-value="newInvoice.email"
            type="email"
            required
            minlength="6"
            maxlength="255"
            autocomplete="off"
        />
      </div>

      <div class="mb-3">
        <label class="form-label required-label" for="phone">Телефон в международном формате
          <span class="text-secondary">(начиная с «+», без пробелов между цифрами)</span>:</label>
        <input-utp1
            id="phone"
            placeholder="Введите телефон (+79210000000)"
            v-model:model-value="newInvoice.phone"
            type="tel"
            required
            minlength="9"
            maxlength="17"
            autocomplete="off"
        />
      </div>

      <div class="pb-3">
        <label class="form-label">Основной продукт:</label>
        <div v-if="!newInvoice.productId">
          <button-primary-utp1 @buttonClick="addProductToInvoiceModalShow">Добавить основной продукт
          </button-primary-utp1>
        </div>
        <div v-else class="card bg-products-to-invoices-input border-0 mb-2">
          <div v-if="newInvoice.autoCost" class="card-body py-2 px-3 row-form">
            <div class="row">
              <div class="pb-1 pb-sm-0 col-12 col-sm-5 col-md-7 col-lg-5 col-xl-6 my-auto">
                {{ product }}
              </div>
              <div class="col my-auto">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Стоимость" v-model="newInvoice.productCost">
                  <span class="input-group-text">₽</span>
                </div>
              </div>
              <div class="col-auto px-0 my-auto">
                +
              </div>
              <div class="col my-auto">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Стоимость" v-model="newInvoice.autoCost">
                  <span class="input-group-text">₽<i class="ps-1 bi bi-clock-history"></i></span>
                </div>
              </div>
              <div class="col-auto my-auto text-end">
                <button type="button" class="btn btn-outline-danger rounded-circle"
                        @click="deleteMainProduct">
                  <i class="bi bi-x-lg mx--125"></i>
                </button>
              </div>
            </div>
          </div>
          <div v-else class="card-body py-2 px-3 row-form">
            <div class="row">
              <div class="pb-1 pb-sm-0 col-12 col-sm-7 col-md-8 col-lg-7 col-xl-8 my-auto">
                {{ product }}
              </div>
              <div class="col my-auto">
                <div class="input-group">
                  <input-utp1
                      v-model:model-value="newInvoice.productCost"
                      placeholder="Cтоимость"
                      type="number"
                      step="0.01"
                      required
                      min="0.01"
                      autocomplete="off"/>
                  <span class="input-group-text">₽</span>
                </div>
              </div>
              <div class="col-auto my-auto text-end">
                <button type="button" class="btn btn-outline-danger rounded-circle"
                        @click="deleteMainProduct">
                  <i class="bi bi-x-lg mx--125"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pb-3">
        <label class="form-label">Дополнительные продукты:</label>
        <div v-for="item in products" class="card bg-products-to-invoices-input border-0 mb-2">
          <div class="card-body py-2 px-3 row-form">
            <div class="row">
              <div class="pb-1 pb-sm-0 col-12 col-sm-7 col-md-8 col-lg-7 col-xl-8 my-auto">
                {{ item.name }}
              </div>
              <div class="col my-auto">
                <div class="input-group">
                  <input-utp1
                      v-model:model-value="item.cost"
                      placeholder="Cтоимость"
                      type="number"
                      step="0.01"
                      required
                      min="0.01"
                      autocomplete="off"/>
                  <span class="input-group-text">₽</span>
                </div>
              </div>
              <div class="col-auto my-auto text-end">
                <button type="button" class="btn btn-outline-danger rounded-circle"
                        @click="deleteProduct(item.id)">
                  <i class="bi bi-x-lg mx--125"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button-primary-utp1 @buttonClick="addProductsToInvoiceModalShow">Добавить дополнительный продукт
          </button-primary-utp1>
        </div>
      </div>

      <div class="pb-3 pt-1">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="status"
                 v-model="newInvoice.affiliateCommissions" checked>
          <label class="form-check-label" for="status">{{
              newInvoice.affiliateCommissions ? 'Партнёрская комиссия на дополнительные продукты включена' :
                  'Без партнёрской комиссии на дополнительные продукты'
            }}</label>
        </div>
      </div>

      <div class="pb-3 row-form">
        <div class="row">
          <div class="col-sm-auto col-6">
            <label for="cost" class="col-form-label">Часовой пояс:</label>
          </div>
          <div class="col-sm-auto col-6">
            <select class="form-select" v-model="newInvoice.timezone">
              <option v-for="item in formatsNullable" :value="item.value">{{ item.name }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="pb-3">
        <div class="row-form">
          <div class="row">
            <div class="col-sm-auto col-12">
              <label for="cost" class="col-form-label">Идентификатор партнёра:</label>
            </div>
            <div class="col-sm col-12">
              <input-utp1
                  id="affiliate"
                  placeholder="Введите идентификатор"
                  v-model:model-value="newInvoice.affiliate"
                  type="text"
                  minlength="2"
                  maxlength="255"
                  autocomplete="off"
              />
            </div>
          </div>
        </div>
        <label class="form-text">
          Если не указать партнёра, он будет добавлен автоматически (при наличии информации в базе).
        </label>
      </div>

      <div class="pb-3">
        <label class="form-label" for="comment">Комментарий:</label>
        <textarea
            id="comment"
            class="form-control"
            v-model="newInvoice.comment"
            placeholder="Введите текст"
            type="text"
            autocomplete="off"
        ></textarea>
      </div>

      <div class="form-check row-form pb-2">
        <label class="form-label row-form">Срок действия счёта:</label>
        <div class="row">
          <div class="col-sm-3 col-6 col-form-label">
            <input class="form-check-input" type="checkbox" id="cancellationDisabled"
                   v-model="cancellationDisabled">
            <label class="form-check-label" for="cancellationDisabled">
              Бессрочно
            </label>
          </div>
          <div class="col-sm-3 col-6 pb-sm-3 pb-2">
            <input-utp1 type="date" v-model:model-value="cancellation.date" :disabled="cancellationDisabled"
                        :required="!cancellationDisabled"></input-utp1>
          </div>
          <div class="col-sm-3 col-6 pb-3 d-flex align-items-center">
            <span class="me-2">в </span>
            <input-utp1 type="time" v-model:model-value="cancellation.time" :disabled="cancellationDisabled"
                        :required="!cancellationDisabled"></input-utp1>
          </div>
          <div class="col-sm-3 col-6 pb-3">
            <select class="form-select" v-model="cancellation.timezone" :disabled="cancellationDisabled">
              <option v-for="item in formats" :value="item.value">{{ item.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </form>

    <button-primary-utp1 @buttonClick="addInvoices">Создать</button-primary-utp1>

  </main>
  <sidebar-right-utp1/>
  <add-product-to-invoice-modal
      :all-products="productsList"
      :main-product="mainProduct"
      @addProductToInvoice="addProductToInvoice"
  />
</template>

<!--suppress JSUnresolvedReference, JSValidateTypes -->
<script>
import PreloaderMain from "@/components/system/preloader/PreloaderMain.vue";
import SidebarRightUtp1 from "@/components/system/SidebarRightUtp1.vue";
import HeaderBackUtp1 from "@/components/UI/headers/HeaderBackUtp1.vue";
import AlertDanger from "@/components/UI/alerts/AlertDanger.vue";
import InputUtp1 from "@/components/UI/inputs/InputUtp1.vue";
import useInvoices from "@/hooks/rest/shop/useInvoices";
import {onMounted, ref, watch} from "vue";
import useMoment from "@/hooks/useMoment";
import ButtonPrimaryUtp1 from "@/components/UI/buttons/ButtonPrimaryUtp1.vue";
import useProducts from "@/hooks/rest/shop/useProducts";
import AddProductToInvoiceModal from "@/components/UI/modals/AddProductToInvoiceModal.vue";
import ButtonDangerUtp1 from "@/components/UI/buttons/ButtonDangerUtp1.vue";
import useInvoiceError from "@/hooks/error/useInvoiceError";
import router from "@/router";

export default {
  name: "add-invoice",
  components: {
    ButtonDangerUtp1,
    AddProductToInvoiceModal,
    ButtonPrimaryUtp1,
    InputUtp1,
    AlertDanger,
    HeaderBackUtp1,
    SidebarRightUtp1,
    PreloaderMain
  },
  setup() {
    const header = {
      title: "Создать счёт",
      buttonRedirect: "/shop/invoices"
    }

    const {formats, formatsNullable, formatsString} = useMoment()
    const {loaderInvoices, exceptionInvoices, addInvoice} = useInvoices()
    const {loaderProducts, productsList, getAllProducts} = useProducts()
    const {invoiceError, invoiceErrorMessage} = useInvoiceError()

    const addProductToInvoiceModal = ref()

    onMounted(async () => {
      await getAllProducts()
      addProductToInvoiceModal.value = new bootstrap.Modal('#addProductToInvoiceModal', {})
    })

    const newInvoice = ref({
      name: "",
      email: "",
      phone: "",
      productId: "",
      productCost: "",
      autoCost: "",
      currency: "RUB",
      products: [],
      affiliateCommissions: true,
      timezone: null,
      affiliate: "",
      comment: "",
      validity: ""
    })

    const product = ref()

    const products = ref([])

    watch(exceptionInvoices, () => {
      invoiceError(exceptionInvoices.value)
    })

    const addInvoices = async () => {
      invoiceErrorMessage.value = null
      const form = document.querySelector('#addInvoiceForm')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        if (!newInvoice.value.productId) {
          exceptionInvoices.value = "No mainProduct"
        } else {
          if (!cancellationDisabled.value) {
            newInvoice.value.validity = cancellation.value.date + 'T' +
                cancellation.value.time + ':00.000' + formatsString(cancellation.value.timezone)
          }
          products.value.forEach(item => {
            newInvoice.value.products.push({
              productId: item.productId,
              cost: item.cost
            })
          })
          await addInvoice(newInvoice.value)
          if (!exceptionInvoices.value) {
            await router.push('/shop/invoices')
          }
        }
      }
    }

    const cancellation = ref({
      date: "",
      time: "",
      timezone: "UTC_P3"
    })

    const cancellationDisabled = ref(true)

    const mainProduct = ref(true)

    const addProductToInvoiceModalShow = () => {
      mainProduct.value = true
      addProductToInvoiceModal.value.show()
    }

    const addProductsToInvoiceModalShow = () => {
      mainProduct.value = false
      addProductToInvoiceModal.value.show()
    }

    const addProductToInvoice = (id) => {
      if (mainProduct.value) {
        productsList.value.productsList.forEach(list => {
          list.products.forEach(item => {
            if (item.id === id) {
              newInvoice.value.productId = id
              newInvoice.value.productCost = item.cost
              newInvoice.value.autoCost = item.autoCost
              product.value = item.name
            }
          })
        })
      } else {
        productsList.value.productsList.forEach(list => {
          list.products.forEach(item => {
            if (item.id === id && !item.autoCost) {
              products.value.push({
                id: products.value.length,
                productId: id,
                cost: item.cost,
                name: item.name
              })
            }
          })
        })
      }
    }

    const deleteMainProduct = () => {
      newInvoice.value.productId = null
      newInvoice.value.productCost = null
      newInvoice.value.autoCost = null
      product.value = null
    }

    const deleteProduct = (id) => {
      products.value.splice(id, 1)
      products.value.forEach(item => {
        item.id = products.value.indexOf(item)
      })
    }

    return {
      header,
      newInvoice,
      loaderInvoices,
      invoiceErrorMessage,
      addInvoices,
      cancellation,
      cancellationDisabled,
      formats,
      formatsNullable,
      loaderProducts,
      productsList,
      addProductToInvoiceModalShow,
      addProductsToInvoiceModalShow,
      mainProduct,
      addProductToInvoice,
      product,
      deleteMainProduct,
      products,
      deleteProduct
    }
  }
}
</script>

<style scoped>

</style>
