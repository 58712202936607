<!--suppress JSUnresolvedVariable -->
<template>
  <basic-details :class="displayChannel.email? '':'d-none'" :name="'E-mail:'">
    <div v-for="item in contact.contacts">
      <div v-if="item.type === 'EMAIL'">
        <a :href="'mailto:' + item.channel" class="pe-1">
          {{ item.channel }}
        </a>
        <!--suppress JSCheckFunctionSignatures -->
        <a tabindex="0" role="button"
           :class="'badge rounded-pill text-white align-top ' + infoClass(item.status)"
           data-bs-toggle="popover" data-bs-html="true" :data-bs-trigger="'popover' + item.id"
           :data-bs-title="infoChannelStatus(item.status)"
           :data-bs-content="infoEmail(item)">
          <i class="bi bi-info-lg badge-info"></i>
        </a>
      </div>
    </div>
  </basic-details>

  <basic-details :class="displayChannel.phone? '':'d-none'" :name="'Телефон:'">
    <div v-for="item in contact.contacts">
      <div v-if="item.type === 'PHONE'">
        <a :href="'tel:' + item.channel" class="pe-1">
          {{ item.channel }}
        </a>
        <!--suppress JSCheckFunctionSignatures -->
        <a tabindex="0" role="button"
           :class="'badge rounded-pill text-white align-top ' + infoClass(item.status)"
           data-bs-toggle="popover" data-bs-html="true" :data-bs-trigger="'popover' + item.id"
           :data-bs-title="infoChannelStatus(item.status)"
           :data-bs-content="infoPhone(item)">
          <i class="bi bi-info-lg badge-info"></i>
        </a>
      </div>
    </div>
  </basic-details>

  <basic-details :class="displayChannel.social? '':'d-none'" :name="'Социальные сети:'">
    <div v-for="item in contact.contacts">
      <div v-if="item.type === 'VK'">
        <a class="text-decoration-none pe-1" :href="'https://vk.com/id' + item.channel"
           target="_blank">
          ВКонтакте
        </a>
        <!--suppress JSCheckFunctionSignatures -->
        <a tabindex="0" role="button"
           :class="'badge rounded-pill text-white align-top ' + infoClass(item.status)"
           data-bs-toggle="popover" data-bs-html="true" :data-bs-trigger="'popover' + item.id"
           :data-bs-title="infoChannelStatus(item.status)"
           :data-bs-content="infoSocial(item)">
          <i class="bi bi-info-lg badge-info"></i>
        </a>
      </div>
    </div>
    <div v-for="item in contact.contacts">
      <div v-if="item.type === 'TELEGRAM'">
        <a class="text-decoration-none pe-1" :href="'https://t.me/' + item.channel"
           target="_blank">
          Telegram
        </a>
        <!--suppress JSCheckFunctionSignatures -->
        <a tabindex="0" role="button"
           :class="'badge rounded-pill text-white align-top ' + infoClass(item.status)"
           data-bs-toggle="popover" data-bs-html="true" :data-bs-trigger="'popover' + item.id"
           :data-bs-title="infoChannelStatus(item.status)"
           :data-bs-content="infoSocial(item)">
          <i class="bi bi-info-lg badge-info"></i>
        </a>
      </div>
    </div>
  </basic-details>

  <basic-details v-if="contact.name" :name="'Имя:'">
    {{ contact.name }}
  </basic-details>

  <basic-details v-if="contact.birthdate" :name="'Дата рождения:'">
    {{ contact.birthdate }}
  </basic-details>

  <basic-details v-if="contact.sex" :name="'Пол:'">
    <div v-if="contact.sex === 'MAN'" class="col-sm-8">мужской</div>
    <div v-else-if="contact.sex === 'WOMAN'" class="col-sm-8">женский</div>
  </basic-details>

  <basic-details v-if="contact.country" :name="'Страна:'">
    {{ contact.country }}
  </basic-details>

  <basic-details v-if="contact.region" :name="'Регион:'">
    {{ contact.region }}
  </basic-details>

  <basic-details v-if="contact.city" :name="'Город:'">
    {{ contact.city }}
  </basic-details>

  <basic-details v-if="contact.timeZone" :name="'Текущее время:'">
    {{ formattedDateTimeTzNow }}
  </basic-details>

  <hr-header-text :text="'Первая регистрация'"/>

  <!--suppress JSCheckFunctionSignatures -->
  <basic-details :name="'Дата и время:'">
    {{ formattedDateTimeTz(contact.createDateTimeTz) }}
  </basic-details>

  <basic-details v-if="contact.createIp !== '0.0.0.0'" :name="'IP-адрес:'">
    {{ contact.createIp }}
  </basic-details>

  <basic-details v-if="contact.affiliate" :name="'Партнёр:'">
    {{ contact.affiliate }}
  </basic-details>
</template>

<script>
import useMoment from "../../../../hooks/useMoment";
import BasicDetails from "../../../UI/universal/BasicDetails";
import HrHeaderText from "../../../UI/universal/HrHeaderText";
import {ref} from "vue";

export default {
  name: "contact-basic",
  components: {HrHeaderText, BasicDetails},
  props: {
    contact: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const displayChannel = ref({
      email: false,
      phone: false,
      social: false
    })
    const infoClass = (status) => {
      if (status === 'ACTIVATED') return "bg-green-dark"
      else if (status === 'SUBSCRIBED') return "bg-green"
      else if (status === 'WAITING') return "bg-orange"
      else if (status === 'UNSIGNED') return "bg-red"
      else if (status === 'UNSUBSCRIBED') return "bg-red-light"
      else if (status === 'NOT_EXISTS') return "bg-blue-gray"
      else return "text-bg-info"
    }
    const infoChannelStatus = (status) => {
      if (status === 'ACTIVATED') return "Активирован"
      else if (status === 'SUBSCRIBED') return "Подписан"
      else if (status === 'WAITING') return "Ожидает активации"
      else if (status === 'UNSIGNED') return "Отписан"
      else if (status === 'UNSUBSCRIBED') return "Отписан сервисом"
      else if (status === 'NOT_EXISTS') return "Не существует"
      else return "Не определён"
    }
    const infoChannel = (contact) => {
      return "<div class='text-secondary'>Дата создания:</div>" +
          "<div class='pb-2'>" + formattedDateTimeTz(contact.createDateTimeTz) + "</div>" +
          "<div><span class='text-secondary'>IP-адрес:</span> " + contact.createIp + "</div>"
    }
    const infoEmail = (contact) => {
      displayChannel.value.email = true
      return infoChannel(contact)
    }
    const infoPhone = (contact) => {
      displayChannel.value.phone = true
      return infoChannel(contact)
    }
    const infoSocial = (contact) => {
      displayChannel.value.social = true
      return infoChannel(contact)
    }

    const {formattedDateTimeTz, formattedDateTimeTzNow, initTime} = useMoment()
    initTime(props.contact.timeZone)

    return {
      infoClass,
      infoChannelStatus,
      displayChannel,
      infoEmail,
      infoPhone,
      infoSocial,
      formattedDateTimeTz,
      formattedDateTimeTzNow
    }
  }
}
</script>

<style scoped>

</style>
