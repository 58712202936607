<template>
  <main>
    <preloader-main v-if="groupsLoader"/>
    <header-button-utp1
        :header="header"
        @buttonClick="$router.push('/mailing/contacts/groups-categories/add')"
    />

    <table class="table table-hover">
      <tbody>
      <tr v-for="item in groupsCategories" @click="getGroupsCategory(item.id)" class="cursor-pointer">
        <td>{{ item.name }}</td>
      </tr>
      </tbody>
    </table>
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import HeaderButtonUtp1 from "../../../components/UI/headers/HeaderButtonUtp1";
import useGroups from "../../../hooks/rest/mailing/contacts/useGroups";
import {onMounted} from "vue";
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import router from "../../../router";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";

export default {
  name: "groups-categories",
  components: {SidebarRightUtp1, PreloaderMain, HeaderButtonUtp1},
  setup() {
    const header = {
      title: "Категории групп",
      buttonTitle: "Добавить категорию"
    }
    const getGroupsCategory = (id) => {
      router.push('/mailing/contacts/groups-categories/' + id)
    }

    const {getGroupsCategories, groupsLoader, groupsCategories} = useGroups()

    onMounted(() => {
      getGroupsCategories()
    })

    return {header, getGroupsCategory, getGroupsCategories, groupsLoader, groupsCategories}
  }
}
</script>

<style scoped>

</style>