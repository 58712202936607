<template>
  <footer class="d-none d-lg-block bg-light-gray-500 navbar navbar-expand">
    <div class="container-xxl">

      <div class="me-auto">
        <footer-content-left/>
      </div>

      <div class="me-auto">
        <footer-content-right/>
      </div>

      <div class="me-auto">
        &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp
      </div>

    </div>
  </footer>
</template>

<script>
import FooterContentLeft from "@/components/system/footer/FooterContentLeft";
import FooterContentRight from "@/components/system/footer/FooterContentRight";

export default {
  name: "footer-utp1",
  components: {FooterContentRight, FooterContentLeft}
}
</script>

<style scoped>

</style>