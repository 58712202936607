<template>
  <div class="h-100 w-100 position-fixed" style="z-index: 1099">
    <div class="vh-100 vw-100 top-0 start-0 bg-white opacity-75 position-fixed full-spinner-z-index"/>
    <div class="position-fixed full-spinner-z-index full-spinner-position">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "preloader-modal"
}
</script>

<style scoped>

</style>