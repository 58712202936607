<template>
  <div :class="(icon || image || logo) ? 'row' : ''" class="draggable-card-utp1">
    <div v-if="icon || image || logo" class="col-auto ps-0 pb-1 container-status-card-utp1">
      <a v-if="icon" @click="icon.redirect ? $router.push(icon.redirect + cardId) : ''">
      <span :class="(icon.little ? 'icon-status-card-utp1-little ' : 'icon-status-card-utp1 ')
      + icon.background" class="status-card-utp1 text-white" :title="icon.title">
          <i :class="icon.icon"></i>
        <span v-if="badge" class="badge-utp1 badge-icon" :class="badge.background">
          <i :class="badge.icon"></i>
        </span>
      </span>
      </a>
      <a v-else-if="image" @click="image.redirect ? $router.push(image.redirect + cardId) : ''">
        <span v-if="image.img" :class="image.round ? 'image-round-card-utp1' : 'image-card-utp1'"
              :style="'background-image:url(' + (image.base64 ? 'data:image;base64,' : '') + image.img + ')'">
          <span v-if="badge" class="badge-utp1 badge-image" :class="badge.background">
            <i :class="badge.icon"></i>
          </span>
        </span>
        <span v-else class="bg-light-gray-400 d-flex align-items-center justify-content-center"
              :class="image.round ? 'image-round-card-utp1' : 'image-card-utp1'">
            <i class="bi bi-images text-secondary" :class="image.round ? 'fs-4' : 'fs-3'"></i>
          <span v-if="badge" class="badge-utp1 badge-image" :class="badge.background">
            <i :class="badge.icon"></i>
          </span>
        </span>
      </a>
      <a v-else-if="logo" @click="logo.redirect ? $router.push(logo.redirect + cardId) : ''">
        <span v-if="logo.icon" class="image-square-card-utp1" :class="logo.class ? logo.class : ''"
              :style="logo.style ? 'color:'+logo.style : ''">
          <i :class="logo.icon" class="fs-image-square-card-utp1"></i>
          <span v-if="badge" class="badge-utp1 badge-image" :class="badge.background">
            <i :class="badge.icon"></i>
          </span>
        </span>
        <span v-else class="d-flex align-items-center justify-content-center image-square-card-utp1">
            <i class="bi bi-images text-secondary fs-1"></i>
          <span v-if="badge" class="badge-utp1 badge-image" :class="badge.background">
            <i :class="badge.icon"></i>
          </span>
        </span>
      </a>
    </div>

    <div :class="(icon || image || logo) ? 'col px-0 container-card-utp1' : ''">
      <div :class="draggable? 'd-flex' : ''">
        <div :class="draggable ? 'draggable-card' : ''">
          <div class="card shadow-sm-card-utp1 mb-3">
            <div class="card-header border-bottom-0" v-if="dropdownCardMenu">

              <div class="float-start col-sm-11 col-10">
                <slot name="cardHeader"></slot>
              </div>

              <div class="float-end mx-option-card-utp1 dropdown">
                <a class="card-link fs-option-card-utp1" id="dropdownCardMenu" data-bs-toggle="dropdown">
                  <i class="bi bi-three-dots"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                  <li v-for="item in dropdownCardMenu">
                    <div v-if="item.dropdownDelete">
                      <a class="dropdown-item" @click="deleteCard">
                        {{ item.dropdownName }}
                      </a>
                    </div>
                    <div v-else-if="item.dropdownHr">
                      <hr class="dropdown-divider">
                    </div>
                    <div v-else>
                      <a class="dropdown-item"
                         @click="item.dropdownEmit? dropdownEmit(item.dropdownEmit, cardId) :
                 item.dropdownPush ? $router.push(item.dropdownPush + cardId) : ''">
                        {{ item.dropdownName }}
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="card-header border-bottom-0" v-else>
              <slot name="cardHeader"></slot>
            </div>

            <div class="card-body py-2 text-secondary">
              <slot></slot>
            </div>

            <div class="card-footer border-top-0">
              <slot name="cardFooter"></slot>
            </div>

          </div>
        </div>
        <div v-if="draggable" class="draggable">
          <i class="bi bi-grip-vertical"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "card-utp1",
  props: {
    icon: {
      type: Object.nullable,
      default: null,
      format: {
        icon: 'bi bi-check',
        background: 'bg-green',
        title: '',
        little: false,
        redirect: '/'
      }
    },
    image: {
      type: Object.nullable,
      default: null,
      format: {
        img: '',
        base64: false,
        round: true,
        redirect: '/'
      }
    },
    logo: {
      type: Object.nullable,
      default: null,
      format: {
        icon: 'icon-utp-icon',
        class: 'text-secondary',
        style: '#cb5152',
        redirect: '/'
      }
    },
    badge: {
      type: Object.nullable,
      default: null,
      format: {
        icon: 'bi bi-check',
        background: 'bg-green'
      }
    },
    draggable: {
      type: Boolean,
      default: false
    },
    cardId: {
      type: Number.nullable,
      default: null
    },
    dropdownCardMenu: {
      type: Array.nullable,
      default: null,
      format: [{
        dropdownName: "",
        dropdownPush: null,
        dropdownEmit: null,
        dropdownHr: false,
        dropdownDelete: false
      }]
    }
  },
  setup(_, {emit}) {
    const dropdownEmit = (dropdownEmit, cardId) => {
      emit(dropdownEmit, cardId)
    }

    const deleteCard = (cardId) => {
      emit('deleteCard', cardId)
    }
    return {deleteCard, dropdownEmit}
  }
}
</script>

<style scoped>

</style>