<template>
  <main>
    <preloader-main v-if="loaderProducts"/>
    <header-back-no-line-utp1
        :header="header"
    />
    <!--suppress JSValidateTypes -->
    <product
        :products-categories="productsCategoryList.productsCategories"
        :product="product"
        v-model:personalCommissions="personalCommissions"
        :disable-personal-commissions="false"
        :id="id"
        :error="exceptionProducts"
        @buttonClick="updateProducts"
        @addPersonalCommissions="addPersonalCommission"
        @updatePersonalCommissions="updatePersonalCommission"
        @deletePersonalCommissions="deletePersonalCommission"
    />
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import HeaderBackNoLineUtp1 from "../../../components/UI/headers/HeaderBackNoLineUtp1";
import Product from "../../../components/pages/shop/products/Product";
import useProducts from "../../../hooks/rest/shop/useProducts";
import {useRoute} from "vue-router";
import {onMounted} from "vue";

export default {
  name: "edit-product",
  components: {Product, HeaderBackNoLineUtp1, PreloaderMain, SidebarRightUtp1},
  setup() {
    const route = useRoute()
    const id = route.params.id

    const header = {
      title: "Редактировать продукт",
      buttonRedirect: "/shop/products"
    }

    const {
      loaderProducts,
      exceptionProducts,
      product,
      productsCategoryList,
      personalCommissions,
      getProduct,
      updateProduct,
      getAllProductsCategories,
      getPersonalCommissions,
      addPersonalCommissions,
      updatePersonalCommissions,
      deletePersonalCommissions
    } = useProducts()

    onMounted(() => {
      getProduct(id)
      getAllProductsCategories()
      getPersonalCommissions(id)
    })

    const addPersonalCommission = () => {
      addPersonalCommissions(id)
    }

    const updatePersonalCommission = (personalCommission) => {
      updatePersonalCommissions(personalCommission, id)
    }

    const deletePersonalCommission = (personalCommissionId) => {
      deletePersonalCommissions(id, personalCommissionId)
    }

    const updateProducts = (product) => {
      updateProduct(product, id)
    }

    return {
      header,
      loaderProducts,
      product,
      productsCategoryList,
      updateProducts,
      personalCommissions,
      addPersonalCommission,
      updatePersonalCommission,
      deletePersonalCommission,
      id,
      exceptionProducts
    }
  }
}
</script>

<style scoped>

</style>