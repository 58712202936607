<template>
  <main>
    <preloader-main v-if="mailingSettingsLoader"/>
    <entry-header-utp1>Подтверждение e-mailа</entry-header-utp1>
    <div v-if="errorMessage">
      <alert-danger :btn-close="false">{{ errorMessage }}</alert-danger>
    </div>
    <div v-else-if="email.email">
      <alert-success :btn-close="false">
        <div class="pb-3">Поздравляем!</div>
        <div>Ваш e-mail <span class="fw-bolder">{{ email.email }}</span> подтверждён и успешно привязан к системе UTP1.
          Теперь Вы можете использовать его для отправки писем и рассылок!
        </div>
      </alert-success>
    </div>

    <div class="d-sm-block d-grid">
      <button type="button" @click="$router.push('/mailing/settings')" class="btn btn-primary">Перейти к настройкам
        рассылки
      </button>
    </div>
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import {onMounted, watch} from "vue";
import {useRoute} from "vue-router";
import EntryHeaderUtp1 from "../../../components/UI/headers/EntryHeaderUtp1";
import useMailingSettings from "../../../hooks/rest/mailing/useMailingSettings";
import useError from "../../../hooks/error/useError";
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import AlertDanger from "../../../components/UI/alerts/AlertDanger";
import AlertSuccess from "../../../components/UI/alerts/AlertSuccess";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";

export default {
  name: "confirmed-email-binding",
  components: {SidebarRightUtp1, AlertSuccess, AlertDanger, PreloaderMain, EntryHeaderUtp1},
  setup() {
    const route = useRoute()
    const code = route.params.code

    const {mailingSettingsLoader, mailingSettingsException, email, confirmedEmailBinding} = useMailingSettings()
    const {error, errorMessage} = useError()

    watch(mailingSettingsException, () => {
      error(mailingSettingsException.value)
    })

    onMounted(() => {
      confirmedEmailBinding(code)
    })

    return {mailingSettingsLoader, errorMessage, email}
  }
}
</script>

<style scoped>

</style>