import {ref} from 'vue';

export default function useProductError(primaryException = null) {

    const productErrorMessage = ref(null)
    const productException = ref(null)

    const productError = (secondaryException) => {
        if (secondaryException !== null) {
            productException.value = secondaryException
        } else if (primaryException !== null) {
            productException.value = primaryException
        } else productException.value = null

        if (productException.value === "" || productException.value === null) {
            productErrorMessage.value = null
        } else {
            if (productException.value === "No ...") productErrorMessage.value = "Отсутствует ..."

            else if (productException.value === "Use identifier") productErrorMessage.value = "Продукт с таким идентификатором уже есть"
            else if (productException.value === "Use ...") productErrorMessage.value = "Пользователь с таким ... уже есть"

            else if (productException.value === "Invalid productId") productErrorMessage.value = "Некорректный ID продукта"
            else if (productException.value === "Invalid name") productErrorMessage.value = "Некорректное название"
            else if (productException.value === "Invalid identifier") productErrorMessage.value = "Некорректный идентификатор"
            else if (productException.value === "Invalid cost") productErrorMessage.value = "Некорректная стоимость"
            else if (productException.value === "Invalid depositCost") productErrorMessage.value = "Некорректный размер задатка"
            else if (productException.value === "Invalid minCost") productErrorMessage.value = "Некорректная минимальная стоимость"
            else if (productException.value === "Invalid maxCost") productErrorMessage.value = "Некорректная максимальная стоимость"
            else if (productException.value === "Invalid minOrMaxCost") productErrorMessage.value = "Некорректная минимальная или максимальная стоимость"
            else if (productException.value === "Invalid autoCost") productErrorMessage.value = "Некорректный размер автоматического платежа"
            else if (productException.value === "Invalid firstProlong") productErrorMessage.value = "Некорректный первый период"
            else if (productException.value === "Invalid periodProlong") productErrorMessage.value = "Некорректный период автоматичского платежа"
            else if (productException.value === "Invalid periodCancellation") productErrorMessage.value = "Некорректный период отмены счёта"
            else if (productException.value === "Invalid datetimeCancellation") productErrorMessage.value = "Некорректная дата отмены счёта"
            else if (productException.value === "periodAction") productErrorMessage.value = "Некорректный период акции"
            else if (productException.value === "Invalid prepaymentCancellation") productErrorMessage.value = "Некорректный период отмены счёта после внесения задатка"
            else if (productException.value === "Invalid prepaymentDatetimeCancellation") productErrorMessage.value = "Некорректная дата отмены счёта после внесения задатка"
            else if (productException.value === "Invalid affiliateCommissions") productErrorMessage.value = "Некорректная партнёрская комиссия"
            else if (productException.value === "Invalid groupsPayments") productErrorMessage.value = "Некорректные группы подписки для добавления после оплаты"
            else if (productException.value === "Invalid deleteGroupsPayments") productErrorMessage.value = "Некорректные группы подписки для удаления после оплаты"
            else if (productException.value === "Invalid groupsPrepayments") productErrorMessage.value = "Некорректные группы подписки для добавления после оплаты"
            else if (productException.value === "Invalid deleteGroupsPrepayments") productErrorMessage.value = "Некорректные группы подписки для удаления после оплаты"
            else if (productException.value === "Invalid productsCategoryId") productErrorMessage.value = "Некорректный ID категории продуктов"
            else if (productException.value === "Invalid commissions") productErrorMessage.value = "Некорректные комиссии"
            else if (productException.value === "Invalid products") productErrorMessage.value = "Некорректные продукты"
            else if (productException.value === "Invalid ...") productErrorMessage.value = "Некорректный ..."

            else if (productException.value === "No access") productErrorMessage.value = "К сожалению у Вас нет доступа"
            else if (productException.value === "Timeout") productErrorMessage.value = "Мы уже исправляем эту ошибку"
            else if (productException.value === "Exception") productErrorMessage.value = "Проверьте данные и попробуйте ещё раз"
            else productErrorMessage.value = "Некорректные данные, проверьте и попробуйте ещё раз"
        }
    }

    return {
        productErrorMessage,
        productError
    }

}
