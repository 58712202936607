<template>
  <div class="row">
    <div class="h6 fw-normal col-auto pt-2 ps-0 pe-1">
      {{ text }}
    </div>
    <div class="col pt-1 ps-1 pe-0 text-secondary">
      <hr/>
    </div>
  </div>
</template>

<script>
export default {
  name: "hr-header-text",
  props: {
    text: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>

</style>