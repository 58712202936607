<template>
  <div class="pb-2 row-form">
    <div class="row">
      <div class="col-sm-4 text-sm-end text-secondary"
           :class="form ? 'd-sm-flex flex-end pb-2 col-12 mt-sm-1' : 'col-auto'">
        {{ name }}
      </div>
      <div class="col-sm-8 col-auto" :class="form ? 'col-12' : 'col-auto'">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "basic-details",
  props: {
    name: {
      type: String,
      default: ""
    },
    form: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.flex-end {
  justify-content: flex-end;
}
</style>