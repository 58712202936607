<template>
  <div class="row">
    <div class="col-6 col-sm-auto px-0 pe-2">
      <button-primary-utp1 :disabled="primaryDisabled" :loader="primaryLoader" @buttonClick="buttonPrimaryClick">
        {{ buttons.primaryTitle }}
      </button-primary-utp1>
    </div>
    <div class="col-6 col-sm-auto px-0 ps-2">
      <button-secondary-utp1 :disabled="secondaryDisabled" @buttonClick="buttonSecondaryClick">
        {{ buttons.secondaryTitle }}
      </button-secondary-utp1>
    </div>
  </div>
</template>

<script>
import ButtonPrimaryUtp1 from "./ButtonPrimaryUtp1";
import ButtonSecondaryUtp1 from "./ButtonSecondaryUtp1";

export default {
  name: "double-button-utp1",
  components: {ButtonSecondaryUtp1, ButtonPrimaryUtp1},
  props: {
    buttons: {
      primaryTitle: "",
      secondaryTitle: ""
    },
    primaryDisabled: {
      type: Boolean,
      default: false
    },
    secondaryDisabled: {
      type: Boolean,
      default: false
    },
    primaryLoader: {
      type: Boolean,
      default: false
    }
  },
  emits: ["buttonPrimaryClick", "buttonSecondaryClick"],
  setup(_, {emit}) {
    const buttonPrimaryClick = () => {
      emit("buttonPrimaryClick")
    }
    const buttonSecondaryClick = () => {
      emit("buttonSecondaryClick")
    }
    return {buttonPrimaryClick, buttonSecondaryClick}
  }
}
</script>

<style scoped>

</style>