// noinspection JSCheckFunctionSignatures

import axios from "axios";
import {ref} from 'vue';
import useTokens from "../../useTokens";
import {useRoute} from "vue-router";

export default function useMailingVk() {
    const route = useRoute()
    const mailingVkLoader = ref(false)
    const mailingVkException = ref(null)
    const listVkMessagesPhoto = ref({
        number: 0,
        vkMessagesPhotos: []
    })

    const getVkMessagesPhotos = async (vkBindingId) => {
        mailingVkException.value = null
        mailingVkLoader.value = true
        const header = await authHeader()
        await axios.get(
            `https://api.utp1.com/mailing/vk/images/` + vkBindingId,
            {headers: header, params: route.query}
        )
            .then(response => {
                listVkMessagesPhoto.value = response.data
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await getVkMessagesPhotos(vkBindingId)
                } else try {
                    mailingVkException.value = e.response.data.error
                } catch {
                    mailingVkException.value = "Exception"
                }
            })
            .finally(() => {
                mailingVkLoader.value = false
            })
    }

    const addVkMessagesPhotos = async (vkMessagesPhoto, vkBindingId) => {
        mailingVkException.value = null
        mailingVkLoader.value = true
        let vkMessagePhoto = null
        const header = await authHeader()
        await axios.post(
            `https://api.utp1.com/mailing/vk/images/` + vkBindingId,
            vkMessagesPhoto,
            {headers: header, params: route.query}
        )
            .then(async response => {
                vkMessagePhoto = await response.data
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await addVkMessagesPhotos(vkMessagesPhoto, vkBindingId)
                } else if (e.response.status === 413) {
                    mailingVkException.value = "Invalid file length"
                } else try {
                    mailingVkException.value = e.response.data.error
                } catch {
                    mailingVkException.value = "Exception"
                }
            })
            .finally(() => {
                mailingVkLoader.value = false
            })
        return vkMessagePhoto
    }

    const deleteVkMessagesPhotos = async (vkBindingId, id) => {
        mailingVkException.value = null
        mailingVkLoader.value = true
        const header = await authHeader()
        await axios.delete(
            `https://api.utp1.com/mailing/vk/images/` + vkBindingId + `/` + id,
            {headers: header}
        )
            .then(async () => {
                await getVkMessagesPhotos(vkBindingId)
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await deleteVkMessagesPhotos(vkBindingId, id)
                } else try {
                    mailingVkException.value = e.response.data.error
                } catch {
                    mailingVkException.value = "Exception"
                }
            })
            .finally(() => {
                mailingVkLoader.value = false
            })
    }

    const {authHeader, updateTokens} = useTokens()

    return {
        mailingVkLoader,
        mailingVkException,
        listVkMessagesPhoto,
        getVkMessagesPhotos,
        addVkMessagesPhotos,
        deleteVkMessagesPhotos
    }

}
