<template>
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-bs-dismiss="offcanvas" @click="support">Служба поддержки</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-bs-dismiss="offcanvas" @click="docs">Юридические документы</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "footer-content-right",
  setup() {
    const support = () => {
      window.open('https://vk.com/utp_1', '_blank')
    }
    const docs = () => {
      window.open('https://utp1.com/docs', '_blank')
    }
    return {support, docs}
  }
}
</script>

<style scoped>

</style>