<template>
  <div :class="btnClose ? 'alert-dismissible': ''" class="alert alert-secondary fade show" role="alert">
    <slot></slot>
    <button v-if="btnClose" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
</template>

<script>
export default {
  name: "alert-secondary",
  props: {
    btnClose: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>