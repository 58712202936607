<template>
  <select class="form-select" v-model="localSelectedItem">
    <option
        v-for="item in selectItems"
        :selected="item.selected"
        :value="item.value">
      {{ item.name }}
    </option>
  </select>
</template>

<!--suppress JSUnusedGlobalSymbols -->
<script>
import {ref, watch} from "vue";

export default {
  name: "input-select-utp1",
  props: {
    selectItems: {
      type: Array,
      default: []
    },
    selectedItem: {
      type: String.nullable,
      default: null
    }
  },
  mounted() {
    this.localSelectedItem = this.selectedItem
  },
  watch: {
    selectedItem() {
      this.localSelectedItem = this.selectedItem
    }
  },
  setup(_, {emit}) {
    const localSelectedItem = ref()

    watch(localSelectedItem, (newVal) => {
      emit('update:selectedItem', newVal)
    })

    return {localSelectedItem}
  }
}
</script>

<style scoped>

</style>