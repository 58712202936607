<template>
  <main>
    <preloader-main v-if="loaderInvoices || loaderProducts"/>
    <header-button-utp1
        :header="header"
        @buttonClick="$router.push('/shop/invoices/add')"
    />
    <form-search
        v-model:search="filter.search"
        v-model:how="how"
        :placeholder="'Поиск по контактам и номеру заказа...'"
        :sorting="sorting"
        @newSearch="updateInvoices"
    />
    <div class="pt-3">
      <div v-for="item in getInvoices.invoices">
        <!--suppress JSUnresolvedReference -->
        <card-utp1
            :card-id="item.id"
            :dropdown-card-menu="item.status === 'WAITING' ? cardHeader :
              item.status === 'PREPAID' ? cardHeaderPrepaid :
              item.status === 'PAID' ? item.recurrent ? cardHeaderPaidRecurrent : cardHeaderReturn :
              item.status === 'FAILED' ? cardHeaderPaidRecurrent :
              item.status === 'CANCELED' ? item.recurrent ? item.transactionCost ? item.transactionCost >= item.cost ? cardHeaderReturn : cardHeaderCanceledPrepaid : cardHeaderCanceled : item.transactionCost ? cardHeaderCanceledPrepaid : cardHeaderCanceled :
              item.status === 'RETURNED' ? item.transactionCost && item.returnCost ? item.transactionCost > item.returnCost ? cardHeaderReturn : cardHeaderNull : cardHeaderNull : cardHeaderNull"
            :icon="item.status === 'WAITING' ? {
            icon: 'bi bi-hourglass-split',
            background: 'bg-secondary',
            title: 'Ожидается',
            little: true,
            redirect: '/shop/invoices/'
          } : item.status === 'PREPAID' ? {
            icon: 'bi bi-check-all',
            background: 'bg-green-dark',
            title: 'Предоплачен',
            little: false,
            redirect: '/shop/invoices/'
          } : item.status === 'PAID' ? {
            icon: 'bi bi-plus',
            background: 'bg-green',
            title: 'Оплачен',
            little: false,
            redirect: '/shop/invoices/'
          } : item.status === 'FAILED' ? {
            icon: 'bi bi-exclamation-diamond',
            background: 'bg-red',
            title: 'Ошибка',
            little: true,
            redirect: '/shop/invoices/'
          } : item.status === 'CANCELED' ? {
            icon: 'bi bi-dash',
            background: 'bg-danger',
            title: 'Отменен',
            little: false,
            redirect: '/shop/invoices/'
          } : item.status === 'RETURNED' ? {
            icon: 'bi bi-arrow-counterclockwise',
            background: 'bg-black',
            title: 'Возврат',
            little: true,
            redirect: '/shop/invoices/'
          } : {
            icon: 'bi bi-question',
            background: 'bg-warning',
            title: 'Не определено',
            little: false,
            redirect: '/shop/invoices/'
          }"
            :badge="item.callCenterStatus === 'SUCCESSFUL_TALK' ? {
            icon: 'bi bi-check',
            background: 'bg-green-dark'
          } : item.callCenterStatus === 'CALL_BACK' ? {
            icon: 'bi bi-clock',
            background: 'bg-info'
          } : (item.callCenterStatus === 'CALL_BACK' || item.callCenterStatus === 'DIDNT_CALL_BUSY') ? {
            icon: 'bi bi-exclamation',
            background: 'bg-orange'
          } : item.callCenterStatus === 'WRONG_PHONE' ? {
            icon: 'bi bi-bug',
            background: 'bg-yellow'
          } : item.callCenterStatus === 'ALREADY_PAID' ? {
            icon: 'bi bi-plus',
            background: 'bg-green'
          } : item.callCenterStatus === 'REFUSAL_PAYMENT' ? {
            icon: 'bi bi-x',
            background: 'bg-red-light'
          } : item.callCenterStatus === 'SECONDARY_ORDER' ? {
            icon: 'bi bi-three-dots',
            background: 'bg-secondary'
          } : item.callCenterStatus === 'COMMENT' ? {
            icon: 'bi bi-chat-text',
            background: 'bg-indigo'
          } : null"
            @prepayment="updateInvoiceModalShow(item.id, 'PREPAYMENT', item.cost - (item.transactionCost ? item.transactionCost : 0), 0)"
            @payment="updateInvoiceModalShow(item.id, 'PAYMENT', 0, 0)"
            @cancel="updateInvoiceModalShow(item.id, 'CANCEL', 0, 0)"
            @recovery="updateInvoiceModalShow(item.id, 'RECOVERY', 0, 0)"
            @return="updateInvoiceModalShow(item.id, 'RETURN', 0, (item.transactionCost ? item.transactionCost : 0) - (item.returnCost ? item.returnCost : 0) )"
            @deleteCard="deleteCardModal(item.id)"
        >
          <template v-slot:cardHeader>
            <div class="row-form">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="text-truncate">
                    <a class="card-link text-decoration-none fw-semibold"
                       @click="$router.push('/shop/invoices/' + item.id)">
                      Счет №{{ item.id }}
                    </a>
                    <span class="px-2 text-secondary">|</span>
                    {{ item.cost.toLocaleString() }} ₽
                    <span v-if="item.autoCost"> + {{ item.autoCost.toLocaleString() }} ₽</span>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="text-truncate text-sm-end">
                  <span v-if="item.product">
                    <!--suppress JSUnresolvedReference -->
                    <a v-if="item.productId" class="card-link text-decoration-none"
                       @click="$router.push('/shop/products/' + item.productId)">
                      {{ item.product }}
                    </a>
                    <span v-else>{{ item.product }}</span>
                  </span>
                    <span v-if="item.products.length > 0" class="text-truncate">
                    <span v-if="item.product"> + </span><span v-else>Продукты: </span>
                    <a class="card-link text-decoration-none dropdown-toggle"
                       data-bs-toggle="dropdown">
                      [ {{ item.products.length }} ]
                    </a>
                      <!--suppress HtmlUnknownTag -->
                    <ul class="dropdown-menu dropdown-menu-end" style="max-width: 80%">
                      <li v-for="product in item.products">
                        <a class="dropdown-item text-truncate">
                          <a class="card-link text-decoration-none">
                            <!--suppress JSUnresolvedReference -->
                            <a v-if="product.productId" class="card-link text-decoration-none"
                               @click="$router.push('/shop/products/' + product.productId)">
                              {{ product.product }}
                            </a>
                            <span v-else>{{ product.product }}</span>
                          </a>
                        </a>
                      </li>
                    </ul>
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="row-form">
            <div class="row">
              <div class="col-auto text-truncate">
                <span>{{ item.name }}</span>
              </div>
              <div class="col-auto text-truncate">
                <a class="text-decoration-none"
                   @click="$router.push('/mailing/contacts?search=' + item.email)">
                  {{ item.email }}
                </a>
              </div>
              <div class="col-auto text-truncate">
                <a class="text-decoration-none"
                   @click="$router.push('/mailing/contacts?search=' + item.phone.slice(1))">
                  {{ item.phone }}
                </a>
              </div>
            </div>
          </div>
          <template v-slot:cardFooter>
            <div class="row">
              <!--suppress JSCheckFunctionSignatures -->
              <div class="col-auto text-truncate p-0">
                {{ formattedDateTimeTz(item.create, 'UTC_P3', 'DD.MM.YYYY в HH:mm') }}
              </div>
              <div class="col"></div>
              <!--suppress JSCheckFunctionSignatures, JSUnresolvedReference -->
              <div class="col-auto text-truncate p-0">
                {{
                  item.status === 'PAID' ? 'Оплачен ' + formattedDateTimeTz(item.transaction, 'UTC_P3', 'DD.MM.YYYY в HH:mm') :
                      item.status === 'PREPAID' ? 'Предоплата ' + item.transactionCost.toLocaleString() + ' ₽ ' + formattedDateTimeTz(item.transaction, 'UTC_P3', 'DD.MM.YYYY в HH:mm') :
                          item.status === 'RETURNED' ? 'Возврат ' + item.returnCost.toLocaleString() + ' ₽ ' + formattedDateTimeTz(item.transaction, 'UTC_P3', 'DD.MM.YYYY в HH:mm') : ''
                }}
              </div>
            </div>
          </template>
        </card-utp1>
      </div>
    </div>
    <pagination-utp1
        v-model:number="number"
        v-model:page="page"
        :total="getInvoices.number"
    />
  </main>
  <sidebar-right-utp1>
    <sidebar-right-filter
        class="sidebar-right-filter"
        @filterApply="updateInvoices"
    >
      <div class="pb-3">
        <label class="pb-2">Создан С - ПО:</label>
        <div class="pb-2">
          <input-utp1
              type="date"
              v-model:model-value="filter.create_before"
          />
        </div>
        <input-utp1
            type="date"
            v-model:model-value="filter.create_after"
        />
      </div>
      <div class="pb-3">
        <label class="pb-2">Статус:</label>
        <select class="form-select" v-model="filter.status">
          <option selected value="">Любой</option>
          <option v-for="item in filterStatus" :value="item.value">{{ item.name }}</option>
        </select>
      </div>
      <div class="pb-3">
        <label class="pb-2">Платеж С - ПО:</label>
        <div class="pb-2">
          <input-utp1
              type="date"
              v-model:model-value="filter.transaction_before"
          />
        </div>
        <input-utp1
            type="date"
            v-model:model-value="filter.transaction_after"
        />
      </div>
      <div class="pb-3">
        <label class="pb-2">Основной продукт:</label>
        <products-accordion
            :all-products="productsList"
            v-model:products="filterProducts"
            :selected-products="[]"
            class="form-control"
        />
      </div>
      <div class="pb-2">
        <label class="pb-2">Тип платежа:</label>
        <select class="form-select" v-model="filter.recurrent">
          <option selected value="">Любой</option>
          <option v-for="item in filterRecurrent" :value="item.value">{{ item.name }}</option>
        </select>
      </div>
    </sidebar-right-filter>
  </sidebar-right-utp1>
  <delete-object
      :id="checkCardId"
      @deleteObject="deleteCard"
  />
  <update-invoice-modal
      :id="checkCardId"
      :action="updateInvoiceData.action"
      :maxCost="updateInvoiceData.maxCost"
      :maxReturnCost="updateInvoiceData.maxReturnCost"
      @updateInvoice="updatesInvoice"
  />
</template>

<script>
import HeaderButtonUtp1 from "@/components/UI/headers/HeaderButtonUtp1.vue";
import PreloaderMain from "@/components/system/preloader/PreloaderMain.vue";
import SidebarRightUtp1 from "@/components/system/SidebarRightUtp1.vue";
import FormSearch from "@/components/UI/forms/FormSearch.vue";
import {onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import PaginationUtp1 from "@/components/UI/paginations/PaginationUtp1.vue";
import useInvoices from "@/hooks/rest/shop/useInvoices";
import SidebarRightFilter from "@/components/system/SidebarRightFilter.vue";
import InputUtp1 from "@/components/UI/inputs/InputUtp1.vue";
import ProductsAccordion from "@/components/UI/accordions/ProductsAccordion.vue";
import useProducts from "@/hooks/rest/shop/useProducts";
import CardUtp1 from "@/components/UI/cards/CardUtp1.vue";
import UpdateInvoiceModal from "@/components/UI/modals/UpdateInvoiceModal.vue";
import DeleteObject from "@/components/UI/modals/DeleteObject.vue";
import useMoment from "@/hooks/useMoment";

export default {
  name: "invoices",
  components: {
    DeleteObject,
    UpdateInvoiceModal,
    CardUtp1,
    ProductsAccordion,
    InputUtp1,
    SidebarRightFilter,
    PaginationUtp1,
    FormSearch,
    SidebarRightUtp1,
    PreloaderMain,
    HeaderButtonUtp1
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const header = {
      title: "Счета",
      buttonTitle: "Создать счет"
    }

    const sorting = [{
      value: 'DDATE',
      label: 'по дате',
      down: true
    }, {
      value: 'ADATE',
      label: 'по дате',
      down: false
    }, {
      value: 'ACOST',
      label: 'по стоимости',
      down: true
    }, {
      value: 'DCOST',
      label: 'по стоимости',
      down: false
    }]

    const cardHeader = [{
      dropdownName: "Открыть",
      dropdownPush: "/shop/invoices/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Внести задаток",
      dropdownEmit: "prepayment",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Оплатить",
      dropdownEmit: "payment",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Отменить",
      dropdownEmit: "cancel",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "",
      dropdownPush: "",
      dropdownHr: true,
      dropdownDelete: false
    }, {
      dropdownName: "Удалить",
      dropdownPush: "",
      dropdownHr: false,
      dropdownDelete: true
    }]
    const cardHeaderPrepaid = [{
      dropdownName: "Открыть",
      dropdownPush: "/shop/invoices/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Внести задаток",
      dropdownEmit: "prepayment",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Оплатить",
      dropdownEmit: "payment",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Отменить",
      dropdownEmit: "cancel",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Сделать возврат",
      dropdownEmit: "return",
      dropdownHr: false,
      dropdownDelete: false
    }]
    const cardHeaderReturn = [{
      dropdownName: "Открыть",
      dropdownPush: "/shop/invoices/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Сделать возврат",
      dropdownEmit: "return",
      dropdownHr: false,
      dropdownDelete: false
    }]
    const cardHeaderPaidRecurrent = [{
      dropdownName: "Открыть",
      dropdownPush: "/shop/invoices/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Отменить",
      dropdownEmit: "cancel",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Сделать возврат",
      dropdownEmit: "return",
      dropdownHr: false,
      dropdownDelete: false
    }]
    const cardHeaderCanceled = [{
      dropdownName: "Открыть",
      dropdownPush: "/shop/invoices/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Восстановить",
      dropdownEmit: "recovery",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "",
      dropdownPush: "",
      dropdownHr: true,
      dropdownDelete: false
    }, {
      dropdownName: "Удалить",
      dropdownPush: "",
      dropdownHr: false,
      dropdownDelete: true
    }]
    const cardHeaderCanceledPrepaid = [{
      dropdownName: "Открыть",
      dropdownPush: "/shop/invoices/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Восстановить",
      dropdownEmit: "recovery",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Сделать возврат",
      dropdownEmit: "return",
      dropdownHr: false,
      dropdownDelete: false
    }]
    const cardHeaderNull = [{
      dropdownName: "Открыть",
      dropdownPush: "/shop/invoices/",
      dropdownHr: false,
      dropdownDelete: false
    }]

    const page = ref(Number(route.query.page) ? Number(route.query.page) : 1)
    const number = ref(Number(route.query.number) ? Number(route.query.number) : 10)
    const how = ref(route.query.how ? route.query.how : "")

    const filter = ref({
      search: route.query.search ? route.query.search : "",
      create_after: route.query.create_after ? route.query.create_after : "",
      create_before: route.query.create_before ? route.query.create_before : "",
      products: route.query.products ? route.query.products : "",
      recurrent: route.query.recurrent ? route.query.recurrent : "",
      status: route.query.status ? route.query.status : "",
      transaction_after: route.query.transaction_after ? route.query.transaction_after : "",
      transaction_before: route.query.transaction_before ? route.query.transaction_before : "",
    })

    const myQuery = () => {
      return {
        how: how.value,
        search: filter.value.search,
        create_after: filter.value.create_after,
        create_before: filter.value.create_before,
        products: filter.value.products,
        recurrent: filter.value.recurrent,
        status: filter.value.status,
        transaction_after: filter.value.transaction_after,
        transaction_before: filter.value.transaction_before,
        page: page.value,
        number: number.value
      }
    }

    const {loaderInvoices, getQueryInvoices, getInvoices, updateInvoice, deleteInvoice} = useInvoices()

    const {loaderProducts, getAllProducts, productsList} = useProducts()

    watch(page, async () => {
      await router.replace({
        query: myQuery()
      })
      await getQueryInvoices()
    })

    watch(number, async () => {
      if (page.value === 1) {
        await router.replace({
          query: myQuery()
        })
        await getQueryInvoices()
      } else page.value = 1
    })

    watch(how, async () => {
      if (page.value === 1) {
        await router.replace({
          query: myQuery()
        })
        await getQueryInvoices()
      } else page.value = 1
    })

    watch(filter.value, async () => {
      await router.replace({
        query: myQuery()
      })
      if (shouldUpdateInvoices.value === true) {
        await updateInvoices()
        shouldUpdateInvoices.value = false
      }
    })

    const shouldUpdateInvoices = ref(false)

    const updateInvoices = () => {
      if (page.value === 1) {
        getQueryInvoices()
      } else page.value = 1
    }

    onMounted(async () => {
      await getQueryInvoices()
      await getAllProducts()
      filterProducts.value = route.query.products ? route.query.products.split(',') : []
      deleteObjectModal.value = new bootstrap.Modal('#deleteObjectModal', {})
      updateInvoiceModal.value = new bootstrap.Modal('#updateInvoiceModal', {})
    })

    const filterRecurrent = [{
      value: false,
      name: "Обычный платёж"
    }, {
      value: true,
      name: "Реккурентный платёж"
    }]

    const filterStatus = [{
      value: 'WAITING',
      name: "Ожидает"
    }, {
      value: 'PREPAID',
      name: "Внесен задаток"
    }, {
      value: 'PAID',
      name: "Оплачен"
    }, {
      value: 'FAILED',
      name: "Ошибка автоплатежа"
    }, {
      value: 'CANCELED',
      name: "Отменен"
    }]

    const filterProducts = ref([])

    const updatesInvoice = async (id, updateInvoiceData) => {
      await updateInvoice(id, updateInvoiceData)
      await getQueryInvoices()
      updateInvoiceModal.value.hide()
    }

    const deleteCard = async (id) => {
      await deleteInvoice(id)
      await getQueryInvoices()
    }

    watch(filterProducts, (newVal) => {
      filter.value.products = newVal.toString().replaceAll(' ', '')
    })

    const checkCardId = ref(0)
    const deleteObjectModal = ref()
    const updateInvoiceModal = ref()

    const deleteCardModal = (id) => {
      checkCardId.value = id
      deleteObjectModal.value.show()
    }

    const {formattedDateTimeTz} = useMoment()

    const updateInvoiceData = ref({
      action: "",
      maxCost: 0,
      maxReturnCost: 0
    })

    const updateInvoiceModalShow = (id, action, maxCost, maxReturnCost) => {
      checkCardId.value = id
      updateInvoiceData.value.action = action
      updateInvoiceData.value.maxCost = maxCost
      updateInvoiceData.value.maxReturnCost = maxReturnCost
      updateInvoiceModal.value.show()
    }

    return {
      loaderInvoices,
      loaderProducts,
      header,
      page,
      number,
      how,
      filter,
      sorting,
      getInvoices,
      updatesInvoice,
      updateInvoices,
      filterRecurrent,
      filterStatus,
      filterProducts,
      productsList,
      deleteCard,
      cardHeader,
      cardHeaderPrepaid,
      cardHeaderReturn,
      cardHeaderPaidRecurrent,
      cardHeaderCanceled,
      cardHeaderCanceledPrepaid,
      cardHeaderNull,
      deleteCardModal,
      formattedDateTimeTz,
      updateInvoiceModalShow,
      updateInvoiceData,
      checkCardId
    }
  }
}
</script>

<style scoped>

</style>
