<template>

  <div class="row-form">

    <form class="row gy-3 pt-0" @submit.prevent>

      <div class="col-sm-auto">
        <label class="form-control-plaintext pb-0 mb-0">
          <slot></slot>
        </label>
      </div>

      <div class="col-sm-auto">

        <!--suppress JSUnresolvedReference -->
        <input-utp1
            v-bind:model-value="modelValue"
            :placeholder="label.placeholder"
            @input="updateInput"
            :type="label.type? label.type : 'text'"
        />
      </div>

      <div class="d-grid gap-2 col-sm-auto">
        <!--suppress JSUnresolvedReference -->
        <button-primary-utp1 @buttonClick="clickButton" v-model:disabled="buttonDisabled">
          {{ label.button }}
        </button-primary-utp1>
      </div>

    </form>

  </div>

</template>

<script>
import InputUtp1 from "../../../components/UI/inputs/InputUtp1";
import {ref} from "vue";
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";

export default {
  name: "form-in-line",
  components: {ButtonPrimaryUtp1, InputUtp1},
  props: {
    modelValue: String,
    label: Object
  },
  setup(_, {emit}) {
    const updateInput = (event) => {
      buttonDisabled.value = event.target.value.length <= 0
      emit("update:modelValue", event.target.value)
    }
    const clickButton = () => {
      emit("clickButton")
    }
    const buttonDisabled = ref(true)
    return {updateInput, buttonDisabled, clickButton}
  }
}
</script>

<style scoped>

</style>