// noinspection JSCheckFunctionSignatures

import axios from "axios";
import {ref} from 'vue';
import useTokens from "../../useTokens";
import {useRoute} from "vue-router";
import router from "../../../router";

export default function useSingleMailing() {
    const route = useRoute()
    const singleMailingLoader = ref(false)
    const singleMailingException = ref("")

    const listSingleMailings = ref({
        number: 0,
        singleMailings: [],
        groups: []
    })
    const newSingleMailing = ref({
        newMailingLetters: {
            mailingLetters: {},
            mailingLetterLinks: [],
            mailingLetterImages: [],
            vkLetterLinks: [],
            vkLetterImages: [],
            emailBindings: [],
            vkBindings: []
        },
        singleMailing: {
            name: "",
            sendingDateTimeTz: null,
            sendingType: null,
            status: null,
            signedAfter: null,
            signedBefore: null
        },
        singleMailingGroups: [],
        singleMailingInvoices: [],
        singleMailingChannels: [],
        singleMailingGroupsNoSending: [],
        singleMailingInvoicesNoSending: []
    })

    const getSingleMailings = async () => {
        singleMailingException.value = ""
        singleMailingLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/mailing/single`, {headers: header, params: route.query})
                .then(response => {
                    listSingleMailings.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getSingleMailings()
                    }
                })
                .finally(() => singleMailingLoader.value = false)
        }
    }

    const getNewSingleMailings = async () => {
        singleMailingException.value = ""
        singleMailingLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/mailing/single/new`, {headers: header})
                .then(response => {
                    newSingleMailing.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getNewSingleMailings()
                    }
                })
                .finally(() => singleMailingLoader.value = false)
        }
    }

    const addNewSingleMailings = async (newSingleMailings) => {
        singleMailingException.value = ""
        singleMailingLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.post(`https://api.utp1.com/mailing/single/new`, newSingleMailings, {headers: header})
                .then(response => {
                    router.push('/mailing/single/' + response.data)
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await addNewSingleMailings(newSingleMailings)
                    } else try {
                        singleMailingException.value = e.response.data.error
                    } catch {
                        singleMailingException.value = "Exception"
                    }
                })
                .finally(() => singleMailingLoader.value = false)
        }
    }

    const getSingleMailing = async (singleMailingId) => {
        singleMailingException.value = ""
        singleMailingLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/mailing/single/` + singleMailingId, {headers: header})
                .then(response => {
                    newSingleMailing.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getSingleMailing(singleMailingId)
                    } else {
                        await router.push('/mailing')
                    }
                })
                .finally(() => singleMailingLoader.value = false)
        }
    }

    const updateSingleMailing = async (newSingleMailings, singleMailingId) => {
        singleMailingException.value = ""
        singleMailingLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(`https://api.utp1.com/mailing/single/` + singleMailingId, newSingleMailings, {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateSingleMailing(newSingleMailings, singleMailingId)
                    } else try {
                        singleMailingException.value = e.response.data.error
                    } catch {
                        singleMailingException.value = "Exception"
                    }
                })
                .finally(() => singleMailingLoader.value = false)
        }
    }

    const updateStatusSingleMailing = async (waiting, singleMailingId) => {
        const newStatus = () => {
            if (waiting) {
                return "WAITING"
            } else return "PAUSE"
        }
        singleMailingException.value = ""
        singleMailingLoader.value = true
        const header = await authHeaderApplicationJson()
        if (header) {
            // noinspection JSVoidFunctionReturnValueUsed
            await axios.patch(`https://api.utp1.com/mailing/single/` + singleMailingId,
                waiting,
                {headers: header})
                .then(listSingleMailings.value.singleMailings.forEach(item => {
                    // noinspection JSUnresolvedVariable
                    if (item.singleMailingId === singleMailingId) item.status = newStatus()
                }))
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateStatusSingleMailing(waiting, singleMailingId)
                    }
                })
                .finally(() => singleMailingLoader.value = false)
        }
    }

    const deleteSingleMailing = async (singleMailingId) => {
        singleMailingException.value = ""
        singleMailingLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.delete(`https://api.utp1.com/mailing/single/` + singleMailingId, {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await deleteSingleMailing(singleMailingId)
                    }
                })
                .finally(() => singleMailingLoader.value = false)
        }
    }

    const duplicateSingleMailing = async (singleMailingId) => {
        singleMailingException.value = ""
        singleMailingLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/mailing/single/new/` + singleMailingId, {headers: header})
                .then(response => {
                    newSingleMailing.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await duplicateSingleMailing(singleMailingId)
                    } else {
                        await router.push('/mailing/single/add')
                    }
                })
                .finally(() => singleMailingLoader.value = false)
        }
    }

    const {authHeader, authHeaderApplicationJson, updateTokens} = useTokens()

    return {
        getSingleMailings,
        getNewSingleMailings,
        addNewSingleMailings,
        getSingleMailing,
        updateSingleMailing,
        updateStatusSingleMailing,
        deleteSingleMailing,
        duplicateSingleMailing,
        singleMailingLoader,
        singleMailingException,
        listSingleMailings,
        newSingleMailing
    }

}
