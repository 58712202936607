<template>
  <main>
    <preloader-main v-if="singleMailingLoader"/>
    <header-back-no-line-utp1
        :header="header"
    />
    <single-mailing
        :newSingleMailing="newSingleMailing"
        :mailingErrorMessage="mailingErrorMessage"
        @buttonClick="addNewSingleMailings"
    />
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import HeaderBackUtp1 from "../../../components/UI/headers/HeaderBackUtp1";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";
import SingleMailing from "../../../components/pages/mailing/single/SingleMailing";
import HeaderBackStatusUtp1 from "../../../components/UI/headers/HeaderBackStatusUtp1";
import HeaderBackNoLineUtp1 from "../../../components/UI/headers/HeaderBackNoLineUtp1";
import ButtonPrimaryUtp1 from "../../../components/UI/buttons/ButtonPrimaryUtp1";
import useSingleMailing from "../../../hooks/rest/mailing/useSingleMailing";
import {onMounted, watch} from "vue";
import useMailingError from "../../../hooks/error/useMailingError";

export default {
  name: "add-single-mailing",
  components: {
    ButtonPrimaryUtp1,
    HeaderBackNoLineUtp1,
    HeaderBackStatusUtp1, SingleMailing, SidebarRightUtp1, HeaderBackUtp1, PreloaderMain
  },
  setup() {
    const header = {
      title: "Создать разовый выпуск",
      buttonRedirect: "/mailing"
    }

    const {
      singleMailingLoader,
      singleMailingException,
      getNewSingleMailings,
      addNewSingleMailings,
      newSingleMailing
    } = useSingleMailing()

    const {mailingError, mailingErrorMessage} = useMailingError()

    watch(singleMailingException, () => {
      mailingError(singleMailingException.value)
    })

    onMounted(() => {
      getNewSingleMailings()
    })

    return {header, singleMailingLoader, newSingleMailing, addNewSingleMailings, mailingErrorMessage}
  }
}
</script>

<style scoped>

</style>