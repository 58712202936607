<template>
  <preloader-modal v-if="imagesLoader"/>
  <div class="modal fade"
       :id="modalId"
       tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Изображения для рассылки</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body row-form">
          <div class="row">
            <div class="col-7 col-sm-8 col-lg-9 col-xl-10">
              <div class="row-form-s">
                <div class="row">
                  <div v-if="errorMessage" class="col-12">
                    <alert-danger>{{ errorMessage }}</alert-danger>
                  </div>
                  <div class="col-sm pb-2 pb-sm-0">
                    <form :id="formId" @submit.prevent novalidate class="col ">
                      <input class="form-control" type="file" id="formFileLogo" ref="newLettersImages"
                             @change="imagesUpload" accept="image/*" required>
                    </form>
                  </div>
                  <div class="col-sm-auto">
                    <button-primary-utp1 @buttonClick="addLettersImage">Загрузить</button-primary-utp1>
                  </div>
                  <div class="col-12 pb-3">
                    <label class="form-text">
                      Рекомендуемая ширина 500px, формат JPG. Максимальное отношение сторон 3:1.
                    </label>
                  </div>
                </div>
              </div>
              <div class="row-form-s">
                <div class="row">
                  <div v-for="item in listLettersImages.lettersImagesMini"
                       class="col-6 col-sm-4 col-lg-3 col-xl-2 pb-3">
                    <input class="d-none"
                           type="checkbox"
                           :value="item.lettersImageId"
                           :id="item.lettersImageId"
                           @change="checkImg(item.lettersImageId)">
                    <div class="ratio ratio-1x1 text-center">
                      <label :for="item.lettersImageId"
                             :class="item.lettersImageId === selectedImg.lettersImageId ? '' : 'border-white'"
                             class="cursor-pointer upload-image-hover p-1 border">
                        <img
                            :src="'data:image;base64,' + item.base64Image"
                            style="max-width: 100%; max-height: 100%" alt="ImageMailing">
                      </label>
                    </div>
                  </div>
                  <pagination-light-utp1
                      v-model:page="page"
                      :total="listLettersImages.number"
                  />
                </div>
              </div>
            </div>
            <div class="col-5 col-sm-4 col-lg-3 col-xl-2">
              <div v-if="selectedImg.lettersImageId" class="pb-3 text-center">
                <img class="pb-2"
                     :src="'data:image;base64,' + selectedImg.base64Image"
                     style="max-width: 100%; max-height: 164px" alt="ImageMailing">
                <button-primary-utp1 class="d-sm-grid pb-2" @buttonClick="uploadImageMailing">Выбрать
                </button-primary-utp1>
                <button-danger-utp1 class="d-sm-grid" @buttonClick="deleteLettersImage">Удалить</button-danger-utp1>
              </div>
              <div class="pb-3">
                <label class="pb-2">Тип рассылки:</label>
                <select class="form-select" v-model="filter.mailing_types">
                  <option selected value="">Любая</option>
                  <option v-for="item in mailingTypes" :value="item.value">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <button-secondary-utp1 @buttonClick="getLetterImages" class="d-sm-grid">Искать</button-secondary-utp1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";
import ButtonSecondaryUtp1 from "../buttons/ButtonSecondaryUtp1";
import {ref, watch} from "vue";
import ButtonDangerUtp1 from "../buttons/ButtonDangerUtp1";
import PaginationLightUtp1 from "../paginations/PaginationLightUtp1";
import {useRoute, useRouter} from "vue-router";
import useImages from "../../../hooks/rest/files/useImages";
import PreloaderMain from "../../system/preloader/PreloaderMain";
import PreloaderModal from "../../system/preloader/PreloaderModal";
import AlertDanger from "../alerts/AlertDanger";
import useError from "../../../hooks/error/useError";

export default {
  name: "upload-image-mailing-modal",
  components: {
    AlertDanger,
    PreloaderModal,
    PreloaderMain,
    PaginationLightUtp1,
    ButtonDangerUtp1,
    ButtonSecondaryUtp1,
    ButtonPrimaryUtp1
  },
  props: {
    letterImageId: {
      type: Number
    },
    mailingType: {
      type: String
    },
    init: {
      type: Boolean
    },
    modalId: {
      type: String
    },
    formId: {
      type: String
    }
  },
  watch: {
    init() {
      this.initUploadImageMailingModal()
    }
  },
  emits: ["uploadImageMailing"],
  setup(props, {emit}) {
    const route = useRoute()
    const router = useRouter()
    const newLettersImages = ref(null)
    const lettersImages = ref(null)

    const mailingTypes = [{
      value: "SINGLE",
      name: "Разовая"
    }, {
      value: "AUTO",
      name: "Автоматическая"
    }, {
      value: "LAUNCH",
      name: "Запуск"
    }]

    const filter = ref({
      mailing_type: props.mailingType,
      mailing_types: route.query.mailing_types ? route.query.mailing_types : "",
    })

    const page = ref(Number(route.query.page) ? Number(route.query.page) : 1)

    const myQuery = () => {
      return {
        mailing_type: filter.value.mailing_type,
        mailing_types: filter.value.mailing_types,
        page: page.value
      }
    }

    watch(filter.value, () => {
      router.replace({
        query: myQuery()
      })
    })

    watch(page, async () => {
      await router.replace({
        query: myQuery()
      })
      await getLettersImages()
    })

    const getLetterImages = () => {
      if (page.value === 1) {
        getLettersImages()
      } else page.value = 1
    }

    const {
      imagesLoader,
      imagesException,
      addLettersImages,
      getLettersImages,
      deleteLettersImages,
      lettersImagesCode,
      listLettersImages
    } = useImages()

    const {error, errorMessage} = useError()

    watch(imagesException, () => {
      error(imagesException.value)
    })

    const initUploadImageMailingModal = () => {
      router.replace({
        query: myQuery()
      })
      getLettersImages()
    }

    const imagesUpload = () => {
      lettersImages.value = newLettersImages.value.files[0]
    }

    const addLettersImage = async () => {
      let formData = new FormData()
      formData.append('file', lettersImages.value)
      const form = document.querySelector('#' + props.formId)
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        resetSelectedImg()
        page.value = 1
        await addLettersImages(formData)
        emit('uploadImageMailing', props.letterImageId, 'https://src.utp1.com/images/mailing/' + lettersImagesCode.value)
        lettersImages.value = null
        newLettersImages.value.value = ''
        form.classList.remove("was-validated")
        await getLettersImages()
      }
    }

    const selectedImg = ref({
      lettersImageId: null,
      code: null,
      base64Image: null
    })

    const resetSelectedImg = () => {
      selectedImg.value.lettersImageId = null
      selectedImg.value.code = null
      selectedImg.value.base64Image = null
    }

    const checkImg = (id) => {
      if (id !== selectedImg.value.lettersImageId) {
        selectedImg.value.lettersImageId = id
        listLettersImages.value.lettersImagesMini.forEach(item => {
          if (item.lettersImageId === id) {
            selectedImg.value.code = item.code
            selectedImg.value.base64Image = item.base64Image
          }
        })
      } else {
        resetSelectedImg()
      }
    }

    const uploadImageMailing = () => {
      emit('uploadImageMailing', props.letterImageId, 'https://src.utp1.com/images/mailing/' + selectedImg.value.code)
    }

    const deleteLettersImage = async () => {
      await deleteLettersImages(selectedImg.value.lettersImageId)
      resetSelectedImg()
      page.value = 1
    }

    return {
      initUploadImageMailingModal,
      mailingTypes,
      newLettersImages,
      selectedImg,
      checkImg,
      filter,
      page,
      imagesLoader,
      errorMessage,
      listLettersImages,
      uploadImageMailing,
      imagesUpload,
      addLettersImage,
      getLetterImages,
      deleteLettersImage
    }
  }

}
</script>

<style scoped>

</style>