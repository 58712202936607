<template>
  <div v-for="item in productList">

    <card-utp1
        :card-id="item.id"
        :image="{
          img: item.image,
          base64: true,
          rounder: false,
          redirect: '/shop/products/'
        }"
        :dropdown-card-menu="cardHeader"
        @deleteCard="deleteCardModal(item.id)"
    >

      <template v-slot:cardHeader>
        <div class="row-form">
          <div class="row">
            <div class="col-6">
              <div class="text-truncate"><span class="pe-3">{{ item.cost.toLocaleString() }} ₽</span>
                <a class="card-link text-decoration-none fw-semibold"
                   @click="$router.push('/shop/products/' + item.id)">
                  {{ item.identifier }}
                </a>
              </div>
            </div>
            <div v-if="item.categoryId" class="col-6">
              <div class="text-truncate text-secondary text-end">
                <!--suppress JSCheckFunctionSignatures -->
                <a class="card-link text-decoration-none"
                   @click="queryCategory(item.categoryId)">
                  {{ categoryName(item.categoryId) }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </template>

      <a class="card-link text-decoration-none"
         @click="$router.push('/shop/products/' + item.id)">
        {{ item.name }}
      </a>

      <template v-slot:cardFooter>
        <div class="row">
          <!--suppress JSUnresolvedVariable -->
          <div class="col-auto p-0">
            {{ item.recurrent ? 'Реккурентный платёж' : 'Обычный разовый платёж' }}
          </div>
          <div class="col"></div>
          <div class="col-auto p-0">
          </div>
        </div>
      </template>

    </card-utp1>

  </div>
  <delete-object
      :id="deleteCardId"
      @deleteObject="deleteCard"
  />
</template>

<script>
import CardUtp1 from "../../../UI/cards/CardUtp1";
import {onMounted, ref} from "vue";
import DeleteObject from "../../../UI/modals/DeleteObject";

export default {
  name: "products-card",
  components: {DeleteObject, CardUtp1},
  props: {
    products: {
      type: Array,
      default: []
    },
    productImageList: {
      productImagesMini: []
    },
    productsCategories: {
      type: Array,
      default: []
    }
  },
  watch: {
    productImageList() {
      this.setProductList()
    }
  },
  emits: ['queryCategory', 'deleteCard'],
  setup(props, {emit}) {
    const cardHeader = [{
      dropdownName: "Изменить",
      dropdownPush: "/shop/products/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Копировать",
      dropdownPush: "/shop/products/add/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "",
      dropdownPush: "",
      dropdownHr: true,
      dropdownDelete: false
    }, {
      dropdownName: "Удалить",
      dropdownPush: "",
      dropdownHr: false,
      dropdownDelete: true
    }]

    const categoryName = (categoryId) => {
      for (let i = 0; i < props.productsCategories.length; i++) {
        if (props.productsCategories[i].id === categoryId) {
          return props.productsCategories[i].name;
        }
      }
      return ''
    }

    const queryCategory = (categoryId) => {
      emit("queryCategory", categoryId)
    }

    const productList = ref([])

    const setProductList = () => {
      productList.value = []
      props.products.forEach(product => {
        productList.value.push({
          id: product.id,
          name: product.name,
          categoryId: product.categoryId,
          identifier: product.identifier,
          status: product.status,
          recurrent: product.recurrent,
          cost: product.cost,
          image: getProductImage(product.id)
        })
      })
    }

    const getProductImage = (id) => {
      // noinspection JSUnresolvedVariable
      const foundItem = props.productImageList.productImagesMini.find(item => item.productId === id);
      return foundItem ? foundItem.base64Image : null;
    }

    const deleteCardId = ref(0)
    const deleteObjectModal = ref()
    const deleteCardModal = (id) => {
      deleteCardId.value = id
      deleteObjectModal.value.show()
    }
    const deleteCard = (profileId) => {
      emit('deleteCard', profileId)
    }

    onMounted(() => {
      deleteObjectModal.value = new bootstrap.Modal('#deleteObjectModal', {})
    })

    return {
      cardHeader,
      categoryName,
      queryCategory,
      getProductImage,
      setProductList,
      productList,
      deleteCard,
      deleteCardId,
      deleteCardModal
    }
  }
}
</script>

<style scoped>

</style>