<template>
  <header class="pt-1 pb-2 mb-4 clearfix">
    <div class="float-start pe-3">
      <button type="button" class="btn btn-outline-primary rounded-circle" @click="routerBack">
        <i class="bi bi-chevron-compact-left mx--125"></i>
      </button>
    </div>
    <div class="float-start">
      <!--suppress JSUnresolvedReference -->
      <h1 class="h3 fw-light">
        {{ header.title }}
      </h1>
    </div>
    <div class="float-end">
      <div v-if="status === 'ACTIVATED'" class="text-green-dark">
        <i class="bi bi-check fs-4 pe-1"/><span class="d-none d-sm-inline">Активирован</span>
      </div>
      <div v-if="status === 'SUBSCRIBED'" class="text-green">
        <i class="bi bi-plus fs-4 pe-1"/><span class="d-none d-sm-inline">Подписан</span>
      </div>
      <div v-if="status === 'WAITING'" class="text-orange">
        <i class="bi bi-clock fs-5 pe-1"/><span class="d-none d-sm-inline">Ожидает активации</span>
      </div>
      <div v-if="status === 'UNSIGNED'" class="text-red">
        <i class="bi bi-x fs-4 pe-1"/><span class="d-none d-sm-inline">Отписан</span>
      </div>
      <div v-if="status === 'UNSUBSCRIBED'" class="text-red-light">
        <i class="bi bi-dash fs-4 pe-1"/><span class="d-none d-sm-inline">Отписан сервисом</span>
      </div>
      <div v-if="status === 'NOT_EXISTS'" class="text-blue-gray">
        <i class="bi bi-bug fs-5 pe-1"/><span class="d-none d-sm-inline">Не существует</span>
      </div>

    </div>
  </header>
</template>

<script>
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";
import {useRouter} from "vue-router";

export default {
  name: "header-back-status-utp1",
  components: {ButtonPrimaryUtp1},
  props: {
    header: {
      type: Object,
      default: null
    },
    status: {
      type: String,
      default: null
    }
  },
  setup(props, {emit}) {
    const router = useRouter()

    const routerBack = () => {
      if (router.options.history.state.back) {
        router.go(-1)
      } else router.push(props.header.buttonRedirect)
    }

    const button = {
      buttonActive: true
    }
    const buttonClick = () => {
      emit("buttonClick")
    }
    return {button, buttonClick, routerBack}
  }

}
</script>

<style scoped>
.mx--125 {
  margin-right: -0.125rem !important;
  margin-left: -0.125rem !important;
}
</style>