<template>
  <nav aria-label="Pagination light UTP1">
    <ul class="pagination justify-content-center pagination-adaptive">
      <li :class="page === 1? 'page-item disabled' : 'page-item'">
        <a class="page-link z-1" @click="updatePage(page - 1)" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li v-for="item in listPageItems"
          :class="item === page ? 'page-item active z-1' : item === '...' ? 'page-item disabled' : 'page-item'">
        <a class="page-link" @click="updatePage(item)">{{ item }}</a>
      </li>
      <li :class="page >= (Math.ceil(total / number)) ? 'page-item disabled' : 'page-item'">
        <a class="page-link" @click="updatePage(page + 1)" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<!--suppress JSUnusedGlobalSymbols -->
<script>
import InputUtp1 from "../inputs/InputUtp1";
import FormSearch from "../forms/FormSearch.vue";
import {onMounted, ref} from "vue";

export default {
  name: "pagination-light-utp1",
  components: {FormSearch, InputUtp1},
  props: {
    number: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 100
    }
  },
  watch: {
    total() {
      this.updatePageListItem()
    },
    page() {
      this.updatePageListItem()
    }
  },
  emits: ["update:page"],
  setup(props, {emit}) {
    const listPageItems = ref([])

    const updatePageListItem = () => {
      const totalPages = Math.ceil(props.total / props.number)

      if (props.page === 1) {
      } else if (props.page < 1) {
        emit('update:page', 1)
      } else if (props.page > totalPages) {
        emit('update:page', totalPages)
      }

      if (totalPages <= 1) {
        listPageItems.value = [1]
      } else if (totalPages === 2) {
        listPageItems.value = [1, 2]
      } else if (totalPages === 3) {
        listPageItems.value = [1, 2, 3]
      } else if (props.page === 1) {
        listPageItems.value = [1, 2, '...', totalPages]
      } else if (props.page === totalPages) {
        listPageItems.value = [1, '...', (totalPages - 1), totalPages]
      } else if (totalPages === 4) {
        listPageItems.value = [1, 2, 3, 4]
      } else if (props.page === 2) {
        listPageItems.value = [1, 2, 3, '...', totalPages]
      } else if (props.page === (totalPages - 1)) {
        listPageItems.value = [1, '...', (totalPages - 2), (totalPages - 1), totalPages]
      } else if (totalPages === 5) {
        listPageItems.value = [1, 2, 3, 4, 5]
      } else {
        listPageItems.value = [1, '...', (props.page - 1), props.page, (props.page + 1), '...', totalPages]
      }
    }

    onMounted(() => {
      updatePageListItem()
    })

    const updatePage = (pageNumber) => {
      emit('update:page', pageNumber)
    }
    return {updatePage, updatePageListItem, listPageItems}
  }
}
</script>

<style scoped>
@media (max-width: 575px) {
  .pagination-adaptive {
    --bs-pagination-padding-x: 0.4rem;
    --bs-pagination-padding-y: 0.2rem;
    --bs-pagination-font-size: 0.7rem;
    --bs-pagination-border-radius: 0.2rem;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .pagination-adaptive {
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.25rem;
    --bs-pagination-font-size: 0.875rem;
    --bs-pagination-border-radius: 0.25rem;
  }
}
</style>