<template>
  <div class="pb-3">
    <!--suppress JSUnresolvedReference -->
    <label v-if="!product.recurrent" class="form-label">Страница после оплаты:</label>
    <label v-else class="form-label">Страница после первой оплаты:</label>
    <!--suppress JSUnresolvedReference -->
    <input-url-utp1
        id="getProductPage"
        v-model:url="product.getProductPage"
    />
  </div>
  <div class="pb-3">
    <!--suppress JSUnresolvedReference -->
    <label class="form-label" for="accessCodes">Одноразовые коды доступа (на данный момент:
      {{ product.accessCodes ? product.accessCodes.split('\n').length : 0 }}):</label>
    <!--suppress JSUnresolvedReference -->
    <textarea
        class="form-control pb-3"
        id="accessCodes"
        v-model="product.accessCodes"
        placeholder="Введите значения"
        type="text"
        rows="3"
        autocomplete="off"></textarea>
    <label for="accessCodes" class="form-text">
      По одному на строчке, без разделителей. Максимальная длина 255 символов, в противном случае код будет
      проигнорирован. После использования автоматически удаляются (но будут доступны в деталях заказа).
    </label>
  </div>
  <div class="pb-3">
    <!--suppress JSUnresolvedReference -->
    <label v-if="!product.recurrent" class="form-label">Добавлять в группы после оплаты:</label>
    <label v-else class="form-label">Подписывать в группы, пока активен автоматический платёж:</label>
    <div class="form-control">
      <!--suppress JSUnresolvedReference -->
      <groups-accordion v-model:groups="product.groupsPayments"/>
    </div>
  </div>
  <div class="pb-3">
    <!--suppress JSUnresolvedReference -->
    <label v-if="!product.recurrent" class="form-label">Удалять из групп после оплаты:</label>
    <label v-else class="form-label">Удалять из групп после первой оплаты:</label>
    <div class="form-control">
      <!--suppress JSUnresolvedReference -->
      <groups-accordion v-model:groups="product.deleteGroupsPayments"/>
    </div>
  </div>
  <!--suppress JSUnresolvedReference -->
  <label v-if="!product.recurrent" class="form-label">Назначать персональную комиссию после оплаты:</label>
  <label v-else class="form-label">Назначать персональную комиссию, пока активен автоматический платёж:</label>

  <!--suppress JSUnresolvedReference -->
  <div v-if="personalCommissions.personalCommissions.length === 0">
    <button-primary-utp1
        @buttonClick="addPersonalCommissions"
        :disabled="disablePersonalCommissions"
    >
      Добавить персональную комиссию
    </button-primary-utp1>
  </div>
  <div v-else>
    <!--suppress JSUnresolvedReference -->
    <div v-for="list in personalCommissions.personalCommissions" class="pb-3">
      <div class="form-control">
        <products-accordion
            :allProducts="productsList"
            :selectedProducts="selectedProducts"
            v-model:products="list.products"
            @update:products="updateSelectedProducts"
        />

        <!--suppress JSUnresolvedVariable -->
        <div v-if="list.commissions.length === 0" class="pt-3">
          <!--suppress JSUnresolvedVariable -->
          <button-primary-utp1
              @buttonClick="addLevelCommissions(list.commissions)"
          >
            Добавить партнёрскую комиссию
          </button-primary-utp1>
        </div>
        <div v-else>
          <div class="row-form pt-3">
            <!--suppress JSUnresolvedVariable -->
            <div v-for="item in list.commissions" class="row">
              <label class="form-label">Партнёрская комиссия {{ item.level }} уровня:</label>
              <div class="col-sm-3 col-12">
                <div class="input-group pb-2">
                  <input-utp1
                      v-model:model-value="item.percent"
                      placeholder="Процентов"
                      type="number"
                      min="0"
                      autocomplete="off"
                  />
                  <span class="input-group-text">%</span>
                </div>
              </div>
              <div class="col-sm-auto d-none d-sm-flex">
                <label class="col-form-label">от стоимости с учётом скидок +</label>
              </div>
              <div class="col-12 d-flex d-sm-none">
                <label class="form-label">от стоимости с учётом скидок +</label>
              </div>
              <div class="col-sm-3 col-12">
                <div class="input-group pb-2">
                  <input-utp1
                      v-model:model-value="item.cost"
                      placeholder="Рублей"
                      type="number"
                      step="0.01"
                      min="0"
                      autocomplete="off"
                  />
                  <span class="input-group-text">₽</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="pt-2 col-6 col-sm-auto px-0 pe-2">
              <!--suppress JSUnresolvedVariable -->
              <button-primary-utp1 @buttonClick="addLevelCommissions(list.commissions)">
                Добавить уровень
              </button-primary-utp1>
            </div>
            <div class="pt-2 col-6 col-sm-auto px-0 ps-2">
              <!--suppress JSUnresolvedVariable -->
              <button-danger-utp1 @buttonClick="deleteLevelCommissions(list.commissions)">
                Удалить уровень
              </button-danger-utp1>
            </div>
          </div>
        </div>
        <hr class="text-secondary mb-2"/>
        <label class="form-text">
          При внесении изменений в отдельно взятую персональную комиссию необходимо их сохранить:
        </label>
        <div class="row">
          <div class="pt-2 pb-2 col-6 col-sm-auto px-0 pe-2">
            <button-primary-utp1 @buttonClick="updatePersonalCommissions(list)">
              Сохранить
            </button-primary-utp1>
          </div>
          <div class="pt-2 pb-2 col-6 col-sm-auto px-0 ps-2">
            <button-danger-utp1 @buttonClick="deletePersonalCommissions(list)">
              Удалить
            </button-danger-utp1>
          </div>
        </div>
      </div>
    </div>
    <button-primary-utp1
        @buttonClick="addPersonalCommissions"
    >
      Добавить персональную комиссию
    </button-primary-utp1>
  </div>
  <!--  <div class="pt-2 pb-2">
      <button-primary-utp1
          data-bs-toggle="collapse" data-bs-target="#presentCollapse"
          aria-expanded="false" aria-controls="presentCollapse">
        Подарок при оплате в течении заданного времени <i class="ps-1 bi bi-caret-down-fill"></i>
      </button-primary-utp1>
    </div>
    <div class="collapse" id="presentCollapse">
      <div class="form-check form-check-inline mt-3 pb-2">
        <input class="form-check-input" type="checkbox" id="present" v-model="product.present">
        <label class="form-check-label" for="present">Отправлять подарок при оплате в течении заданного времени</label>
      </div>
      <div class="pb-3">
        <label class="form-label" for="presentAction">Название акции:</label>
        <input-utp1
            id="presentAction"
            v-model:model-value="product.presentAction"
            placeholder="Введите название"
            type="text"
            minlength="1"
            maxlength="255"
            autocomplete="off"
        />
      </div>
      <div class="pb-3">
        <label class="form-label" for="presentName">Наименование подарка:</label>
        <input-utp1
            id="presentName"
            v-model:model-value="product.presentName"
            placeholder="Введите наименование"
            type="text"
            minlength="1"
            maxlength="255"
            autocomplete="off"
        />
      </div>
      <div class="pb-3">
        <label class="form-label" for="presentPage">Ссылка на скачивание:</label>
        <input-url-utp1
            id="presentPage"
            v-model:url="product.presentPage"
        />
      </div>
      <div class="row-form">
        <div class="row">
          <label class="form-label">Срок действия акции с момента оформления заказа:</label>
          <div class="col-sm-4 col-6">
            <div class="input-group pb-2">
              <input-utp1
                  id="presentActionDays"
                  v-model:model-value="product.presentActionDays"
                  placeholder="Дней"
                  type="number"
                  min="0"
                  autocomplete="off"
              />
              <span class="input-group-text">дней</span>
            </div>
          </div>
          <div class="col-sm-4 col-6">
            <div class="input-group pb-2">
              <input-utp1
                  id="presentActionHours"
                  v-model:model-value="product.presentActionHours"
                  placeholder="Часов"
                  type="number"
                  min="0"
                  max="23"
                  autocomplete="off"
              />
              <span class="input-group-text">часов</span>
            </div>
          </div>
          <div class="col-sm-4 col-6">
            <div class="input-group pb-2">
              <input-utp1
                  id="presentActionMinutes"
                  v-model:model-value="product.presentActionMinutes"
                  placeholder="Минут"
                  type="number"
                  min="0"
                  max="59"
                  autocomplete="off"
              />
              <span class="input-group-text">минут</span>
            </div>
          </div>
        </div>
      </div>
    </div>-->
</template>

<script>
import InputUrlUtp1 from "../../../UI/inputs/InputUrlUtp1";
import GroupsAccordion from "../../../UI/accordions/GroupsAccordion";
import ButtonPrimaryUtp1 from "../../../UI/buttons/ButtonPrimaryUtp1";
import InputUtp1 from "../../../UI/inputs/InputUtp1";
import ProductsAccordion from "../../../UI/accordions/ProductsAccordion";
import useProducts from "../../../../hooks/rest/shop/useProducts";
import {onMounted, ref} from "vue";
import ButtonDangerUtp1 from "../../../UI/buttons/ButtonDangerUtp1";

export default {
  name: "product-get",
  components: {ButtonDangerUtp1, ProductsAccordion, InputUtp1, ButtonPrimaryUtp1, GroupsAccordion, InputUrlUtp1},
  props: {
    product: {
      type: Object
    },
    personalCommissions: {
      type: Object
    },
    disablePersonalCommissions: {
      type: Boolean
    }
  },
  watch: {
    personalCommissions() {
      this.updateSelectedProducts()
    }
  },
  emits: ['addPersonalCommissions', 'updatePersonalCommissions', 'deletePersonalCommissions'],
  setup(props, {emit}) {
    const {productsList, getAllProducts} = useProducts()
    onMounted(() => {
      getAllProducts()
    })

    const addLevelCommissions = (commissions) => {
      const newLevel = commissions.length + 1
      commissions.push({
        level: newLevel,
        percent: null,
        cost: null
      })
    }

    const deleteLevelCommissions = (commissions) => {
      commissions.splice(-1, 1)
    }

    const selectedProducts = ref([])

    const updateSelectedProducts = () => {
      selectedProducts.value = []
      props.personalCommissions.personalCommissions.forEach(list => {
        list.products.forEach(item => {
          selectedProducts.value.push(item)
        })
      })
    }

    const addPersonalCommissions = () => {
      emit('addPersonalCommissions')
    }

    const updatePersonalCommissions = (personalCommission) => {
      emit('updatePersonalCommissions', personalCommission)
    }

    const deletePersonalCommissions = (personalCommission) => {
      const indexToRemove = props.personalCommissions.personalCommissions.indexOf(personalCommission);
      props.personalCommissions.personalCommissions.splice(indexToRemove, 1)
      emit('deletePersonalCommissions', personalCommission.id)
    }

    return {
      productsList,
      selectedProducts,
      addLevelCommissions,
      deleteLevelCommissions,
      addPersonalCommissions,
      updatePersonalCommissions,
      deletePersonalCommissions,
      updateSelectedProducts
    }
  }
}
</script>

<style scoped>

</style>