<template>
  <div class="pt-1 mb-3 d-block">
    <div class="card text-bg-light">
      <div class="card-header">Так будет выглядеть подвал Вашего письма:</div>
      <div class="card-body text-center">
        <!--suppress JSUnresolvedReference -->
        <div class="mx-auto"
             style="max-width: 550px;color: #9299A2;font-family:Arial,sans-serif;font-size: 12px;line-height: 16px;text-align: center;">

          <a href="https://utp1.com" target="_blank">Письмо отправлено сервисом UTP1.com</a><br>

          Вы получили это письмо, так как 00.00.0000 года в 00:00 МСК с IP адреса 000.000.000.000 подписались на
          рассылки (и подтвердили свой e-mail)
          {{ emailSettings.name ? 'от автора «' + emailSettings.name + '»' : '' }}
          {{ emailSettings.projectUrl ? 'на сайте:' : '' }}<br>

          <!--suppress JSUnresolvedReference -->
          <a v-if="emailSettings.projectUrl" :href="emailSettings.projectUrl" target="_blank">{{
              emailSettings.projectName ? emailSettings.projectName :
                  emailSettings.projectUrl ? emailSettings.projectUrl : ''
            }}</a>

          <!--suppress JSUnresolvedReference -->
          <br v-if="emailSettings.projectUrl">

          {{
            emailSettings.company ? emailSettings.legalForms === 'IE' ? 'ИП ' :
                emailSettings.legalForms === 'LLC' ? 'ООО ' :
                    emailSettings.legalForms === 'JSC' ? 'АО ' :
                        emailSettings.legalForms === 'PJSC' ? 'ПАО ' :
                            emailSettings.legalForms === 'INDIVIDUAL' ? 'НДП ' :
                                emailSettings.legalForms === 'OTHER' ? 'Другое ' : '' : ''
          }}

          {{ emailSettings.company ? emailSettings.company + ';' : '' }}
          {{ emailSettings.ogrn ? 'ОГРН: ' + emailSettings.ogrn + ',' : '' }}
          {{ emailSettings.inn ? 'ИНН: ' + emailSettings.inn + '.' : '' }}

          <!--suppress JSUnresolvedReference -->
          <br v-if="emailSettings.company ||
                emailSettings.ogrn ||
                emailSettings.inn">

          {{ emailSettings.country ? emailSettings.country + ',' : '' }}
          {{ emailSettings.region ? emailSettings.region + ',' : '' }}
          {{ emailSettings.city ? emailSettings.city + ',' : '' }}
          {{ emailSettings.street ? 'ул. ' + emailSettings.street + ',' : '' }}
          {{ emailSettings.home ? 'д. ' + emailSettings.home + ',' : '' }}
          {{ emailSettings.building ? 'к. ' + emailSettings.building + ',' : '' }}
          {{ emailSettings.office ? 'офис ' + emailSettings.office + ',' : '' }}
          {{ emailSettings.phone ? '☎ ' + emailSettings.phone + '.' : '' }}

          <!--suppress JSUnresolvedReference -->
          <br v-if="emailSettings.country ||
                emailSettings.region ||
                emailSettings.city ||
                emailSettings.street ||
                emailSettings.home ||
                emailSettings.building ||
                emailSettings.office ||
                emailSettings.phone">

          Если Вы хотите отписаться от рассылки, перейдите: <a href="#">Управление рассылками</a>
        </div>
      </div>
      <div class="card-footer text-muted">
        Мы настоятельно рекомендуем заполнить максимальное количество полей, так как это не только повысит
        доставляемость Ваших писем, но и поможет Вам в случае возникновения споров с почтовыми сервисами, а мы,
        в свою очередь, окажем всяческое содействие.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "letter-footer",
  props: {
    emailSettings: {
      type: Object,
      default: {}
    }
  }
}
</script>

<style scoped>

</style>