// noinspection JSCheckFunctionSignatures

import axios from "axios";
import {ref} from 'vue';
import {useStore} from "vuex";
import useTokens from "../../../hooks/useTokens";

export default function useUser() {
    const store = useStore()
    const userLoader = ref(false)
    const fullUserLoader = ref(true)
    const userException = ref(null)

    const basic = ref({
        id: null,
        login: "",
        name: "",
        email: "",
        phone: ""
    })
    const hideEmail = ref("")
    const password = ref("")
    const userVk = ref({
        authorise: false,
        userVk: null
    })
    // const vkAuth = ref(false)

    const getAuth = async () => {
        userException.value = null
        const header = await authHeader()
        if (header) {
            setTimeout(await axios.get(`https://api.utp1.com/users/admin/settings`, {
                headers: header
            })
                .then(response => {
                    basic.value = response.data
                    store.commit('auth/auth', basic.value)
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getAuth()
                    } else store.commit('auth/unAuth')
                })
                .finally(() => {
                    // loader.value = false
                }), 10000)
            fullUserLoader.value = false
        } else store.commit('auth/unAuth')
    }

    const getUser = async () => {
        userException.value = null
        const header = await authHeader()
        if (header) {
            userLoader.value = true
            axios.get(`https://api.utp1.com/users/admin/settings`, {
                headers: header
            })
                .then(response => {
                    basic.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getUser()
                    }
                })
                .finally(() => {
                    userLoader.value = false
                })
        }
    }

    const updateName = async () => {
        userException.value = null
        const header = await authHeader()
        const name = {
            name: basic.value.name
        }
        if (header) {
            userLoader.value = true
            axios.put(`https://api.utp1.com/users/admin/settings/name`, name, {
                headers: header
            })
                .then(() => store.commit('auth/updateName', basic.value.name))
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateName()
                    } else try {
                        userException.value = e.response.data.error
                    } catch {
                        userException.value = "Exception"
                    }
                })
                .finally(() => userLoader.value = false)
        }
    }

    const updateEmail = async () => {
        userException.value = null
        const header = await authHeader()
        const email = {
            email: basic.value.email
        }
        if (header) {
            userLoader.value = true
            axios.put(`https://api.utp1.com/users/admin/settings/email`, email, {
                headers: header
            })
                .then(response => {
                    hideEmail.value = response.data.email
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateEmail()
                    } else try {
                        userException.value = e.response.data.error
                    } catch {
                        userException.value = "Exception"
                    }
                })
                .finally(() => userLoader.value = false)
        }
    }

    const updatePhone = async () => {
        userException.value = null
        const header = await authHeader()
        const phone = {
            phone: basic.value.phone
        }
        if (header) {
            userLoader.value = true
            axios.put(`https://api.utp1.com/users/admin/settings/phone`, phone, {
                headers: header
            })
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updatePhone()
                    } else try {
                        userException.value = e.response.data.error
                    } catch {
                        userException.value = "Exception"
                    }
                })
                .finally(() => userLoader.value = false)
        }
    }

    const updatePassword = async () => {
        userException.value = null
        const header = await authHeader()
        const data = {
            password: password.value
        }
        if (header) {
            userLoader.value = true
            axios.put(`https://api.utp1.com/users/admin/settings/password`, data, {
                headers: header
            })
                .then(response => {
                    hideEmail.value = response.data.email
                    password.value = ""
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updatePassword()
                    } else try {
                        userException.value = e.response.data.error
                    } catch {
                        userException.value = "Exception"
                    }
                })
                .finally(() => userLoader.value = false)
        }
    }

    const logoutAll = async () => {
        userException.value = null
        const header = await authHeader()
        if (header) {
            userLoader.value = true
            axios.put(`https://api.utp1.com/users/admin/settings/logout/all`, null, {
                headers: header
            })
                .then(delTokens)
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await logoutAll()
                    } else try {
                        userException.value = e.response.data.error
                    } catch {
                        userException.value = "Exception"
                    }
                })
                .finally(() => userLoader.value = false)
        }
    }

    const getUserVk = async () => {
        userException.value = null
        const header = await authHeader()
        if (header) {
            userLoader.value = true
            axios.get(`https://api.utp1.com/users/admin/social/vk`, {
                headers: header
            })
                .then(response => {
                    userVk.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getUserVk()
                    } else if (e.response.status === 403) {
                        userVk.value = {
                            authorise: false,
                            userVk: null
                        }
                    }
                })
                .finally(() => {
                    userLoader.value = false
                })
        }
    }

    const addVkToken = async (vkCode) => {
        const header = await authHeader()
        if (header) {
            userLoader.value = true
            axios.post(`https://api.utp1.com/users/admin/social/vk`,
                vkCode,
                {headers: header})
                .then(async response => {
                    if (response.status === 200) {
                        await getUserVk()
                    }
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await addVkToken()
                    }
                })
                .finally(() => {
                    userLoader.value = false
                })
        }
    }

    const deleteUsersVk = async () => {
        const header = await authHeader()
        if (header) {
            userLoader.value = true
            axios.delete(`https://api.utp1.com/users/admin/social/vk`,
                {headers: header})
                .then(() => {
                    userVk.value = {
                        authorise: false,
                        userVk: null
                    }
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await deleteUsersVk()
                    }
                })
                .finally(() => {
                    userLoader.value = false
                })
        }
    }

    const {authHeader, updateTokens, delTokens} = useTokens()

    return {
        getAuth,
        getUser,
        basic,
        updateName,
        updateEmail,
        updatePhone,
        updatePassword,
        userLoader,
        fullUserLoader,
        userException,
        hideEmail,
        password,
        logoutAll,
        getUserVk,
        addVkToken,
        deleteUsersVk,
        userVk
    }

}
