<template>
  <div class="input-group">
    <select class="input-group-text" id="inputGroupSelect01" v-model="protocol" @input="updateProtocol"
            :disabled="disabled">
      <option selected :value="null">Выбрать</option>
      <option value="https://">https://</option>
      <!--suppress HttpUrlsUsage -->
      <option value="http://">http://</option>
    </select>

    <!--suppress JSValidateTypes -->
    <input-utp1
        :type="protocol ? '' : 'url'"
        :required="required ? true : !!protocol"
        :placeholder=placeholder
        :disabled="disabled"
        :readonly="readonly"
        v-model:model-value="urlNoProtocol"
        maxlength="255"
        @input="updateUrlNoProtocol"
    />
  </div>
</template>

<!--suppress HttpUrlsUsage -->
<script>
import InputUtp1 from "./InputUtp1";
import {onMounted, ref} from "vue";

export default {
  name: "input-url-utp1",
  components: {InputUtp1},
  props: {
    url: {
      type: String.nullable,
      default: null
    },
    placeholder: {
      type: String,
      default: "Введите URL"
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    url() {
      this.initUrl()
    }
  },
  setup(props, {emit}) {
    const protocol = ref(null)
    const urlNoProtocol = ref(null)

    const deleteDoubleProtocol = (url) => {
      if (url.substring(0, 8) === "https://") {
        return url.substring(8)
      } else if (url.substring(0, 7) === "http://") {
        return url.substring(7)
      } else return url
    }

    const initUrl = () => {
      if (props.url === null || props.url === "") {
        protocol.value = null
        urlNoProtocol.value = ""
      } else if (props.url.substring(0, 8) === "https://") {
        protocol.value = "https://"
        urlNoProtocol.value = deleteDoubleProtocol(props.url.substring(8))
      } else if (props.url.substring(0, 7) === "http://") {
        protocol.value = "http://"
        urlNoProtocol.value = deleteDoubleProtocol(props.url.substring(7))
      } else {
        protocol.value = null
        urlNoProtocol.value = props.url
      }
    }

    const updateUrlNoProtocol = (event) => {
      if (protocol.value !== "https://" && protocol.value !== "http://" && event.target.value === "") {
        emit("update:url", null)
      } else if (protocol.value === "https://" || protocol.value === "http://") {
        if (event.target.value.substring(0, 8) === "https://") {
          emit("update:url", protocol.value + event.target.value.substring(8))
        } else if (event.target.value.substring(0, 7) === "http://") {
          emit("update:url", protocol.value + event.target.value.substring(7))
        } else {
          emit("update:url", protocol.value + event.target.value)
        }
      } else {
        emit("update:url", urlNoProtocol.value)
      }
    }

    const updateProtocol = (event) => {
      if (event.target.value !== "https://" && event.target.value !== "http://" &&
          (urlNoProtocol.value === "" || urlNoProtocol.value === null || urlNoProtocol.value === "null")) {
        emit("update:url", null)
      } else if (event.target.value === "https://" || event.target.value === "http://") {
        if (urlNoProtocol.value === "" || urlNoProtocol.value === null || urlNoProtocol.value === "null") {
          emit("update:url", event.target.value)
        } else emit("update:url", event.target.value + urlNoProtocol.value)
      } else {
        protocol.value = null
        emit("update:url", urlNoProtocol.value)
      }
    }

    onMounted(() => {
      initUrl()
    })

    return {initUrl, protocol, urlNoProtocol, updateUrlNoProtocol, updateProtocol}
  }
}
</script>

<style scoped>

</style>