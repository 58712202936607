import {ref} from "vue";
import {onBeforeRouteLeave} from "vue-router";

export default function useDeepCopy() {

    const deepCopyObject = (obj) => {
        if (!obj) {
            return {}
        }
        const result = {}
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                result[key] = deepCopyObject(obj[key])
            } else {
                result[key] = obj[key]
            }
        }
        return result;
    }

    const deepCopyArray = (array) => {
        if (!array) {
            return []
        }
        const result = []
        array.forEach(item => {
            result.push(deepCopyObject(item))
        });
        return result
    }

    return {deepCopyObject, deepCopyArray}

}
