<template>
  <div class="modal fade" :id="id" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Вставить изображение</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">

          <form :id="uniqueId" @submit.prevent novalidate>
            <div class="row-form">
              <div class="row">
                <div class="col-sm pb-2">
                  <input-url-utp1
                      v-model:url="newImage.imageUrl"
                      :placeholder="'URL изображения'"
                      :required="true"
                  />
                </div>
                <div class="col-sm-auto pb-2">
                  <button-secondary-utp1 @click="uploadImageModalShow">
                    Загрузить
                  </button-secondary-utp1>
                </div>
                <div class="col-12 pb-2">
                  <input-url-utp1
                      v-model:url="newImage.imageLink"
                      :placeholder="'Ссылка при клике'"
                  />
                </div>
                <div :class="images.length > 0 ? 'pb-3' : ''">
                  <button-primary-utp1 @click="addNewImage">
                    Добавить изображение
                  </button-primary-utp1>
                </div>
              </div>
            </div>
          </form>
          <table class="table table-hover">
            <tbody>
            <tr v-for="item in images" class="cursor-pointer">
              <td style="max-width: 47px" @click="insertImage(item.letterImageId)">
                <div class="row-form">
                  <div class="row-form">
                    <div class="row row-form">
                      <div class="col-auto text-center" style="min-width: 100px">
                        <img v-if="item.imageUrl"
                             :src="item.imageUrl.substring(0, 36) === 'https://src.utp1.com/images/mailing/' ?
                             'https://src.utp1.com/images/mailing/mini/' + item.imageUrl.substring(36) : item.imageUrl"
                             style="max-width: 48px; max-height: 48px;" alt="Mailing letter image">
                        <img v-else src="../../../../src/assets/images/gravatar.jpg"
                             style="max-width: 48px; max-height: 48px;" alt="Mailing letter image">
                      </div>
                      <div class="col">
                        <div class="text-truncate">
                          {{ item.imageUrl ? item.imageUrl : 'URL изображения не указан' }}
                        </div>
                        <div class="text-truncate">
                          <a class="link- text-decoration-none">
                            {{ item.imageLink ? item.imageLink : 'Ссылка при клике не указана' }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <upload-image-mailing-modal
      :modal-id="'uploadImageModal'"
      :form-id="'imageModalForm'"
      :letter-image-id="images.length"
      :mailing-type="'SINGLE'"
      :init="initUploadImageModal"
      @uploadImageMailing="updateImages"
  />
</template>

<script>
import InputUrlUtp1 from "../inputs/InputUrlUtp1";
import ButtonSecondaryUtp1 from "../buttons/ButtonSecondaryUtp1";
import {ref} from "vue";
import UploadImageMailingModal from "./UploadImageMailingModal";
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";

export default {
  name: "add-images-from-list",
  components: {ButtonPrimaryUtp1, UploadImageMailingModal, ButtonSecondaryUtp1, InputUrlUtp1},
  props: {
    id: {
      type: String,
      default: "addImagesFromList"
    },
    images: {
      type: Array,
      default: []
    }
  },
  emits: ['insertImage', 'addImagesFromListShow', 'addImagesFromListHide'],
  setup(props, {emit}) {
    const codeGenerate = () => {
      const chars = 'abcdefghijklmnopqrstuvwxyz'
      let str = ''
      for (let i = 0; i < 5; i++) {
        const pos = Math.floor(Math.random() * chars.length)
        str += chars.substring(pos, pos + 1)
      }
      return str
    }

    const uniqueId = codeGenerate()

    const initUploadImageModal = ref(false)
    const uploadImageModal = ref(null)

    const newImage = ref({
      imageUrl: null,
      imageLink: null
    })

    const addImagesFromListShow = () => {
      emit('addImagesFromListShow')
    }

    const addImagesFromListHide = () => {
      emit('addImagesFromListHide')
    }

    const uploadImageModalShow = async () => {
      addImagesFromListHide()
      if (!uploadImageModal.value) {
        initUploadImageModal.value = true
        uploadImageModal.value = await new bootstrap.Modal('#uploadImageModal', {})
      }
      uploadImageModal.value.show()
    }

    const updateImages = (letterImageId, selectedImg) => {
      uploadImageModal.value.hide()
      newImage.value.imageUrl = selectedImg
      addImagesFromListShow()
    }

    const insertImage = (id) => {
      emit('insertImage', id)
    }

    const addNewImage = () => {
      const form = document.querySelector('#' + uniqueId)
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        form.classList.remove("was-validated")
        const id = props.images.length
        props.images.push({
          letterImageId: id,
          imageUrl: newImage.value.imageUrl,
          imageLink: newImage.value.imageLink
        })
        newImage.value.imageUrl = null
        newImage.value.imageLink = null
        insertImage(id)
      }
    }

    return {
      insertImage,
      newImage,
      addNewImage,
      initUploadImageModal,
      updateImages,
      addImagesFromListShow,
      addImagesFromListHide,
      uploadImageModalShow,
      uniqueId
    }
  }
}
</script>

<style scoped>

</style>