<template>
  <div class="d-sm-inline d-grid gap-2">
    <button type="button" :class="disabled ? 'btn btn-danger disabled' : 'btn btn-danger'" @click="buttonClick">
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "button-danger-utp1",
  props: {
    disabled: Boolean
  },
  setup(_, {emit}) {
    const buttonClick = () => {
      emit("buttonClick")
    }
    return {buttonClick}
  }
}
</script>

<style scoped>

</style>