<!--suppress JSUnresolvedVariable -->
<template>
  <div v-for="item in contacts">
    <card-utp1
        :card-id="item.id"
        :dropdown-card-menu="cardHeader"
        :icon="item.status === 'ACTIVATED' ? {
          icon: 'bi bi-check',
          background: 'bg-green-dark',
          title: 'Активирован',
          little: false,
          redirect: '/mailing/contacts/'
        } : item.status === 'SUBSCRIBED' ? {
          icon: 'bi bi-plus',
          background: 'bg-green',
          title: 'Подписан',
          little: false,
          redirect: '/mailing/contacts/'
        } : item.status === 'WAITING' ? {
          icon: 'bi bi-clock',
          background: 'bg-orange',
          title: 'Ожидает активации',
          little: false,
          redirect: '/mailing/contacts/'
        } : item.status === 'UNSIGNED' ? {
          icon: 'bi bi-x',
          background: 'bg-red',
          title: 'Отписан',
          little: false,
          redirect: '/mailing/contacts/'
        } : item.status === 'UNSUBSCRIBED' ? {
          icon: 'bi bi-dash',
          background: 'bg-red-light',
          title: 'Отписан сервисом',
          little: false,
          redirect: '/mailing/contacts/'
        } : item.status === 'NOT_EXISTS' ? {
          icon: 'bi bi-bug',
          background: 'bg-blue-gray',
          title: 'Не существует',
          little: false,
          redirect: '/mailing/contacts/'
        } : null"
        @deleteCard="deleteCardModal(item.id)">

      <template v-slot:cardHeader>
        <div class="text-truncate">
          <a class="card-link text-decoration-none fw-semibold"
             @click="$router.push('/mailing/contacts/' + item.id)">
            {{ item.name ? item.name : "Без имени" }}
          </a>
        </div>
      </template>

      <div class="row">
        <div class="col p-0">
          <div v-if="item.emails.length > 0" class="btn-group d-block d-sm-inline-flex pe-sm-3">
            <div class="text-truncate">
              <a v-if="item.emails.length > 1"
                 class="card-link text-decoration-none dropdown-toggle"
                 data-bs-toggle="dropdown">
                {{ item.emails[0] }}
              </a>
              <a v-else
                 class="card-link"
                 :href="'mailto:' + item.emails[0]">
                {{ item.emails[0] }}
              </a>
              <ul class="dropdown-menu dropdown-menu-start">
                <li v-for="email in item.emails">
                  <a class="dropdown-item" :href="'mailto:' + email">
                    <a class="card-link">{{ email }}</a>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="item.phones.length > 0" class="btn-group d-block d-sm-inline-flex">
            <div class="text-truncate">
              <a v-if="item.phones.length > 1"
                 class="card-link text-decoration-none dropdown-toggle"
                 data-bs-toggle="dropdown">
                {{ item.phones[0] }}
              </a>
              <a v-else
                 class="card-link text-truncate"
                 :href="'tel:' + item.phones[0]">
                {{ item.phones[0] }}
              </a>
              <ul class="dropdown-menu dropdown-menu-start">
                <li v-for="phone in item.phones">
                  <a class="dropdown-item" :href="'tel:' + phone">
                    <a class="card-link">{{ phone }}</a>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-auto p-0 social-link">
          <a v-if="item.vkId" class="card-link text-decoration-none fs-4"
             @click="redirectVk(item.vkId)">
            <i class="icon-vk-round icommoon"></i>
          </a>
          <span v-else class="card-link text-decoration-none fs-4">
            <i class="icon-vk-round icommoon"></i>
          </span>
          <a v-if="item.telegram" class="card-link text-decoration-none fs-4">
            <i class="bi bi-telegram"></i>
          </a>
          <span v-else class="card-link text-decoration-none fs-4">
            <i class="bi bi-telegram"></i>
          </span>
          <a v-if="item.facebook" class="card-link text-decoration-none fs-4">
            <i class="bi bi-facebook"></i>
          </a>
          <span v-else class="card-link text-decoration-none fs-4">
            <i class="bi bi-facebook"></i>
          </span>
        </div>
      </div>

      <template v-slot:cardFooter>
        <div class="row">
          <!--suppress JSCheckFunctionSignatures -->
          <div class="col-auto ps-0 text-truncate">
            {{ formattedDateTimeTz(item.createDateTimeTz, null, 'DD.MM.YYYY в HH:mm') }}
          </div>
          <div class="col ps-0 text-end text-truncate" style="padding-right: 0.001rem">
            <div v-if="item.groups.length === 1" class="text-truncate">
              <a class="card-link text-decoration-none">
                {{ item.groups[0].name }}
              </a>
            </div>
            <div v-else-if="item.groups.length > 1" class="text-truncate">
              <span class="d-none d-sm-inline">Группы: </span>
              <a class="card-link text-decoration-none dropdown-toggle"
                 data-bs-toggle="dropdown">
                [ {{ item.groups.length }} ]
              </a>
              <ul class="dropdown-menu dropdown-menu-end" style="max-width: 80%">
                <li v-for="group in item.groups">
                  <a class="dropdown-item">
                    <a class="card-link text-decoration-none">
                      {{ group.name }}
                    </a>
                  </a>
                </li>
              </ul>
            </div>
            <div v-else class="text-truncate">
              <a class="card-link text-decoration-none">
                Без групп
              </a>
            </div>
          </div>
        </div>
      </template>
    </card-utp1>
  </div>
  <delete-object
      :id="deleteCardId"
      @deleteObject="deleteCard"
  />
</template>

<script>
import CardUtp1 from "../../../UI/cards/CardUtp1";
import useMoment from "../../../../hooks/useMoment";
import DeleteObject from "../../../UI/modals/DeleteObject";
import {onMounted, ref} from "vue";

export default {
  name: "contacts-cards",
  components: {DeleteObject, CardUtp1},
  props: {
    contacts: {
      type: Array,
      default: []
    }
  },
  emits: ["deleteCard"],
  setup(_, {emit}) {
    const cardHeader = [{
      dropdownName: "Изменить",
      dropdownPush: "/mailing/contacts/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "",
      dropdownPush: "",
      dropdownHr: true,
      dropdownDelete: false
    }, {
      dropdownName: "Удалить",
      dropdownPush: "",
      dropdownHr: false,
      dropdownDelete: true
    }]

    const deleteCardId = ref(0)
    const deleteObjectModal = ref()
    const redirectVk = (vkId) => {
      window.open('https://vk.com/id' + vkId, '_blanc')
    }
    const deleteCardModal = (profileId) => {
      deleteCardId.value = profileId
      deleteObjectModal.value.show()
    }
    const deleteCard = (profileId) => {
      emit('deleteCard', profileId)
    }

    onMounted(() => {
      deleteObjectModal.value = new bootstrap.Modal('#deleteObjectModal', {})
    })

    const {formattedDateTimeTz} = useMoment()
    return {cardHeader, redirectVk, formattedDateTimeTz, deleteCardId, deleteCardModal, deleteCard}
  }
}
</script>

<style scoped>

</style>