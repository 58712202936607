<template>
  <preloader-main v-if="mailingSettingsLoader || imagesLoader"/>
  <div v-if="errorMessage">
    <alert-danger>{{ errorMessage }}</alert-danger>
  </div>
  <div class="row-form">
    <div class="row">
      <form id="group-letter-logo" @submit.prevent novalidate class="col-12">
        <div class="card p-3 mb-3">
          <div class="mb-3">
            <label for="formFileLogo" class="form-label">Логотип</label>
            <div v-if="letterLogo" class="mb-3">
              <img
                  :src="'data:image;base64,' + letterLogo"
                  style="max-width: 400px; max-height: 40px" alt="Logo">
            </div>
            <input class="form-control" type="file" id="formFileLogo" ref="newLettersLogo"
                   @change="logoUpload" accept="image/*" required>
            <label class="form-text">
              Рекомендуемая высота 50px, формат JPG. Отношение сторон не более чем 1:10.
            </label>
          </div>
          <double-button-utp1
              v-if="letterLogo"
              :buttons="updateImageButton"
              :primary-disabled="!lettersLogo"
              @buttonPrimaryClick="updateLetterLogo"
              @buttonSecondaryClick="deleteLettersLogos"/>
          <button-primary-utp1 v-else :disabled="!lettersLogo" @buttonClick="updateLetterLogo">Добавить логотип
          </button-primary-utp1>
        </div>
      </form>

      <form id="group-letter-header" @submit.prevent novalidate class="col-12">
        <div class="card p-3 mb-3">
          <div class="mb-3">
            <label for="formFileHeader" class="form-label">Шапка</label>
            <div v-if="letterHeader" class="mb-3">
              <img
                  :src="'data:image;base64,' + letterHeader"
                  style="max-width: 440px; max-height: 40px" alt="Logo">
            </div>
            <input class="form-control" type="file" id="formFileHeader" ref="newLettersHeader"
                   @change="headerUpload" accept="image/*" required>
            <label class="form-text">
              Рекомендуемая ширина 550px, формат JPG. Отношение сторон не более чем 1:1.
            </label>
          </div>
          <double-button-utp1
              v-if="letterHeader"
              :buttons="updateImageButton"
              :primary-disabled="!lettersHeader"
              @buttonPrimaryClick="updateLetterHeader"
              @buttonSecondaryClick="deleteLettersHeaders"/>
          <button-primary-utp1 v-else :disabled="!lettersHeader" @buttonClick="updateLetterHeader">Добавить шапку
          </button-primary-utp1>
        </div>
      </form>
    </div>

    <form id="group-letter-setting" @submit.prevent novalidate>
      <div class="row">
        <div class="mb-3">
          <label class="pb-2" for="logoUrl">Ссылка при клике на логотип</label>
          <input-url-utp1
              id="logoUrl"
              v-model:url="lettersSettings.logoUrl"/>
        </div>
        <div class="mb-3">
          <div class="row">
            <label for="additionalColorInput" class="form-label p-0">Дополнительный цвет</label>
            <div class="col-auto ps-0 pe-4">
              <input type="color" class="form-control form-control-color" id="additionalColorInput"
                     v-model="lettersSettings.color">
            </div>
            <div class="btn-group-colors col p-0">
              <button class="btn btn-color-primary" type="button" @click="lettersSettings.color='#23282d'"></button>
              <button class="btn btn-color-default" type="button" @click="lettersSettings.color='#0073aa'"></button>
              <button class="btn btn-color-info" type="button" @click="lettersSettings.color='#31b0d5'"></button>
              <button class="btn btn-color-success" type="button" @click="lettersSettings.color='#00bd9b'"></button>
              <button class="btn btn-color-warning" type="button" @click="lettersSettings.color='#f56e28'"></button>
              <button class="btn btn-color-sunflower" type="button" @click="lettersSettings.color='#ffb900'"></button>
              <button class="btn btn-color-danger" type="button" @click="lettersSettings.color='#cb5152'"></button>
              <button class="btn btn-color-pomegranate" type="button" @click="lettersSettings.color='#dc3232'"></button>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="row">
            <label for="backgroundColorInput" class="form-label p-0">Фоновый цвет</label>
            <div class="col-auto ps-0 pe-4">
              <input type="color" class="form-control form-control-color" id="backgroundColorInput"
                     v-model="lettersSettings.backgroundColor">
            </div>
            <div class="btn-group-colors col p-0">
              <button class="btn btn-color-white border" type="button"
                      @click="lettersSettings.backgroundColor='#ffffff'"></button>
              <button class="btn btn-color-primary-light border" type="button"
                      @click="lettersSettings.backgroundColor='#eeefef'"></button>
              <button class="btn btn-color-dark-light border" type="button"
                      @click="lettersSettings.backgroundColor='#d8d8da'"></button>
              <button class="btn btn-color-default-light border" type="button"
                      @click="lettersSettings.backgroundColor='#ecf4f9'"></button>
              <button class="btn btn-color-success-light border" type="button"
                      @click="lettersSettings.backgroundColor='#eafaf7'"></button>
              <button class="btn btn-color-warning-light border" type="button"
                      @click="lettersSettings.backgroundColor='#fdefe7'"></button>
              <button class="btn btn-color-sunflower-light border" type="button"
                      @click="lettersSettings.backgroundColor='#fdf9e7'"></button>
              <button class="btn btn-color-danger-light border" type="button"
                      @click="lettersSettings.backgroundColor='#fde7e7'"></button>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label for="defaultTextTemplate" class="form-label">Шаблон текста по-умолчанию</label>
          <textarea class="form-control" id="defaultTextTemplate" rows="3"
                    v-model="lettersSettings.defaultTextTemplate"></textarea>
        </div>

        <div class="mb-3">
          <label for="contactNotes" class="form-label">
            Примечание к контакту
          </label>
          <input-utp1
              id="contactNotes"
              placeholder="Примечание к контакту"
              v-model:model-value="lettersSettings.contactNotes"
              maxlength="70"/>
        </div>

        <div class="mb-3 col-sm-5">
          <label for="contact" class="form-label">
            Контакт
          </label>
          <input-utp1
              id="contact"
              placeholder="Контакт"
              v-model:model-value="lettersSettings.contact"
              maxlength="30"/>
        </div>

        <div class="mb-3 col-sm-7">
          <label for="contactUrl" class="form-label">
            Ссылка при клике
          </label>
          <input-url-utp1 id="contactUrl" v-model:url="lettersSettings.contactUrl"/>
        </div>

        <div class="mb-3">
          <label for="socialNetworksCall" class="form-label">
            Призыв для социальных сетей
          </label>
          <input-utp1
              id="socialNetworksCall"
              placeholder="Призыв для социальных сетей"
              v-model:model-value="lettersSettings.socialNetworksCall"
              maxlength="50"/>
        </div>

        <div class="mb-3 col-sm-6">
          <label for="socialButtonColor" class="form-label">
            Цвет кнопок социальных сетей
          </label>
          <input-select-utp1
              id="socialButtonColor"
              :select-items="socialButtonColors"
              v-model:selected-item="lettersSettings.socialButtonColor"
          />
        </div>

        <div class="mb-3 col-sm-6">
          <label for="socialButtonType" class="form-label">
            Вид кнопок социальных сетей
          </label>
          <input-select-utp1
              id="socialButtonType"
              :select-items="socialButtonTypes"
              v-model:selected-item="lettersSettings.socialButtonType"
          />
        </div>

        <div class="mb-3">
          <div class="mb-3">
            <social-buttons-input
                v-model:social-buttons="lettersSettings.socialButtons"
                @deleteSocialButton="deleteSocialButton"
                @updateSocialButton="updateSocialButton"
            />
          </div>
          <button-primary-utp1
              :disabled="lettersSettings.socialButtons ? lettersSettings.socialButtons.length > 9 : false"
              @buttonClick="socialButtonModalShow"
          >
            Добавить иконку
          </button-primary-utp1>
        </div>

        <letter-body
            :letters-settings="lettersSettings"
            :letters-logo="letterLogo"
            :letters-header="letterHeader"
        />

      </div>
    </form>
  </div>

  <double-button-utp1
      :buttons="buttons"
      @buttonPrimaryClick="updateLettersSettings"
      @buttonSecondaryClick="cancelChanges"
  />

  <social-button-modal
      v-model:social-button="socialButton"
      @applySocialButton="applySocialButton"
  />

</template>

<script>
import InputUrlUtp1 from "../../../UI/inputs/InputUrlUtp1";
import InputUtp1 from "../../../UI/inputs/InputUtp1";
import InputSelectUtp1 from "../../../UI/inputs/InputSelectUtp1";
import useMailingSettings from "../../../../hooks/rest/mailing/useMailingSettings";
import useError from "../../../../hooks/error/useError";
import {onMounted, ref, watch} from "vue";
import PreloaderMain from "../../../system/preloader/PreloaderMain";
import AlertDanger from "../../../UI/alerts/AlertDanger";
import DoubleButtonUtp1 from "../../../UI/buttons/DoubleButtonUtp1";
import SocialButtonsInput from "../../../UI/forms/SocialButtonsInput";
import ButtonPrimaryUtp1 from "../../../UI/buttons/ButtonPrimaryUtp1";
import AddSocialButton from "../../../UI/modals/SocialButtonModal";
import SocialButtonModal from "../../../UI/modals/SocialButtonModal";
import LetterFooter from "./LetterFooter";
import LetterBody from "./LetterBody";
import useImages from "../../../../hooks/rest/files/useImages";

export default {
  name: "letter-setting",
  components: {
    LetterBody,
    LetterFooter,
    SocialButtonModal,
    AddSocialButton,
    ButtonPrimaryUtp1,
    SocialButtonsInput,
    DoubleButtonUtp1, AlertDanger, PreloaderMain, InputSelectUtp1, InputUtp1, InputUrlUtp1
  },
  props: {
    letterSettings: {
      type: Object
    }
  },
  watch: {
    letterSettings() {
      this.cancelChanges()
    }
  },
  setup(props) {
    const newLettersLogo = ref(null)
    const newLettersHeader = ref(null)
    const lettersLogo = ref(null)
    const lettersHeader = ref(null)

    const socialButtonColors = [{
      name: "Цветные",
      value: true,
      selected: true
    }, {
      name: "Однотонные",
      value: false,
      selected: true
    }]
    const socialButtonTypes = [{
      name: "Круглые",
      value: "ROUND",
      selected: true
    }, {
      name: "Квадратные",
      value: "SQUARE",
      selected: true
    }, {
      name: "Иконки",
      value: "ICON",
      selected: true
    }]

    const socialButtonModal = ref()
    onMounted(() => {
      socialButtonModal.value = new bootstrap.Modal('#socialButtonModal', {})
    })

    const socialButtonModalShow = () => {
      socialButtonModal.value.show()
    }

    const buttons = {
      primaryTitle: "Сохранить",
      primaryDisabled: false,
      secondaryTitle: "Отменить",
      secondaryDisabled: false
    }

    const updateImageButton = {
      primaryTitle: "Изменить",
      primaryDisabled: false,
      secondaryTitle: "Удалить",
      secondaryDisabled: false
    }

    const lettersSettings = ref({
      logoUrl: "",
      color: "#23282d",
      backgroundColor: "#ffffff",
      contactNotes: "",
      contact: "",
      contactUrl: "",
      defaultTextTemplate: "",
      socialNetworksCall: "",
      socialButtonColor: true,
      socialButtonType: "ROUND",
      socialButtons: [{
        socialNetwork: "",
        url: "",
        priority: null
      }]
    })

    const socialButton = ref({
      socialNetwork: null,
      url: null,
      priority: null
    })

    const cancelChanges = () => {
      lettersSettings.value.logoUrl = props.letterSettings.logoUrl
      lettersSettings.value.color = props.letterSettings.color
      lettersSettings.value.backgroundColor = props.letterSettings.backgroundColor
      lettersSettings.value.contactNotes = props.letterSettings.contactNotes
      lettersSettings.value.contact = props.letterSettings.contact
      lettersSettings.value.contactUrl = props.letterSettings.contactUrl
      lettersSettings.value.defaultTextTemplate = props.letterSettings.defaultTextTemplate
      lettersSettings.value.socialNetworksCall = props.letterSettings.socialNetworksCall
      lettersSettings.value.socialButtonColor = props.letterSettings.socialButtonColor
      lettersSettings.value.socialButtonType = props.letterSettings.socialButtonType
      lettersSettings.value.socialButtons.splice(
          0, lettersSettings.value.socialButtons.length, ...props.letterSettings.socialButtons)
    }

    const updateSocialButtonsList = () => {
      lettersSettings.value.socialButtons.forEach(function (item, i) {
        item.priority = i
      })
    }

    const applySocialButton = () => {
      if (socialButton.value.priority === null) {
        lettersSettings.value.socialButtons.push({
          socialNetwork: socialButton.value.socialNetwork,
          url: socialButton.value.url,
          priority: null
        })
      } else if (socialButton.value.priority >= 0 && socialButton.value.priority <= 9) {
        lettersSettings.value.socialButtons[socialButton.value.priority] = {
          socialNetwork: socialButton.value.socialNetwork,
          url: socialButton.value.url,
          priority: socialButton.value.priority
        }
      }
      socialButton.value.socialNetwork = null
      socialButton.value.url = null
      socialButton.value.priority = null
      socialButtonModal.value.hide()
      updateSocialButtonsList()
    }

    const updateSocialButton = (id) => {
      socialButton.value.socialNetwork = lettersSettings.value.socialButtons[id].socialNetwork
      socialButton.value.url = lettersSettings.value.socialButtons[id].url
      socialButton.value.priority = lettersSettings.value.socialButtons[id].priority
      socialButtonModalShow()
    }

    const deleteSocialButton = (id) => {
      lettersSettings.value.socialButtons.splice(id, 1)
      updateSocialButtonsList()
    }

    const resetException = () => {
      mailingSettingsException.value = null
      imagesException.value = null
    }

    const updateLettersSettings = () => {
      const form = document.querySelector('#group-letter-setting')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        resetException()
        updateLetterSettings(lettersSettings.value)
        form.classList.remove("was-validated")
      }
    }

    const logoUpload = () => {
      lettersLogo.value = newLettersLogo.value.files[0]
    }

    const updateLetterLogo = () => {
      let formData = new FormData()
      formData.append('file', lettersLogo.value)
      const form = document.querySelector('#group-letter-logo')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        resetException()
        updateLettersLogo(formData)
        lettersLogo.value = null
        newLettersLogo.value.value = ''
        form.classList.remove("was-validated")
      }
    }

    const headerUpload = () => {
      lettersHeader.value = newLettersHeader.value.files[0]
    }

    const updateLetterHeader = () => {
      let formData = new FormData()
      formData.append('file', lettersHeader.value)
      const form = document.querySelector('#group-letter-header')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        resetException()
        updateLettersHeader(formData)
        lettersHeader.value = null
        newLettersHeader.value.value = ''
        form.classList.remove("was-validated")
      }
    }

    const deleteLettersLogos = () => {
      resetException()
      deleteLettersLogo()
    }

    const deleteLettersHeaders = () => {
      resetException()
      deleteLettersHeader()
    }

    const {mailingSettingsLoader, mailingSettingsException, updateLetterSettings} = useMailingSettings()
    const {
      imagesException,
      imagesLoader,
      letterLogo,
      letterHeader,
      updateLettersLogo,
      deleteLettersLogo,
      getLettersLogo,
      updateLettersHeader,
      deleteLettersHeader,
      getLettersHeader,
    } = useImages()
    const {error, errorMessage} = useError()

    onMounted(() => {
      getLettersLogo()
      getLettersHeader()
    })

    watch(mailingSettingsException, () => {
      error(mailingSettingsException.value)
    })

    watch(imagesException, () => {
      error(imagesException.value)
    })

    return {
      socialButtonColors,
      socialButtonTypes,
      buttons,
      updateImageButton,
      lettersSettings,
      socialButton,
      socialButtonModal,
      cancelChanges,
      applySocialButton,
      updateLettersSettings,
      updateSocialButton,
      deleteSocialButton,
      socialButtonModalShow,
      mailingSettingsLoader,
      errorMessage,

      newLettersLogo,
      newLettersHeader,
      lettersLogo,
      lettersHeader,
      logoUpload,
      headerUpload,
      imagesLoader,
      letterLogo,
      letterHeader,
      updateLetterLogo,
      deleteLettersLogos,
      updateLetterHeader,
      deleteLettersHeaders
    }
  }
}
</script>

<style scoped>

</style>