<template>
  <div class="d-sm-inline d-grid gap-2">
    <button type="button" :class="inactive ? 'btn btn-secondary disabled' : 'btn btn-secondary'"
            @click="buttonClick">
      <slot></slot>
    </button>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: "button-secondary-utp1",
  props: {
    disabled: Boolean,
    disposable: Boolean
  },
  watch: {
    disabled() {
      this.initDisabled()
    }
  },
  setup(props, {emit}) {
    const inactive = ref(false)

    const initDisabled = () => {
      inactive.value = props.disabled
    }

    const buttonClick = async () => {
      if (props.disposable) {
        emit("buttonClick")
        inactive.value = true
        active()
      } else {
        emit("buttonClick")
      }
    }

    const active = () => {
      setTimeout(() => {
        inactive.value = false
      }, 600000)
    }

    return {initDisabled, inactive, buttonClick}
  }
}
</script>

<style scoped>

</style>