<template>
  <preloader-modal v-if="preloader"/>
  <div class="modal fade" :id="id" tabindex="-1">
    <div class="modal-dialog modal-lg modal-fullscreen-lg-down modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Предпросмотр HTML версии письма</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body p-0" v-html="emailLetterHtmlString">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreloaderModal from "../../system/preloader/PreloaderModal";
export default {
  name: "preview-email-letters-modal",
  components: {PreloaderModal},
  props: {
    id: {
      type: String,
      default: "previewEmailLettersModal"
    },
    emailLetterHtmlString: {
      type: String,
      default: "<h1>Заголовок</h1><p>Текст</p>"
    },
    preloader: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>