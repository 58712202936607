import moment from "moment";
import {ref} from "vue";

export default function useMoment() {

    const format = ref('DD.MM.YYYY HH:mm:ss Z')

    const formattedDateTimeTz = (dateTimeTz, timezone, customFormat) => {
        const formatDateTimeTz = ref(moment(dateTimeTz).format(customFormat ? customFormat : format.value))
        if (timezone === 'UTC_M12') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-12 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_M11') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-11 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_M10') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-10 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_M930') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-9.5 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_M9') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-9 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_M8') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-8 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_M7') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-7 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_M6') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-6 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_M5') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-5 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_M4') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-4 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_M330') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-3.5 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_M3') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-3 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_M2') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-2 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_M1') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(-1 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC') {
            formatDateTimeTz.value = moment(dateTimeTz).utc().format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P1') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P2') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(2 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P3') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(3 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P330') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(3.5 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P4') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(4 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P430') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(4.5 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P5') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(5 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P530') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(5.5 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P545') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(5.75 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P6') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(6 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P630') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(6.5 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P7') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(7 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P8') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(8 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P845') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(8.75 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P9') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(9 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P930') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(9.5 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P10') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(10 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P1030') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(10.5 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P11') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(11 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P12') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(12 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P1245') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(12.75 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P13') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(13 * 60).format(customFormat ? customFormat : format.value)
        } else if (timezone === 'UTC_P14') {
            formatDateTimeTz.value = moment(dateTimeTz).utcOffset(14 * 60).format(customFormat ? customFormat : format.value)
        }
        return formatDateTimeTz.value
    }

    const formattedDateTimeTzNow = ref(moment().format(format.value))

    const formattedDateTimeTzNowFixed = ref((timezone) => {
        const formatDateTimeTzNowFixed = ref(moment().format(format.value))
        if (timezone === 'UTC_M12') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-12 * 60).format(format.value)
        } else if (timezone === 'UTC_M11') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-11 * 60).format(format.value)
        } else if (timezone === 'UTC_M10') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-10 * 60).format(format.value)
        } else if (timezone === 'UTC_M930') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-9.5 * 60).format(format.value)
        } else if (timezone === 'UTC_M9') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-9 * 60).format(format.value)
        } else if (timezone === 'UTC_M8') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-8 * 60).format(format.value)
        } else if (timezone === 'UTC_M7') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-7 * 60).format(format.value)
        } else if (timezone === 'UTC_M6') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-6 * 60).format(format.value)
        } else if (timezone === 'UTC_M5') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-5 * 60).format(format.value)
        } else if (timezone === 'UTC_M4') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-4 * 60).format(format.value)
        } else if (timezone === 'UTC_M330') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-3.5 * 60).format(format.value)
        } else if (timezone === 'UTC_M3') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-3 * 60).format(format.value)
        } else if (timezone === 'UTC_M2') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-2 * 60).format(format.value)
        } else if (timezone === 'UTC_M1') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(-1 * 60).format(format.value)
        } else if (timezone === 'UTC') {
            formatDateTimeTzNowFixed.value = moment().utc().format(format.value)
        } else if (timezone === 'UTC_P1') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(60).format(format.value)
        } else if (timezone === 'UTC_P2') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(2 * 60).format(format.value)
        } else if (timezone === 'UTC_P3') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(3 * 60).format(format.value)
        } else if (timezone === 'UTC_P330') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(3.5 * 60).format(format.value)
        } else if (timezone === 'UTC_P4') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(4 * 60).format(format.value)
        } else if (timezone === 'UTC_P430') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(4.5 * 60).format(format.value)
        } else if (timezone === 'UTC_P5') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(5 * 60).format(format.value)
        } else if (timezone === 'UTC_P530') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(5.5 * 60).format(format.value)
        } else if (timezone === 'UTC_P545') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(5.75 * 60).format(format.value)
        } else if (timezone === 'UTC_P6') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(6 * 60).format(format.value)
        } else if (timezone === 'UTC_P630') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(6.5 * 60).format(format.value)
        } else if (timezone === 'UTC_P7') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(7 * 60).format(format.value)
        } else if (timezone === 'UTC_P8') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(8 * 60).format(format.value)
        } else if (timezone === 'UTC_P845') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(8.75 * 60).format(format.value)
        } else if (timezone === 'UTC_P9') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(9 * 60).format(format.value)
        } else if (timezone === 'UTC_P930') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(9.5 * 60).format(format.value)
        } else if (timezone === 'UTC_P10') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(10 * 60).format(format.value)
        } else if (timezone === 'UTC_P1030') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(10.5 * 60).format(format.value)
        } else if (timezone === 'UTC_P11') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(11 * 60).format(format.value)
        } else if (timezone === 'UTC_P12') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(12 * 60).format(format.value)
        } else if (timezone === 'UTC_P1245') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(12.75 * 60).format(format.value)
        } else if (timezone === 'UTC_P13') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(13 * 60).format(format.value)
        } else if (timezone === 'UTC_P14') {
            formatDateTimeTzNowFixed.value = moment().utcOffset(14 * 60).format(format.value)
        }
        initTime(timezone)
        return formatDateTimeTzNowFixed.value
    })

    const intervalId = ref()

    const initTime = (timezone) => {
        if (timezone === 'UTC_M12') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-12 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_M11') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-11 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_M10') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-10 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_M930') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-9.5 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_M9') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-9 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_M8') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-8 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_M7') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-7 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_M6') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-6 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_M5') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-5 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_M4') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-4 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_M330') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-3.5 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_M3') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-3 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_M2') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-2 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_M1') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(-1 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utc().format(format.value), 1000)
        } else if (timezone === 'UTC_P1') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(60).format(format.value), 1000)
        } else if (timezone === 'UTC_P2') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(2 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P3') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(3 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P330') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(3.5 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P4') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(4 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P430') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(4.5 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P5') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(5 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P530') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(5.3 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P545') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(5.75 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P6') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(6 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P630') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(6.5 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P7') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(7 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P8') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(8 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P845') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(8.75 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P9') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(9 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P930') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(9.5 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P10') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(10 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P1030') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(10.5 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P11') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(11 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P12') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(12 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P1245') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(12.75 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P13') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(13 * 60).format(format.value), 1000)
        } else if (timezone === 'UTC_P14') {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().utcOffset(14 * 60).format(format.value), 1000)
        } else {
            intervalId.value = setInterval(() => formattedDateTimeTzNow.value = moment().format(format.value), 1000)
        }
    }

    const deInitTime = () => {
        clearInterval(intervalId.value)
    }

    const formatsNullable = [{
        name: 'Не выбран',
        value: null,
        string: null,
    }, {
        name: 'МСК',
        value: 'UTC_P3',
        string: '+0300',
    }, {
        name: 'По-умолчанию',
        value: 'UTC_P3',
        string: '+0300'
    }, {
        name: 'На моём компьютере',
        value: 'UTC_P3',
        string: '+0300'
    }, {
        name: 'UTC -12:00',
        value: 'UTC_M12',
        string: '-1200'
    }, {
        name: 'UTC -11:00',
        value: 'UTC_M11',
        string: '-1100'
    }, {
        name: 'UTC -10:00',
        value: 'UTC_M10',
        string: '-1000'
    }, {
        name: 'UTC -09:30',
        value: 'UTC_M930',
        string: '-0930'
    }, {
        name: 'UTC -09:00',
        value: 'UTC_M9',
        string: '-0900'
    }, {
        name: 'UTC -08:00',
        value: 'UTC_M8',
        string: '-0800'
    }, {
        name: 'UTC -07:00',
        value: 'UTC_M7',
        string: '-0700'
    }, {
        name: 'UTC -06:00',
        value: 'UTC_M6',
        string: '-0600'
    }, {
        name: 'UTC -05:00',
        value: 'UTC_M5',
        string: '-0500'
    }, {
        name: 'UTC -04:00',
        value: 'UTC_M4',
        string: '-0400'
    }, {
        name: 'UTC -03:30',
        value: 'UTC_M330',
        string: '-0330'
    }, {
        name: 'UTC -03:00',
        value: 'UTC_M3',
        string: '-0300'
    }, {
        name: 'UTC -02:00',
        value: 'UTC_M2',
        string: '-1200'
    }, {
        name: 'UTC -01:00',
        value: 'UTC_M1',
        string: '-1200'
    }, {
        name: 'UTC',
        value: 'UTC',
        string: '+0000'
    }, {
        name: 'UTC +01:00',
        value: 'UTC_P1',
        string: '+0100'
    }, {
        name: 'UTC +02:00',
        value: 'UTC_P2',
        string: '+0200'
    }, {
        name: 'UTC +03:00',
        value: 'UTC_P3',
        string: '+0300'
    }, {
        name: 'UTC +03:30',
        value: 'UTC_P330',
        string: '+0330'
    }, {
        name: 'UTC +04:00',
        value: 'UTC_P4',
        string: '+0400'
    }, {
        name: 'UTC +04:30',
        value: 'UTC_P430',
        string: '+0430'
    }, {
        name: 'UTC +05:00',
        value: 'UTC_P5',
        string: '+0500'
    }, {
        name: 'UTC +05:30',
        value: 'UTC_P530',
        string: '+0530'
    }, {
        name: 'UTC +05:45',
        value: 'UTC_P545',
        string: '+0545'
    }, {
        name: 'UTC +06:00',
        value: 'UTC_P6',
        string: '+0600'
    }, {
        name: 'UTC +06:30',
        value: 'UTC_P630',
        string: '+0630'
    }, {
        name: 'UTC +07:00',
        value: 'UTC_P7',
        string: '+0700'
    }, {
        name: 'UTC +08:00',
        value: 'UTC_P8',
        string: '+0800'
    }, {
        name: 'UTC +08:45',
        value: 'UTC_P845',
        string: '+0845'
    }, {
        name: 'UTC +09:00',
        value: 'UTC_P9',
        string: '+0900'
    }, {
        name: 'UTC +09:30',
        value: 'UTC_P930',
        string: '+0930'
    }, {
        name: 'UTC +10:00',
        value: 'UTC_P10',
        string: '+1000'
    }, {
        name: 'UTC +10:30',
        value: 'UTC_P1030',
        string: '+1030'
    }, {
        name: 'UTC +11:00',
        value: 'UTC_P11',
        string: '+1100'
    }, {
        name: 'UTC +12:00',
        value: 'UTC_P12',
        string: '+1200'
    }, {
        name: 'UTC +12:45',
        value: 'UTC_P1245',
        string: '+1245'
    }, {
        name: 'UTC +13:00',
        value: 'UTC_P13',
        string: '+1300'
    }, {
        name: 'UTC +14:00',
        value: 'UTC_P14',
        string: '+1400'
    }]

    const formats = [{
        name: 'МСК',
        value: 'UTC_P3',
        string: '+0300',
    }, {
        name: 'По-умолчанию',
        value: 'UTC_P3',
        string: '+0300'
    }, {
        name: 'На моём компьютере',
        value: 'UTC_P3',
        string: '+0300'
    }, {
        name: 'UTC -12:00',
        value: 'UTC_M12',
        string: '-1200'
    }, {
        name: 'UTC -11:00',
        value: 'UTC_M11',
        string: '-1100'
    }, {
        name: 'UTC -10:00',
        value: 'UTC_M10',
        string: '-1000'
    }, {
        name: 'UTC -09:30',
        value: 'UTC_M930',
        string: '-0930'
    }, {
        name: 'UTC -09:00',
        value: 'UTC_M9',
        string: '-0900'
    }, {
        name: 'UTC -08:00',
        value: 'UTC_M8',
        string: '-0800'
    }, {
        name: 'UTC -07:00',
        value: 'UTC_M7',
        string: '-0700'
    }, {
        name: 'UTC -06:00',
        value: 'UTC_M6',
        string: '-0600'
    }, {
        name: 'UTC -05:00',
        value: 'UTC_M5',
        string: '-0500'
    }, {
        name: 'UTC -04:00',
        value: 'UTC_M4',
        string: '-0400'
    }, {
        name: 'UTC -03:30',
        value: 'UTC_M330',
        string: '-0330'
    }, {
        name: 'UTC -03:00',
        value: 'UTC_M3',
        string: '-0300'
    }, {
        name: 'UTC -02:00',
        value: 'UTC_M2',
        string: '-1200'
    }, {
        name: 'UTC -01:00',
        value: 'UTC_M1',
        string: '-1200'
    }, {
        name: 'UTC',
        value: 'UTC',
        string: '+0000'
    }, {
        name: 'UTC +01:00',
        value: 'UTC_P1',
        string: '+0100'
    }, {
        name: 'UTC +02:00',
        value: 'UTC_P2',
        string: '+0200'
    }, {
        name: 'UTC +03:00',
        value: 'UTC_P3',
        string: '+0300'
    }, {
        name: 'UTC +03:30',
        value: 'UTC_P330',
        string: '+0330'
    }, {
        name: 'UTC +04:00',
        value: 'UTC_P4',
        string: '+0400'
    }, {
        name: 'UTC +04:30',
        value: 'UTC_P430',
        string: '+0430'
    }, {
        name: 'UTC +05:00',
        value: 'UTC_P5',
        string: '+0500'
    }, {
        name: 'UTC +05:30',
        value: 'UTC_P530',
        string: '+0530'
    }, {
        name: 'UTC +05:45',
        value: 'UTC_P545',
        string: '+0545'
    }, {
        name: 'UTC +06:00',
        value: 'UTC_P6',
        string: '+0600'
    }, {
        name: 'UTC +06:30',
        value: 'UTC_P630',
        string: '+0630'
    }, {
        name: 'UTC +07:00',
        value: 'UTC_P7',
        string: '+0700'
    }, {
        name: 'UTC +08:00',
        value: 'UTC_P8',
        string: '+0800'
    }, {
        name: 'UTC +08:45',
        value: 'UTC_P845',
        string: '+0845'
    }, {
        name: 'UTC +09:00',
        value: 'UTC_P9',
        string: '+0900'
    }, {
        name: 'UTC +09:30',
        value: 'UTC_P930',
        string: '+0930'
    }, {
        name: 'UTC +10:00',
        value: 'UTC_P10',
        string: '+1000'
    }, {
        name: 'UTC +10:30',
        value: 'UTC_P1030',
        string: '+1030'
    }, {
        name: 'UTC +11:00',
        value: 'UTC_P11',
        string: '+1100'
    }, {
        name: 'UTC +12:00',
        value: 'UTC_P12',
        string: '+1200'
    }, {
        name: 'UTC +12:45',
        value: 'UTC_P1245',
        string: '+1245'
    }, {
        name: 'UTC +13:00',
        value: 'UTC_P13',
        string: '+1300'
    }, {
        name: 'UTC +14:00',
        value: 'UTC_P14',
        string: '+1400'
    }]

    const formatsString = (value) => {
        const string = ref('')
        formats.forEach(item => {
            if (item.value === value) string.value = item.string
        })
        return string.value
    }

    const stringToDate = (value) => {
        const [day, month, year] = value.split('.')
        return `${year}-${month}-${day}`
    }

    const dateToString = (value) => {
        return moment(value).format('DD.MM.YYYY')
    }

    return {
        formattedDateTimeTz,
        formattedDateTimeTzNow,
        formattedDateTimeTzNowFixed,
        formats,
        formatsNullable,
        initTime,
        deInitTime,
        formatsString,
        stringToDate,
        dateToString
    }

}
