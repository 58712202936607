<template>
  <main>
    <preloader-main v-if="groupsLoader"/>
    <header-back-utp1
        :header="header"
    />
    <groups-category
        v-model:groups-category="groupCategory"
        :button="button"
        @buttonClick="updateGroupsCategory"
        @buttonDeleteClick="deleteGroupsCategory"
    />
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import HeaderBackUtp1 from "../../../components/UI/headers/HeaderBackUtp1";
import GroupsCategory from "../../../components/pages/mailing/contacts/GroupsCategory";
import {onMounted} from "vue";
import useGroups from "../../../hooks/rest/mailing/contacts/useGroups";
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import {useRoute} from "vue-router";
import router from "../../../router";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";

export default {
  name: "edit-groups-categories",
  components: {SidebarRightUtp1, PreloaderMain, GroupsCategory, HeaderBackUtp1},
  setup() {
    const route = useRoute()

    const header = {
      title: "Изменить категорию групп",
      buttonRedirect: "/mailing/contacts/groups-categories"
    }
    const button = {
      buttonTitle: "Изменить",
      buttonDelete: true
    }

    const groupsCategoryId = route.params.id

    const updateGroupsCategory = () => {
      updateGroupsCategories(groupsCategoryId)
    }

    const deleteGroupsCategory = async () => {
      await deleteGroupsCategories(groupsCategoryId)
      await router.push('/mailing/contacts/groups-categories')
    }

    const {groupsLoader, groupCategory, getGroupsCategory, updateGroupsCategories, deleteGroupsCategories} = useGroups()

    onMounted(() => {
      getGroupsCategory(groupsCategoryId)
    })

    return {groupsLoader, header, button, groupCategory, updateGroupsCategory, deleteGroupsCategory}
  }
}
</script>

<style scoped>

</style>