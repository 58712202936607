<template>
  <input :value="modelValue" @input="updateInput" :disabled="disabled" :readonly="readonly" class="form-control">
</template>

<script>
export default {
  name: "input-utp1",
  props: {
    modelValue: [String, Number],
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(_, {emit}) {
    const updateInput = (event) => {
      emit("update:modelValue", event.target.value)
    }
    return {updateInput}
  }
}
</script>

<style scoped>

</style>