<template>
  <div id="vk_community_messages"
       class="me-3 me-lg-0 vk-community-messages"></div>
</template>

<script>
import {onMounted, ref} from "vue";

export default {
  name: "vk-community-messages",
  setup() {
    const vkCommunityMessages = ref(null)
    onMounted(async () => {
      // noinspection JSUnresolvedVariable,JSUnresolvedFunction
      VK.Widgets.CommunityMessages("vk_community_messages",
          // 160250510, {tooltipButtonText: "Есть вопросы? Обращайтесь!"});
          160250510, {disableButtonTooltip: 1});
      vkCommunityMessages.value = document.getElementById("vk_community_messages")
    })
  }
}
</script>

<style scoped>

</style>
