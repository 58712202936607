<template>
  <aside id="header-nav-block" class="pb-3 pt--1">
    <div class="overflow-auto text-nowrap d-grid">
      <nav id="header-nav-scroll" class="nav d-block nav-tabs" aria-label="Secondary navigation">
        <a v-for="item in nav"
           :class="item.active? 'nav-link active d-inline-block' :
           item.disabled? 'nav-link disabled d-inline-block' :
           'nav-link d-inline-block'"
           data-bs-toggle="tab" :data-bs-target="'#' + item.value" role="tab" :aria-controls="item.value">
          {{ item.name }}
        </a>
      </nav>
    </div>
  </aside>
</template>

<script>
export default {
  name: "header-nav-utp1",
  props: {
    nav: {
      type: Array,
      default: []
    }
  }
}
</script>

<style scoped>
.pt--1 {
  margin-top: -1.6rem;
}
</style>
