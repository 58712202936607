import {ref} from 'vue';

export default function useMailingError(primaryException = null) {

    const mailingErrorMessage = ref(null)
    const mailingException = ref(null)

    const mailingError = (secondaryException) => {
        if (secondaryException !== null) {
            mailingException.value = secondaryException
        } else if (primaryException !== null) {
            mailingException.value = primaryException
        } else mailingException.value = null

        if (mailingException.value === "" || mailingException.value === null) {
            mailingErrorMessage.value = null
        } else {
            if (mailingException.value === "No ...") mailingErrorMessage.value = "Отсутствует ..."
            else if (mailingException.value === "Use ...") mailingErrorMessage.value = "Пользователь с таким ... уже есть"

            else if (mailingException.value === "Invalid name") mailingErrorMessage.value = "Некорректное название"
            else if (mailingException.value === "Invalid letterTopic") mailingErrorMessage.value = "Некорректная тема письма"
            else if (mailingException.value === "Invalid emailBindingId") mailingErrorMessage.value = "Некорректные данные отправителя"
            else if (mailingException.value === "Invalid letterTopic") mailingErrorMessage.value = "Некорректный заголовок письма"
            else if (mailingException.value === "Invalid actionText") mailingErrorMessage.value = "Некорректный призыв к действию"
            else if (mailingException.value === "Invalid actionButtonUrl") mailingErrorMessage.value = "Некорректный URL кнопки"
            else if (mailingException.value === "Invalid actionButtonText") mailingErrorMessage.value = "Некорректный текст кнопки"
            else if (mailingException.value === "Invalid warningText") mailingErrorMessage.value = "Некорректное предупреждение"
            else if (mailingException.value === "Invalid informationTitle") mailingErrorMessage.value = "Некорректный заголовок блока информации"
            else if (mailingException.value === "Invalid letterLinkId") mailingErrorMessage.value = "Некорректный идентификатор ссылки"
            else if (mailingException.value === "Invalid linkUrl") mailingErrorMessage.value = "Некорректный URL ссылки"
            else if (mailingException.value === "Invalid linkText") mailingErrorMessage.value = "Некорректный текст ссылки"
            else if (mailingException.value === "Invalid letterImageId") mailingErrorMessage.value = "Некорректный идентификатор картинки"
            else if (mailingException.value === "Invalid imageUrl") mailingErrorMessage.value = "Некорректный URL картинки"
            else if (mailingException.value === "Invalid imageLink") mailingErrorMessage.value = "Некорректный ссылка картинки"
            else if (mailingException.value === "Invalid ...") mailingErrorMessage.value = "Некорректный ..."

            else if (mailingException.value === "Invalid productId") mailingErrorMessage.value = "Некорректный данные продукта"

            else if (mailingException.value === "No access") mailingErrorMessage.value = "К сожалению у Вас нет доступа"
            else if (mailingException.value === "Timeout") mailingErrorMessage.value = "Мы уже исправляем эту ошибку"
            else if (mailingException.value === "Exception") mailingErrorMessage.value = "Проверьте данные и попробуйте ещё раз"
            else mailingErrorMessage.value = "Некорректные данные, проверьте и попробуйте ещё раз"
        }
    }

    return {
        mailingErrorMessage,
        mailingError
    }

}
