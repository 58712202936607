<template>
  <main>
    <preloader-main v-if="groupsLoader"/>
    <header-back-utp1
        :header="header"
    />
    <groups-category
        v-model:groups-category="groupsCategory"
        :button="button"
        @buttonClick="newGroupsCategories"
    />
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import HeaderBackUtp1 from "../../../components/UI/headers/HeaderBackUtp1";
import GroupsCategory from "../../../components/pages/mailing/contacts/GroupsCategory";
import {ref} from "vue";
import useGroups from "../../../hooks/rest/mailing/contacts/useGroups";
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import router from "../../../router";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";

export default {
  name: "add-groups-categories",
  components: {SidebarRightUtp1, PreloaderMain, GroupsCategory, HeaderBackUtp1},
  setup() {
    const header = {
      title: "Добавить категорию групп",
      buttonRedirect: "/mailing/contacts/groups-categories"
    }
    const button = {
      buttonTitle: "Создать",
      buttonDelete: false
    }
    const groupsCategory = ref({
      name: "",
      vkGroupId: null
    })

    const newGroupsCategories = async () => {
      await addGroupsCategories(groupsCategory.value)
      await router.push('/mailing/contacts/groups-categories')
    }

    const {groupsLoader, addGroupsCategories} = useGroups()

    return {groupsLoader, header, groupsCategory, button, newGroupsCategories}
  }
}
</script>

<style scoped>

</style>