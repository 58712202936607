<template id="app">
  <preloader-full v-if="fullUserLoader"/>
  <header-utp1/>
  <div class="container-xxl">
    <div class="row">
      <sidebar-utp1/>
      <div class="row col-lg-10 border-start wrapper pb-5 pb-lg-4">
        <!--suppress HtmlUnknownTag -->
        <router-view/>
      </div>
    </div>
  </div>
  <footer-utp1/>
</template>

<style>
@import "assets/css/common.css";
@import "assets/css/style.css";
@import "assets/css/vars.css";
@import "assets/css/colors.css";
@import "assets/css/layout.css";
@import "assets/css/forms.css";
@import "assets/css/spinner.css";
@import "assets/css/widget.css";
@import "assets/css/card.css";
@import "assets/fonts/icomoon/style.css";
@import "assets/css/headers.css";
@import "assets/css/site.css";
@import "assets/css/elements.css";
@import "assets/css/other.css";
/*noinspection CssUnknownTarget*/
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.css);
</style>
<script>
import HeaderUtp1 from "./components/system/NavbarUtp1";
import SidebarUtp1 from "./components/system/SidebarUtp1";
import FooterUtp1 from "./components/system/FooterUtp1";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import useUser from "./hooks/rest/settings/useUser";
import {onMounted, watch} from "vue";
import PreloaderFull from "./components/system/preloader/PreloaderFull";
import useImages from "./hooks/rest/files/useImages";

export default {
  components: {PreloaderFull, FooterUtp1, SidebarUtp1, HeaderUtp1},
  setup() {
    const store = useStore()
    const route = useRoute()

    const {fullUserLoader, getAuth} = useUser()
    const {getSettingsAvatar} = useImages()

    onMounted(() => {
      getAuth()
    })

    watch(store.state.auth, () => {
      if (store.state.auth.auth === null) {
      } else if (store.state.auth.auth === true) {
        if (!store.state.avatar.avatar) {
          getSettingsAvatar()
        }
      } else if (store.state.auth.auth === false) {
        const redirect = route.path.substring(1).toLowerCase()
        window.location.href = `https://login.utp1.com${redirect ? `?redirect=${redirect}` : ``}`
      }
    })

    return {fullUserLoader}
  }
}
</script>
