import axios from "axios";
import {ref} from 'vue';
import useTokens from "@/hooks/useTokens";
import router from "@/router";

export default function useShopSettings() {

    const loaderShopSettings = ref(false)
    const exceptionShopSettings = ref("")

    const utpPays = ref({utpPays: []})
    const utpPay = ref({})
    const utpPayFull = ref({
        settings: Object,
        owners: Array,
        beneficiaries: Array
    })
    const newUtpPayId = ref()

    const getUtpPays = async () => {
        exceptionShopSettings.value = ""
        loaderShopSettings.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(
                `https://api.utp1.com/payments/settings`,
                {headers: header}
            ).then(response => {
                utpPays.value = response.data
            }).catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await getUtpPays()
                } else {
                    await errorSetting(e.response.data.error ? e.response.data.error : "Exception")
                }
            }).finally(() => loaderShopSettings.value = false)
        }
    }

    const newUtpPay = async (newUtpPays) => {
        exceptionShopSettings.value = ""
        loaderShopSettings.value = true
        const header = await authHeader()
        if (header) {
            await axios.post(
                `https://api.utp1.com/payments/settings`,
                newUtpPays,
                {headers: header}
            ).then(response => {
                newUtpPayId.value = response.data
            }).catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await newUtpPay(newUtpPays)
                } else {
                    await errorSetting(e.response.data.error ? e.response.data.error : "Exception")
                }
            }).finally(() => loaderShopSettings.value = false)
        }
    }

    const updateUtpPay = async (id, newUtpPays) => {
        exceptionShopSettings.value = ""
        loaderShopSettings.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(
                `https://api.utp1.com/payments/settings/` + id,
                newUtpPays,
                {headers: header}
            ).then(response => {
                newUtpPayId.value = response.data
            }).catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await updateUtpPay(id, newUtpPays)
                } else {
                    await errorSetting(e.response.data.error ? e.response.data.error : "Exception")
                }
            }).finally(() => loaderShopSettings.value = false)
        }
    }

    const updatePayMethods = async (id, utpPayMethods) => {
        exceptionShopSettings.value = ""
        loaderShopSettings.value = true
        const header = await authHeader()
        if (header) {
            await axios.patch(
                `https://api.utp1.com/payments/settings/` + id,
                utpPayMethods,
                {headers: header}
            ).then().catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await updatePayMethods(id, utpPayMethods)
                } else {
                    await errorSetting(e.response.data.error ? e.response.data.error : "Exception")
                }
            }).finally(() => loaderShopSettings.value = false)
        }
    }

    const getUtpPay = async (id) => {
        exceptionShopSettings.value = ""
        loaderShopSettings.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(
                `https://api.utp1.com/payments/settings/` + id,
                {headers: header}
            ).then(response => {
                utpPay.value = response.data
            }).catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await getUtpPay(id)
                } else {
                    await router.push('/shop/settings')
                }
            }).finally(() => loaderShopSettings.value = false)
        }
    }

    const getUtpPaySettings = async (id) => {
        exceptionShopSettings.value = ""
        loaderShopSettings.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(
                `https://api.utp1.com/payments/settings/` + id + `/utp-pay`,
                {headers: header}
            ).then(response => {
                utpPayFull.value = response.data
            }).catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await getUtpPaySettings(id)
                } else {
                    await errorSetting(e.response.data.error ? e.response.data.error : "Exception")
                }
            }).finally(() => loaderShopSettings.value = false)
        }
    }

    const addUtpPaySettings = async (id, newUtpPayFull) => {
        exceptionShopSettings.value = ""
        loaderShopSettings.value = true
        const header = await authHeader()
        if (header) {
            await axios.post(
                `https://api.utp1.com/payments/settings/` + id + `/utp-pay`,
                newUtpPayFull,
                {headers: header}
            ).then().catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await addUtpPaySettings(id, newUtpPayFull)
                } else {
                    await errorSetting(e.response.data.error ? e.response.data.error : "Exception")
                }
            }).finally(() => loaderShopSettings.value = false)
        }
    }

    const updateUtpPaySettings = async (id, newUtpPayFull) => {
        exceptionShopSettings.value = ""
        loaderShopSettings.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(
                `https://api.utp1.com/payments/settings/` + id + `/utp-pay`,
                newUtpPayFull,
                {headers: header}
            ).then().catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await updateUtpPaySettings(id, newUtpPayFull)
                } else {
                    await errorSetting(e.response.data.error ? e.response.data.error : "Exception")
                }
            }).finally(() => loaderShopSettings.value = false)
        }
    }

    const initModeration = async (id) => {
        exceptionShopSettings.value = ""
        loaderShopSettings.value = true
        const header = await authHeader()
        if (header) {
            await axios.patch(
                `https://api.utp1.com/payments/settings/` + id + `/utp-pay`,
                null,
                {headers: header}
            ).then().catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await initModeration(id)
                } else {
                    await errorSetting(e.response.data.error ? e.response.data.error : "Exception")
                }
            }).finally(() => loaderShopSettings.value = false)
        }
    }

    const updateDefaultUtpPay = async (id) => {
        exceptionShopSettings.value = ""
        loaderShopSettings.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(
                `https://api.utp1.com/payments/settings/` + id + `/default`,
                {headers: header}
            ).then(async () => await getUtpPays())
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateDefaultUtpPay(id)
                    } else {
                        await errorSetting(e.response.data.error ? e.response.data.error : "Exception")
                    }
                }).finally(() => loaderShopSettings.value = false)
        }
    }

    const deleteUtpPay = async (id) => {
        exceptionShopSettings.value = ""
        loaderShopSettings.value = true
        const header = await authHeader()
        if (header) {
            await axios.delete(
                `https://api.utp1.com/payments/settings/` + id,
                {headers: header}
            ).then(async () => await getUtpPays()).catch(async e => {
                if (e.response.status === 401) {
                    await updateTokens()
                    await deleteUtpPay(id)
                } else {
                    await errorSetting(e.response.data.error ? e.response.data.error : "Exception")
                }
            }).finally(() => loaderShopSettings.value = false)
        }
    }

    const errorSetting = (exception) => {
        if (exception === "" || exception === null) {
            exceptionShopSettings.value = null
        } else {
            if (exception === "No ...") exceptionShopSettings.value = "Отсутствует ..."
            else if (exception === "Use ...") exceptionShopSettings.value = "Пользователь с таким ... уже есть"
            else if (exception === "Invalid ...") exceptionShopSettings.value = "Некорректный ..."
            else if (exception === "Invalid file length") exceptionShopSettings.value = "Слишком большой размер файла (максимум 20МБ)"
            else if (exception === "No access") exceptionShopSettings.value = "К сожалению у Вас нет доступа"
            else if (exception === "Timeout") exceptionShopSettings.value = "Мы уже исправляем эту ошибку"
            else if (exception === "Exception") exceptionShopSettings.value = "Проверьте данные и попробуйте ещё раз"
            else exceptionShopSettings.value = "Неизвестная ошибка, попробуйте позже"
        }
    }

    const {authHeader, updateTokens} = useTokens()

    return {
        utpPays,
        newUtpPayId,
        utpPay,
        utpPayFull,
        loaderShopSettings,
        exceptionShopSettings,
        getUtpPays,
        newUtpPay,
        getUtpPay,
        getUtpPaySettings,
        addUtpPaySettings,
        updateUtpPaySettings,
        initModeration,
        updateDefaultUtpPay,
        deleteUtpPay,
        updateUtpPay,
        updatePayMethods
    }

}
