<!--suppress JSUnresolvedVariable -->
<template>
  <div v-for="item in singleMailings">
    <card-utp1
        :card-id="item.singleMailingId"
        :dropdown-card-menu="item.status === 'WAITING' ? cardHeaderWaiting :
        item.status === 'PAUSE' ? cardHeaderPause :
        item.status === 'SENDING' ? cardHeaderSent :
        item.status === 'SENT' ? cardHeaderSent : cardHeader"
        @deleteCard="deleteCard(item.singleMailingId)"
        @updateStatusSingleMailingFalse="updateStatusSingleMailingFalse"
        @updateStatusSingleMailingTrue="updateStatusSingleMailingTrue"
    >

      <template v-slot:cardHeader>
        <div class="text-truncate">
          <span v-if="item.status === 'WAITING'" class="pe-2" title="Ожидает отправки">
            <i class="bi bi-play-fill text-success"></i>
          </span>
          <span v-else-if="item.status === 'PAUSE'" class="pe-2" title="Отправка приостановлена">
            <i class="bi bi-pause-fill animation-pause text-secondary"></i>
          </span>
          <span v-else-if="item.status === 'SENDING'" class="pe-2" title="Отправляется">
            <span class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </span>
          </span>
          <span v-else-if="item.status === 'SENT'" class="pe-2" title="Отправлено">
            <i class="bi bi-check-lg text-info"></i>
          </span>
          <span v-else-if="item.status === 'ERROR'" class="pe-2" title="Ошибка отправки">
            <i class="bi bi-exclamation-diamond-fill text-danger"></i>
          </span>

          <a class="card-link text-decoration-none fw-semibold"
             @click="$router.push('/mailing/single/' + item.singleMailingId)">
            {{ item.name }}
          </a>
        </div>
      </template>

      <div class="text-secondary">
        <div class="row">
          <div class="col-auto ps-0">
            <!--suppress JSCheckFunctionSignatures -->
            <div class="text-truncate">
              <span v-if="item.status === 'SENT'" class="d-none d-sm-inline">Отправлено:</span>
              <span v-else-if="item.status === 'ERROR'" class="d-none d-sm-inline">Отправлялось:</span>
              <span v-else class="d-none d-sm-inline">Отправить:</span>
              {{ formattedDateTimeTz(item.sendingDateTimeTz, 'UTC_P3', 'DD.MM.YYYY в HH:mm МСК') }}
            </div>
          </div>
          <div class="col px-0 text-end text-truncate">
            <div v-if="item.sendingType === 'ACTIVITY'" class="text-truncate">По активности</div>
            <div v-else-if="groupsArraySingleMailing(item.singleMailingGroups).length === 0" class="text-truncate">
              По группам и счетам
            </div>
            <div v-else-if="groupsArraySingleMailing(item.singleMailingGroups).length === 1" class="text-truncate">
              <a class="card-link text-decoration-none">
                {{ groupsArraySingleMailing(item.singleMailingGroups)[0].name }}
              </a>
            </div>
            <div v-else class="text-truncate">
              <span class="d-none d-sm-inline">По группам: </span>
              <a class="card-link text-decoration-none dropdown-toggle"
                 data-bs-toggle="dropdown">
                [ {{ groupsArraySingleMailing(item.singleMailingGroups).length }} ]
              </a>
              <ul class="dropdown-menu dropdown-menu-end" style="max-width: 80%">
                <li v-for="group in groupsArraySingleMailing(item.singleMailingGroups)">
                  <a class="dropdown-item text-truncate">
                    <a class="card-link text-decoration-none">
                      {{ group.name }}
                    </a>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <template v-slot:cardFooter>
        <div class="row">
          <div class="col-auto p-0">
            <span v-if="item.status !== 'SENT' && item.status !== 'ERROR'" class="text-silver">
              Потенциальных получателей: {{ item.count.toLocaleString() }}</span>
            <span v-else>
                <span class="text-silver">
                   <i class="bi bi-envelope"></i> {{ item.count.toLocaleString() }}
                </span><span class="px-2">|</span>
                <span class="text-red-light">
                  <i class="bi bi-exclamation-diamond"></i>
                  {{
                    item.count === 0 ? 0 : ((item.error + item.spam) * 100 / item.count).toFixed(2).replace('.00', '')
                  }}%
              ({{ (item.error + item.spam).toLocaleString() }})
                </span>
              </span>
          </div>
          <div class="col"></div>
          <div v-if="item.status === 'SENT' || item.status === 'ERROR'" class="col-auto p-0">
              <span class="text-info">
                <i class="bi bi-check2-all"></i>
                {{ item.count === 0 ? 0 : (item.open * 100 / item.count).toFixed(2).replace('.00', '') }}%
              ({{ item.open.toLocaleString() }})</span><span class="px-2">|</span>
            <span class="text-success">
                <i class="bi bi-cursor"></i>
                {{ item.count === 0 ? 0 : (item.click * 100 / item.count).toFixed(2).replace('.00', '') }}%
              ({{ item.click.toLocaleString() }})</span><span class="px-2">|</span>
            <span class="text-danger">
                <i class="bi bi-x-lg"></i>
                {{ item.count === 0 ? 0 : ((item.unsubscribe) * 100 / item.count).toFixed(2).replace('.00', '') }}%
              ({{ item.unsubscribe.toLocaleString() }})
              </span>
          </div>
        </div>
      </template>

    </card-utp1>

  </div>
</template>

<script>
import CardUtp1 from "../../../UI/cards/CardUtp1";
import useMoment from "../../../../hooks/useMoment";

export default {
  name: "single-mailings-cards",
  components: {CardUtp1},
  props: {
    singleMailings: {
      type: Array,
      default: []
    },
    groups: {
      type: Array,
      default: []
    }
  },
  emits: ['deleteCard', 'updateStatusSingleMailingFalse', 'updateStatusSingleMailingTrue'],
  setup(props, {emit}) {
    const cardHeaderWaiting = [{
      dropdownName: "Изменить",
      dropdownPush: "/mailing/single/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Приостановить",
      dropdownEmit: "updateStatusSingleMailingFalse",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Дублировать",
      dropdownPush: "/mailing/single/add/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "",
      dropdownPush: "",
      dropdownHr: true,
      dropdownDelete: false
    }, {
      dropdownName: "Удалить",
      dropdownPush: "",
      dropdownHr: false,
      dropdownDelete: true
    }]
    const cardHeaderPause = [{
      dropdownName: "Изменить",
      dropdownPush: "/mailing/single/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Возобновить",
      dropdownEmit: "updateStatusSingleMailingTrue",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Дублировать",
      dropdownPush: "/mailing/single/add/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "",
      dropdownPush: "",
      dropdownHr: true,
      dropdownDelete: false
    }, {
      dropdownName: "Удалить",
      dropdownPush: "",
      dropdownHr: false,
      dropdownDelete: true
    }]
    const cardHeader = [{
      dropdownName: "Изменить",
      dropdownPush: "/mailing/single/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Дублировать",
      dropdownPush: "/mailing/single/add/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "",
      dropdownPush: "",
      dropdownHr: true,
      dropdownDelete: false
    }, {
      dropdownName: "Удалить",
      dropdownPush: "",
      dropdownHr: false,
      dropdownDelete: true
    }]
    const cardHeaderSent = [{
      dropdownName: "Открыть",
      dropdownPush: "/mailing/single/",
      dropdownHr: false,
      dropdownDelete: false
    }, {
      dropdownName: "Дублировать",
      dropdownPush: "/mailing/single/add/",
      dropdownHr: false,
      dropdownDelete: false
    }]

    const groupsArraySingleMailing = (singleMailingGroups) => {
      const singleMailingGroupsArray = []
      props.groups.forEach(item => {
        singleMailingGroups.forEach(id => {
          if (id === item.id) {
            singleMailingGroupsArray.push({
              id: id,
              name: item.name
            })
          }
        })
      })
      return singleMailingGroupsArray
    }

    const updateStatusSingleMailingFalse = (singleMailingId) => {
      emit("updateStatusSingleMailingFalse", singleMailingId)
    }

    const updateStatusSingleMailingTrue = (singleMailingId) => {
      emit("updateStatusSingleMailingTrue", singleMailingId)
    }

    const deleteCard = (singleMailingId) => {
      emit('deleteCard', singleMailingId)
    }
    const {formattedDateTimeTz} = useMoment()

    return {
      cardHeaderWaiting,
      cardHeaderPause,
      cardHeaderSent,
      cardHeader,
      formattedDateTimeTz,
      updateStatusSingleMailingFalse,
      updateStatusSingleMailingTrue,
      deleteCard,
      groupsArraySingleMailing
    }
  }
}
</script>

<style scoped>
.animation-pause {
  animation: fa-flash 2s infinite ease;
}

@keyframes fa-flash {
  0%, 100%, 50% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

.spinner-border-sm {
  margin-left: 2px;
  margin-right: 2px;
  --bs-spinner-width: 12px;
  --bs-spinner-height: 12px;
  --bs-spinner-border-width: 2px;
  --bs-spinner-vertical-align: 0;
}
</style>