<template>
  <div class="row">
    <div class="input-group p-0 col">
      <input-utp1
          type="search"
          v-model:model-value="localSearch"
          :placeholder="placeholder"
          @keyup.enter="newSearch"
          maxlength="255"
      />
      <a class="input-group-text " @click="newSearch">
        <i class="bi bi-search" aria-hidden="true"></i>
      </a>

    </div>
    <a class="input-group-text bg-white border-0 col-auto px-3" data-bs-toggle="collapse"
       data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
      <div class="d-block">
        <div class="caret-up-down"><i class="bi bi-caret-up-fill caret-up-down" aria-hidden="true"></i></div>
        <div class="caret-up-down"><i class="bi bi-caret-down-fill caret-up-down" aria-hidden="true"></i></div>
      </div>
    </a>
  </div>

  <div class="collapse small" id="collapseExample">
    <div class="card card-body border-start-0 border-end-0 border-top-0 rounded-0 py-2">
      <div class="row">
        <div class="col-auto px-1">
          Сортировать:
        </div>
        <div class="col-auto px-1">
          <input type="radio" class="btn-check" id="default" value="" v-model="localHow">
          <label class="link-primary cursor-pointer" for="default">
            по умолчанию
          </label>
        </div>
        <div class="col-auto px-1" v-for="item in sorting">
          <input type="radio" class="btn-check" :id="item.value" :value="item.value" v-model="localHow">
          <label class="link-primary cursor-pointer" :for="item.value">
            {{ item.label }} <i class="bi" :class="item.down ? 'bi-sort-down-alt' : 'bi-sort-down'"></i>
          </label>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import InputUtp1 from "../inputs/InputUtp1.vue";
import {ref, watch} from "vue";

export default {
  name: "form-search",
  components: {InputUtp1},
  props: {
    search: {
      type: String,
      default: ""
    },
    how: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Поиск..."
    },
    sorting: {
      type: Array,
      default: [{
        value: 'DDATE',
        label: 'по дате',
        down: true
      }, {
        value: 'ADATE',
        label: 'по дате',
        down: false
      }]
    }
  },
  mounted() {
    this.localSearch = this.search
    this.localHow = this.how
  },
  watch: {
    search() {
      this.localSearch = this.search
    },
    how() {
      this.localHow = this.how
    }
  },
  emits: ["newSearch", "update:how", "update:search"],
  setup(props, {emit}) {
    const localSearch = ref()
    const localHow = ref()

    watch(localSearch, (newVal) => {
      emit('update:search', newVal)
    })
    watch(localHow, (newVal) => {
      emit('update:how', newVal)
    })

    const newSearch = () => {
      emit('newSearch', null)
    }

    return {newSearch, localHow, localSearch}
  }
}
</script>

<style scoped>
.caret-up-down {
  margin-top: -0.8rem !important;
  margin-bottom: -0.8rem !important;
}

input:checked + label {
  color: var(--bs-secondary) !important;
  cursor: default !important;
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
}
</style>