<template>
  <main>
    <preloader-main v-if="userLoader"/>
    <entry-header-utp1>Безопасность</entry-header-utp1>
    <alert-danger v-if="errorMessage">{{ errorMessage }}</alert-danger>
    <alert-success v-if="hideEmail">
      <p>Новые данные для входа направлены Вам на e-mail: <strong>{{ hideEmail }}</strong></p>
      <p>Если письмо не пришло в течение нескольких минут, пожалуйста, проверьте папку <i>«Спам»</i>, и нажмите
        <strong><i>«НЕ спам»</i></strong></p>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end" v-if="redirectButton">
        <button type="button" class="btn btn-outline-success" @click="redirect">{{ redirectButton }}</button>
      </div>
    </alert-success>

    <button-danger-utp1 @buttonClick="logoutAll">Выйти на всех устройствах</button-danger-utp1>
    <p class="text-secondary mt-3">После выхода Вы можете оставаться залогиными на некоторых устройствах в рамках одной
      сессии (максимум 24 часа, если Вы отмечали «Запомнить меня на этом устройстве», и 30 минут, если не отмечали). При
      этом все старые сессии (в рамках которых Вы не совершали никаких действий за последние 24 часа (30 минут) будут
      отменены.</p>
    <form-in-line
        v-model="password"
        :label="labelPassword"
        @clickButton="updatePassword"
    >Текущий пароль:
    </form-in-line>
    <p class="text-secondary mt-3">После изменения пароля новые данные для входа будут направлены Вам на e-mail. При
      этом все активные сессии будут сохранены. Если Вы хотите разлогиниться, нажмите на соответствующую кнопку
      выше.</p>
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import EntryHeaderUtp1 from "../../components/UI/headers/EntryHeaderUtp1";
import useUser from "../../hooks/rest/settings/useUser";
import useError from "../../hooks/error/useError";
import useRedirect from "../../hooks/rest/useRedirect";
import {ref, watch} from "vue";
import FormInLine from "../../components/UI/forms/FormInLine";
import SidebarRightUtp1 from "../../components/system/SidebarRightUtp1";
import AlertDanger from "../../components/UI/alerts/AlertDanger";
import AlertSuccess from "../../components/UI/alerts/AlertSuccess";
import PreloaderMain from "../../components/system/preloader/PreloaderMain";
import ButtonDangerUtp1 from "../../components/UI/buttons/ButtonDangerUtp1";

export default {
  name: "security",
  components: {ButtonDangerUtp1, PreloaderMain, AlertSuccess, AlertDanger, SidebarRightUtp1, FormInLine, EntryHeaderUtp1},
  setup() {
    const sidebarRight = [
      {
        active: false,
        basic: true,
        disabled: false,
        route: '/settings',
        name: 'Настройки'
      },
      {
        active: true,
        basic: true,
        disabled: false,
        route: '/settings/security',
        name: 'Безопасность'
      },
    ]

    const labelPassword = {
      placeholder: "Введите свой пароль",
      type: "password",
      button: "Сменить"
    }

    const redirectButton = ref("")

    const {userLoader, updatePassword, userException, password, hideEmail, logoutAll} = useUser()
    const {error, errorMessage} = useError()
    const {redirect, buttonRedirect, button} = useRedirect(hideEmail)

    watch(userException, () => {
      error(userException.value)
    })

    watch(hideEmail, () => {
      buttonRedirect()
      if (!button.value) {
        redirectButton.value = ""
      } else redirectButton.value = "Перейти в свой почтовый ящик"
    })

    return {
      sidebarRight,
      userLoader,
      updatePassword,
      hideEmail,
      password,
      labelPassword,
      errorMessage,
      redirectButton,
      redirect,
      logoutAll
    }
  }
}
</script>

<style scoped>

</style>