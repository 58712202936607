import axios from "axios";
import {ref} from 'vue';
import useTokens from "../../useTokens";
import {useRoute} from "vue-router";
import router from "@/router";

export default function useInvoices() {
    const route = useRoute()
    const loaderInvoices = ref(false)
    const exceptionInvoices = ref("")

    const getInvoices = ref({
        invoices: [],
        number: 0
    })

    const getInvoice = ref({
        invoice: {},
        products: [],
        affiliates: [],
        events: {},
        invoices: {},
        emailInformation: {}
    })

    const getQueryInvoices = async () => {
        exceptionInvoices.value = ""
        loaderInvoices.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/shop/invoices`,
                {headers: header, params: route.query}
            )
                .then(response => {
                    getInvoices.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getQueryInvoices()
                    } else {
                        e.response.data.error ?
                            (exceptionInvoices.value = e.response.data.error) : (exceptionInvoices.value = "Exception")
                    }
                })
                .finally(() => loaderInvoices.value = false)
        }
    }

    const addInvoice = async (newInvoice) => {
        exceptionInvoices.value = ""
        loaderInvoices.value = true
        const header = await authHeader()
        if (header) {
            await axios.post(`https://api.utp1.com/shop/invoices`,
                newInvoice,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await addInvoice(newInvoice)
                    } else {
                        e.response.data.error ?
                            (exceptionInvoices.value = e.response.data.error) : (exceptionInvoices.value = "Exception")
                    }
                })
                .finally(() => loaderInvoices.value = false)
        }
    }

    const updateInvoice = async (id, updateInvoiceData) => {
        exceptionInvoices.value = ""
        loaderInvoices.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(`https://api.utp1.com/shop/invoices/` + id,
                updateInvoiceData,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateInvoice(id, updateInvoiceData)
                    } else {
                        e.response.data.error ?
                            (exceptionInvoices.value = e.response.data.error) : (exceptionInvoices.value = "Exception")
                    }
                })
                .finally(() => loaderInvoices.value = false)
        }
    }

    const updateInvoiceEmailInformation = async (id, invoiceEmailInformation) => {
        exceptionInvoices.value = ""
        loaderInvoices.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(`https://api.utp1.com/shop/invoices/` + id + `/email-information`,
                invoiceEmailInformation,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateInvoice(id, updateInvoiceEmailInformation)
                    } else {
                        e.response.data.error ?
                            (exceptionInvoices.value = e.response.data.error) : (exceptionInvoices.value = "Exception")
                    }
                })
                .finally(() => loaderInvoices.value = false)
        }
    }

    const updateReminders = async (id, reminders) => {
        exceptionInvoices.value = ""
        loaderInvoices.value = true
        const header = await authHeader()
        if (header) {
            await axios.patch(`https://api.utp1.com/shop/invoices/` + id,
                reminders,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateReminders(id, reminders)
                    } else {
                        e.response.data.error ?
                            (exceptionInvoices.value = e.response.data.error) : (exceptionInvoices.value = "Exception")
                    }
                })
                .finally(() => loaderInvoices.value = false)
        }
    }

    const updateTimezone = async (id, timezone) => {
        exceptionInvoices.value = ""
        loaderInvoices.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(`https://api.utp1.com/shop/invoices/` + id + `/timezone`,
                timezone,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateTimezone(id, timezone)
                    } else {
                        e.response.data.error ?
                            (exceptionInvoices.value = e.response.data.error) : (exceptionInvoices.value = "Exception")
                    }
                })
                .finally(() => loaderInvoices.value = false)
        }
    }

    const updateValidity = async (id, validity) => {
        exceptionInvoices.value = ""
        loaderInvoices.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(`https://api.utp1.com/shop/invoices/` + id + `/validity`,
                validity,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateValidity(id, validity)
                    } else {
                        e.response.data.error ?
                            (exceptionInvoices.value = e.response.data.error) : (exceptionInvoices.value = "Exception")
                    }
                })
                .finally(() => loaderInvoices.value = false)
        }
    }

    const updateInvoiceProducts = async (id, invoiceProducts) => {
        exceptionInvoices.value = ""
        loaderInvoices.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(`https://api.utp1.com/shop/invoices/` + id + `/products`,
                invoiceProducts,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateInvoiceProducts(id, invoiceProducts)
                    } else {
                        e.response.data.error ?
                            (exceptionInvoices.value = e.response.data.error) : (exceptionInvoices.value = "Exception")
                    }
                })
                .finally(() => loaderInvoices.value = false)
        }
    }

    const addEvent = async (id, newInvoiceEvent) => {
        exceptionInvoices.value = ""
        loaderInvoices.value = true
        const header = await authHeader()
        if (header) {
            await axios.post(`https://api.utp1.com/shop/invoices/` + id + `/events`,
                newInvoiceEvent,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await addEvent(id, newInvoiceEvent)
                    } else {
                        e.response.data.error ?
                            (exceptionInvoices.value = e.response.data.error) : (exceptionInvoices.value = "Exception")
                    }
                })
                .finally(() => loaderInvoices.value = false)
        }
    }

    const deleteInvoice = async (id) => {
        exceptionInvoices.value = ""
        loaderInvoices.value = true
        const header = await authHeader()
        if (header) {
            await axios.delete(`https://api.utp1.com/shop/invoices/` + id,
                {headers: header}
            )
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await deleteInvoice(id)
                    } else {
                        e.response.data.error ?
                            (exceptionInvoices.value = e.response.data.error) : (exceptionInvoices.value = "Exception")
                    }
                })
                .finally(() => loaderInvoices.value = false)
        }
    }

    const getInvoiceRest = async (id) => {
        exceptionInvoices.value = ""
        loaderInvoices.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/shop/invoices/` + id,
                {headers: header}
            )
                .then(response => {
                    getInvoice.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getInvoiceRest(id)
                    } else {
                        await router.push('/shop/invoices')
                    }
                })
                .finally(() => loaderInvoices.value = false)
        }
    }

    const getEvents = async (id, page) => {
        exceptionInvoices.value = ""
        loaderInvoices.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/shop/invoices/` + id + `/events?page=` + page,
                {headers: header}
            )
                .then(response => {
                    getInvoice.value.events = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getEvents(id, page)
                    } else {
                        e.response.data.error ?
                            (exceptionInvoices.value = e.response.data.error) : (exceptionInvoices.value = "Exception")
                    }
                })
                .finally(() => loaderInvoices.value = false)
        }
    }

    const getOtherInvoices = async (id, page) => {
        exceptionInvoices.value = ""
        loaderInvoices.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/shop/invoices/` + id + `/other-invoices?page=` + page,
                {headers: header}
            )
                .then(response => {
                    getInvoice.value.invoices = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getOtherInvoices(id, page)
                    } else {
                        e.response.data.error ?
                            (exceptionInvoices.value = e.response.data.error) : (exceptionInvoices.value = "Exception")
                    }
                })
                .finally(() => loaderInvoices.value = false)
        }
    }

    const {authHeader, updateTokens} = useTokens()

    return {
        loaderInvoices,
        exceptionInvoices,
        getQueryInvoices,
        getInvoices,
        addInvoice,
        updateInvoice,
        updateInvoiceEmailInformation,
        updateReminders,
        updateValidity,
        updateTimezone,
        updateInvoiceProducts,
        addEvent,
        deleteInvoice,
        getInvoiceRest,
        getInvoice,
        getEvents,
        getOtherInvoices
    }

}
