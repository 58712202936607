<template>
  <preloader-main v-if="loaderShopSettings"/>
  <header-nav-utp1 class="mt-3" :nav="newUtpPaySetting.entity ? utpPayNavEntity : utpPayNav"/>
  <div v-if="exceptionShopSettings">
    <alert-danger>{{ exceptionShopSettings }}</alert-danger>
  </div>
  <alert-secondary :btn-close="false" v-if="newUtpPaySetting.status === null">
    <div class="fw-semibold">Для приёма оплат через UTP Pay необходимо заполнить все данные и пройти модерацию
    </div>
    <div class="fst-italic">
      <hr>
      В настоящее время могут быть подключены Российские Юридические лица, Индивидуальные Предприниматели и
      Самозанятые. Возможность подключения зарубежных Организации и Физических лиц подразумевается в будущем.
      Приносим извинения за возможные неудобства!
    </div>
  </alert-secondary>
  <alert-info :btn-close="false" v-else-if="newUtpPaySetting.status === 'DISABLED'">
    <div class="fw-semibold">Для приёма оплат через UTP Pay необходимо отправить данные на модерацию</div>
    <div class="fst-italic" v-if="newUtpPaySetting.comment">
      <hr>
      {{ newUtpPaySetting.comment }}
    </div>
  </alert-info>
  <alert-primary :btn-close="false"
                 v-else-if="['MODERATION', 'MODERATION_SUCCESS', 'MODERATION_CHECK', 'MODERATION_WARNING'].includes(newUtpPaySetting.status)">
    <div class="fw-semibold">При успешном прохождении модерации приём оплат будет активирован автоматически</div>
    <div class="fst-italic" v-if="newUtpPaySetting.comment">
      <hr>
      {{ newUtpPaySetting.comment }}
    </div>
  </alert-primary>
  <alert-success :btn-close="false" v-else-if="['ACTIVATED', 'PLATFORM'].includes(newUtpPaySetting.status)">
    <div class="fw-semibold">Данные успешно прошли модерацию, приём оплат через UTP Pay активирован</div>
    <div class="fst-italic" v-if="newUtpPaySetting.comment">
      <hr>
      {{ newUtpPaySetting.comment }}
    </div>
  </alert-success>
  <alert-warning :btn-close="false" v-else-if="newUtpPaySetting.status === 'FAILED'">
    <div class="fw-semibold">Предоставленные данные не прошли модерацию, приём оплат через UTP Pay недоступен
    </div>
    <div class="fst-italic" v-if="newUtpPaySetting.comment">
      <hr>
      {{ newUtpPaySetting.comment }}
    </div>
  </alert-warning>
  <alert-danger :btn-close="false" v-else-if="newUtpPaySetting.status === 'BLOCKED'">
    <div class="fw-semibold">Приём оплат через UTP Pay заблокирован</div>
    <div class="fst-italic" v-if="newUtpPaySetting.comment">
      <hr>
      {{ newUtpPaySetting.comment }}
    </div>
  </alert-danger>
  <div class="tab-content">
    <form id="utpPaySettingsForm" class="tab-content">
      <div class="tab-pane active" id="aboutCompany">
        <div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="entity" id="entityTrue" :value=true
                   v-model="newUtpPaySetting.entity" required :disabled="newUtpPaySetting.moderation"
                   @click="newUtpPaySettingEntity">
            <label class="form-check-label" for="entityTrue">Юридическое лицо / Организация</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="entity" id="entityFalse" :value=false
                   v-model="newUtpPaySetting.entity" required :disabled="newUtpPaySetting.moderation"
                   @click="newUtpPaySettingNoEntity">
            <label class="form-check-label" for="entityFalse">Физическое лицо / Индивидуальный
              предприниматель</label>
          </div>
        </div>
        <div class="row-form pt-2 pt-sm-3">
          <div class="row">
            <div class="col-xl-7 col-lg-8 col-md-6 col-sm-8 col-12">
              <label for="legalForm" class="col-form-label required-label">
                Организационно-правовая форма:</label>
            </div>
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-4 col-12">
              <select class="form-select" id="legalForm" aria-label="Select legalForm"
                      v-model="newUtpPaySetting.legalForm" required :disabled="newUtpPaySetting.moderation">
                <option v-for="item in newUtpPaySetting.entity ? legalFormEntity : legalFormNoEntity"
                        :value="item.value" :key="item">{{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="newUtpPaySetting.legalForm === 'OTHER'" class="pt-3">
          <label class="form-label required-label" for="legalFormDescription">Свой вариант организационно-правовой
            формы:</label>
          <input-utp1 id="legalFormDescription" v-model:model-value="newUtpPaySetting.legalFormDescription"
                      placeholder="Укажите организационно-правовую форму" type="text"
                      :required="newUtpPaySetting.legalForm === 'OTHER'" minlength="1" maxlength="255"
                      autocomplete="off"/>
        </div>
        <div class="row-form pt-2 pt-sm-3">
          <div class="row">
            <div class="col-xl-7 col-lg-8 col-md-6 col-sm-8 col-12">
              <label for="inn" class="col-form-label"
                     :class="newUtpPaySetting.resident ? 'required-label' : ''">ИНН:</label>
            </div>
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-4 col-12">
              <input-utp1 id="inn" v-model:model-value="newUtpPaySetting.inn" placeholder="Введите ИНН"
                          type="text" minlength="1" maxlength="30" autocomplete="off"
                          :required="newUtpPaySetting.resident"
                          :disabled="newUtpPaySetting.moderation" :readonly="newUtpPaySetting.moderation"/>
            </div>
          </div>
        </div>
        <div class="pt-3">
          <label class="form-label required-label" for="name">{{
              newUtpPaySetting.entity ?
                  'Полное наименование Организации (БЕЗ организационно-правовой формы и кавычек): ' :
                  'Фамилия Имя Отчество(полностью, Отчество при наличии): '
            }}</label>
          <input-utp1 id="name" v-model:model-value="newUtpPaySetting.name"
                      :placeholder="newUtpPaySetting.entity ? 'Введите наименование' : 'Введите ФИО'" type="text"
                      required
                      minlength="1" maxlength="255" autocomplete="off" :disabled="newUtpPaySetting.moderation"
                      :readonly="newUtpPaySetting.moderation"/>
        </div>
        <div class="form-check form-switch pt-3">
          <input class="form-check-input" type="checkbox" role="switch" id="resident"
                 v-model="newUtpPaySetting.resident" :disabled="newUtpPaySetting.moderation">
          <label class="form-check-label" for="resident">{{
              newUtpPaySetting.resident ?
                  'Является налоговым резидентом Российской Федерации' :
                  'Не является налоговым резидентом Российской Федерации'
            }}</label>
        </div>
        <div v-if="newUtpPaySetting.legalForm !== 'INDIVIDUAL'" class="row-form pt-2 pt-sm-3">
          <div class="row">
            <div class="col-xl-7 col-lg-8 col-md-6 col-sm-8 col-12">
              <label for="grn" class="col-form-label"
                     :class="['IE', 'LCC', 'JSC', 'PJSC'].includes(newUtpPaySetting.legalForm) && newUtpPaySetting.resident ? 'required-label' : ''">
                Государственный регистрационный номер:</label>
            </div>
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-4 col-12">
              <input-utp1 id="grn" v-model:model-value="newUtpPaySetting.grn" placeholder="Введите ГРН"
                          type="text" minlength="1" maxlength="30" autocomplete="off"
                          :required="['IE', 'LCC', 'JSC', 'PJSC'].includes(newUtpPaySetting.legalForm) && newUtpPaySetting.resident"
                          :disabled="newUtpPaySetting.moderation" :readonly="newUtpPaySetting.moderation"/>
            </div>
          </div>
        </div>
        <div v-if="newUtpPaySetting.legalForm !== 'INDIVIDUAL'" class="row-form pt-2 pt-sm-3">
          <div class="row">
            <div class="col-xl-7 col-lg-8 col-md-6 col-sm-8 col-12">
              <label for="create" class="col-form-label"
                     :class="['IE', 'LCC', 'JSC', 'PJSC'].includes(newUtpPaySetting.legalForm) && newUtpPaySetting.resident ? 'required-label' : ''">
                Дата регистрации:</label>
            </div>
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-4 col-12">
              <input-utp1 id="create" v-model:model-value="newUtpPaySetting.create" placeholder="Дата регистрации"
                          type="date" autocomplete="off"
                          :required="['IE', 'LCC', 'JSC', 'PJSC'].includes(newUtpPaySetting.legalForm)"
                          :disabled="newUtpPaySetting.moderation" :readonly="newUtpPaySetting.moderation"/>
            </div>
          </div>
        </div>
        <div v-if="newUtpPaySetting.legalForm !== 'INDIVIDUAL'" class="row-form pt-2 pt-sm-3">
          <div class="row">
            <div class="col-xl-7 col-lg-8 col-md-6 col-sm-8 col-12">
              <label for="vat" class="col-form-label required-label">
                Налог на добавленную стоимость:</label>
            </div>
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-4 col-12">
              <select class="form-select" id="vat" aria-label="Select vat" v-model="newUtpPaySetting.vat"
                      required>
                <option v-for="item in vat" :value="item.value" :key="item">{{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="newUtpPaySetting.vat === 'OTHER' && newUtpPaySetting.legalForm !== 'INDIVIDUAL'"
             class="row-form pt-2 pt-sm-3">
          <div class="row">
            <div class="col-xl-7 col-lg-8 col-md-6 col-sm-8 col-12">
              <label for="vatRete" class="col-form-label required-label">
                Размер НДС:</label>
            </div>
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-4 col-12">
              <div class="input-group">
                <input-utp1 id="vatRete" v-model:model-value="newUtpPaySetting.create"
                            placeholder="Введите размер НДС" type="number" autocomplete="off" step="1" min="1"
                            :required="newUtpPaySetting.vat === 'OTHER'"/>
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="newUtpPaySetting.legalForm !== 'INDIVIDUAL'" class="row-form pt-2 pt-sm-3">
          <div class="row">
            <div class="col-xl-7 col-lg-8 col-md-6 col-sm-8 col-12">
              <label for="vatJustification" class="col-form-label required-label">
                Обоснование НДС:</label>
            </div>
            <div class="col-xl-5 col-lg-4 col-md-6 col-sm-4 col-12">
              <select class="form-select" id="vatJustification" aria-label="Select vatJustification"
                      v-model="newUtpPaySetting.vatJustification" required>
                <option v-for="item in vatJustification" :value="item.value" :key="item">{{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="pt-3"
             v-if="newUtpPaySetting.vatJustification === 'OTHER' && newUtpPaySetting.legalForm !== 'INDIVIDUAL'">
          <label class="form-label required-label" for="vatJustificationDescription">
            Ваш вариант обоснования НДС:</label>
          <input-utp1 id="vatJustificationDescription"
                      v-model:model-value="newUtpPaySetting.vatJustificationDescription"
                      placeholder="Введите Ваш вариант"
                      type="text" :required="newUtpPaySetting.vatJustification === 'OTHER'" minlength="1"
                      maxlength="255"
                      autocomplete="off"/>
        </div>
        <div v-if="newUtpPaySetting.legalForm !== 'INDIVIDUAL'" class="row-form pt-3">
          <div class="row">
            <div class="col-4">
              <label for="okato" class="form-label d-block text-truncate"
                     :class="['IE', 'LCC', 'JSC', 'PJSC'].includes(newUtpPaySetting.legalForm) && newUtpPaySetting.resident ? 'required-label' : ''">
                Код ОКАТО:</label>
              <input-utp1 id="okato" v-model:model-value="newUtpPaySetting.okato" placeholder="Введите ОКАТО"
                          type="text" minlength="2" maxlength="11" autocomplete="off"
                          :required="['IE', 'LCC', 'JSC', 'PJSC'].includes(newUtpPaySetting.legalForm) && newUtpPaySetting.resident"/>
            </div>
            <div class="col-4">
              <label for="oktmo" class="form-label d-block text-truncate"
                     :class="['IE', 'LCC', 'JSC', 'PJSC'].includes(newUtpPaySetting.legalForm) && newUtpPaySetting.resident ? 'required-label' : ''">
                Код ОКТМО:</label>
              <input-utp1 id="oktmo" v-model:model-value="newUtpPaySetting.oktmo" placeholder="Введите ОКТМО"
                          type="text" minlength="8" maxlength="11" autocomplete="off"
                          :required="['IE', 'LCC', 'JSC', 'PJSC'].includes(newUtpPaySetting.legalForm) && newUtpPaySetting.resident"/>
            </div>
            <div class="col-4">
              <label for="okpo" class="form-label d-block text-truncate"
                     :class="['IE', 'LCC', 'JSC', 'PJSC'].includes(newUtpPaySetting.legalForm) && newUtpPaySetting.resident ? 'required-label' : ''">
                Код ОКПО:</label>
              <input-utp1 id="okpo" v-model:model-value="newUtpPaySetting.okpo" placeholder="Введите ОКПО"
                          type="text" minlength="8" maxlength="14" autocomplete="off"
                          :required="['IE', 'LCC', 'JSC', 'PJSC'].includes(newUtpPaySetting.legalForm) && newUtpPaySetting.resident"/>
            </div>
          </div>
        </div>
        <div class="pt-3">
          <label class="form-label" for="taxOfficeName"
                 :class="newUtpPaySetting.resident ? 'required-label' : ''">
            Полное наименование регистрирующего налогового органа:</label>
          <input-utp1 id="taxOfficeName" v-model:model-value="newUtpPaySetting.taxOfficeName"
                      placeholder="Введите наименование налоговой" type="text"
                      :required="newUtpPaySetting.resident"
                      minlength="1" maxlength="255" autocomplete="off"/>
        </div>
        <div v-if="newUtpPaySetting.entity" class="row-form pt-2 pt-sm-3">
          <div class="row">
            <div class="col-sm-auto col-12">
              <label for="authorizedCapital" class="col-form-label required-label">
                Размер уставного капитала:</label>
            </div>
            <div class="col-sm col-12">
              <div class="input-group">
                <input-utp1 id="authorizedCapital" v-model:model-value="newUtpPaySetting.authorizedCapital"
                            placeholder="Введите уставной капитал" type="number"
                            :required="['LCC', 'JSC', 'PJSC'].includes(newUtpPaySetting.legalForm) && newUtpPaySetting.resident"
                            autocomplete="off" step="0.01" min="10000"/>
                <span class="input-group-text">₽</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="activityCompany">
        <div>
          <label class="form-label required-label" for="shopName">
            Наименование интернет-магазина:</label>
          <input-utp1 id="shopName" v-model:model-value="newUtpPaySetting.shopName"
                      placeholder="Введите наименования магазина" type="text" required minlength="1"
                      maxlength="255"
                      autocomplete="off"/>
        </div>
        <div class="pt-3">
          <label class="form-label required-label" for="mainActivity">
            Основной вид деятельности (с кодом ОКВЭД):</label>
          <input-utp1 id="mainActivity" v-model:model-value="newUtpPaySetting.mainActivity"
                      placeholder="Введите основной вид деятельности" type="text" required minlength="1"
                      maxlength="255"
                      autocomplete="off"/>
        </div>
        <div class="pt-3">
          <label class="form-label required-label" for="description">
            Описание реализуемых товаров, работ, услуг на сайте:</label>
          <textarea class="form-control pb-3" id="description" v-model="newUtpPaySetting.description"
                    placeholder="Введите описание" type="text" rows="3" required autocomplete="off"></textarea>
        </div>
        <div class="row-form pt-sm-3 pt-2">
          <div class="row">
            <div class="col-xl-6 col-lg-8 col-md-6 col-sm-8 col-12">
              <label for="businessInformation" class="col-form-label required-label">
                Сведения о хозяйственной деятельности:</label>
            </div>
            <div class="col-xl-6 col-lg-4 col-md-6 col-sm-4 col-12">
              <select class="form-select" id="businessInformation" aria-label="Select businessInformation"
                      v-model="newUtpPaySetting.businessInformation" required>
                <option v-for="item in businessInformation" :value="item.value" :key="item">{{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="newUtpPaySetting.businessInformation === 'OTHER'" class="pt-3">
          <label class="form-label required-label" for="businessInformationDescription">Свой вариант хозяйственной
            деятельности:</label>
          <input-utp1 id="businessInformationDescription"
                      v-model:model-value="newUtpPaySetting.businessInformationDescription"
                      placeholder="Укажите хозяйственную деятельность" type="text"
                      :required="newUtpPaySetting.businessInformation === 'OTHER'" minlength="1" maxlength="255"
                      autocomplete="off"/>
        </div>
        <div class="row-form pt-2 pt-sm-3">
          <div class="row">
            <div class="col-xl-6 col-lg-8 col-md-6 col-sm-8 col-12">
              <label for="volumeSales" class="col-form-label required-label">
                Прогнозируемые объемы продаж в месяц:</label>
            </div>
            <div class="col-xl-6 col-lg-4 col-md-6 col-sm-4 col-12">
              <div class="input-group">
                <input-utp1 id="volumeSales" v-model:model-value="newUtpPaySetting.volumeSales"
                            placeholder="Введите объемы продаж" type="number" required autocomplete="off" step="1"
                            min="1"/>
                <span class="input-group-text">₽</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-2">
          <label class="col-form-label required-label">
            Виды договоров (контрактов), расчеты по которым Вы собираетесь осуществлять:</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="servicesContract"
                 v-model="newUtpPaySetting.servicesContract">
          <label class="form-check-label" for="servicesContract">
            Договор на оказание услуг</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="salesContract"
                 v-model="newUtpPaySetting.salesContract">
          <label class="form-check-label" for="salesContract">
            Договор на куплю-продажу товаров</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="agencyContract"
                 v-model="newUtpPaySetting.agencyContract">
          <label class="form-check-label" for="agencyContract">
            Агентский договор</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="trainingContract"
                 v-model="newUtpPaySetting.trainingContract">
          <label class="form-check-label" for="trainingContract">
            Договор на проведение обучения/коучинга/тренинга/семинара</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="deliveryContract"
                 v-model="newUtpPaySetting.deliveryContract">
          <label class="form-check-label" for="deliveryContract">
            Договор поставки</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="anotherContract"
                 v-model="newUtpPaySetting.anotherContract">
          <label class="form-check-label" for="anotherContract">
            Иной вид договоров</label>
        </div>
        <div v-if="newUtpPaySetting.anotherContract" class="pt-2">
          <label class="form-label required-label" for="anotherContractDescription">Другие виды договоров:</label>
          <input-utp1 id="anotherContractDescription"
                      v-model:model-value="newUtpPaySetting.anotherContractDescription"
                      placeholder="Укажите виды договоров"
                      type="text" :required="newUtpPaySetting.anotherContract" minlength="1" maxlength="255"
                      autocomplete="off"/>
        </div>
      </div>
      <div class="tab-pane" id="detailsCompany">
        <div class="row-form">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-4 col-sm-5 col-12">
              <label for="accountNumber" class="col-form-label col-form-label-first required-label">
                Расчетный счет в банке:</label>
            </div>
            <div class="col-xl-8 col-lg-7 col-md-8 col-sm-7 col-12">
              <input-utp1 id="accountNumber" v-model:model-value="newUtpPaySetting.accountNumber"
                          placeholder="Введите номер счета" type="text" minlength="20" maxlength="20"
                          autocomplete="off"
                          required/>
            </div>
          </div>
        </div>
        <div class="row-form pt-sm-3 pt-2">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-4 col-sm-5 col-12">
              <label for="bankName" class="col-form-label required-label">
                Наименование банка:</label>
            </div>
            <div class="col-xl-8 col-lg-7 col-md-8 col-sm-7 col-12">
              <input-utp1 id="bankName" v-model:model-value="newUtpPaySetting.bankName"
                          placeholder="Введите наименование банка" type="text" minlength="1" maxlength="255"
                          autocomplete="off" required/>
            </div>
          </div>
        </div>
        <div class="row-form pt-sm-3 pt-2">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-4 col-sm-5 col-12">
              <label for="bankBic" class="col-form-label required-label">
                БИК банка:</label>
            </div>
            <div class="col-xl-8 col-lg-7 col-md-8 col-sm-7 col-12">
              <input-utp1 id="bankBic" v-model:model-value="newUtpPaySetting.bankBic"
                          placeholder="Введите БИК банка" type="text" minlength="9" maxlength="9"
                          autocomplete="off"
                          required/>
            </div>
          </div>
        </div>
        <div class="row-form pt-sm-3 pt-2">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-4 col-sm-5 col-12">
              <label for="corrAccount" class="col-form-label required-label">
                Корр. счет банка:</label>
            </div>
            <div class="col-xl-8 col-lg-7 col-md-8 col-sm-7 col-12">
              <input-utp1 id="corrAccount" v-model:model-value="newUtpPaySetting.corrAccount"
                          placeholder="Введите К/С банка" type="text" minlength="20" maxlength="20"
                          autocomplete="off"
                          required/>
            </div>
          </div>
        </div>
        <div class="row-form">
          <div class="row">
            <div class="col-6 pt-3">
              <label for="bankInn" class="form-label required-label d-block text-truncate">
                ИНН банка:</label>
              <input-utp1 id="bankInn" v-model:model-value="newUtpPaySetting.bankInn"
                          placeholder="Введите ИНН банка" type="text" minlength="10" maxlength="12"
                          autocomplete="off"
                          required/>
            </div>
            <div class="col-6 pt-3">
              <label for="bankKpp" class="form-label required-label d-block text-truncate">
                КПП банка:</label>
              <input-utp1 id="bankKpp" v-model:model-value="newUtpPaySetting.bankKpp"
                          placeholder="Введите КПП банка" type="text" minlength="9" maxlength="9"
                          autocomplete="off"
                          required/>
            </div>
          </div>
        </div>
        <div class="row-form">
          <div class="row">
            <div class="col-6 pt-3">
              <label for="swift" class="form-label d-block text-truncate">
                SWIFT:</label>
              <input-utp1 id="swift" v-model:model-value="newUtpPaySetting.swift" placeholder="Введите SWIFT"
                          type="text" minlength="8" maxlength="11" autocomplete="off"/>
            </div>
            <div class="col-6 pt-3">
              <label for="iban" class="form-label d-block text-truncate">
                IBAN:</label>
              <input-utp1 id="iban" v-model:model-value="newUtpPaySetting.iban" placeholder="Введите IBAN"
                          type="text" minlength="14" maxlength="34" autocomplete="off"/>
            </div>
          </div>
        </div>
        <div class="pt-3">
          <label class="form-label" for="additionalDetails">
            Дополнительная информация:</label>
          <textarea class="form-control pb-3" id="additionalDetails" v-model="newUtpPaySetting.additionalDetails"
                    placeholder="Введите дополнительную информацию" type="text" rows="3"
                    autocomplete="off"></textarea>
        </div>
      </div>
      <div class="tab-pane" id="contactsCompany">
        <hr-header-text :text="'Юридический адрес'"/>
        <div class="row-form">
          <div class="row">
            <div class="col-12 col-sm-4 pt-2">
              <label for="legalPostcode" class="form-label required-label">
                Почтовый индекс:</label>
              <div>
                <input-utp1 id="legalPostcode" v-model:model-value="newUtpPaySetting.legalPostcode"
                            placeholder="Введите индекс" type="text" minlength="4" maxlength="10"
                            autocomplete="off"
                            required/>
              </div>
            </div>
            <div class="col-12 col-sm-8 pt-2">
              <label for="legalCountry" class="form-label required-label">
                Страна:</label>
              <div>
                <input-utp1 id="legalCountry" v-model:model-value="newUtpPaySetting.legalCountry"
                            placeholder="Введите страну" type="text" minlength="2" maxlength="70"
                            autocomplete="off"
                            required/>
              </div>
            </div>
          </div>
        </div>
        <div class="row-form">
          <div class="row">
            <div class="col-12 col-sm-6 pt-3">
              <label for="legalRegion" class="form-label">
                Регион:</label>
              <div>
                <input-utp1 id="legalRegion" v-model:model-value="newUtpPaySetting.legalRegion"
                            placeholder="Введите регион" type="text" minlength="2" maxlength="255"
                            autocomplete="off"/>
              </div>
            </div>
            <div class="col-12 col-sm-6 pt-3">
              <label for="legalCity" class="form-label required-label">
                Город:</label>
              <div>
                <input-utp1 id="legalCity" v-model:model-value="newUtpPaySetting.legalCity"
                            placeholder="Введите город" type="text" minlength="2" maxlength="255"
                            autocomplete="off"
                            required/>
              </div>
            </div>
          </div>
        </div>
        <div class="row-form">
          <div class="row">
            <div class="col-12 col-sm-6 pt-3">
              <label for="legalStreet" class="form-label required-label">
                Улица:</label>
              <input-utp1 id="legalStreet" v-model:model-value="newUtpPaySetting.legalStreet"
                          placeholder="Введите улицу" type="text" minlength="2" maxlength="150" autocomplete="off"
                          required/>
            </div>
            <div class="col-4 col-sm-2 pt-3">
              <label for="legalHome" class="form-label required-label d-block text-truncate">
                Дом:</label>
              <input-utp1 id="legalHome" v-model:model-value="newUtpPaySetting.legalHome"
                          placeholder="Введите дом" type="text" minlength="1" maxlength="10" autocomplete="off"
                          required/>
            </div>
            <div class="col-4 col-sm-2 pt-3">
              <label for="legalBuilding" class="form-label d-block text-truncate">
                Корпус:</label>
              <input-utp1 id="legalBuilding" v-model:model-value="newUtpPaySetting.legalBuilding"
                          placeholder="Введите корпус" type="text" minlength="1" maxlength="10"
                          autocomplete="off"/>
            </div>
            <div class="col-4 col-sm-2 pt-3">
              <label for="legalOffice" class="form-label d-block text-truncate">
                Офис:</label>
              <input-utp1 id="legalOffice" v-model:model-value="newUtpPaySetting.legalOffice"
                          placeholder="Введите офис" type="text" minlength="1" maxlength="10" autocomplete="off"/>
            </div>
          </div>
        </div>
        <div class="pt-2">
          <hr-header-text :text="'Фактический адрес'"/>
        </div>
        <div class="form-check form-switch pt-1">
          <input class="form-check-input" type="checkbox" role="switch" id="actualEqLegal"
                 v-model="newUtpPaySetting.actualEqLegal" @input="updateActualEqLegal">
          <label class="form-check-label" for="actualEqLegal">{{
              newUtpPaySetting.actualEqLegal ?
                  'Фактический адрес соответствует юридическому' :
                  'Фактический адрес не соответствует юридическому'
            }}</label>
        </div>
        <div v-if="!newUtpPaySetting.actualEqLegal">
          <div class="row-form">
            <div class="row">
              <div class="col-12 col-sm-4 pt-sm-2 pt-3">
                <label for="actualPostcode" class="form-label required-label">
                  Почтовый индекс:</label>
                <div>
                  <input-utp1 id="actualPostcode" v-model:model-value="newUtpPaySetting.actualPostcode"
                              placeholder="Введите индекс" type="text" minlength="4" maxlength="10"
                              autocomplete="off"
                              required/>
                </div>
              </div>
              <div class="col-12 col-sm-8 pt-sm-2 pt-3">
                <label for="actualCountry" class="form-label required-label">
                  Страна:</label>
                <div>
                  <input-utp1 id="actualCountry" v-model:model-value="newUtpPaySetting.actualCountry"
                              placeholder="Введите страну" type="text" minlength="2" maxlength="70"
                              autocomplete="off"
                              required/>
                </div>
              </div>
            </div>
          </div>
          <div class="row-form">
            <div class="row">
              <div class="col-12 col-sm-6 pt-3">
                <label for="actualRegion" class="form-label">
                  Регион:</label>
                <div>
                  <input-utp1 id="actualRegion" v-model:model-value="newUtpPaySetting.actualRegion"
                              placeholder="Введите регион" type="text" minlength="2" maxlength="255"
                              autocomplete="off"/>
                </div>
              </div>
              <div class="col-12 col-sm-6 pt-3">
                <label for="actualCity" class="form-label required-label">
                  Город:</label>
                <div>
                  <input-utp1 id="actualCity" v-model:model-value="newUtpPaySetting.actualCity"
                              placeholder="Введите город" type="text" minlength="2" maxlength="255"
                              autocomplete="off"
                              required/>
                </div>
              </div>
            </div>
          </div>
          <div class="row-form">
            <div class="row">
              <div class="col-12 col-sm-6 pt-3">
                <label for="actualStreet" class="form-label required-label">
                  Улица:</label>
                <input-utp1 id="actualStreet" v-model:model-value="newUtpPaySetting.actualStreet"
                            placeholder="Введите улицу" type="text" minlength="2" maxlength="150"
                            autocomplete="off"
                            required/>
              </div>
              <div class="col-4 col-sm-2 pt-3">
                <label for="actualHome" class="form-label required-label d-block text-truncate">
                  Дом:</label>
                <input-utp1 id="actualHome" v-model:model-value="newUtpPaySetting.actualHome"
                            placeholder="Введите дом" type="text" minlength="1" maxlength="10" autocomplete="off"
                            required/>
              </div>
              <div class="col-4 col-sm-2 pt-3">
                <label for="actualBuilding" class="form-label d-block text-truncate">
                  Корпус:</label>
                <input-utp1 id="actualBuilding" v-model:model-value="newUtpPaySetting.actualBuilding"
                            placeholder="Введите корпус" type="text" minlength="1" maxlength="10"
                            autocomplete="off"/>
              </div>
              <div class="col-4 col-sm-2 pt-3">
                <label for="actualOffice" class="form-label d-block text-truncate">
                  Офис:</label>
                <input-utp1 id="actualOffice" v-model:model-value="newUtpPaySetting.actualOffice"
                            placeholder="Введите офис" type="text" minlength="1" maxlength="10"
                            autocomplete="off"/>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-2">
          <hr-header-text :text="'Почтовый адрес'"/>
        </div>
        <div class="form-check form-switch pt-1">
          <input class="form-check-input" type="checkbox" role="switch" id="corrEqLegal"
                 @input="updateCorrEqLegal" v-model="newUtpPaySetting.corrEqLegal"
                 :disabled="newUtpPaySetting.corrEqActual && !newUtpPaySetting.corrEqLegal">
          <label class="form-check-label" for="corrEqLegal">{{
              newUtpPaySetting.corrEqLegal ?
                  'Почтовый адрес соответствует юридическому' :
                  'Почтовый адрес не соответствует юридическому'
            }}</label>
        </div>
        <div class="form-check form-switch pt-1" v-if="!newUtpPaySetting.actualEqLegal">
          <input class="form-check-input" type="checkbox" role="switch" id="corrEqActual"
                 @input="updateCorrEqActual" v-model="newUtpPaySetting.corrEqActual"
                 :disabled="newUtpPaySetting.corrEqLegal && !newUtpPaySetting.corrEqActual">
          <label class="form-check-label" for="corrEqActual">{{
              newUtpPaySetting.corrEqActual ?
                  'Почтовый адрес соответствует фактическому' :
                  'Почтовый адрес не соответствует фактическому'
            }}</label>
        </div>
        <div v-if="!newUtpPaySetting.corrEqLegal && !newUtpPaySetting.corrEqActual">
          <div class="row-form">
            <div class="row">
              <div class="col-12 col-sm-4 pt-sm-2 pt-3">
                <label for="corrPostcode" class="form-label required-label">
                  Почтовый индекс:</label>
                <div>
                  <input-utp1 id="corrPostcode" v-model:model-value="newUtpPaySetting.corrPostcode"
                              placeholder="Введите индекс" type="text" minlength="4" maxlength="10"
                              autocomplete="off"
                              required/>
                </div>
              </div>
              <div class="col-12 col-sm-8 pt-sm-2 pt-3">
                <label for="corrCountry" class="form-label required-label">
                  Страна:</label>
                <div>
                  <input-utp1 id="corrCountry" v-model:model-value="newUtpPaySetting.corrCountry"
                              placeholder="Введите страну" type="text" minlength="2" maxlength="70"
                              autocomplete="off"
                              required/>
                </div>
              </div>
            </div>
          </div>
          <div class="row-form">
            <div class="row">
              <div class="col-12 col-sm-6 pt-3">
                <label for="corrRegion" class="form-label">
                  Регион:</label>
                <div>
                  <input-utp1 id="corrRegion" v-model:model-value="newUtpPaySetting.corrRegion"
                              placeholder="Введите регион" type="text" minlength="2" maxlength="255"
                              autocomplete="off"/>
                </div>
              </div>
              <div class="col-12 col-sm-6 pt-3">
                <label for="corrCity" class="form-label required-label">
                  Город:</label>
                <div>
                  <input-utp1 id="corrCity" v-model:model-value="newUtpPaySetting.corrCity"
                              placeholder="Введите город" type="text" minlength="2" maxlength="255"
                              autocomplete="off"
                              required/>
                </div>
              </div>
            </div>
          </div>
          <div class="row-form">
            <div class="row">
              <div class="col-12 col-sm-6 pt-3">
                <label for="corrStreet" class="form-label required-label">
                  Улица:</label>
                <input-utp1 id="corrStreet" v-model:model-value="newUtpPaySetting.corrStreet"
                            placeholder="Введите улицу" type="text" minlength="2" maxlength="150"
                            autocomplete="off"
                            required/>
              </div>
              <div class="col-4 col-sm-2 pt-3">
                <label for="corrHome" class="form-label required-label d-block text-truncate">
                  Дом:</label>
                <input-utp1 id="corrHome" v-model:model-value="newUtpPaySetting.corrHome"
                            placeholder="Введите дом" type="text" minlength="1" maxlength="10" autocomplete="off"
                            required/>
              </div>
              <div class="col-4 col-sm-2 pt-3">
                <label for="corrBuilding" class="form-label d-block text-truncate">
                  Корпус:</label>
                <input-utp1 id="corrBuilding" v-model:model-value="newUtpPaySetting.corrBuilding"
                            placeholder="Введите корпус" type="text" minlength="1" maxlength="10"
                            autocomplete="off"/>
              </div>
              <div class="col-4 col-sm-2 pt-3">
                <label for="corrOffice" class="form-label d-block text-truncate">
                  Офис:</label>
                <input-utp1 id="corrOffice" v-model:model-value="newUtpPaySetting.corrOffice"
                            placeholder="Введите офис" type="text" minlength="1" maxlength="10"
                            autocomplete="off"/>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-2">
          <hr-header-text :text="'Контактное лицо'"/>
        </div>
        <div class="pt-2">
          <label for="contactName" class="form-label required-label">
            Фамилия Имя Отчество (полностью, Отчество при наличии):</label>
          <input-utp1 id="contactName" v-model:model-value="newUtpPaySetting.contactName"
                      placeholder="Введите ФИО контактного лица" type="text" minlength="1" maxlength="255"
                      autocomplete="off" required/>
        </div>
        <div class="row-form pt-sm-3 pt-2">
          <div class="row">
            <div class="col-sm-3 col-12">
              <label for="contactPosition" class="col-form-label required-label">
                Должность:</label>
            </div>
            <div class="col-sm-9 col-12">
              <input-utp1 id="contactPosition" v-model:model-value="newUtpPaySetting.contactPosition"
                          placeholder="Введите должность контактного лица" type="text" minlength="1"
                          maxlength="255"
                          autocomplete="off" required/>
            </div>
          </div>
        </div>
        <div class="row-form">
          <div class="row">
            <div class="col-sm-6 col-12 pt-3">
              <label for="contactPhone" class="form-label required-label">
                Телефон контактного лица:</label>
              <input-utp1 id="contactPhone" v-model:model-value="newUtpPaySetting.contactPhone"
                          placeholder="Введите телефон контактного лица" type="text" minlength="1" maxlength="255"
                          autocomplete="off" pattern="\+[1-9][0-9]{7,15}" required/>
            </div>
            <div class="col-sm-6 col-12 pt-3">
              <label for="contactEmail" class="form-label required-label">
                E-mail контактного лица:</label>
              <input-utp1 id="contactEmail" v-model:model-value="newUtpPaySetting.contactEmail"
                          placeholder="Введите e-mail контактного лица" type="text" minlength="1" maxlength="255"
                          autocomplete="off" required/>
            </div>
            <label for="contactPhone" class="form-text">
              Телефон в международном формате, +79210000000 (без пробелов и других символов)</label>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="directorCompany">
        <label for="ceoName" class="form-label required-label">
          Фамилия Имя Отчество (полностью, Отчество при наличии):</label>
        <input-utp1 id="ceoName" v-model:model-value="newUtpPaySetting.ceoName"
                    placeholder="Введите ФИО руководителя" type="text" minlength="1" maxlength="255"
                    autocomplete="off"
                    required/>
        <div class="row-form pt-2 pt-sm-3">
          <div class="row">
            <div class="col-sm-4 col-12">
              <label for="ceoBirthdate" class="col-form-label required-label">
                Дата рождения:</label>
            </div>
            <div class="col-sm-8 col-12">
              <input-utp1 id="ceoBirthdate" v-model:model-value="newUtpPaySetting.ceoBirthdate"
                          placeholder="Введите дату рождения" type="date" autocomplete="off" require/>
            </div>
          </div>
        </div>
        <div class="row-form pt-2 pt-sm-3">
          <div class="row">
            <div class="col-sm-4 col-12">
              <label for="ceoPosition" class="col-form-label required-label">
                Должность:</label>
            </div>
            <div class="col-sm-8 col-12">
              <input-utp1 id="ceoPosition" v-model:model-value="newUtpPaySetting.ceoPosition"
                          placeholder="Введите должность" type="text" minlength="1" maxlength="255"
                          autocomplete="off"
                          required/>
            </div>
          </div>
        </div>
        <div class="form-check form-switch pt-3">
          <input class="form-check-input" type="checkbox" role="switch" id="ceoResident"
                 v-model="newUtpPaySetting.ceoResident">
          <label class="form-check-label" for="ceoResident">
            {{
              newUtpPaySetting.ceoResident ? 'Является налоговым резидентом Российской Федерации' :
                  'Не является налоговым резидентом Российской Федерации'
            }}</label>
        </div>
        <div class="row-form">
          <div class="row">
            <div class="col-12 col-sm-6 pt-2">
              <label for="ceoDocument" class="form-label required-label">
                Документ:</label>
              <input-utp1 id="ceoDocument" v-model:model-value="newUtpPaySetting.ceoDocument"
                          placeholder="Введите документ" type="text" minlength="2" maxlength="30"
                          autocomplete="off"
                          required/>
            </div>
            <div class="col-6 col-sm-3 pt-sm-2 pt-3">
              <label for="ceoDocumentSeries" class="form-label d-block text-truncate">
                Серия:</label>
              <input-utp1 id="ceoDocumentSeries" v-model:model-value="newUtpPaySetting.ceoDocumentSeries"
                          placeholder="Введите серию документа" type="text" minlength="1" maxlength="10"
                          autocomplete="off"/>
            </div>
            <div class="col-6 col-sm-3 pt-sm-2 pt-3">
              <label for="ceoDocumentNumber" class="form-label required-label d-block text-truncate">
                Номер:</label>
              <input-utp1 id="ceoDocumentNumber" v-model:model-value="newUtpPaySetting.ceoDocumentNumber"
                          placeholder="Введите номер документа" type="text" minlength="1" maxlength="30"
                          autocomplete="off"
                          required/>
            </div>
          </div>
        </div>
        <div class="row-form">
          <div class="row">
            <div class="col-12 col-sm-6 pt-3">
              <label for="ceoDocumentIssuedBy" class="form-label required-label">
                Документ выдан:</label>
              <input-utp1 id="ceoDocumentIssuedBy" v-model:model-value="newUtpPaySetting.ceoDocumentIssuedBy"
                          placeholder="Введите кем выдан документ" type="text" minlength="1" maxlength="255"
                          autocomplete="off" required/>
            </div>
            <div class="col-6 col-sm-3 pt-3">
              <label for="ceoDocumentDate" class="form-label required-label d-block text-truncate">
                Дата выдачи:</label>
              <input-utp1 id="ceoDocumentDate" v-model:model-value="newUtpPaySetting.ceoDocumentDate"
                          placeholder="Введите дату выдачи" type="date" autocomplete="off" require/>
            </div>
            <div class="col-6 col-sm-3 pt-3">
              <label for="ceoDocumentIssuedCode" class="form-label d-block text-truncate">
                Код под-ния:</label>
              <input-utp1 id="ceoDocumentIssuedCode" v-model:model-value="newUtpPaySetting.ceoDocumentIssuedCode"
                          placeholder="Введите код подразделения" type="text" minlength="1" maxlength="10"
                          autocomplete="off"/>
            </div>
          </div>
        </div>
        <div class="pt-3">
          <label for="ceoRegistrationAddress" class="form-label required-label">
            Адрес регистрации руководителя:</label>
          <input-utp1 id="ceoRegistrationAddress" v-model:model-value="newUtpPaySetting.ceoRegistrationAddress"
                      placeholder="Введите адрес регистрации руководителя" type="text" minlength="1"
                      maxlength="255"
                      autocomplete="off" required/>
        </div>
        <div class="row-form">
          <div class="row">
            <div class="col-sm-6 col-12 pt-3">
              <label for="ceoEmail" class="form-label required-label">
                E-mail руководителя:</label>
              <input-utp1 id="ceoEmail" v-model:model-value="newUtpPaySetting.ceoEmail"
                          placeholder="Введите e-mail руководителя" type="email" minlength="1" maxlength="255"
                          autocomplete="off" required/>
            </div>
            <div class="col-sm-6 col-12 pt-3">
              <label for="ceoPhone" class="form-label required-label">
                Телефон руководителя:</label>
              <input-utp1 id="ceoPhone" v-model:model-value="newUtpPaySetting.ceoPhone"
                          placeholder="Введите телефон руководителя" type="tel" minlength="9" maxlength="17"
                          autocomplete="off" pattern="\+[1-9][0-9]{7,15}" required/>
            </div>
            <label for="contactPhone" class="form-text">
              Телефон в международном формате, +79210000000 (без пробелов и других символов)</label>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="ownersCompany">
        <hr-header-text :text="'Структура органов управления'"/>
        <label class="form-label required-label">
          Коллегиальный орган:</label>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="collegialBodyGeneralMeeting"
                 v-model="newUtpPaySetting.collegialBodyGeneralMeeting">
          <label class="form-check-label" for="collegialBodyGeneralMeeting">
            Общее собрание</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="collegialBodyBoardDirectors"
                 v-model="newUtpPaySetting.collegialBodyBoardDirectors">
          <label class="form-check-label" for="collegialBodyBoardDirectors">
            Совет директоров</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="collegialBodyGoverning"
                 v-model="newUtpPaySetting.collegialBodyGoverning">
          <label class="form-check-label" for="collegialBodyGoverning">
            Правление</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="collegialBodyOther"
                 v-model="newUtpPaySetting.collegialBodyOther">
          <label class="form-check-label" for="collegialBodyOther">
            Иное</label>
        </div>
        <div v-if="newUtpPaySetting.collegialBodyOther" class="pt-2">
          <label class="form-label required-label" for="collegialBodyOtherDescription">Иной коллегиальный
            орган:</label>
          <input-utp1 id="collegialBodyOtherDescription"
                      v-model:model-value="newUtpPaySetting.collegialBodyOtherDescription"
                      placeholder="Укажите иной коллегиальный орган" type="text"
                      :required="newUtpPaySetting.entity && newUtpPaySetting.collegialBodyOther" minlength="1"
                      maxlength="255" autocomplete="off"/>
        </div>
        <div class="pt-2">
          <label class="form-label required-label">
            Единоличный исполнительный орган:</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" id="soleExecutiveBodyDirector"
                 name="soleExecutiveBodyDirector" :value="true"
                 v-model="newUtpPaySetting.soleExecutiveBodyDirector">
          <label class="form-check-label" for="soleExecutiveBodyDirector">
            Генеральный директор / Директор</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" id="soleExecutiveBodyOther"
                 name="soleExecutiveBodyDirector" :value="false"
                 v-model="newUtpPaySetting.soleExecutiveBodyDirector">
          <label class="form-check-label" for="soleExecutiveBodyOther">
            Иное</label>
        </div>
        <div v-if="!newUtpPaySetting.soleExecutiveBodyDirector" class="pt-2">
          <label class="form-label required-label" for="soleExecutiveBodyOtherDescription">Иной единоличный
            исполнительный орган:</label>
          <input-utp1 id="soleExecutiveBodyOtherDescription"
                      v-model:model-value="newUtpPaySetting.soleExecutiveBodyDescription"
                      placeholder="Укажите иной единоличный исполнительный орган" type="text"
                      :required="newUtpPaySetting.entity && !newUtpPaySetting.soleExecutiveBodyDirector"
                      minlength="1"
                      maxlength="255" autocomplete="off"/>
        </div>
        <div class="pt-2">
          <hr-header-text :text="'Сведения об учредителях (участниках)'"/>
        </div>
        <div v-for="item in newOwners" class="card card-body mt-2" :key="item">
          <div class="form-check">
            <input class="form-check-input" type="checkbox"
                   :id="'ownerParticipantGeneralMeeting' + newOwners.indexOf(item)"
                   v-model="item.participantGeneralMeeting" :required="!item.boardMember">
            <label class="form-check-label" :for="'ownerParticipantGeneralMeeting' + newOwners.indexOf(item)">
              Участник общего собрания</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" :id="'ownerBoardMember' + newOwners.indexOf(item)"
                   v-model="item.boardMember" :required="!item.participantGeneralMeeting">
            <label class="form-check-label" :for="'ownerBoardMember' + newOwners.indexOf(item)">
              Член правления</label>
          </div>
          <div class="pt-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'ownerEntity' + newOwners.indexOf(item)"
                     :id="'ownerEntityTrue' + newOwners.indexOf(item)" :value="true" v-model="item.entity">
              <label class="form-check-label" :for="'ownerEntityTrue' + newOwners.indexOf(item)">Юридическое
                лицо</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'ownerEntity' + newOwners.indexOf(item)"
                     :id="'ownerEntityFalse' + newOwners.indexOf(item)" :value="false" v-model="item.entity">
              <label class="form-check-label" :for="'ownerEntityFalse' + newOwners.indexOf(item)">Физическое
                лицо</label>
            </div>
          </div>
          <div class="pt-2" v-if="item.entity">
            <label class="form-label required-label" :for="'ownerNameEntity' + newOwners.indexOf(item)">
              Полное наименование организации(с указанием организационно - правовой формы):</label>
            <input-utp1 :id="'ownerNameEntity' + newOwners.indexOf(item)" v-model:model-value="item.name"
                        placeholder="Введите полное наименование организации" type="text" :required="item.entity"
                        minlength="1" maxlength="255" autocomplete="off"/>
          </div>
          <div v-else>
            <div class="pt-2">
              <label class="form-label required-label" :for="'ownerSurname' + newOwners.indexOf(item)">
                Фамилия:</label>
              <input-utp1 :id="'ownerSurname' + newOwners.indexOf(item)" v-model:model-value="item.surname"
                          placeholder="Введите Фамилию" type="text" :required="!item.entity" minlength="1"
                          maxlength="50"
                          autocomplete="off"/>
            </div>
            <div class="row-form">
              <div class="row">
                <div class="col-sm-6 col-12 pt-3">
                  <label class="form-label required-label" :for="'ownerName' + newOwners.indexOf(item)">
                    Имя:</label>
                  <input-utp1 :id="'ownerName' + newOwners.indexOf(item)" v-model:model-value="item.name"
                              placeholder="Введите Имя" type="text" :required="!item.entity" minlength="1"
                              maxlength="30"
                              autocomplete="off"/>
                </div>
                <div class="col-sm-6 col-12 pt-3">
                  <label class="form-label" :for="'ownerPatronymic' + newOwners.indexOf(item)">
                    Отчество (при наличии):</label>
                  <input-utp1 :id="'ownerPatronymic' + newOwners.indexOf(item)"
                              v-model:model-value="item.patronymic" placeholder="Введите Отчество" type="text"
                              minlength="1"
                              maxlength="30" autocomplete="off"/>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-3">
            <label class="form-label required-label" :for="'ownerAddress' + newOwners.indexOf(item)">
              {{ item.entity ? 'Юридический адрес' : 'Адрес регистрации' }}
            </label>
            <input-utp1 :id="'ownerAddress' + newOwners.indexOf(item)" v-model:model-value="item.address"
                        placeholder="Введите адрес" type="text" required minlength="1" maxlength="255"
                        autocomplete="off"/>
          </div>
          <div class="row-form">
            <div class="row">
              <div class="col-sm-6 col-12 pt-3">
                <label class="form-label" :for="'ownerInn' + newOwners.indexOf(item)">
                  ИНН:</label>
                <input-utp1 :id="'ownerInn' + newOwners.indexOf(item)" v-model:model-value="item.inn"
                            placeholder="Введите ИНН" type="text" minlength="1" maxlength="30"
                            autocomplete="off"/>
              </div>
              <div class="col-sm-6 col-12 pt-3">
                <label class="form-label required-label" :for="'ownerShare' + newOwners.indexOf(item)">
                  Размер доли:</label>
                <input-utp1 :id="'ownerShare' + newOwners.indexOf(item)" v-model:model-value="item.share"
                            placeholder="Введите размер доли" type="number" min="5.01" max="100" step="0.01"
                            autocomplete="off" required/>
              </div>
            </div>
          </div>
          <div class="pt-3">
            <button-danger-utp1 @buttonClick="deleteOwner(newOwners.indexOf(item))">
              Удалить учредителя (участника)
            </button-danger-utp1>
          </div>
        </div>
        <div class="pt-2">
          <button-primary-utp1 @buttonClick="addOwner">Добавить учредителя (участника)</button-primary-utp1>
        </div>
        <div class="row-form pt-sm-3 pt-2">
          <div class="row">
            <div class="col-xl-5 col-lg-6 col-md-5 col-sm-6 col-12">
              <label class="col-form-label" for="minorityShare">
                Доля миноритарных владельцев:</label>
            </div>
            <div class="col-xl-7 col-lg-6 col-md-7 col-sm-6 col-12">
              <input-utp1 id="minorityShare" v-model:model-value="newUtpPaySetting.minorityShare"
                          placeholder="Введите значение" type="number" min="0.01" max="100" step="0.01"
                          autocomplete="off"/>
            </div>
            <label for="minorityShare" class="form-text">
              Размер доли каждого миноритарного владельца не должен превышать 5%</label>
          </div>
        </div>
        <div class="pt-2">
          <hr-header-text :text="'Сведения о бенефициарных владельцах'"/>
          <label class="form-text mt-0 pb-2">Бенефициарным владельцем является лицо,
            прямо или косвенно владеющее долей более 25%</label>
        </div>
        <div v-for="item in newBeneficiaries" class="card card-body mt-2" :key="item">
          <div class="form-check">
            <input class="form-check-input" type="checkbox"
                   :id="'beneficiarySupervisor' + newBeneficiaries.indexOf(item)" v-model="item.supervisor">
            <label class="form-check-label" :for="'beneficiarySupervisor' + newBeneficiaries.indexOf(item)">
              Руководитель предприятия</label>
          </div>
          <div class="pt-2">
            <label class="form-label required-label" :for="'beneficiarySurname' + newBeneficiaries.indexOf(item)">
              Фамилия:</label>
            <input-utp1 :id="'beneficiarySurname' + newBeneficiaries.indexOf(item)"
                        v-model:model-value="item.surname" placeholder="Введите Фамилию" type="text" required
                        minlength="1"
                        maxlength="50" autocomplete="off"/>
          </div>
          <div class="row-form">
            <div class="row">
              <div class="col-sm-6 col-12 pt-3">
                <label class="form-label required-label"
                       :for="'beneficiaryName' + newBeneficiaries.indexOf(item)">
                  Имя:</label>
                <input-utp1 :id="'beneficiaryName' + newBeneficiaries.indexOf(item)"
                            v-model:model-value="item.name" placeholder="Введите Имя" type="text" required
                            minlength="1"
                            maxlength="30" autocomplete="off"/>
              </div>
              <div class="col-sm-6 col-12 pt-3">
                <label class="form-label" :for="'beneficiaryPatronymic' + newBeneficiaries.indexOf(item)">
                  Отчество (при наличии):</label>
                <input-utp1 :id="'beneficiaryPatronymic' + newBeneficiaries.indexOf(item)"
                            v-model:model-value="item.patronymic" placeholder="Введите Отчество" type="text"
                            minlength="1"
                            maxlength="30" autocomplete="off"/>
              </div>
            </div>
          </div>
          <div class="row-form pt-2 pt-sm-3">
            <div class="row">
              <div class="col-sm-4 col-12">
                <label :for="'beneficiaryBirthday' + newBeneficiaries.indexOf(item)"
                       class="col-form-label required-label">
                  Дата рождения:</label>
              </div>
              <div class="col-sm-8 col-12">
                <input-utp1 :id="'beneficiaryBirthday' + newBeneficiaries.indexOf(item)"
                            v-model:model-value="item.birthday" placeholder="Введите дату рождения" type="date"
                            autocomplete="off" require/>
              </div>
            </div>
          </div>
          <div class="row-form pt-2 pt-sm-3">
            <div class="row">
              <div class="col-sm-4 col-12">
                <label :for="'beneficiaryPlaceBirth' + newBeneficiaries.indexOf(item)"
                       class="col-form-label required-label">
                  Место рождения:</label>
              </div>
              <div class="col-sm-8 col-12">
                <input-utp1 :id="'beneficiaryPlaceBirth' + newBeneficiaries.indexOf(item)"
                            v-model:model-value="item.placeBirth" placeholder="Введите место рождения" type="text"
                            minlength="1" maxlength="255" autocomplete="off" required/>
              </div>
            </div>
          </div>
          <div class="pt-3">
            <label :for="'beneficiaryActualAddress' + newBeneficiaries.indexOf(item)"
                   class="form-label required-label">
              Адрес места жительства:</label>
            <input-utp1 :id="'beneficiaryActualAddress' + newBeneficiaries.indexOf(item)"
                        v-model:model-value="item.actualAddress" placeholder="Введите адрес места жительства"
                        type="text"
                        minlength="1" maxlength="255" autocomplete="off" required/>
          </div>
          <div class="form-check form-switch pt-3">
            <input class="form-check-input" type="checkbox" role="switch"
                   :id="'beneficiaryResident' + newBeneficiaries.indexOf(item)" v-model="item.resident">
            <label class="form-check-label" :for="'beneficiaryResident' + newBeneficiaries.indexOf(item)">
              {{
                item.resident ? 'Является налоговым резидентом Российской Федерации' :
                    'Не является налоговым резидентом Российской Федерации'
              }}</label>
          </div>
          <div class="row-form pt-2 pt-sm-3">
            <div class="row">
              <div class="col-xl-7 col-lg-8 col-md-7 col-sm-9 col-12">
                <label :for="'beneficiaryDocument' + newBeneficiaries.indexOf(item)"
                       class="col-form-label required-label">Вид документа, удостоверяющего личность:</label>
              </div>
              <div class="col-xl-5 col-lg-4 col-md-5 col-sm-3 col-12">
                <select class="form-select" :id="'beneficiaryDocument' + newBeneficiaries.indexOf(item)"
                        aria-label="Select beneficiary document" v-model="item.document" required>
                  <option v-for="item in utpPayBeneficiaryDocument" :value="item.value" :key="item">{{
                      item.name
                    }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="item.document === 'OTHER_DOCUMENT'" class="pt-3">
            <label class="form-label required-label"
                   :for="'beneficiaryDocumentDescription' + newBeneficiaries.indexOf(item)">
              Свой вариант документа:</label>
            <input-utp1 :id="'beneficiaryDocumentDescription' + newBeneficiaries.indexOf(item)"
                        v-model:model-value="item.documentDescription" placeholder="Укажите документ" type="text"
                        :required="item.document === 'OTHER_DOCUMENT'" minlength="1" maxlength="255"
                        autocomplete="off"/>
          </div>
          <div class="row-form">
            <div class="row">
              <div class="col-6 pt-3">
                <label :for="'beneficiaryDocumentSeries' + newBeneficiaries.indexOf(item)"
                       class="form-label d-block text-truncate"
                       :class="item.document === 'RUSSIAN_PASSPORT' ? 'required-label' : ''">
                  Серия:</label>
                <input-utp1 :id="'beneficiaryDocumentSeries' + newBeneficiaries.indexOf(item)"
                            v-model:model-value="item.documentSeries" placeholder="Введите серию документа"
                            type="text"
                            minlength="1" maxlength="10" autocomplete="off"
                            :required="item.document === 'RUSSIAN_PASSPORT'"/>
              </div>
              <div class="col-6 pt-3">
                <label :for="'beneficiaryDocumentNumber' + newBeneficiaries.indexOf(item)"
                       class="form-label required-label d-block text-truncate">
                  Номер:</label>
                <input-utp1 :id="'beneficiaryDocumentNumber' + newBeneficiaries.indexOf(item)"
                            v-model:model-value="item.documentNumber" placeholder="Введите номер документа"
                            type="text"
                            minlength="1" maxlength="30" autocomplete="off" required/>
              </div>
            </div>
          </div>
          <div class="row-form">
            <div class="row">
              <div class="col-12 col-sm-6 pt-3">
                <label :for="'beneficiaryDocumentIssuedBy' + newBeneficiaries.indexOf(item)"
                       class="form-label required-label">
                  Документ выдан:</label>
                <!--suppress JSUnresolvedReference -->
                <input-utp1 :id="'beneficiaryDocumentIssuedBy' + newBeneficiaries.indexOf(item)"
                            v-model:model-value="item.documentIssuedBy" placeholder="Введите кем выдан документ"
                            type="text"
                            minlength="1" maxlength="255" autocomplete="off" required/>
              </div>
              <div class="col-6 col-sm-3 pt-3">
                <label :for="'beneficiaryDocumentDate' + newBeneficiaries.indexOf(item)"
                       class="form-label required-label d-block text-truncate">
                  Дата выдачи:</label>
                <input-utp1 :id="'beneficiaryDocumentDate' + newBeneficiaries.indexOf(item)"
                            v-model:model-value="item.documentDate" placeholder="Введите дату выдачи" type="date"
                            autocomplete="off" require/>
              </div>
              <div class="col-6 col-sm-3 pt-3">
                <label :for="'beneficiaryDocumentIssuedCode' + newBeneficiaries.indexOf(item)"
                       class="form-label d-block text-truncate"
                       :class="item.document === 'RUSSIAN_PASSPORT' ? 'required-label' : ''">
                  Код под-ния:</label>
                <input-utp1 :id="'beneficiaryDocumentIssuedCode' + newBeneficiaries.indexOf(item)"
                            v-model:model-value="item.documentIssuedCode" placeholder="Введите код подразделения"
                            type="text" minlength="1" maxlength="10" autocomplete="off"
                            :required="item.document === 'RUSSIAN_PASSPORT'"/>
              </div>
            </div>
          </div>
          <div class="row-form pt-2 pt-sm-3">
            <div class="row">
              <div class="col-sm-4 col-12">
                <label :for="'ownerShare' + newOwners.indexOf(item)" class="col-form-label required-label">Размер
                  доли:</label>
              </div>
              <div class="col-sm-8 col-12">
                <input-utp1 :id="'ownerShare' + newOwners.indexOf(item)" v-model:model-value="item.share"
                            placeholder="Введите размер доли" type="number" min="25.01" max="100" step="0.01"
                            autocomplete="off" required/>
              </div>
            </div>
          </div>
          <div class="row-form">
            <div class="row">
              <div class="col-6 pt-3">
                <label :for="'beneficiaryInn' + newBeneficiaries.indexOf(item)" class="form-label"
                       :class="item.resident ? 'required-label' : ''">ИНН:</label>
                <input-utp1 :id="'beneficiaryInn' + newBeneficiaries.indexOf(item)" v-model:model-value="item.inn"
                            placeholder="Введите ИНН" type="text" minlength="1" maxlength="30" autocomplete="off"
                            :required="item.resident"/>
              </div>
              <div class="col-6 pt-3">
                <label :for="'beneficiarySnils' + newBeneficiaries.indexOf(item)" class="form-label"
                       :class="item.resident ? 'required-label' : ''">СНИЛС:</label>
                <input-utp1 :id="'beneficiarySnils' + newBeneficiaries.indexOf(item)"
                            v-model:model-value="item.snils" placeholder="Введите СНИЛС" type="text" minlength="1"
                            maxlength="30" autocomplete="off" :required="item.resident"/>
              </div>
            </div>
          </div>
          <div class="pt-3">
            <label :for="'beneficiaryPhones' + newBeneficiaries.indexOf(item)" class="form-label required-label">
              Номера контактных телефонов и факсов:</label>
            <input-utp1 :id="'beneficiaryPhones' + newBeneficiaries.indexOf(item)"
                        v-model:model-value="item.phones" placeholder="Введите контактные номера" type="text"
                        minlength="9"
                        maxlength="255" autocomplete="off" required/>
          </div>
          <div class="form-check form-switch pt-3">
            <input class="form-check-input" type="checkbox" role="switch"
                   :id="'beneficiaryIpdl' + newBeneficiaries.indexOf(item)" v-model="item.ipdl">
            <label class="form-check-label" :for="'beneficiaryIpdl' + newBeneficiaries.indexOf(item)">
              {{
                item.ipdl ? 'Есть сведения о принадлежности к иностранному публичному должностному лицу (ИПДЛ)' :
                    'Нет сведений о принадлежности к иностранному публичному должностному лицу (ИПДЛ)'
              }}</label>
          </div>
          <div class="form-check form-switch pt-3">
            <input class="form-check-input" type="checkbox" role="switch"
                   :id="'beneficiaryMpdl' + newBeneficiaries.indexOf(item)" v-model="item.mpdl">
            <label class="form-check-label" :for="'beneficiaryMpdl' + newBeneficiaries.indexOf(item)">
              {{
                item.mpdl ? 'Есть сведения о принадлежности к должностным лицам публичных международных организаций (МПДЛ)' :
                    'Нет сведений о принадлежности к должностным лицам публичных международных организаций (МПДЛ)'
              }}</label>
          </div>
          <div class="pt-3">
            <button-danger-utp1 @buttonClick="deleteBeneficiaries(newBeneficiaries.indexOf(item))">
              Удалить бенефициарного владельца
            </button-danger-utp1>
          </div>
        </div>
        <div class="pt-2">
          <button-primary-utp1 @buttonClick="addBeneficiaries">Добавить бенефициарного владельца
          </button-primary-utp1>
        </div>
      </div>
    </form>
    <div class="tab-pane" id="documentationCompany">
      <div class="row-form">
        <div class="row">
          <form id="group-passport-main-image" @submit.prevent novalidate class="col-12 no-validated">
            <div class="card p-3 mb-3">
              <div class="mb-3">
                <label for="formFilePassportMain" class="form-label required-label">
                  Основной разворот паспорта руководителя:</label>
                <div v-if="paymentsDocumentsMini.base64PassportMain" class="mb-3">
                  <img :src="'data:image;base64,' + paymentsDocumentsMini.base64PassportMain"
                       style="max-width: 170px; max-height: 85px" alt="PassportMain" class="cursor-pointer"
                       @click="getPassportMain">
                </div>
                <!--suppress JSUnresolvedReference -->
                <input class="form-control" type="file" id="formFilePassportMain" ref="newPassportMainImage"
                       @change="passportMainUpload" accept="image/*" required>
                <label class="form-text" for="formFilePassportMain">
                  Рекомендуемая ширина или высота (что меньше) 1 106px, формат JPG. Отношение сторон не более чем
                  1:2.
                </label>
              </div>
              <button-primary-utp1 :loader="imagesLoader" :disabled="!passportMainImage"
                                   @buttonClick="addPassportMain">Загрузить
              </button-primary-utp1>
            </div>
          </form>
        </div>
      </div>
      <div class="row-form">
        <div class="row">
          <form id="group-passport-registration-image" @submit.prevent novalidate class="col-12 no-validated">
            <div class="card p-3 mb-3">
              <div class="mb-3">
                <label for="formFilePassportRegistration" class="form-label required-label">
                  Разворот паспорта руководителя с актуальной регистрацией:</label>
                <div v-if="paymentsDocumentsMini.base64PassportRegistration" class="mb-3">
                  <img :src="'data:image;base64,' + paymentsDocumentsMini.base64PassportRegistration"
                       style="max-width: 170px; max-height: 85px" alt="PassportRegistration"
                       class="cursor-pointer"
                       @click="getPassportRegistration">
                </div>
                <!--suppress JSUnresolvedReference -->
                <input class="form-control" type="file" id="formFilePassportRegistration"
                       ref="newPassportRegistrationImage" @change="passportRegistrationUpload" accept="image/*"
                       required>
                <label class="form-text" for="formFilePassportRegistration">
                  Рекомендуемая ширина или высота (что меньше) 1 106px, формат JPG. Отношение сторон не более чем
                  1:2.
                </label>
              </div>
              <button-primary-utp1 :loader="imagesLoader" :disabled="!passportRegistrationImage"
                                   @buttonClick="addPassportRegistration">Загрузить
              </button-primary-utp1>
            </div>
          </form>
        </div>
      </div>
      <div class="row-form">
        <div class="row">
          <form id="group-inn-image" @submit.prevent novalidate class="col-12 no-validated">
            <div class="card p-3 mb-3">
              <div class="mb-3">
                <label for="formFileInn" class="form-label"
                       :class="newUtpPaySetting.resident ? 'required-label' : ''">
                  Свидетельство ИНН:</label>
                <div v-if="paymentsDocumentsMini.base64Inn" class="mb-3">
                  <img :src="'data:image;base64,' + paymentsDocumentsMini.base64Inn"
                       style="max-width: 170px; max-height: 85px" alt="Inn" class="cursor-pointer"
                       @click="getInn">
                </div>
                <!--suppress JSUnresolvedReference -->
                <input class="form-control" type="file" id="formFileInn" ref="newInnImage" @change="innUpload"
                       accept="image/*" required>
                <label class="form-text" for="formFileInn">
                  Рекомендуемая ширина или высота (что меньше) 1 106px, формат JPG. Отношение сторон не более чем
                  1:2.
                </label>
              </div>
              <button-primary-utp1 :loader="imagesLoader" :disabled="!innImage" @buttonClick="addInn">Загрузить
              </button-primary-utp1>
            </div>
          </form>
        </div>
      </div>
      <div class="row-form">
        <div class="row">
          <form id="group-grn-image" @submit.prevent novalidate class="col-12 no-validated">
            <div class="card p-3 mb-3">
              <div class="mb-3">
                <label for="formFileGrn" class="form-label"
                       :class="['IE', 'LCC', 'JSC', 'PJSC'].includes(newUtpPaySetting.legalForm) && newUtpPaySetting.resident ? 'required-label' : ''">
                  Свидетельство ГРН:</label>
                <div v-if="paymentsDocumentsMini.base64Grn" class="mb-3">
                  <img :src="'data:image;base64,' + paymentsDocumentsMini.base64Grn"
                       style="max-width: 170px; max-height: 85px" alt="Grn" class="cursor-pointer"
                       @click="getGrn">
                </div>
                <!--suppress JSUnresolvedReference -->
                <input class="form-control" type="file" id="formFileGrn" ref="newGrnImage" @change="grnUpload"
                       accept="image/*" required>
                <label class="form-text" for="formFileGrn">
                  Рекомендуемая ширина или высота (что меньше) 1 106px, формат JPG. Отношение сторон не более чем
                  1:2.
                </label>
              </div>
              <button-primary-utp1 :loader="imagesLoader" :disabled="!grnImage" @buttonClick="addGrn">Загрузить
              </button-primary-utp1>
            </div>
          </form>
        </div>
      </div>
      <div class="row-form">
        <div class="row">
          <form id="group-accession-agreement-image" @submit.prevent novalidate class="col-12 no-validated">
            <div class="card p-3 mb-3">
              <div class="mb-3">
                <label for="formFileAccessionAgreement" class="form-label required-label mb-1">
                  Соглашение о присоединении:</label>
                <label class="small text-secondary">
                  Скачайте и заполните (текст рекомендуется заполнять от руки)
                  <a class="link-primary text-decoration-none" @click="openAccessionAgreement">форму</a>
                  с сайта (<a class="link-primary text-decoration-none" @click="openAccessionAgreement">accession_agreement.pdf</a>):
                  <ul class="mb-2">
                    <li>номер соглашения: <span class="fw-bolder fst-italic">{{ id }}</span>;</li>
                    <li>текущая дата в формате <span class="fst-italic">ДД «месяца» ГГГГ г.</span>;</li>
                    <li>полное наименование с указанием организационно-правовой формы;</li>
                    <li>должность;</li>
                    <li>фамилия имя отчество (при наличии), полностью, без сокращений;</li>
                    <li>основание полномочий, например:</li>
                    <ul>
                      <li><span class="fst-italic">устав</span>;</li>
                      <li><span class="fst-italic">свидетельство о регистрации в качестве ИП</span>;</li>
                      <li><span class="fst-italic">уведомление налоговорго органа</span>;</li>
                      <li>и т.д.</li>
                    </ul>
                    <li>подпись;</li>
                    <li>печать (при её наличии, рядом с подписью, не закрывая её).</li>
                  </ul>
                </label>
                <div v-if="paymentsDocumentsMini.base64AccessionAgreement" class="mb-3">
                  <img :src="'data:image;base64,' + paymentsDocumentsMini.base64AccessionAgreement"
                       style="max-width: 170px; max-height: 85px" alt="AccessionAgreement" class="cursor-pointer"
                       @click="getAccessionAgreement">
                </div>
                <!--suppress JSUnresolvedReference -->
                <input class="form-control" type="file" id="formFileAccessionAgreement"
                       ref="newAccessionAgreementImage" @change="accessionAgreementUpload" accept="image/*"
                       required>
                <label class="form-text" for="formFileAccessionAgreement">
                  Рекомендуемая ширина или высота (что меньше) 1 106px, формат JPG. Отношение сторон не более чем
                  1:2.
                </label>
              </div>
              <button-primary-utp1 :loader="imagesLoader" :disabled="!accessionAgreementImage"
                                   @buttonClick="addAccessionAgreement">Загрузить
              </button-primary-utp1>
            </div>
          </form>
        </div>
      </div>
      <div class="row-form">
        <div class="row">
          <form id="group-photo-image" @submit.prevent novalidate class="col-12 no-validated">
            <div class="card p-3 mb-3">
              <div class="mb-3">
                <label for="formFilePhoto" class="form-label required-label mb-1">
                  Фотография руководителя с пасспортом и соглашением о присоединении:</label>
                <label class="small text-secondary mb-2" for="formFilePhoto">
                  Обязательно должно быть видно лицо, так же в одной руке необходимо держать пасспорт, отрытый на
                  главной странице, не закрывая основных данных, в другой подписанное соглашение о присоединении.
                </label>
                <div v-if="paymentsDocumentsMini.base64Photo" class="mb-3">
                  <img :src="'data:image;base64,' + paymentsDocumentsMini.base64Photo"
                       style="max-width: 170px; max-height: 85px" alt="Photo" class="cursor-pointer"
                       @click="getPhoto">
                </div>
                <!--suppress JSUnresolvedReference -->
                <input class="form-control" type="file" id="formFilePhoto" ref="newPhotoImage"
                       @change="photoUpload" accept="image/*" required>
                <label class="form-text" for="formFilePhoto">
                  Рекомендуемая ширина или высота (что меньше) 1 106px, формат JPG. Отношение сторон не более чем
                  1:2.
                </label>
              </div>
              <button-primary-utp1 :loader="imagesLoader" :disabled="!photoImage"
                                   @buttonClick="addPhoto">Загрузить
              </button-primary-utp1>
            </div>
          </form>
        </div>
      </div>
      <button-primary-utp1
          :disabled="newUtpPaySetting.status !== 'DISABLED' ? true :
                ((paymentsDocumentsMini.base64PassportMain === null) ||
                (paymentsDocumentsMini.base64PassportRegistration === null) ||
                (paymentsDocumentsMini.base64AccessionAgreement === null) ||
                (paymentsDocumentsMini.base64Photo === null)) ? true :
                !newUtpPaySetting.resident ? false :
                ['IE', 'LCC', 'JSC', 'PJSC'].includes(newUtpPaySetting.legalForm) ?
                ((paymentsDocumentsMini.base64Inn === null) ||
                (paymentsDocumentsMini.base64Grn === null)) :
                paymentsDocumentsMini.base64Inn === null"
          @buttonClick="initsModeration">Отправить данные на модерацию
      </button-primary-utp1>
      <label class="form-text">
        Отправить данные на модерацию можно только предварительно их сохранив и получив соотвествующий статус.
        Так же обязательно загрузите все документы, отмечанные <span class="text-danger">*</span>, иначе кнопка
        будет не активна.
      </label>
    </div>
  </div>
  <div class="pt-3">
    <double-button-utp1 :buttons="buttons" @buttonPrimaryClick="buttonPrimaryClick"
                        @buttonSecondaryClick="buttonSecondaryClick"/>
  </div>
  <div class="modal fade" id="updateUtpPaySettingsModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-warning">
          <h5 class="modal-title">Внести изменения?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          После сохранения новых данных приём платежей через
          UTP Pay будет заблокирован до прохождения модерации.
        </div>
        <div class="modal-footer">
          <button-secondary-utp1 class="col col-sm-auto" :disabled="false" data-bs-dismiss="modal">Отменить
          </button-secondary-utp1>
          <button-primary-utp1 class="col col-sm-auto" data-bs-dismiss="modal"
                               @buttonClick="updateUtpPaySetting">Подтвердить
          </button-primary-utp1>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="paymentDocumentModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ paymentDocumentModalTitle }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <div v-if="paymentDocument.base64Image" class="mb-3">
            <img :src="'data:image;base64,' + paymentDocument.base64Image"
                 style="width: 100%; max-width: 1106px; max-height: 2212px" alt="PaymentDocument">
          </div>
          <p v-if="paymentDocument.dateTime">
            Дата и время загрузки документа: {{ formattedDateTimeTz(paymentDocument.dateTime) }}
          </p>
          <p v-if="paymentDocument.check" class="text-success">
            Документ прошел модерацию и принят
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<!--suppress JSValidateTypes, JSUnresolvedReference -->
<script>
import {onMounted, ref, watch} from "vue";
import AlertDanger from "@/components/UI/alerts/AlertDanger.vue";
import AlertPrimary from "@/components/UI/alerts/AlertPrimary.vue";
import HeaderNavUtp1 from "@/components/UI/headers/HeaderNavUtp1.vue";
import AlertSuccess from "@/components/UI/alerts/AlertSuccess.vue";
import InputUtp1 from "@/components/UI/inputs/InputUtp1.vue";
import HrHeaderText from "@/components/UI/universal/HrHeaderText.vue";
import ButtonPrimaryUtp1 from "@/components/UI/buttons/ButtonPrimaryUtp1.vue";
import AlertWarning from "@/components/UI/alerts/AlertWarning.vue";
import AlertInfo from "@/components/UI/alerts/AlertInfo.vue";
import DoubleButtonUtp1 from "@/components/UI/buttons/DoubleButtonUtp1.vue";
import ButtonDangerUtp1 from "@/components/UI/buttons/ButtonDangerUtp1.vue";
import AlertSecondary from "@/components/UI/alerts/AlertSecondary.vue";
import useDeepCopy from "@/hooks/functions/useDeepCopy";
import useShopSettings from "@/hooks/rest/shop/useShopSettings";
import useImages from "@/hooks/rest/files/useImages";
import useMoment from "@/hooks/useMoment";
import useOnBeforeUnload from "@/hooks/functions/useOnBeforeUpload";
import ButtonSecondaryUtp1 from "@/components/UI/buttons/ButtonSecondaryUtp1.vue";
import PreloaderMain from "@/components/system/preloader/PreloaderMain.vue";

export default {
  name: "payments-settings",
  components: {
    PreloaderMain,
    ButtonSecondaryUtp1,
    AlertSecondary,
    ButtonDangerUtp1,
    DoubleButtonUtp1,
    AlertInfo,
    AlertWarning,
    ButtonPrimaryUtp1,
    HrHeaderText,
    InputUtp1,
    AlertSuccess,
    HeaderNavUtp1,
    AlertPrimary,
    AlertDanger
  },
  props: {
    id: '',
    initNavUtpPay: {
      type: Boolean
    }
  },
  watch: {
    initNavUtpPay() {
      if (this.initNavUtpPay) {
        this.clickUtpPayTab()
      }
    }
  },
  setup(props) {
    const utpPayNavEntity = [{
      value: "aboutCompany",
      name: "О компании",
      active: true,
      disabled: false
    }, {
      value: "activityCompany",
      name: "Деятельность",
      active: false,
      disabled: false
    }, {
      value: "detailsCompany",
      name: "Реквизиты",
      active: false,
      disabled: false
    }, {
      value: "contactsCompany",
      name: "Контакты",
      active: false,
      disabled: false
    }, {
      value: "directorCompany",
      name: "Руководитель",
      active: false,
      disabled: false
    }, {
      value: "ownersCompany",
      name: "Владельцы",
      active: false,
      disabled: false
    }, {
      value: "documentationCompany",
      name: "Документация",
      active: false,
      disabled: false
    }]

    const utpPayNav = [{
      value: "aboutCompany",
      name: "О компании",
      active: true,
      disabled: false
    }, {
      value: "activityCompany",
      name: "Деятельность",
      active: false,
      disabled: false
    }, {
      value: "detailsCompany",
      name: "Реквизиты",
      active: false,
      disabled: false
    }, {
      value: "contactsCompany",
      name: "Контакты",
      active: false,
      disabled: false
    }, {
      value: "directorCompany",
      name: "Руководитель",
      active: false,
      disabled: false
    }, {
      value: "documentationCompany",
      name: "Документация",
      active: false,
      disabled: false
    }]

    const buttons = {
      primaryTitle: "Сохранить",
      secondaryTitle: "Отменить"
    }

    const newUtpPaySetting = ref({
      status: null,
      moderation: false,
      comment: null,
      entity: false,
      legalForm: "IE",
      legalFormDescription: null,
      inn: null,
      name: "",
      resident: true,
      grn: null,
      create: null,
      vat: "WITHOUT_VAT",
      vatRete: null,
      vatJustification: "NOTICE",
      vatJustificationDescription: null,
      okato: null,
      oktmo: null,
      okpo: null,
      taxOfficeName: null,
      authorizedCapital: null,
      shopName: "",
      mainActivity: "",
      description: "",
      businessInformation: "SERVICES",
      businessInformationDescription: null,
      volumeSales: 1,
      volumeSalesCurrency: "RUB",
      servicesContract: false,
      salesContract: false,
      agencyContract: false,
      trainingContract: false,
      deliveryContract: false,
      anotherContract: false,
      anotherContractDescription: null,
      accountNumber: "",
      bankName: "",
      bankBic: "",
      corrAccount: "",
      bankInn: "",
      bankKpp: "",
      swift: null,
      iban: null,
      preferentialBank: false,
      additionalDetails: null,
      legalPostcode: "",
      legalCountry: "",
      legalRegion: null,
      legalCity: "",
      legalStreet: "",
      legalHome: "",
      legalBuilding: null,
      legalOffice: null,
      actualEqLegal: true,
      actualPostcode: null,
      actualCountry: null,
      actualRegion: null,
      actualCity: null,
      actualStreet: null,
      actualHome: null,
      actualBuilding: null,
      actualOffice: null,
      corrEqLegal: true,
      corrEqActual: false,
      corrPostcode: null,
      corrCountry: null,
      corrRegion: null,
      corrCity: null,
      corrStreet: null,
      corrHome: null,
      corrBuilding: null,
      corrOffice: null,
      contactName: "",
      contactPosition: "",
      contactPhone: "",
      contactEmail: "",
      ceoName: "",
      ceoBirthdate: "",
      ceoPosition: "",
      ceoResident: true,
      ceoDocument: "",
      ceoDocumentSeries: null,
      ceoDocumentNumber: "",
      ceoDocumentDate: "",
      ceoDocumentIssuedBy: "",
      ceoDocumentIssuedCode: null,
      ceoRegistrationAddress: "",
      ceoEmail: "",
      ceoPhone: "",
      collegialBodyGeneralMeeting: false,
      collegialBodyBoardDirectors: false,
      collegialBodyGoverning: false,
      collegialBodyOther: false,
      collegialBodyOtherDescription: null,
      soleExecutiveBodyDirector: true,
      soleExecutiveBodyDescription: null,
      minorityShare: null
    })

    const {deepCopyObject, deepCopyArray} = useDeepCopy()

    const reset = () => {
      newUtpPaySetting.value.status = utpPayFull.value.settings.status
      newUtpPaySetting.value.moderation = utpPayFull.value.settings.moderation
      newUtpPaySetting.value.comment = utpPayFull.value.settings.comment
      newUtpPaySetting.value.entity = utpPayFull.value.settings.entity
      newUtpPaySetting.value.legalForm = utpPayFull.value.settings.legalForm
      newUtpPaySetting.value.legalFormDescription = utpPayFull.value.settings.legalFormDescription
      newUtpPaySetting.value.inn = utpPayFull.value.settings.inn
      newUtpPaySetting.value.name = utpPayFull.value.settings.name
      newUtpPaySetting.value.resident = utpPayFull.value.settings.resident
      newUtpPaySetting.value.grn = utpPayFull.value.settings.grn
      newUtpPaySetting.value.create = utpPayFull.value.settings.create ?
          stringToDate(utpPayFull.value.settings.create) : null
      newUtpPaySetting.value.vat = utpPayFull.value.settings.vat
      newUtpPaySetting.value.vatRete = utpPayFull.value.settings.vatRete
      newUtpPaySetting.value.vatJustification = utpPayFull.value.settings.vatJustification
      newUtpPaySetting.value.vatJustificationDescription = utpPayFull.value.settings.vatJustificationDescription
      newUtpPaySetting.value.okato = utpPayFull.value.settings.okato
      newUtpPaySetting.value.oktmo = utpPayFull.value.settings.oktmo
      newUtpPaySetting.value.okpo = utpPayFull.value.settings.okpo
      newUtpPaySetting.value.taxOfficeName = utpPayFull.value.settings.taxOfficeName
      newUtpPaySetting.value.authorizedCapital = utpPayFull.value.settings.authorizedCapital
      newUtpPaySetting.value.shopName = utpPayFull.value.settings.shopName
      newUtpPaySetting.value.mainActivity = utpPayFull.value.settings.mainActivity
      newUtpPaySetting.value.description = utpPayFull.value.settings.description
      newUtpPaySetting.value.businessInformation = utpPayFull.value.settings.businessInformation
      newUtpPaySetting.value.businessInformationDescription = utpPayFull.value.settings.businessInformationDescription
      newUtpPaySetting.value.volumeSales = utpPayFull.value.settings.volumeSales
      newUtpPaySetting.value.volumeSalesCurrency = utpPayFull.value.settings.volumeSalesCurrency
      newUtpPaySetting.value.servicesContract = utpPayFull.value.settings.servicesContract
      newUtpPaySetting.value.salesContract = utpPayFull.value.settings.salesContract
      newUtpPaySetting.value.agencyContract = utpPayFull.value.settings.agencyContract
      newUtpPaySetting.value.trainingContract = utpPayFull.value.settings.trainingContract
      newUtpPaySetting.value.deliveryContract = utpPayFull.value.settings.deliveryContract
      newUtpPaySetting.value.anotherContract = utpPayFull.value.settings.anotherContract
      newUtpPaySetting.value.anotherContractDescription = utpPayFull.value.settings.anotherContractDescription
      newUtpPaySetting.value.accountNumber = utpPayFull.value.settings.accountNumber
      newUtpPaySetting.value.bankName = utpPayFull.value.settings.bankName
      newUtpPaySetting.value.bankBic = utpPayFull.value.settings.bankBic
      newUtpPaySetting.value.corrAccount = utpPayFull.value.settings.corrAccount
      newUtpPaySetting.value.bankInn = utpPayFull.value.settings.bankInn
      newUtpPaySetting.value.bankKpp = utpPayFull.value.settings.bankKpp
      newUtpPaySetting.value.swift = utpPayFull.value.settings.swift
      newUtpPaySetting.value.iban = utpPayFull.value.settings.iban
      newUtpPaySetting.value.additionalDetails = utpPayFull.value.settings.additionalDetails
      newUtpPaySetting.value.preferentialBank = utpPayFull.value.settings.preferentialBank
      newUtpPaySetting.value.legalPostcode = utpPayFull.value.settings.legalPostcode
      newUtpPaySetting.value.legalCountry = utpPayFull.value.settings.legalCountry
      newUtpPaySetting.value.legalRegion = utpPayFull.value.settings.legalRegion
      newUtpPaySetting.value.legalCity = utpPayFull.value.settings.legalCity
      newUtpPaySetting.value.legalStreet = utpPayFull.value.settings.legalStreet
      newUtpPaySetting.value.legalHome = utpPayFull.value.settings.legalHome
      newUtpPaySetting.value.legalBuilding = utpPayFull.value.settings.legalBuilding
      newUtpPaySetting.value.legalOffice = utpPayFull.value.settings.legalOffice
      newUtpPaySetting.value.actualEqLegal = utpPayFull.value.settings.actualEqLegal
      newUtpPaySetting.value.actualPostcode = utpPayFull.value.settings.actualPostcode
      newUtpPaySetting.value.actualCountry = utpPayFull.value.settings.actualCountry
      newUtpPaySetting.value.actualRegion = utpPayFull.value.settings.actualRegion
      newUtpPaySetting.value.actualCity = utpPayFull.value.settings.actualCity
      newUtpPaySetting.value.actualStreet = utpPayFull.value.settings.actualStreet
      newUtpPaySetting.value.actualHome = utpPayFull.value.settings.actualHome
      newUtpPaySetting.value.actualBuilding = utpPayFull.value.settings.actualBuilding
      newUtpPaySetting.value.actualOffice = utpPayFull.value.settings.actualOffice
      newUtpPaySetting.value.corrEqLegal = utpPayFull.value.settings.corrEqLegal
      newUtpPaySetting.value.corrEqActual = utpPayFull.value.settings.actualEqLegal ?
          false : utpPayFull.value.settings.corrEqActual
      newUtpPaySetting.value.corrPostcode = utpPayFull.value.settings.corrPostcode
      newUtpPaySetting.value.corrCountry = utpPayFull.value.settings.corrCountry
      newUtpPaySetting.value.corrRegion = utpPayFull.value.settings.corrRegion
      newUtpPaySetting.value.corrCity = utpPayFull.value.settings.corrCity
      newUtpPaySetting.value.corrStreet = utpPayFull.value.settings.corrStreet
      newUtpPaySetting.value.corrHome = utpPayFull.value.settings.corrHome
      newUtpPaySetting.value.corrBuilding = utpPayFull.value.settings.corrBuilding
      newUtpPaySetting.value.corrOffice = utpPayFull.value.settings.corrOffice
      newUtpPaySetting.value.contactName = utpPayFull.value.settings.contactName
      newUtpPaySetting.value.contactPosition = utpPayFull.value.settings.contactPosition
      newUtpPaySetting.value.contactPhone = utpPayFull.value.settings.contactPhone
      newUtpPaySetting.value.contactEmail = utpPayFull.value.settings.contactEmail
      newUtpPaySetting.value.ceoName = utpPayFull.value.settings.ceoName
      newUtpPaySetting.value.ceoBirthdate = utpPayFull.value.settings.ceoBirthdate ?
          stringToDate(utpPayFull.value.settings.ceoBirthdate) : null
      newUtpPaySetting.value.ceoPosition = utpPayFull.value.settings.ceoPosition
      newUtpPaySetting.value.ceoResident = utpPayFull.value.settings.ceoResident
      newUtpPaySetting.value.ceoDocument = utpPayFull.value.settings.ceoDocument
      newUtpPaySetting.value.ceoDocumentSeries = utpPayFull.value.settings.ceoDocumentSeries
      newUtpPaySetting.value.ceoDocumentNumber = utpPayFull.value.settings.ceoDocumentNumber
      newUtpPaySetting.value.ceoDocumentIssuedBy = utpPayFull.value.settings.ceoDocumentIssuedBy
      newUtpPaySetting.value.ceoDocumentDate = utpPayFull.value.settings.ceoDocumentDate ?
          stringToDate(utpPayFull.value.settings.ceoDocumentDate) : null
      newUtpPaySetting.value.ceoDocumentIssuedCode = utpPayFull.value.settings.ceoDocumentIssuedCode
      newUtpPaySetting.value.ceoRegistrationAddress = utpPayFull.value.settings.ceoRegistrationAddress
      newUtpPaySetting.value.ceoEmail = utpPayFull.value.settings.ceoEmail
      newUtpPaySetting.value.ceoPhone = utpPayFull.value.settings.ceoPhone
      newUtpPaySetting.value.collegialBodyGeneralMeeting = utpPayFull.value.settings.collegialBodyGeneralMeeting
      newUtpPaySetting.value.collegialBodyBoardDirectors = utpPayFull.value.settings.collegialBodyBoardDirectors
      newUtpPaySetting.value.collegialBodyGoverning = utpPayFull.value.settings.collegialBodyGoverning
      newUtpPaySetting.value.collegialBodyOther = utpPayFull.value.settings.collegialBodyOther
      newUtpPaySetting.value.collegialBodyOtherDescription = utpPayFull.value.settings.collegialBodyOtherDescription
      newUtpPaySetting.value.soleExecutiveBodyDirector = utpPayFull.value.settings.soleExecutiveBodyDirector
      newUtpPaySetting.value.soleExecutiveBodyDescription = utpPayFull.value.settings.soleExecutiveBodyDescription
      newUtpPaySetting.value.minorityShare = utpPayFull.value.settings.minorityShare
      newOwners.value = deepCopyArray(utpPayFull.value.owners)
      newBeneficiaries.value = deepCopyArray(utpPayFull.value.beneficiaries)
      newBeneficiaries.value.forEach(item => {
        item.birthday = stringToDate(item.birthday)
        item.documentDate = stringToDate(item.documentDate)
      })
    }

    const canceled = async () => {
      await reset()
      unSetOnBeforeUnload()
    }

    const newOwners = ref([])

    const newBeneficiaries = ref([])

    const addOwner = () => {
      newOwners.value.push({
        participantGeneralMeeting: false,
        boardMember: false,
        entity: false,
        name: '',
        surname: null,
        patronymic: null,
        address: '',
        inn: null,
        share: 5.01
      })
    }

    const deleteOwner = (index) => {
      newOwners.value.splice(index, 1)
    }

    const addBeneficiaries = () => {
      newBeneficiaries.value.push({
        supervisor: false,
        surname: '',
        name: '',
        patronymic: null,
        birthday: null,
        placeBirth: '',
        actualAddress: '',
        resident: true,
        document: 'RUSSIAN_PASSPORT',
        documentDescription: null,
        documentSeries: null,
        documentNumber: '',
        documentDate: null,
        documentIssuedBy: '',
        documentIssuedCode: null,
        share: 25.01,
        inn: null,
        snils: null,
        phones: '',
        ipdl: false,
        mpdl: false
      })
    }

    const deleteBeneficiaries = (index) => {
      newBeneficiaries.value.splice(index, 1)
    }

    const legalFormEntity = [{
      name: 'Общество с ограниченной ответственностью',
      value: 'LCC'
    }, {
      name: 'Акционерное общество',
      value: 'JSC'
    }, {
      name: 'Публичное акционерное общество',
      value: 'PJSC'
    }, {
      name: 'Другое',
      value: 'OTHER'
    }]

    const legalFormNoEntity = [{
      name: 'Индивидуальный предприниматель',
      value: 'IE'
    }, {
      name: 'Самозанятый',
      value: 'INDIVIDUAL'
    }, {
      name: 'Другое',
      value: 'OTHER'
    }]

    const vat = [{
      name: 'Без НДС',
      value: 'WITHOUT_VAT'
    }, {
      name: 'НДС 0%',
      value: 'VAT_0'
    }, {
      name: 'НДС 10%',
      value: 'VAT_10'
    }, {
      name: 'НДС 20%',
      value: 'VAT_20'
    }, {
      name: 'НДС 10/110',
      value: 'VAT_10_110'
    }, {
      name: 'НДС 20/120',
      value: 'VAT_20_120'
    }, {
      name: 'Другой размер НДС',
      value: 'OTHER'
    }]

    const vatJustification = [{
      name: 'Извещение налогового органа',
      value: 'NOTICE'
    }, {
      name: 'Заявление о переходе на УСН',
      value: 'STATEMENT'
    }, {
      name: 'Иное',
      value: 'OTHER'
    }]

    const businessInformation = [{
      name: 'Производство',
      value: 'PRODUCTION'
    }, {
      name: 'Розничная торговля',
      value: 'RETAIL'
    }, {
      name: 'Оптовая торговля',
      value: 'WHOLESALE'
    }, {
      name: 'Услуги',
      value: 'SERVICES'
    }, {
      name: 'Иное',
      value: 'OTHER'
    }]

    const utpPayBeneficiaryDocument = [{
      name: 'Паспорт РФ',
      value: 'RUSSIAN_PASSPORT'
    }, {
      name: 'Загранпаспорт',
      value: 'INTERNATIONAL_PASSPORT'
    }, {
      name: 'Иной документ',
      value: 'OTHER_DOCUMENT'
    }]

    const {
      loaderShopSettings,
      exceptionShopSettings,
      getUtpPaySettings,
      addUtpPaySettings,
      updateUtpPaySettings,
      initModeration,
      utpPayFull
    } = useShopSettings()

    const {
      imagesLoader,
      imagesException,
      paymentsDocumentsMini,
      paymentDocument,
      getPaymentsDocument,
      addPaymentsDocument,
      getPaymentsDocumentsMini
    } = useImages()

    const {stringToDate, dateToString, formattedDateTimeTz} = useMoment()

    const updateUtpPaySettingsModal = ref()
    const paymentDocumentModal = ref()

    onMounted(() => {
      updateUtpPaySettingsModal.value = new bootstrap.Modal('#updateUtpPaySettingsModal', {})
      paymentDocumentModal.value = new bootstrap.Modal('#paymentDocumentModal', {})
    })

    const oldOwners = ref([])

    const oldBeneficiaries = ref([])

    const newUtpPaySettingEntity = () => {
      newUtpPaySetting.value.legalForm = 'LCC'
      newOwners.value = deepCopyArray(oldOwners.value)
      newBeneficiaries.value = deepCopyArray(oldBeneficiaries.value)
    }

    const newUtpPaySettingNoEntity = () => {
      newUtpPaySetting.value.legalForm = 'IE'
      oldOwners.value = deepCopyArray(newOwners.value)
      oldBeneficiaries.value = deepCopyArray(newBeneficiaries.value)
      newOwners.value = []
      newBeneficiaries.value = []
    }

    const updateActualEqLegal = (event) => {
      const checked = event.target.checked
      if (checked) {
        newUtpPaySetting.value.corrEqActual = false
      }
    }

    const updateCorrEqLegal = (event) => {
      const checked = event.target.checked
      if (checked) {
        newUtpPaySetting.value.corrEqActual = false
      }
    }

    const updateCorrEqActual = (event) => {
      const checked = event.target.checked
      if (checked) {
        newUtpPaySetting.value.corrEqLegal = false
      }
    }


    const {setOnBeforeUnload, unSetOnBeforeUnload} = useOnBeforeUnload()

    watch(newUtpPaySetting.value, () => {
      setOnBeforeUnload()
    })

    const newUtpPayFull = ref({
      settings: {},
      owners: [],
      beneficiaries: []
    })

    const buttonPrimaryClick = () => {
      const form = document.querySelector('#utpPaySettingsForm')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        form.classList.remove("was-validated")
        if (newUtpPaySetting.value.status === null) {
          addUtpPaySetting()
        } else if (!['ACTIVATED', 'PLATFORM'].includes(newUtpPaySetting.value.status)) {
          updateUtpPaySetting()
        } else {
          updateUtpPaySettingsModal.value.show()
        }
      }
    }

    const addUtpPaySetting = async () => {
      await initNewUtpPayFull()
      await addUtpPaySettings(props.id, newUtpPayFull.value)
      if (!exceptionShopSettings.value) {
        await updateNewUtpPaySettingStatus('DISABLED')
        unSetOnBeforeUnload()
      }
    }

    const updateUtpPaySetting = async () => {
      await initNewUtpPayFull()
      await updateUtpPaySettings(props.id, newUtpPayFull.value)
      if (!exceptionShopSettings.value) {
        await updateNewUtpPaySettingStatus('DISABLED')
        unSetOnBeforeUnload()
      }
    }

    const updateNewUtpPaySettingStatus = async (status) => {
      newUtpPaySetting.value.status = status
    }

    const initNewUtpPayFull = () => {
      newUtpPayFull.value.settings = deepCopyObject(newUtpPaySetting.value)
      newUtpPayFull.value.owners = deepCopyArray(newOwners.value)
      newUtpPayFull.value.beneficiaries = deepCopyArray(newBeneficiaries.value)
      newUtpPayFull.value.settings.create = newUtpPayFull.value.settings.create ?
          dateToString(newUtpPayFull.value.settings.create) : null
      newUtpPayFull.value.settings.ceoBirthdate = dateToString(newUtpPayFull.value.settings.ceoBirthdate)
      newUtpPayFull.value.settings.ceoDocumentDate = dateToString(newUtpPayFull.value.settings.ceoDocumentDate)
      newUtpPayFull.value.beneficiaries.forEach(item => {
        item.birthday = dateToString(item.birthday)
        item.documentDate = dateToString(item.documentDate)
      })
    }

    const clickUtpPayTab = async () => {
      await getUtpPaySettings(props.id)
      await canceled()
      await getPaymentsDocumentsMini(props.id)
    }

    const buttonSecondaryClick = () => {
      const form = document.querySelector('#utpPaySettingsForm')
      form.classList.remove("was-validated")
      canceled()
    }

    const paymentDocumentModalTitle = ref('')

    const getPassportMain = async () => {
      await getPaymentsDocument(props.id, 'passport-main')
      paymentDocumentModalTitle.value = 'Основной разворот паспорта руководителя'
      paymentDocumentModal.value.show()
    }

    const newPassportMainImage = ref(null)
    const passportMainImage = ref(null)

    const passportMainUpload = () => {
      passportMainImage.value = newPassportMainImage.value.files[0]
    }

    const addPassportMain = async () => {
      let formData = new FormData()
      formData.append('file', passportMainImage.value)
      await addPaymentsDocument(formData, props.id, 'passport-main')
      passportMainImage.value = null
      newPassportMainImage.value.value = ''
      if (!imagesException.value) {
        await updateNewUtpPaySettingStatus('DISABLED')
        unSetOnBeforeUnload()
      }
    }

    const getPassportRegistration = async () => {
      await getPaymentsDocument(props.id, 'passport-registration')
      paymentDocumentModalTitle.value = 'Разворот паспорта руководителя с актуальной регистрацией'
      paymentDocumentModal.value.show()
    }

    const newPassportRegistrationImage = ref(null)
    const passportRegistrationImage = ref(null)

    const passportRegistrationUpload = () => {
      passportRegistrationImage.value = newPassportRegistrationImage.value.files[0]
    }

    const addPassportRegistration = async () => {
      let formData = new FormData()
      formData.append('file', passportRegistrationImage.value)
      await addPaymentsDocument(formData, props.id, 'passport-registration')
      passportRegistrationImage.value = null
      newPassportRegistrationImage.value.value = ''
      if (!imagesException.value) {
        await updateNewUtpPaySettingStatus('DISABLED')
        unSetOnBeforeUnload()
      }
    }

    const getInn = async () => {
      await getPaymentsDocument(props.id, 'inn')
      paymentDocumentModalTitle.value = 'Свидетельство ИНН'
      paymentDocumentModal.value.show()
    }

    const newInnImage = ref(null)
    const innImage = ref(null)

    const innUpload = () => {
      innImage.value = newInnImage.value.files[0]
    }

    const addInn = async () => {
      let formData = new FormData()
      formData.append('file', innImage.value)
      await addPaymentsDocument(formData, props.id, 'inn')
      innImage.value = null
      newInnImage.value.value = ''
      if (!imagesException.value) {
        await updateNewUtpPaySettingStatus('DISABLED')
        unSetOnBeforeUnload()
      }
    }

    const getGrn = async () => {
      await getPaymentsDocument(props.id, 'grn')
      paymentDocumentModalTitle.value = 'Свидетельство ГРН'
      paymentDocumentModal.value.show()
    }

    const newGrnImage = ref(null)
    const grnImage = ref(null)

    const grnUpload = () => {
      grnImage.value = newGrnImage.value.files[0]
    }

    const addGrn = async () => {
      let formData = new FormData()
      formData.append('file', grnImage.value)
      await addPaymentsDocument(formData, props.id, 'grn')
      grnImage.value = null
      newGrnImage.value.value = ''
      if (!imagesException.value) {
        await updateNewUtpPaySettingStatus('DISABLED')
        unSetOnBeforeUnload()
      }
    }

    const getAccessionAgreement = async () => {
      await getPaymentsDocument(props.id, 'accession-agreement')
      paymentDocumentModalTitle.value = 'Соглашение о присоединении'
      paymentDocumentModal.value.show()
    }

    const newAccessionAgreementImage = ref(null)
    const accessionAgreementImage = ref(null)

    const accessionAgreementUpload = () => {
      accessionAgreementImage.value = newAccessionAgreementImage.value.files[0]
    }

    const addAccessionAgreement = async () => {
      let formData = new FormData()
      formData.append('file', accessionAgreementImage.value)
      await addPaymentsDocument(formData, props.id, 'accession-agreement')
      accessionAgreementImage.value = null
      newAccessionAgreementImage.value.value = ''
      if (!imagesException.value) {
        await updateNewUtpPaySettingStatus('DISABLED')
        unSetOnBeforeUnload()
      }
    }

    const getPhoto = async () => {
      await getPaymentsDocument(props.id, 'photo')
      paymentDocumentModalTitle.value = 'Фотография с пасспортом и соглашением о присоединении'
      paymentDocumentModal.value.show()
    }

    const newPhotoImage = ref(null)
    const photoImage = ref(null)

    const photoUpload = () => {
      photoImage.value = newPhotoImage.value.files[0]
    }

    const addPhoto = async () => {
      let formData = new FormData()
      formData.append('file', photoImage.value)
      await addPaymentsDocument(formData, props.id, 'photo')
      photoImage.value = null
      newPhotoImage.value.value = ''
      if (!imagesException.value) {
        await updateNewUtpPaySettingStatus('DISABLED')
        unSetOnBeforeUnload()
      }
    }

    const initsModeration = async () => {
      await initModeration(props.id)
      if (!exceptionShopSettings.value) {
        await updateNewUtpPaySettingStatus('MODERATION')
        unSetOnBeforeUnload()
      }
    }

    const openAccessionAgreement = () => {
      window.open('https://files.utp1.com/docs/utp1.com/docs/oferta/accession_agreement.pdf', '_blanc')
    }

    return {
      loaderShopSettings,
      utpPayNav,
      utpPayNavEntity,
      newUtpPaySetting,
      newOwners,
      newBeneficiaries,
      utpPayBeneficiaryDocument,
      legalFormEntity,
      legalFormNoEntity,
      vat,
      vatJustification,
      businessInformation,
      addOwner,
      deleteOwner,
      addBeneficiaries,
      deleteBeneficiaries,
      updateActualEqLegal,
      updateCorrEqLegal,
      updateCorrEqActual,
      stringToDate,
      buttons,
      buttonPrimaryClick,
      buttonSecondaryClick,
      newUtpPaySettingEntity,
      newUtpPaySettingNoEntity,
      updateUtpPaySetting,
      exceptionShopSettings,
      imagesLoader,
      paymentsDocumentsMini,
      paymentDocument,
      addPassportMain,
      passportMainUpload,
      newPassportMainImage,
      passportMainImage,
      getPassportMain,
      addPassportRegistration,
      passportRegistrationUpload,
      newPassportRegistrationImage,
      passportRegistrationImage,
      getPassportRegistration,
      addInn,
      innUpload,
      newInnImage,
      innImage,
      getInn,
      addGrn,
      grnUpload,
      newGrnImage,
      grnImage,
      getGrn,
      addAccessionAgreement,
      accessionAgreementUpload,
      newAccessionAgreementImage,
      accessionAgreementImage,
      getAccessionAgreement,
      addPhoto,
      photoUpload,
      newPhotoImage,
      photoImage,
      getPhoto,
      paymentDocumentModalTitle,
      formattedDateTimeTz,
      initsModeration,
      clickUtpPayTab,
      openAccessionAgreement
    }
  }
}
</script>

<style scoped>

</style>