<template>
  <div>
    <hr>
    <div class="pb-3">
      <i class="bi bi-funnel-fill"></i> Фильтр
    </div>
    <slot>
    </slot>
    <div class="pt-3">
      <div class="d-grid gap-2">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="offcanvas" @click="filterApply">
          Применить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sidebar-right-filter",
  setup(_, {emit}) {
    const filterApply = () => {
      emit("filterApply")
    }
    return {filterApply}
  }
}
</script>

<style scoped>

</style>
