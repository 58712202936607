import {createStore} from 'vuex'
import {auth} from "./auth";
import {avatar} from "./avatar";

export default createStore({
    modules: {
        auth: auth,
        avatar: avatar
    }
})
