<template>
  <main>
    <preloader-main v-if="singleMailingLoader"/>
    <header-button-utp1
        :header="header"
        @buttonClick="$router.push('/mailing/single/add')"
    />

    <form-search
        v-model:search="filter.search"
        v-model:how="how"
        :placeholder="'Поиск по названию рассылки...'"
        @newSearch="updateSingleMailings"
    />

    <div class="pt-3">
      <single-mailings-cards
          :single-mailings="listSingleMailings.singleMailings"
          :groups="listSingleMailings.groups"
          @deleteCard="deleteSingleMailings"
          @updateStatusSingleMailingFalse="updateStatusSingleMailingFalse"
          @updateStatusSingleMailingTrue="updateStatusSingleMailingTrue"
      />
    </div>
    <pagination-utp1
        v-model:number="number"
        v-model:page="page"
        :total="listSingleMailings.number"
    />
  </main>

  <sidebar-right-utp1>
    <sidebar-right-filter
        class="sidebar-right-filter"
        @filterApply="updateSingleMailings"
    >
      <div class="pb-3">
        <label class="pb-2">Дата С - ПО:</label>
        <div class="pb-2">
          <input-utp1
              type="date"
              v-model:model-value="filter.send_before"
          />
        </div>
        <input-utp1
            type="date"
            v-model:model-value="filter.send_after"
        />
      </div>
      <label class="pb-2">Статус:</label>
      <select class="form-select" v-model="filter.status">
        <option selected value="">Любой</option>
        <option v-for="item in status" :value="item.value">{{ item.name }}</option>
      </select>
    </sidebar-right-filter>
  </sidebar-right-utp1>

</template>

<script>
import HeaderButtonUtp1 from "../../../components/UI/headers/HeaderButtonUtp1";
import useSingleMailing from "../../../hooks/rest/mailing/useSingleMailing";
import SingleMailingsCards from "../../../components/pages/mailing/single/SingleMailingsCards";
import {ref, watch, onMounted} from "vue";
import PaginationUtp1 from "../../../components/UI/paginations/PaginationUtp1";
import {useRoute, useRouter} from "vue-router";
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import InputUtp1 from "../../../components/UI/inputs/InputUtp1";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";
import SidebarRightFilter from "../../../components/system/SidebarRightFilter";
import ButtonPrimaryUtp1 from "../../../components/UI/buttons/ButtonPrimaryUtp1";
import FormSearch from "../../../components/UI/forms/FormSearch.vue";

export default {
  name: "single-mailings",
  components: {
    FormSearch,
    ButtonPrimaryUtp1,
    SidebarRightFilter,
    SidebarRightUtp1,
    InputUtp1,
    PreloaderMain,
    PaginationUtp1,
    SingleMailingsCards,
    HeaderButtonUtp1
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const header = {
      title: "Разовая рассылка",
      buttonTitle: "Создать разовый выпуск"
    }

    const status = [{
      value: "WAITING",
      name: "Ожидает"
    }, {
      value: "PAUSE",
      name: "Пауза"
    }, {
      value: "SENT",
      name: "Отправлено"
    }, {
      value: "ERROR",
      name: "Ошибка"
    }]

    const page = ref(Number(route.query.page) ? Number(route.query.page) : 1)
    const number = ref(Number(route.query.number) ? Number(route.query.number) : 10)
    const how = ref(route.query.how ? route.query.how : "")

    const filter = ref({
      search: route.query.search ? route.query.search : "",
      status: route.query.status ? route.query.status : "",
      send_before: route.query.send_before ? route.query.send_before : "",
      send_after: route.query.send_after ? route.query.send_after : "",
    })

    const myQuery = () => {
      return {
        how: how.value,
        search: filter.value.search,
        status: filter.value.status,
        send_before: filter.value.send_before,
        send_after: filter.value.send_after,
        page: page.value,
        number: number.value
      }
    }

    watch(page, async () => {
      await router.replace({
        query: myQuery()
      })
      await getSingleMailings()
    })

    watch(number, async () => {
      if (page.value === 1) {
        await router.replace({
          query: myQuery()
        })
        await getSingleMailings()
      } else page.value = 1
    })

    watch(how, async () => {
      if (page.value === 1) {
        await router.replace({
          query: myQuery()
        })
        await getSingleMailings()
      } else page.value = 1
    })

    watch(filter.value, () => {
      router.replace({
        query: myQuery()
      })
    })

    const updateSingleMailings = () => {
      if (page.value === 1) {
        getSingleMailings()
      } else page.value = 1
    }

    const updateStatusSingleMailingFalse = (singleMailingId) => {
      updateStatusSingleMailing(false, singleMailingId)
    }

    const updateStatusSingleMailingTrue = (singleMailingId) => {
      updateStatusSingleMailing(true, singleMailingId)
    }

    const deleteSingleMailings = async (singleMailingId) => {
      await deleteSingleMailing(singleMailingId)
      await getSingleMailings()
    }

    const {
      getSingleMailings,
      updateStatusSingleMailing,
      deleteSingleMailing,
      listSingleMailings,
      singleMailingLoader
    } = useSingleMailing()

    onMounted(() => {
      getSingleMailings()
    })

    return {
      header,
      status,
      page,
      number,
      filter,
      how,
      updateSingleMailings,
      updateStatusSingleMailingFalse,
      updateStatusSingleMailingTrue,
      deleteSingleMailings,
      listSingleMailings,
      singleMailingLoader
    }
  }
}
</script>

<style scoped>
input:checked + label {
  color: var(--bs-secondary) !important;
  cursor: default !important;
}
</style>
