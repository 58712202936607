<template>
  <div class="modal fade" id="socialButtonModal" tabindex="-1" aria-labelledby="socialButtonLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="socialButtonLabel">Иконки социальных сетей</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form id="social-button-modal" @submit.prevent novalidate>

            <div class="mb-3 text-center">

              <div class="form-check form-check-inline">
                <!--suppress JSUnresolvedReference -->
                <input type="radio" class="btn-check form-check-input" name="options" id="vk-button-input"
                       autocomplete="off" value="VK" v-model="socialButton.socialNetwork" required>
                <label class="btn btn-outline-secondary form-check-label border-0 py-0 fs-3" for="vk-button-input">
                  <i class="icon-vk-icon"/>
                </label>
              </div>

              <div class="form-check form-check-inline">
                <!--suppress JSUnresolvedReference -->
                <input type="radio" class="btn-check form-check-input" name="options" id="telegram-button-input"
                       autocomplete="off" value="TELEGRAM" v-model="socialButton.socialNetwork" required>
                <label class="btn btn-outline-secondary form-check-label border-0 py-0 fs-3"
                       for="telegram-button-input">
                  <i class="icon-telegram-icon"/>
                </label>
              </div>

              <div class="form-check form-check-inline">
                <!--suppress JSUnresolvedReference -->
                <input type="radio" class="btn-check form-check-input" name="options" id="youtube-button-input"
                       autocomplete="off" value="YOUTUBE" v-model="socialButton.socialNetwork" required>
                <label class="btn btn-outline-secondary form-check-label border-0 py-0 fs-3" for="youtube-button-input">
                  <i class="bi bi-youtube"/>
                </label>
              </div>

              <div class="form-check form-check-inline">
                <!--suppress JSUnresolvedReference -->
                <input type="radio" class="btn-check form-check-input" name="options" id="instagram-button-input"
                       autocomplete="off" value="INSTAGRAM" v-model="socialButton.socialNetwork" required>
                <label class="btn btn-outline-secondary form-check-label border-0 py-0 fs-3"
                       for="instagram-button-input">
                  <i class="bi bi-instagram"/>
                </label>
              </div>

              <div class="form-check form-check-inline">
                <!--suppress JSUnresolvedReference -->
                <input type="radio" class="btn-check form-check-input" name="options" id="facebook-button-input"
                       autocomplete="off" value="FACEBOOK" v-model="socialButton.socialNetwork" required>
                <label class="btn btn-outline-secondary form-check-label border-0 py-0 fs-4"
                       for="facebook-button-input">
                  <i class="icon-facebook-icon"/>
                </label>
              </div>

              <div class="form-check form-check-inline">
                <!--suppress JSUnresolvedReference -->
                <input type="radio" class="btn-check form-check-input" name="options" id="twitter-button-input"
                       autocomplete="off" value="TWITTER" v-model="socialButton.socialNetwork" required>
                <label class="btn btn-outline-secondary form-check-label border-0 py-0 fs-4" for="twitter-button-input">
                  <i class="bi bi-twitter-x"/>
                </label>
              </div>

              <div class="form-check form-check-inline">
                <!--suppress JSUnresolvedReference -->
                <input type="radio" class="btn-check form-check-input" name="options" id="site-button-input"
                       autocomplete="off" value="SITE" v-model="socialButton.socialNetwork" required>
                <label class="btn btn-outline-secondary form-check-label border-0 py-0 fs-4" for="site-button-input">
                  <i class="bi bi-globe"/>
                </label>
              </div>

            </div>

            <!--suppress JSUnresolvedReference -->
            <input-url-utp1
                class="mb-3"
                v-model:url="socialButton.url"
                :required="true"
            />
          </form>
          <div class="text-secondary">
            Не забудьте сохранить изменения!
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отменить</button>
          <button type="button" class="btn btn-primary" @click="applySocialButton">Применить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputUrlUtp1 from "../inputs/InputUrlUtp1";
import AlertDanger from "../alerts/AlertDanger";

export default {
  name: "social-button-modal",
  components: {AlertDanger, InputUrlUtp1},
  props: {
    socialButton: {
      type: Object,
      default: {
        socialNetwork: {
          type: String.nullable,
          default: null
        },
        url: {
          type: String.nullable,
          default: null
        },
        priority: {
          type: Number.nullable,
          default: null
        }
      }
    }
  },
  watch: {
    socialButton(newSocialButton) {
      this.$emit('update:socialButton', newSocialButton)
    }
  },
  setup(props, {emit}) {

    const applySocialButton = () => {
      const form = document.querySelector('#social-button-modal')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        emit('applySocialButton')
      }
    }

    return {applySocialButton}

  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>

@media (max-width: 575px) {
  #social-button-modal .btn {
    width: 2.5rem;
    padding: 0;
    margin: 0;
  }

  #social-button-modal .form-check-inline {
    margin-right: 0.1rem !important;
  }

  #social-button-modal .form-check {
    padding-left: 0.2rem !important;
  }
}

@media (min-width: 576px) {
  #social-button-modal .btn {
    width: 3.5rem;
    padding: 0;
    margin: 0;
  }

  #social-button-modal .form-check-inline {
    margin-right: 0.25rem !important;
  }

  #social-button-modal .form-check {
    padding-left: 0.41rem !important;
  }
}

#social-button-modal i {
  padding-bottom: 0.4rem;
}

.was-validated .form-check-input:valid:focus ~ .form-check-label,
.was-validated .form-check-input:valid:hover ~ .form-check-label,
.was-validated .form-check-input:valid:checked ~ .form-check-label {
  color: #d1e7dd;
}

.was-validated .form-check-input:invalid:focus ~ .form-check-label,
.was-validated .form-check-input:invalid:hover ~ .form-check-label,
.was-validated .form-check-input:invalid:checked ~ .form-check-label {
  color: #f8d7da;
}

</style>