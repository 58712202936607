<template>
  <main>
    <preloader-main v-if="contactsLoader"/>
    <div :class="errorMessage? 'delete-pb' : ''">
      <header-back-utp1
          :header="header"
      />
    </div>

    <div class="pt-3" v-if="errorMessage">
      <alert-danger>{{ errorMessage }}</alert-danger>
    </div>

    <form id="group" @submit.prevent novalidate>
      <div class="mb-3">
        <label class="form-label">
          Имя <span class="text-secondary">(можно Фамилия Имя Отчество, при наличии)</span>
        </label>
        <input-utp1
            placeholder="Введите имя"
            v-model:model-value="contact.name"
            type="text"
            minlength="2"
            maxlength="255"
        />
      </div>

      <div class="mb-3">
        <label class="form-label">
          Выбрать тип контакта
        </label>
        <select class="form-select" required v-model="contact.type">
          <option v-for="item in type" :selected="item.selected" :disabled="item.disabled" :value="item.value">
            {{ item.text }}
          </option>
        </select>
      </div>

      <div v-if="contact.type==='EMAIL'" class="mb-3">
        <label class="form-label">
          E-mail
        </label>
        <input-utp1
            placeholder="Введите e-mail"
            v-model:model-value="contact.channel"
            type="email"
            required
            minlength="6"
            maxlength="255"
        />
      </div>
      <div v-else-if="contact.type==='PHONE'" class="mb-3">
        <label class="form-label">
          Телефон в международном формате <span
            class="text-secondary">(начиная с «+», без пробелов между цифрами)</span>
        </label>
        <input-utp1
            placeholder="Введите телефон (+79210000000)"
            v-model:model-value="contact.channel"
            type="tel"
            required
            minlength="9"
            maxlength="17"
        />
      </div>
      <div v-else-if="contact.type==='VK'" class="mb-3">
        <label class="form-label">
          ID профиля ВКонтакте <span class="text-secondary">(только цифры)</span>
        </label>
        <input-utp1
            placeholder="Введите ID профиля ВКонтакте"
            v-model:model-value="contact.channel"
            type="number"
            required
            minlength="1"
            maxlength="255"
        />
      </div>
      <div v-else-if="contact.type==='TELEGRAM'" class="mb-3">
        <label class="form-label">
          Профиль Telegram
        </label>
        <input-utp1
            placeholder="Введите профиль Telegram"
            v-model:model-value="contact.channel"
            type="text"
            required
            minlength="1"
            maxlength="255"
        />
      </div>

      <label class="form-label">
        Выберите группы
      </label>
      <div class="mb-3 form-control">
        <groups-accordion
            v-model:groups="contact.groups"
        />
      </div>

      <button-primary-utp1 @buttonClick="newContact">
        Создать
      </button-primary-utp1>
    </form>
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import HeaderBackUtp1 from "../../../components/UI/headers/HeaderBackUtp1";
import InputUtp1 from "../../../components/UI/inputs/InputUtp1";
import {ref} from "vue";
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import GroupsAccordion from "../../../components/UI/accordions/GroupsAccordion";
import ButtonPrimaryUtp1 from "../../../components/UI/buttons/ButtonPrimaryUtp1";
import useContacts from "../../../hooks/rest/mailing/contacts/useContacts";
import AlertDanger from "../../../components/UI/alerts/AlertDanger";
import useError from "../../../hooks/error/useError";
import {watch} from "vue";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";

export default {
  name: "add-contact",
  components: {
    SidebarRightUtp1,
    AlertDanger,
    ButtonPrimaryUtp1, GroupsAccordion, PreloaderMain, InputUtp1, HeaderBackUtp1
  },
  setup() {
    const header = {
      title: "Добавить контакт",
      buttonRedirect: "/mailing/contacts"
    }
    const contact = ref({
      name: "",
      type: "EMAIL",
      channel: "",
      groups: []
    })
    const type = [{
      value: "EMAIL",
      text: "E-mail",
      selected: true,
      disabled: false
    }, {
      value: "PHONE",
      text: "Телефон",
      selected: false,
      disabled: false
    }, {
      value: "VK",
      text: "ВКонтакте",
      selected: false,
      disabled: false
    }, {
      value: "TELEGRAM",
      text: "Telegram",
      selected: false,
      disabled: true
    }]

    const newContact = () => {
      const form = document.querySelector('#group')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        addContact(contact.value)
      }
    }

    const {contactsLoader, contactsException, addContact} = useContacts()
    const {error, errorMessage} = useError()

    watch(contactsException, () => {
      error(contactsException.value)
    })

    return {header, contact, type, newContact, contactsLoader, errorMessage}
  }
}
</script>

<style scoped>

</style>