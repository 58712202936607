<template>
  <preloader-main v-if="mailingSettingsLoader"/>
  <div v-if="errorMessage">
    <alert-danger>{{ errorMessage }}</alert-danger>
  </div>
  <form id="group-email-setting" @submit.prevent novalidate class="row-form">
    <div class="row">

      <div class="mb-3">
        <label for="name" class="form-label">
          Имя автора рассылки
        </label>
        <input-utp1
            id="name"
            placeholder="Имя"
            v-model:model-value="emailsSetting.name"
            maxlength="50"/>
      </div>

      <div class="mb-3">
        <label for="projectName" class="form-label">
          Название проекта
        </label>
        <input-utp1
            id="projectName"
            placeholder="Введите название проекта"
            v-model:model-value="emailsSetting.projectName"
            maxlength="70"/>
      </div>

      <div class="mb-3">
        <label for="projectUrl" class="form-label">
          Основной URL Вашего проекта
        </label>
        <input-url-utp1 id="projectUrl" v-model:url="emailsSetting.projectUrl"/>
      </div>

      <div class="mb-3 col-sm-4">
        <label for="legalForms" class="form-label">
          Правовая форма
        </label>
        <input-select-utp1 id="legalForms" :select-items="legalForms" v-model:selected-item="emailsSetting.legalForms"/>
      </div>

      <div class="mb-3 col-sm-8">
        <label for="company" class="form-label">
          Наименование организации (ФИО ИП)
        </label>
        <input-utp1
            id="company"
            placeholder="Организация"
            v-model:model-value="emailsSetting.company"
            maxlength="255"/>
      </div>

      <div class="mb-3 col-sm-6">
        <label for="ogrn" class="form-label">
          ОГРН (ОГРНИП)
        </label>
        <input-utp1
            id="ogrn"
            placeholder="Введите ОГРН"
            v-model:model-value="emailsSetting.ogrn"
            maxlength="30"/>
      </div>

      <div class="mb-3 col-sm-6">
        <label for="inn" class="form-label">
          ИНН
        </label>
        <input-utp1
            id="inn"
            placeholder="Введите ИНН"
            v-model:model-value="emailsSetting.inn"
            maxlength="30"/>
      </div>

      <div class="mb-3">
        <label for="country" class="form-label">
          Страна
        </label>
        <input-utp1
            id="country"
            placeholder="Страна"
            v-model:model-value="emailsSetting.country"
            maxlength="70"/>
      </div>

      <div class="mb-3 col-sm-6">
        <label for="region" class="form-label">
          Регион (область)
        </label>
        <input-utp1
            id="region"
            placeholder="Регион"
            v-model:model-value="emailsSetting.region"
            maxlength="255"/>
      </div>

      <div class="mb-3 col-sm-6">
        <label for="city" class="form-label">
          Город (населённый пункт)
        </label>
        <input-utp1
            id="city"
            placeholder="Город"
            v-model:model-value="emailsSetting.city"
            maxlength="255"/>
      </div>

      <div class="mb-3 col-sm-5">
        <label for="street" class="form-label">
          Улица
        </label>
        <input-utp1
            id="street"
            placeholder="Улица"
            v-model:model-value="emailsSetting.street"
            maxlength="150"/>
      </div>

      <div class="mb-3 col-sm-2">
        <label for="home" class="form-label">
          Дом
        </label>
        <input-utp1
            id="home"
            placeholder="Дом"
            v-model:model-value="emailsSetting.home"
            maxlength="10"/>
      </div>

      <div class="mb-3 col-sm-3">
        <label for="building" class="form-label">
          Корпус
        </label>
        <input-utp1
            id="building"
            placeholder="Корпус"
            v-model:model-value="emailsSetting.building"
            maxlength="10"/>
      </div>

      <div class="mb-3 col-sm-2">
        <label for="office" class="form-label">
          Офис
        </label>
        <input-utp1
            id="office"
            placeholder="Офис"
            v-model:model-value="emailsSetting.office"
            maxlength="10"/>
      </div>

      <div class="mb-3">
        <label for="phone" class="form-label">
          Телефон в международном формате
          <span class="text-secondary">(начиная с «+», без пробелов между цифрами)</span>
        </label>
        <input-utp1
            id="phone"
            type="tel"
            placeholder="Телефон (+79210000000)"
            v-model:model-value="emailsSetting.phone"
            minlength="9"
            maxlength="17"/>
      </div>

      <div class="mb-3">
        <label for="author" class="form-label">
          Имя автора рассылки (для переменной в письмах)
        </label>
        <input-utp1
            id="author"
            placeholder="Имя для переменной"
            v-model:model-value="emailsSetting.author"
            maxlength="255"/>
      </div>

      <div class="mb-3">
        <label for="authorContact" class="form-label">
          E-mail адрес автора рассылки (для переменной в письмах)
        </label>
        <input-utp1
            type="email"
            id="authorContact"
            placeholder="E-mail для переменной"
            v-model:model-value="emailsSetting.authorContact"
            maxlength="255"/>
        <label for="authorContact" class="form-text">Необязательное поле, в котором можно указать персональный
          e-mail</label>
      </div>

      <letter-footer
          :email-settings="emailsSetting"
      />

      <div class="px-5 mb-3 form-check form-switch d-none">
        <input class="form-check-input" type="checkbox" role="switch" id="autoUnsubscribeCheck"
               v-model="emailsSetting.autoUnsubscribe" disabled>
        <label class="form-check-label" for="autoUnsubscribeCheck">Автоматически отписывать неактивные контакты для
          повышения доставляемости писем и получения максимально объективной статистики</label>
        <div class="mt-2 text-secondary">
          Алгоритм отписывает только тех, кто не проявлял активность по 20 последним письмам подряд в течение не менее
          60 дней (обязательно должны наступить оба события), и построен таким образом, что Вы не потеряете ни одного
          активного подписчика. Перед отпиской будет отправлено 2 письма (за 2 недели и за неделю) с предложением
          остаться в Вашей рассылке, а в момент отписки, придёт письмо со ссылкой, перейдя по которой, подписчик
          в любой момент сможет вернуться в рассылку одним кликом.
        </div>
      </div>

    </div>
  </form>

  <double-button-utp1
      :buttons="buttons"
      @buttonPrimaryClick="updateEmailsSettings"
      @buttonSecondaryClick="cancelChanges"
  />

</template>

<script>
import InputUtp1 from "../../../UI/inputs/InputUtp1";
import InputUrlUtp1 from "../../../UI/inputs/InputUrlUtp1";
import InputSelectUtp1 from "../../../UI/inputs/InputSelectUtp1";
import DoubleButtonUtp1 from "../../../UI/buttons/DoubleButtonUtp1";
import useMailingSettings from "../../../../hooks/rest/mailing/useMailingSettings";
import PreloaderMain from "../../../system/preloader/PreloaderMain";
import AlertDanger from "../../../UI/alerts/AlertDanger";
import useError from "../../../../hooks/error/useError";
import {ref, watch} from "vue";
import LetterFooter from "./LetterFooter";

export default {
  name: "email-setting",
  components: {LetterFooter, AlertDanger, PreloaderMain, DoubleButtonUtp1, InputSelectUtp1, InputUrlUtp1, InputUtp1},
  props: {
    emailSetting: {
      type: Object
    }
  },
  watch: {
    emailSetting() {
      this.cancelChanges()
    }
  },
  setup(props) {
    const emailsSetting = ref({
      name: "",
      projectName: "",
      projectUrl: "",
      legalForms: "OTHER",
      company: "",
      ogrn: "",
      inn: "",
      country: "",
      region: "",
      city: "",
      street: "",
      home: "",
      building: "",
      office: "",
      phone: "",
      author: "",
      authorContact: "",
      autoUnsubscribe: false
    })

    const cancelChanges = () => {
      emailsSetting.value.name = props.emailSetting.name
      emailsSetting.value.projectName = props.emailSetting.projectName
      emailsSetting.value.projectUrl = props.emailSetting.projectUrl
      emailsSetting.value.legalForms = props.emailSetting.legalForms
      emailsSetting.value.company = props.emailSetting.company
      emailsSetting.value.ogrn = props.emailSetting.ogrn
      emailsSetting.value.inn = props.emailSetting.inn
      emailsSetting.value.country = props.emailSetting.country
      emailsSetting.value.region = props.emailSetting.region
      emailsSetting.value.city = props.emailSetting.city
      emailsSetting.value.street = props.emailSetting.street
      emailsSetting.value.home = props.emailSetting.home
      emailsSetting.value.building = props.emailSetting.building
      emailsSetting.value.office = props.emailSetting.office
      emailsSetting.value.phone = props.emailSetting.phone
      emailsSetting.value.author = props.emailSetting.author
      emailsSetting.value.authorContact = props.emailSetting.authorContact
      emailsSetting.value.autoUnsubscribe = props.emailSetting.autoUnsubscribe
    }

    const legalForms = [{
      name: "ИП",
      value: "IE",
      selected: false
    }, {
      name: "ООО",
      value: "LLC",
      selected: false
    }, {
      name: "АО",
      value: "JSC",
      selected: false
    }, {
      name: "ПАО",
      value: "PJSC",
      selected: false
    }, {
      name: "НДП",
      value: "INDIVIDUAL",
      selected: false
    }, {
      name: "Другое",
      value: "OTHER",
      selected: false
    }]

    const buttons = {
      primaryTitle: "Сохранить",
      primaryDisabled: false,
      secondaryTitle: "Отменить",
      secondaryDisabled: false
    }

    const updateEmailsSettings = () => {
      const form = document.querySelector('#group-email-setting')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        updateEmailSettings(emailsSetting.value)
      }
    }

    const {mailingSettingsLoader, mailingSettingsException, updateEmailSettings} = useMailingSettings()
    const {error, errorMessage} = useError()

    watch(mailingSettingsException, () => {
      error(mailingSettingsException.value)
    })

    return {
      emailsSetting,
      cancelChanges,
      legalForms,
      buttons,
      mailingSettingsLoader,
      updateEmailsSettings,
      errorMessage
    }
  }
}
</script>

<style scoped>

</style>
