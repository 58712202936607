<template>
  <div class="modal modal-lg fade" id="invoiceEventLetterModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <!--suppress JSUnresolvedReference -->
        <div class="modal-header" :class="
          invoiceEventLetter.type === 'COMMENT' ? 'bg-indigo' :
          invoiceEventLetter.type === 'SUCCESSFUL_TALK' ? 'bg-green-dark' :
          invoiceEventLetter.type === 'CALL_BACK' ? 'bg-info' :
          invoiceEventLetter.type === 'DIDNT_CALL' ? 'bg-orange' :
          invoiceEventLetter.type === 'WRONG_PHONE' ? 'bg-yellow' :
          invoiceEventLetter.type === 'ALREADY_PAID' ? 'bg-success' :
          invoiceEventLetter.type === 'REFUSAL_PAYMENT' ? 'bg-red-light' :
          invoiceEventLetter.type === 'SECONDARY_ORDER' ? 'bg-secondary' : ''">
          <!--suppress JSUnresolvedReference -->
          <h5 v-if="invoiceEventLetter.type === 'COMMENT'" class="modal-title text-white">Отправлено письмо</h5>
          <!--suppress JSUnresolvedReference -->
          <h5 v-else-if="invoiceEventLetter.type === 'SUCCESSFUL_TALK'" class="modal-title text-white">Отправлено
            письмо</h5>
          <!--suppress JSUnresolvedReference -->
          <h5 v-else-if="invoiceEventLetter.type === 'CALL_BACK'" class="modal-title text-white">Отправлено письмо</h5>
          <!--suppress JSUnresolvedReference -->
          <h5 v-else-if="invoiceEventLetter.type === 'DIDNT_CALL'" class="modal-title text-white">Отправлено письмо</h5>
          <!--suppress JSUnresolvedReference -->
          <h5 v-else-if="invoiceEventLetter.type === 'WRONG_PHONE'" class="modal-title text-white">Отправлено
            письмо</h5>
          <!--suppress JSUnresolvedReference -->
          <h5 v-else-if="invoiceEventLetter.type === 'ALREADY_PAID'" class="modal-title text-white">Отправлено
            письмо</h5>
          <!--suppress JSUnresolvedReference -->
          <h5 v-else-if="invoiceEventLetter.type === 'REFUSAL_PAYMENT'" class="modal-title text-white">Отправлено
            письмо</h5>
          <!--suppress JSUnresolvedReference -->
          <h5 v-else-if="invoiceEventLetter.type === 'SECONDARY_ORDER'" class="modal-title text-white">Отправлено
            письмо</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
        </div>

        <div class="modal-body">
          <!--suppress JSCheckFunctionSignatures, JSUnresolvedReference -->
          <div class="alert alert-secondary" role="alert">
            {{ formattedDateTimeTz(invoiceEventLetter.datetime, 'UTC_P3', 'DD.MM.YYYY в HH:mm МСК') }}
          </div>

          <div class="row-form pb-3">
            <div class="row">
              <div class="col">
                <div class="fw-bolder">Отправитель:</div>
                <!--suppress JSUnresolvedReference -->
                <div>{{ invoiceEventLetter.senderName + ' <' + invoiceEventLetter.senderEmail + '>' }}</div>
              </div>
              <div class="col-auto mt-2">
                <div class="d-sm-inline d-grid gap-2">
                  <button type="button" class="btn btn-outline-secondary"
                          @click="copyLetterTitleText">
                    Копировать тему и текст <i class="ps-1" :class="copyLetterTitleTextIconClass"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="pb-3">
            <div class="row-form form-label">
              <div class="row">
                <div class="col">
                  <label>Тема письма:</label>
                </div>
                <div class="col-auto">
                  <a class="text-end link-secondary bi bi-files" @click="copyLetterTitle"></a>
                </div>
              </div>
            </div>
            <!--suppress JSUnresolvedReference -->
            <div id="letterTitle" class="form-control">{{ invoiceEventLetter.letterTitle }}</div>
          </div>

          <div>
            <div class="row-form form-label">
              <div class="row">
                <div class="col">
                  <label>Текст письма:</label>
                </div>
                <div class="col-auto">
                  <a class="text-end link-secondary bi bi-files" @click="copyLetterText"></a>
                </div>
              </div>
            </div>
            <!--suppress JSUnresolvedReference -->
            <div id="letterText" class="form-control"
                 v-html="invoiceEventLetter.letterText.replaceAll('\n', '<br>')"></div>
          </div>
        </div>

        <div class="modal-footer">
          <button-secondary-utp1
              class="col col-sm-auto"
              :disabled="false"
              data-bs-dismiss="modal"
          >Закрыть
          </button-secondary-utp1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonSecondaryUtp1 from "@/components/UI/buttons/ButtonSecondaryUtp1.vue";
import ButtonPrimaryUtp1 from "@/components/UI/buttons/ButtonPrimaryUtp1.vue";
import InputUtp1 from "@/components/UI/inputs/InputUtp1.vue";
import useMoment from "@/hooks/useMoment";
import MarkdownWysiwyg from "@/components/UI/inputs/MarkdownWysiwyg.vue";
import {ref} from "vue";

export default {
  name: "invoice-event-letter-modal",
  components: {MarkdownWysiwyg, InputUtp1, ButtonPrimaryUtp1, ButtonSecondaryUtp1},
  props: {
    invoiceEventLetter: {
      type: Object,
      default: {
        type: "",
        datetime: "",
        senderName: "",
        senderEmail: "",
        letterTitle: "",
        letterText: ""
      }
    }
  },
  setup(props) {
    const {formattedDateTimeTz} = useMoment()

    const copyLetterTitle = () => {
      const copyText = document.getElementById('letterTitle')
      const range = document.createRange()
      range.selectNode(copyText)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      navigator.clipboard.writeText(props.invoiceEventLetter.letterTitle)
    }

    const copyLetterText = () => {
      const copyText = document.getElementById('letterText')
      const range = document.createRange()
      range.selectNode(copyText)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      navigator.clipboard.writeText(props.invoiceEventLetter.letterText)
    }

    const copyLetterTitleTextIconClass = ref('bi bi-files')

    const copyLetterTitleText = () => {
      navigator.clipboard.writeText(props.invoiceEventLetter.letterTitle + '\n\n' + props.invoiceEventLetter.letterText)
      copyLetterTitleTextIconClass.value = 'bi bi-file-check'
      setTimeout(() => {
        copyLetterTitleTextIconClass.value = 'bi-files'
      }, 3000)
    }

    return {
      formattedDateTimeTz,
      copyLetterTitle,
      copyLetterText,
      copyLetterTitleText,
      copyLetterTitleTextIconClass
    }
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

textarea {
  resize: none;
  overflow: hidden;
  height: auto;
}
</style>
