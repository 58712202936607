<template>
  <ul class="nav pb-2" aria-label="Social navigation" style="margin-left: -1rem">
    <li v-for="item in socialNav" class="nav-item">
      <a class="nav-link pt-0" :class="item.active?'active':item.disabled?'disabled':''"
         data-bs-toggle="tab" :data-bs-target="'#' + item.value" role="tab" :aria-controls="item.value">
        {{ item.name }}
      </a>
    </li>
  </ul>

  <div class="tab-content">
    <div class="tab-pane active" id="vk">
      <vk-letter
          v-model:newMailingLetters="localNewMailingLetters"
          :readOnly="readOnly"
      />
    </div>

    <div class="tab-pane" id="telegram">
      Телега
    </div>
  </div>

</template>

<script>
import VkLetter from "./VkLetter";
import {ref, watch} from "vue";

export default {
  name: "social-letters",
  props: {
    newMailingLetters: {
      mailingLetters: {},
      mailingLetterLinks: [],
      mailingLetterImages: [],
      vkLetterLinks: [],
      vkLetterImages: [],
      emailBindings: [],
      vkBindings: []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
      this.localNewMailingLetters = this.newMailingLetters
  },
  watch: {
    newMailingLetters() {
      this.localNewMailingLetters = this.newMailingLetters
    }
  },
  components: {VkLetter},
  emits: ['update:newMailingLetters'],
  setup(_, {emit}) {
    const localNewMailingLetters = ref({
      mailingLetters: {},
      mailingLetterLinks: [],
      mailingLetterImages: [],
      vkLetterLinks: [],
      vkLetterImages: [],
      emailBindings: [],
      vkBindings: []
    })

    watch(localNewMailingLetters, (newVal) => {
      emit('update:newMailingLetters', newVal)
    })

    const socialNav = [{
      value: "vk",
      name: "ВКонтакте",
      active: true,
      disabled: false
    }, {
      value: "telegram",
      name: "Telegram",
      active: false,
      disabled: true
    }]

    return {socialNav, localNewMailingLetters}
  }
}
</script>

<style scoped>

</style>