<template>
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link footer-color-copyright" data-bs-dismiss="offcanvas" @click="copyright">
        © 2017 - {{ new Date().getFullYear() }} UTP1 | Все права защищены.</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "footer-content-left",
  setup() {
    const copyright = () => {
      window.open('https://utp1.com', '_blank')
    }
    return {copyright}
  }
}
</script>

<style scoped>
@import "../../../../src/assets/css/footer.css";
</style>