<template>
  <preloader-main v-if="mailingSettingsLoader"/>

  <div v-if="errorMessage">
    <alert-danger>{{ errorMessage }}</alert-danger>
  </div>

  <div v-if="emailBindingError">
    <alert-danger>{{ emailBindingError }}</alert-danger>
  </div>

  <div v-if="email.email">
    <alert-success>Необходимо подтвердить владение почтовым ящиком. Письмо с персональной ссылкой отправлено на e-mail:
      <span class="alert-link">{{ email.email }}</span>. Срок действия ссылки ограничен!
      Если письмо не пришло в течение нескольких минут, пожалуйста, проверьте папку <i>«Спам»</i>, и нажмите
      <strong><i>«НЕ спам»</i></strong>
    </alert-success>
  </div>

  <!--suppress JSUnresolvedReference -->
  <div class="pb-3" v-if="emailBindingSettings.domainStatus.length > 0">
    <alert-danger :btn-close="false">Произведите настройки!</alert-danger>

    <!--suppress JSUnresolvedReference -->
    <alert-warning :btn-close="false" v-for="item in emailBindingSettings.domainStatus">
      <div class="row">
        <div class="col">
          <h4 class="alert-heading">Домен: {{ item.domainName }}</h4>
          <hr class="border border-1" style="border-color: #664d03 !important;"/>
        </div>
      </div>

      <div v-if="item.spf || item.dmarc || item.dkimKey" class="pt-2">
        <div class="row">
          <div class="col">
            <h6 class="alert-heading">Добавьте DNS-записи:</h6>
            <hr/>
          </div>
        </div>
      </div>

      <div v-if="item.spf" :class="item.dmarc || item.dkimKey ? 'pb-2' : ''">
        <div class="row">
          <div class="col-sm-2">
            <label class="col-form-label">SPF</label>
          </div>
          <div class="col-sm-auto row">
            <div class="col-auto">
              <label class="col-form-label">Имя:</label>
            </div>
            <div class="col-auto">
              <input type="text" readonly class="form-control-plaintext" :value="item.domainName + '.'">
            </div>
          </div>
          <div class="col-sm-auto row">
            <div class="col-auto">
              <label class="col-form-label">Тип:</label>
            </div>
            <div class="col-auto">
              <input type="text" readonly class="form-control-plaintext" value="TXT">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2 col-auto">
            <label class="col-form-label">Значение:</label>
          </div>
          <div class="col">
            <input type="text" :id="item.domainName + 'Spf'" readonly class="form-control-plaintext"
                   value="v=spf1 a mx include:_send.utp1.com ~all">
          </div>
          <div class="col-auto">
            <label class="col-form-label">
              <a class="link-secondary" @click="copyTextFunction(item.domainName, 'Spf')">
                <i class="bi bi-files"></i>
              </a>
            </label>
          </div>
        </div>
      </div>

      <div v-if="item.dmarc" :class="item.dkimKey ? 'pb-2' : ''">
        <div class="row">
          <div class="col-sm-2">
            <label class="col-form-label">DMARC</label>
          </div>
          <div class="col-sm-auto row">
            <div class="col-auto">
              <label class="col-form-label">Имя:</label>
            </div>
            <div class="col-auto">
              <input type="text" readonly class="form-control-plaintext" value="_dmarc">
            </div>
          </div>
          <div class="col-sm-auto row">
            <div class="col-auto">
              <label class="col-form-label">Тип:</label>
            </div>
            <div class="col-auto">
              <input type="text" readonly class="form-control-plaintext" value="TXT">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2 col-auto">
            <label class="col-form-label">Значение:</label>
          </div>
          <div class="col">
            <input type="text" :id="item.domainName + 'Dmarc'" readonly class="form-control-plaintext"
                   value="v=DMARC1; p=quarantine; sp=quarantine; aspf=r;">
          </div>
          <div class="col-auto">
            <label class="col-form-label">
              <a class="link-secondary" @click="copyTextFunction(item.domainName, 'Dmarc')">
                <i class="bi bi-files"></i>
              </a>
            </label>
          </div>
        </div>
      </div>

      <div v-if="item.dkimKey" :class="(item.returnPathConfirmation || item.fblConfirmation) ? '' : 'pb-3'">
        <div class="row">
          <div class="col-sm-2">
            <label class="col-form-label">DKIM</label>
          </div>
          <div class="col-sm-auto row">
            <div class="col-auto">
              <label class="col-form-label">Имя:</label>
            </div>
            <div class="col-auto">
              <input type="text" readonly class="form-control-plaintext" :value="item.dkimSelector + '._domainkey'">
            </div>
          </div>
          <div class="col-sm-auto row">
            <div class="col-auto">
              <label class="col-form-label">Тип:</label>
            </div>
            <div class="col-auto">
              <input type="text" readonly class="form-control-plaintext" value="TXT">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2 col-auto">
            <label class="col-form-label">Значение:</label>
          </div>
          <div class="col">
            <input type="text" :id="item.domainName + 'Dkim'" readonly class="form-control-plaintext"
                   :value="'v=DKIM1;k=rsa;p=' + item.dkimKey">
          </div>
          <div class="col-auto">
            <label class="col-form-label">
              <a class="link-secondary" @click="copyTextFunction(item.domainName, 'Dkim')">
                <i class="bi bi-files"></i>
              </a>
            </label>
          </div>
        </div>
      </div>

      <div v-if="!item.returnPathConfirmation || !item.fblConfirmation || item.fblMailUrl" class="pt-2">
        <div class="row">
          <div class="col">
            <h6 class="alert-heading">Добавьте системные e-mailы:</h6>
            <hr/>
          </div>
        </div>
      </div>

      <div v-if="!item.returnPathConfirmation" :class="!item.fblConfirmation || item.fblMailUrl ? 'pb-3' : ''">
        <form :id="'returnPath' + item.domainName.replace('.', '')" class="row" @submit.prevent novalidate>
          <div class="col-sm-4 col-md-3 col-lg-4 col-xl-3">
            <label class="col-form-label position-relative">Return-Path
              <span class="badge-question bg-info" data-bs-toggle="tooltip"
                    data-bs-title="Системный e-mail для получения и обработки автоматических служебных ответов"><i
                  class="bi bi-question"></i></span>
            </label>
          </div>
          <div class="col-sm-5 col-md-6 col-lg-5 col-xl-6 pb-2 pb-sm-0">
            <input-utp1
                v-model="item.returnPath"
                placeholder="Return-Path e-mail"
                type="email"
                maxlength="255"
                required
            />
          </div>
          <div class="col-sm-3">
            <button-secondary-utp1
                class="d-sm-grid"
                @buttonClick="updatedReturnPath(item.returnPath, item.domainName)">
              Применить
            </button-secondary-utp1>
          </div>
          <label class="form-text">
            Создайте системный e-mail на домене {{ item.domainName }}, например, return-path@{{ item.domainName }},
            включите безусловную переадресацию всех входящих писем (включая спам) на
            <strong>return-path@utp1.com</strong>. Рекомендуем настроить автоматическое удаление переадресованных писем
            и полностью отключить все спам-фильтры. После чего добавьте его в соответствующее поле.
          </label>
        </form>
      </div>

      <div v-if="!item.fblConfirmation" :class="item.fblMailUrl ? 'pb-3' : ''">
        <form :id="'fbl' + item.domainName.replace('.', '')" class="row" @submit.prevent novalidate>
          <div class="col-sm-4 col-md-3 col-lg-4 col-xl-3">
            <label class="col-form-label position-relative">Feedback-Loop
              <span class="badge-question bg-info" data-bs-toggle="tooltip"
                    data-bs-title="Системный e-mail для автоматической отписки кликающих на спам"><i
                  class="bi bi-question"></i></span>
            </label>
          </div>
          <div class="col-sm-5 col-md-6 col-lg-5 col-xl-6 pb-2 pb-sm-0">
            <input-utp1
                v-model="item.fbl"
                placeholder="FBL e-mail"
                type="email"
                maxlength="255"
                required
            />
          </div>
          <div class="col-sm-3">
            <button-secondary-utp1
                class="d-sm-grid"
                @buttonClick="updatedFbl(item.fbl, item.domainName)">
              Применить
            </button-secondary-utp1>
          </div>
          <label class="form-text">
            Создайте системный e-mail на домене {{ item.domainName }}, например, fbl@{{ item.domainName }},
            настройте получение fbl на сайте <a class="link-primary"
                                                @click="postmasterMailRedirect">postmaster.mail.ru</a>,
            включите безусловную переадресацию всех входящих писем (включая спам) на
            <strong>fbl@utp1.com</strong>. Рекомендуем настроить автоматическое удаление переадресованных писем
            и полностью отключить все спам-фильтры. После чего добавьте его в соответствующее поле.
            Обязательно выполняйте действия в описанной выше последовательности.
          </label>
        </form>
      </div>

      <div v-if="item.fblMailUrl">
        <div class="row">
          <div class="pb-2">Подтвердите для Postmaster Mail.ru доступ к FBL e-mail:</div>
          <div>
            <button-secondary-utp1
                class="d-sm-grid"
                @buttonClick="warningModalShow(item.fblMailUrl, item.domainName)">
              {{ item.fblMailUrl }}
            </button-secondary-utp1>
          </div>
          <label class="form-text">
            Обращаем внимание, ссылка одноразовая. Она будет безвозвратна удалена после использования.
            Перед кликом убедитесь, что Вы авторизованы на сайте <a class="link-primary"
                                                                    @click="postmasterMailRedirect">postmaster.mail.ru</a>.
            В противном случае код подтверждения не будет активирован и придётся его запрашивать повторно.
          </label>
        </div>
      </div>

    </alert-warning>
    <div class="text-secondary pb-3">
      Обычно обновления применяются в течение от нескольких минут до часа, тем не менее,
      могут быть ситуации, когда обновления вступают в силу в срок до 3-х дней.
      Системные e-mailы так же добавляются не мгновенно. Рекомендуем проверять настройки через 10 минут.
    </div>
    <button-primary-utp1
        @buttonClick="getEmailBindingConfigured"
    >Проверить настройки
    </button-primary-utp1>
  </div>

  <form id="group-email-binding" @submit.prevent novalidate>

    <div class="mb-3">
      <label for="emailBindingName" class="form-label">
        Имя отправителя
      </label>
      <!--suppress JSUnresolvedReference -->
      <input-utp1
          id="emailBindingName"
          placeholder="Имя"
          v-model:model-value="emailBinding.name"
          required
          maxlength="50"/>
    </div>

    <div class="mb-3">
      <label for="emailBindingEmail" class="form-label">
        E-mail отправителя
      </label>
      <!--suppress JSUnresolvedReference -->
      <input-utp1
          id="emailBindingEmail"
          placeholder="E-mail"
          v-model:model-value="emailBinding.email"
          type="email"
          :disabled="emailBinding.id"
          required
          maxlength="255"/>
      <label class="pt-2 form-text">
        Необходимо указать e-mail на Вашем домене, к редактированию DNS записей которого у Вас есть доступ!
        E-mailы предоставленные службами электронной почты привязаны не будут.
      </label>
      <label class="pt-1 text-secondary">
        Нет своего домена? Можете заказать по минимальной цене у наших партнёров: <a
          class="link-primary text-decoration-none" @click="orderDomain">SprintHost</a>
      </label>
    </div>

    <div class="mb-4">
      <!--suppress JSUnresolvedReference -->
      <double-button-utp1
          v-if="emailBinding.id"
          :buttons="buttons"
          @buttonPrimaryClick="updateEmailBinding(emailBinding.id)"
          @buttonSecondaryClick="cancelEmailBindings"
      >
      </double-button-utp1>
      <button-primary-utp1
          v-else
          @buttonClick="addEmailBinding"
      >Добавить
      </button-primary-utp1>
    </div>
  </form>

  <!--suppress JSUnresolvedReference -->
  <card-utp1
      v-for="item in emailBindingSettings.emailBinding"
      :cardId="item.id"
      :icon="{
        icon: !item.confirmed ? 'bi bi-hourglass-split' : !item.configured ? 'bi bi-exclamation' : item.default ? 'bi bi-check-all' : 'bi bi-check',
        background: !item.configured ? 'bg-red' : !item.confirmed ? 'bg-orange' : item.default ? 'bg-green-dark' : 'bg-green',
        title: !item.configured ? 'Ошибка DNS' : !item.confirmed ? 'Ожидает подтверждение' : item.default ? 'По-умолчанию' : 'Активирован',
        little: !item.confirmed,
        redirect: null
      }"
      :dropdown-card-menu="item.confirmed ? cardHeader : cardHeaderNoConfirmed"
      @updateEmailBindings="updateEmailBindings"
      @reAddEmailBinding="reAddEmailBinding(item.id)"
      @deleteCard="deleteEmailBinding(item.id)"
  >

    <template v-slot:cardHeader>
      <div class="fw-semibold text-truncate">
        {{ item.email }}
      </div>
    </template>

    <div class="text-truncate">
      {{ item.name }}
    </div>

    <template v-slot:cardFooter>
      <div v-if="!item.configured" class="text-truncate">Проверьте настройки домена!</div>
      <div v-else-if="!item.confirmed" class="text-truncate">Ожидается подтверждение электронной почты</div>
      <div v-else-if="item.default" class="text-truncate">По-умолчанию</div>
      <div v-else class="text-truncate">
        <a class="card-link text-decoration-none" @click="getNewEmailBindingDefault(item.id)">Назначить по-умолчанию</a>
      </div>

    </template>

  </card-utp1>

  <warning-modal @buttonClick="clickFblMailUrl">
    <template v-slot:title>
      Подтвердите действие!
    </template>
    Обращаем Ваше внимание, данная ссылка одноразовая. Она будет безвозвратна удалена после использования.
    <template v-slot:label>
      Я авторизован на <a class="link-primary"
                          @click="postmasterMailRedirect">postmaster.mail.ru</a>
    </template>
  </warning-modal>

</template>

<!--suppress JSUnusedLocalSymbols -->
<script>
import InputUtp1 from "../../../UI/inputs/InputUtp1";
import {ref, onUpdated, onMounted, watch} from "vue";
import ButtonPrimaryUtp1 from "../../../UI/buttons/ButtonPrimaryUtp1";
import DoubleButtonUtp1 from "../../../UI/buttons/DoubleButtonUtp1";
import PreloaderMain from "../../../system/preloader/PreloaderMain";
import AlertDanger from "../../../UI/alerts/AlertDanger";
import AlertWarning from "../../../UI/alerts/AlertWarning";
import AlertSuccess from "../../../UI/alerts/AlertSuccess";
import ButtonSecondaryUtp1 from "../../../UI/buttons/ButtonSecondaryUtp1";
import useMailingSettings from "../../../../hooks/rest/mailing/useMailingSettings";
import WarningModal from "../../../UI/modals/WarningModal";
import useError from "../../../../hooks/error/useError";
import CardUtp1 from "@/components/UI/cards/CardUtp1.vue";

export default {
  name: "email-binding",
  components: {
    CardUtp1,
    WarningModal,
    ButtonSecondaryUtp1,
    AlertSuccess,
    AlertWarning, AlertDanger, PreloaderMain, DoubleButtonUtp1, ButtonPrimaryUtp1, InputUtp1
  },
  props: {
    emailBindingSettings: {
      type: Object,
      default: {}
    },
    errorMessage: {
      type: String.nullable,
      default: null
    },
    emailBinding: {
      type: Object,
      default: {}
    },
    email: {
      type: String.nullable,
      default: null
    },
  },
  emits: ['getEmailBindingConfigured', 'addEmailBinding', 'getNewEmailBindingDefault', 'updateEmailBinding', 'reAddEmailBinding', 'deleteEmailBinding', 'updateEmailBindings', 'cancelEmailBindings'],
  setup(props, {emit}) {

    const cardHeader = [{
      dropdownName: "Изменить",
      dropdownEmit: "updateEmailBindings",
    }, {
      dropdownHr: true,
    }, {
      dropdownName: "Удалить",
      dropdownPush: "",
      dropdownDelete: true
    }]

    const cardHeaderNoConfirmed = [{
      dropdownName: "Изменить",
      dropdownEmit: "updateEmailBindings",
    }, {
      dropdownName: "Отправить подтверждение",
      dropdownEmit: "reAddEmailBinding",
    }, {
      dropdownHr: true,
      dropdownDelete: false
    }, {
      dropdownName: "Удалить",
      dropdownDelete: true
    }]

    const buttons = ref({
      primaryTitle: "Изменить",
      primaryDisabled: false,
      secondaryTitle: "Отменить",
      secondaryDisabled: false
    })

    const getEmailBindingConfigured = () => {
      emit('getEmailBindingConfigured')
    }

    const addEmailBinding = () => {
      const form = document.querySelector('#group-email-binding')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        emit('addEmailBinding')
      }
    }

    const getNewEmailBindingDefault = (id) => {
      emit('getNewEmailBindingDefault', id)
    }

    const updateEmailBinding = (id) => {
      const form = document.querySelector('#group-email-binding')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        emit('updateEmailBinding', id)
      }
    }

    const reAddEmailBinding = (id) => {
      emit('reAddEmailBinding', id)
    }

    const deleteEmailBinding = (id) => {
      emit('deleteEmailBinding', id)
    }

    const updateEmailBindings = (id) => {
      emit('updateEmailBindings', id)
    }

    const cancelEmailBindings = () => {
      emit('cancelEmailBindings')
    }

    const orderDomain = () => {
      window.open('https://ad.sprinthost.ru/clk?sl=YToyOntzOjM6ImNpbiI7aTo0OTE0O3M6NjoidGFyZ2V0IjtzOjIxOiIvdGFyaWZmcy9kb21haW5zLmh0bWwiO30=', '_blanc')
    }

    const copyTextFunction = (domain, type) => {
      const copyText = document.getElementById(domain + type);
      copyText.select();
      navigator.clipboard.writeText(copyText.value);
    }

    onUpdated(() => {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
      const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
    })

    const postmasterMailRedirect = () => {
      window.open('https://postmaster.mail.ru/settings/', '_blanc')
    }

    const clickFblMailUrl = async () => {
      if (fblMailUrl.value !== null) window.open(fblMailUrl.value, '_blanc')
      if (domainFbl.value !== null) await deleteFblMailUrl(domainFbl.value)
      fblMailUrl.value = null
      domainFbl.value = null
    }

    const fblMailUrl = ref(null)
    const domainFbl = ref(null)
    const warningModal = ref()

    onMounted(() => {
      warningModal.value = new bootstrap.Modal('#warningModal', {})
    })

    const warningModalShow = (url, domain) => {
      fblMailUrl.value = url
      domainFbl.value = domain
      warningModal.value.show()
    }

    const updatedReturnPath = (email, domain) => {
      const form = document.querySelector('#returnPath' + domain.replace('.', ''))
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        updateReturnPath(email, domain)
      }
    }

    const updatedFbl = (email, domain) => {
      const form = document.querySelector('#fbl' + domain.replace('.', ''))
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        updateFbl(email, domain)
      }
    }

    const {
      updateReturnPath,
      updateFbl,
      deleteFblMailUrl,
      mailingSettingsLoader,
      mailingSettingsException
    } = useMailingSettings()

    watch(mailingSettingsException, () => {
      error(mailingSettingsException.value)
    })

    const {error, errorMessage} = useError()

    return {
      cardHeader,
      updateEmailBindings,
      cancelEmailBindings,
      cardHeaderNoConfirmed,
      buttons,
      getEmailBindingConfigured,
      addEmailBinding,
      getNewEmailBindingDefault,
      updateEmailBinding,
      reAddEmailBinding,
      deleteEmailBinding,
      orderDomain,
      copyTextFunction,
      postmasterMailRedirect,
      updatedReturnPath,
      updatedFbl,
      clickFblMailUrl,
      warningModalShow,
      mailingSettingsLoader,
      emailBindingError: errorMessage
    }
  }
}
</script>

<style scoped>

</style>