<template>
  <main>
    <preloader-main v-if="loaderShopSettings"/>
    <header-tabs-utp1 class="mb-3" :header-nav="headerNav" id="navLinkUtpPay"/>
    <div class="tab-content">
      <div class="tab-pane active" id="mainSettings">

        <div class="pt-2" v-if="exceptionShopSettings">
          <alert-danger>{{ exceptionShopSettings }}</alert-danger>
        </div>

        <div v-if="!updatingName">
          <span class="lead">{{ utpPay.name }}</span>
          <a @click="startUpdatingName" class="ps-3 link-secondary bi bi-pencil-fill"></a>
        </div>

        <div v-else class="row-form pb-1 pt-2">
          <div class="row">
            <div class="col-12 col-sm">
              <input-utp1
                  id="name"
                  placeholder="Введите название магазина"
                  v-model:model-value="newUtpPay.name"
                  type="text"
                  required
                  minlength="2"
                  maxlength="255"
                  autocomplete="off"
              />
            </div>
            <div class="col-12 col-sm-auto pt-2 pt-sm-0">
              <double-button-utp1
                  :buttons="buttonUpdateName"
                  @buttonPrimaryClick="updateUtpPays"
                  @buttonSecondaryClick="finishUpdatingName"
              />
            </div>
          </div>
        </div>

        <div class="pt-3">
          <card-utp1
              :title="'UTP Pay'"
              :logo="{
                icon: 'icon-utp-icon',
                class: null,
                style: '#cb5152',
                redirect: null
              }"
              :dropdown-card-menu="null"
          >

            <template v-slot:cardHeader>Оплата через UTP Pay</template>

            <!--suppress JSUnresolvedReference -->
            <div v-if="utpPay.utpPayStatus === null" class="text-secondary">
              Для приёма оплат необходимо заполнить все данные и пройти модерацию
            </div>
            <!--suppress JSUnresolvedReference -->
            <div v-else-if="utpPay.utpPayStatus === 'DISABLED'" class="text-info">
              Для приёма оплат необходимо отправить данные на модерацию
            </div>
            <!--suppress JSUnresolvedReference -->
            <div v-else-if="['MODERATION', 'MODERATION_SUCCESS', 'MODERATION_CHECK', 'MODERATION_WARNING']
                .includes(utpPay.utpPayStatus)" class="text-primary">
              Данные проходят модерацию, это может занять какое-то время
            </div>
            <!--suppress JSUnresolvedReference -->
            <div v-else-if="['ACTIVATED', 'PLATFORM'].includes(utpPay.utpPayStatus)" class="text-success">
              Данные успешно прошли модерацию, приём оплат активирован
            </div>
            <!--suppress JSUnresolvedReference -->
            <div v-else-if="utpPay.utpPayStatus === 'FAILED'" class="text-warning">
              Предоставленные данные не прошли модерацию, приём оплат недоступен
            </div>
            <!--suppress JSUnresolvedReference -->
            <div v-else-if="utpPay.utpPayStatus === 'BLOCKED'" class="text-danger">Приём оплат заблокирован</div>
            <div v-else>Информация о приёме оплат недоступна</div>

            <template v-slot:cardFooter>
              <div class="row-form">
                <div class="row">
                  <!--suppress JSUnresolvedReference -->
                  <div class="col-sm-auto col-12 text-info">
                    <i class="bi bi-card-checklist"></i>
                    Транзакций: {{
                      utpPay.utpPayTransactionNumber ? utpPay.utpPayTransactionNumber.toLocaleString() : 0
                    }} ({{
                      utpPay.transactionNumber === 0 ? 0 : (utpPay.utpPayTransactionNumber * 100 / utpPay.transactionNumber).toFixed(2).replace('.00', '')
                    }}%)
                  </div>
                  <div class="col-sm-auto d-none d-sm-block">|</div>
                  <!--suppress JSUnresolvedReference -->
                  <div class="col-sm-auto col-12 text-success">
                    <i class="bi bi-credit-card-2-front"></i>
                    Оборот: {{
                      utpPay.utpPayTransactionSum ? utpPay.utpPayTransactionSum.toLocaleString() : 0
                    }} ₽ ({{
                      utpPay.transactionSum === 0 ? 0 : (utpPay.utpPayTransactionSum * 100 / utpPay.transactionSum).toFixed(2).replace('.00', '')
                    }}%)
                  </div>
                </div>
              </div>
            </template>
          </card-utp1>
        </div>

        <draggable
            v-model="utpPayMethod"
            direction="vertical"
            animation="150"
            item-key="draggable"
            handle=".draggable"
        >
          <!--suppress VueUnrecognizedSlot -->
          <template #item="{element}">
            <card-utp1
                :draggable="true"
                :logo="payMethodIcon(element.payMethod)"
                @deleteCard="deletePayMethod(element)"
                :dropdown-card-menu="[{
                dropdownName: 'Удалить',
                dropdownPush: '',
                dropdownHr: false,
                dropdownDelete: true
              }]">

              <template v-slot:cardHeader>
                <div class="form-check form-switch">
                  <!--suppress JSUnresolvedReference -->
                  <input class="form-check-input" type="checkbox" role="switch" v-model="element.included">
                  <div class="form-check-label">{{ payMethodName(element.payMethod) }}</div>
                </div>
              </template>

              <!--suppress JSUnresolvedReference -->
              <div class="text-secondary">
<!--                Убедитесь, что у Вас произведены необходимые настройки данного метода!-->
                Метод работает в демонстрационном режиме, будет доступен совсем скоро!
              </div>

              <template v-slot:cardFooter>
                <div class="row-form">
                  <div class="row">
                    <!--suppress JSUnresolvedReference -->
                    <div class="col-sm-auto col-12 text-info">
                      <i class="bi bi-card-checklist"></i>
                      Транзакций: {{
                        element.transactionNumber ? element.transactionNumber.toLocaleString() : 0
                      }} ({{
                        utpPay.transactionNumber === 0 ? 0 : (element.transactionNumber * 100 / utpPay.transactionNumber).toFixed(2).replace('.00', '')
                      }}%)
                    </div>
                    <div class="col-sm-auto d-none d-sm-block">|</div>
                    <!--suppress JSUnresolvedReference -->
                    <div class="col-sm-auto col-12 text-success">
                      <i class="bi bi-credit-card-2-front"></i>
                      Оборот: {{
                        element.transactionSum ? element.transactionSum.toLocaleString() : 0
                      }} ₽ ({{
                        utpPay.transactionSum === 0 ? 0 : (element.transactionSum * 100 / utpPay.transactionSum).toFixed(2).replace('.00', '')
                      }}%)
                    </div>
                  </div>
                </div>
              </template>
            </card-utp1>
          </template>
          <!--suppress VueUnrecognizedSlot -->
          <template #footer>
            <div class="row-form">
              <div class="row">
                <div class="col-sm col-12">
                  <double-button-utp1
                      :buttons="{
                        primaryTitle: 'Сохранить',
                        secondaryTitle: 'Отменить'
                       }"
                      @buttonPrimaryClick="updatePayMethod"
                      @buttonSecondaryClick="cancelPayMethod"
                  />
                </div>
                <div class="col-sm-auto col-12 pt-2 pt-sm-0">
                  <button-primary-utp1 @buttonClick="addPayMethodModalShow">Добавить способ оплаты</button-primary-utp1>
                </div>
              </div>
            </div>
          </template>
        </draggable>
      </div>

      <div class="tab-pane" id="utpPay">
        <payments-settings
            :id="id"
            :init-nav-utp-pay="initNavUtpPay"
        />
      </div>
      <div class="tab-pane" id="yooMoney">

      </div>
    </div>
  </main>
  <sidebar-right-utp1/>

  <div class="modal" tabindex="-1" id="addPayMethodModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Добавление способа оплаты</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <table class="table table-hover">
            <tbody>
            <tr v-for="item in payMethods">
              <td v-if="accessAddPayMethod(item.value)" class="cursor-pointer" @click="addPayMethod(item.value)">
                <div class="row-form">
                  <div class="row">
                    <div class="col-auto m-auto pe-0" :style="'color:' + item.icon.style">
                      <i class="fs-4" :class="item.icon.icon"/>
                    </div>
                    <div class="col align-middle">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </td>
              <td v-else class="bg-white-disabled">
                <div class="row-form">
                  <div class="row">
                    <div class="col-auto m-auto pe-0 text-secondary">
                      <i class="fs-4" :class="item.icon.icon"/>
                    </div>
                    <div class="col text-secondary align-middle">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import useShopSettings from "@/hooks/rest/shop/useShopSettings";
import {useRoute} from "vue-router";
import PreloaderMain from "@/components/system/preloader/PreloaderMain.vue";
import HeaderTabsUtp1 from "@/components/UI/headers/HeaderTabsUtp1.vue";
import CardUtp1 from "@/components/UI/cards/CardUtp1.vue";
import PaymentsSettings from "@/components/pages/shop/settings/PaymentsSettings.vue";
import SidebarRightUtp1 from "@/components/system/SidebarRightUtp1.vue";
import InputUtp1 from "@/components/UI/inputs/InputUtp1.vue";
import DoubleButtonUtp1 from "@/components/UI/buttons/DoubleButtonUtp1.vue";
import AlertDanger from "@/components/UI/alerts/AlertDanger.vue";
import ButtonPrimaryUtp1 from "@/components/UI/buttons/ButtonPrimaryUtp1.vue";
import useDeepCopy from "@/hooks/functions/useDeepCopy";
import draggable from 'vuedraggable'

export default {
  name: "shop-settings",
  components: {
    draggable,
    ButtonPrimaryUtp1,
    AlertDanger,
    DoubleButtonUtp1,
    InputUtp1,
    SidebarRightUtp1,
    PaymentsSettings,
    CardUtp1,
    HeaderTabsUtp1,
    PreloaderMain
  },
  setup() {
    const payMethods = [{
      value: "YOOMONEY_CARD",
      name: "Оплата банковскими картами через YooMoney",
      icon: {
        icon: 'icon-yoo-icon',
        class: null,
        style: '#8b3ffd',
        redirect: null
      }
    }, {
      value: "YOOMONEY_WALLET",
      name: "Оплата через YooMoney",
      icon: {
        icon: 'icon-yoo-icon',
        class: null,
        style: '#8b3ffd',
        redirect: null
      }
    }]
    const route = useRoute()
    const id = route.params.id
    const headerNav = [{
      value: "mainSettings",
      name: "Основные",
      active: true,
      disabled: false
    }, {
      value: "utpPay",
      name: "UTP Pay",
      active: false,
      disabled: false
    }, {
      value: "yooMoney",
      name: "YooMoney",
      active: false,
      disabled: true
    }]

    const {
      loaderShopSettings,
      exceptionShopSettings,
      getUtpPay,
      utpPay,
      updateUtpPay,
      updatePayMethods
    } = useShopSettings()

    const {deepCopyArray} = useDeepCopy()

    const utpPayTab = ref()
    const initNavUtpPay = ref(false)
    const addPayMethodModal = ref()

    onMounted(async () => {
      await getUtpPay(id)
      utpPayTab.value = document.querySelector('#navLinkUtpPay')
      utpPayTab.value.addEventListener('click', clickUtpPayTab)
      // noinspection JSUnresolvedReference
      utpPayMethod.value = deepCopyArray(utpPay.value.utpPayMethods)
      addPayMethodModal.value = new bootstrap.Modal('#addPayMethodModal', {})
      onbeforeunload = null
    })

    const utpPayMethod = ref([])

    const addPayMethodModalShow = () => {
      addPayMethodModal.value.show()
    }

    const accessAddPayMethod = (payMethod) => {
      // noinspection JSUnresolvedReference
      return !utpPayMethod.value.some(item => item.payMethod === payMethod)
    }

    const addPayMethod = (payMethod) => {
      utpPayMethod.value.push({
        payMethod: payMethod,
        priority: utpPayMethod.value.length,
        included: true,
        transactionNumber: 0,
        transactionSum: 0
      })
      addPayMethodModal.value.hide()
    }

    const updatePayMethod = async () => {
      const utpPayMethods = {
        utpPayMethods: utpPayMethod.value.map((item, index) => {
          return {...item, priority: index}
        })
      }
      await updatePayMethods(id, utpPayMethods)
      if (!exceptionShopSettings.value) {
        utpPay.value.utpPayMethods = deepCopyArray(utpPayMethod.value)
      }
    }

    const deletePayMethod = (payMethod) => {
      utpPayMethod.value.splice(payMethod, 1)
    }

    const cancelPayMethod = () => {
      // noinspection JSUnresolvedReference
      utpPayMethod.value = deepCopyArray(utpPay.value.utpPayMethods)
    }

    const clickUtpPayTab = () => {
      if (!initNavUtpPay.value) {
        initNavUtpPay.value = true
      }
    }

    const updatingName = ref(false)

    const startUpdatingName = () => {
      newUtpPay.value.name = utpPay.value.name
      updatingName.value = true
    }

    const finishUpdatingName = () => {
      updatingName.value = false
    }

    const newUtpPay = ref({
      name: ''
    })

    const updateUtpPays = async () => {
      await updateUtpPay(id, newUtpPay.value)
      if (!exceptionShopSettings.value) {
        utpPay.value.name = newUtpPay.value.name
        updatingName.value = false
      }
    }

    const buttonUpdateName = {
      primaryTitle: "Сохранить",
      secondaryTitle: "Отменить"
    }

    const payMethodName = (payMethodValue) => {
      const method = payMethods.find(method => method.value === payMethodValue)
      return method ? method.name : 'Оплата'
    }

    const payMethodIcon = (payMethodValue) => {
      const method = payMethods.find(method => method.value === payMethodValue)
      return method ? method.icon : {
        icon: null,
        class: null,
        style: null,
        redirect: null
      }
    }

    return {
      exceptionShopSettings,
      id,
      loaderShopSettings,
      headerNav,
      utpPay,
      initNavUtpPay,
      updatingName,
      startUpdatingName,
      finishUpdatingName,
      updateUtpPays,
      newUtpPay,
      buttonUpdateName,
      payMethodName,
      payMethodIcon,
      utpPayMethod,
      payMethods,
      addPayMethodModalShow,
      accessAddPayMethod,
      addPayMethod,
      updatePayMethod,
      cancelPayMethod,
      deletePayMethod
    }
  }
}
</script>

<style scoped>
.bg-white-disabled {
  --bs-table-hover-bg: white !important;
}

.card-after {
  position: relative;
  padding-right: 2rem !important;
  box-sizing: border-box;
}

.card-after::after {
  content: '...'; /* Три точки */
  position: absolute;
  top: calc(50% - 1rem);
  right: 5px; /* Расстояние от края карточки до точек */
  transform: translateY(-50%);
  color: #666; /* Цвет точек */
  width: 20px; /* Ширина области с точками */
  text-align: center;
  cursor: move;
}
</style>
