<template>
  <div class="row-form">
    <div class="row">
      <label class="form-label" for="selectEmailBinding">Отправить от имени:</label>
      <div class="pb-3">
        <select id="selectEmailBinding" class="form-select"
                v-model="localNewMailingLetters.mailingLetters.emailBindingId"
                :disabled="readOnly">
          <option :value="null" disabled>
            Выберите отправителя...
          </option>
          <option v-for="item in localNewMailingLetters.emailBindings" :value="item.id">
            {{ item.name + ' <' + item.email + '>' }}
          </option>
        </select>
      </div>
      <label class="form-label" for="letterTopic">Тема письма:</label>
      <div class="pb-3">
        <input-utp1
            id="letterTopic"
            v-model:model-value="localNewMailingLetters.mailingLetters.letterTopic"
            placeholder="Введите тему"
            type="text"
            minlength="1"
            maxlength="255"
            autocomplete="off"
            :disabled="readOnly"
            :readonly="readOnly"
        />
      </div>
      <label class="form-label" for="letterTitle">Заголовок письма (ключевую мысль выделите *звёздочками*):</label>
      <div class="pb-3">
        <input-utp1
            id="letterTitle"
            v-model:model-value="localNewMailingLetters.mailingLetters.letterTitle"
            placeholder="Введите заголовок"
            type="text"
            minlength="1"
            maxlength="255"
            autocomplete="off"
            :disabled="readOnly"
            :readonly="readOnly"
        />
      </div>
      <div class="pb-2">
        <!--suppress XmlInvalidId -->
        <label class="form-label" for="letterText">Текст письма:</label>
        <markdown-wysiwyg
            :id="'letterText'"
            :links="localNewMailingLetters.mailingLetterLinks"
            :images="localNewMailingLetters.mailingLetterImages"
            :variables="variablesLetterText"
            v-model:model-value="localNewMailingLetters.mailingLetters.letterText"
            :disabled="readOnly"
            :readonly="readOnly"
            @uploadImageMailingModalShow="uploadImageMailingModalShow"
        />
      </div>

      <div class="row pb-3">
        <div class="col-auto pb-2">
          <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#applySocialModal"
                  :disabled="readOnly">
            Применить для соц. сетей
          </button>
        </div>
        <div class="col-auto pb-3">
          <button class="btn btn-secondary" @click="previewEmailLettersModalShow">
            Предпросмотр
          </button>
        </div>
      </div>

      <label class="form-label">Ссылки в письме:</label>

      <div v-for="item in localNewMailingLetters.mailingLetterLinks" class="row">
        <div class="col-sm pb-2">
          <input-url-utp1
              :placeholder="'URL ссылки'"
              :required="true"
              :disabled="readOnly"
              :readonly="readOnly"
              v-model:url="item.linkUrl"
          />
        </div>
        <div class="col-sm-4 col pb-3">
          <input-utp1
              v-model:model-value="item.linkText"
              placeholder="Текст ссылки"
              type="text"
              minlength="1"
              maxlength="255"
              autocomplete="off"
              :disabled="readOnly"
              :readonly="readOnly"
          />
        </div>
        <div class="col-sm-2 col-auto pb-2">
          <input type="text" :id="'copyLink' + item.letterLinkId" readonly
                 :value="'[#link' + item.letterLinkId + '()]'"
                 class="form-control-plaintext mailing-letter-code text-center small cursor-pointer"
                 @click="copyTextFunction('copyLink' + item.letterLinkId)">
        </div>
        <div class="col-auto pb-2">
          <button type="button" class="btn btn-outline-danger rounded-circle" :disabled="readOnly"
                  @click="deleteMailingLettersLinks(item.letterLinkId)">
            <i class="bi bi-x-lg mx--125"></i>
          </button>
        </div>
      </div>
      <label v-if="localNewMailingLetters.mailingLetterLinks.length > 0"
             class="form-text pb-2" style="margin-top: -0.5rem">
        Обращаем Ваше внимание! Удаление ссылки может привести к изменению индексов других ссылок.
        При этом из текста письма ссылки с несуществующими индексами автоматически удаляться не будут.
      </label>
      <div class="pb-3">
        <button-primary-utp1 @buttonClick="addMailingLettersLinks" :disabled="readOnly">
          Добавить ссылку
        </button-primary-utp1>
      </div>

      <label class="form-label">Изображения для e-mail:</label>

      <div v-for="item in localNewMailingLetters.mailingLetterImages" class="col-12">
        <div class="card p-3 mb-2">
          <div class="row row-form">
            <div class="col-auto text-center pb-1" style="min-width: 100px">
              <img v-if="item.imageUrl" :src="item.imageUrl.substring(0, 36) === 'https://src.utp1.com/images/mailing/' ?
              'https://src.utp1.com/images/mailing/mini/' + item.imageUrl.substring(36) : item.imageUrl"
                   style="max-width: 76px; max-height: 76px;" alt="Mailing letter image">
              <img v-else src="../../../../src/assets/images/gravatar.jpg"
                   style="max-width: 76px; max-height: 76px;" alt="Mailing letter image">
            </div>

            <div v-if="displayWidth <= 380" class="col"></div>
            <div v-if="displayWidth <= 380" class="col-auto">
              <div class="pb-2 text-end">
                <button type="button" class="btn btn-outline-danger rounded-circle"
                        @click="deleteMailingLettersImages(item.letterImageId)">
                  <i class="bi bi-x-lg mx--125"></i>
                </button>
              </div>
              <div class="pb-2 text-end">
                <input type="text" :id="'copyImage' + item.letterImageId" readonly
                       :value="'[#imag' + item.letterImageId + '()]'"
                       class="form-control-plaintext mailing-letter-code text-center small cursor-pointer"
                       @click="copyTextFunction('copyImage' + item.letterImageId)">
              </div>
            </div>

            <div class="col px-0">
              <div class="row">
                <div class="col px-0">
                  <div class="row row-form">
                    <div class="col-sm pb-2">
                      <input-url-utp1
                          v-model:url="item.imageUrl"
                          :placeholder="'URL изображения'"
                          :required="true"
                          :disabled="readOnly"
                          :readonly="readOnly"
                      />
                    </div>
                    <div class="col-sm-auto pb-2">
                      <button-secondary-utp1 @buttonClick="uploadImageMailingModalShow(item.letterImageId)"
                                             :disabled="readOnly">
                        Загрузить
                      </button-secondary-utp1>
                    </div>
                    <div class="col-12">
                      <input-url-utp1
                          v-model:url="item.imageLink"
                          :disabled="readOnly"
                          :readonly="readOnly"
                          :placeholder="'Ссылка при клике'"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="displayWidth >= 381" class="col-auto">
                  <div class="pb-2 text-end">
                    <button type="button" class="btn btn-outline-danger rounded-circle" :disabled="readOnly"
                            @click="deleteMailingLettersImages(item.letterImageId)">
                      <i class="bi bi-x-lg mx--125"></i>
                    </button>
                  </div>
                  <div class="pb-2 text-end">
                    <input type="text" :id="'copyImage' + item.letterImageId" readonly
                           :value="'[#imag' + item.letterImageId + '()]'"
                           class="form-control-plaintext mailing-letter-code text-center small cursor-pointer"
                           @click="copyTextFunction('copyImage' + item.letterImageId)">
                  </div>
                </div>
                <label class="form-text">
                  Рекомендуемая ширина 500px, формат JPG. Отношение сторон не более чем 3:1.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <label v-if="localNewMailingLetters.mailingLetterImages.length > 0" class="form-text pb-2">
        Обращаем Ваше внимание! Удаление изображения может привести к изменению индексов других изображений.
        При этом из текста письма изображения с несуществующими индексами автоматически удаляться не будут.
      </label>
      <div class="pb-3">
        <button-primary-utp1 @buttonClick="addMailingLettersImages" :disabled="readOnly">
          Добавить изображение
        </button-primary-utp1>
      </div>
      <label class="form-label" for="actionText">Призыв к действию:</label>
      <div class="pb-3">
        <input-utp1
            id="actionText"
            v-model:model-value="localNewMailingLetters.mailingLetters.actionText"
            placeholder="Введите призыв к действию"
            type="text"
            minlength="1"
            maxlength="255"
            autocomplete="off"
            :disabled="readOnly"
            :readonly="readOnly"
        />
      </div>
      <label class="form-label">Кнопка внизу письма:</label>
      <div class="col-sm-6 pb-2">
        <input-url-utp1 v-model:url="localNewMailingLetters.mailingLetters.actionButtonUrl" :placeholder="'URL кнопки'"
                        :disabled="readOnly" :readonly="readOnly"/>
      </div>
      <div class="col-sm-6 pb-3">
        <input-utp1
            v-model:model-value="localNewMailingLetters.mailingLetters.actionButtonText"
            placeholder="Текст кнопки"
            type="text"
            minlength="1"
            maxlength="255"
            autocomplete="off"
            :disabled="readOnly"
            :readonly="readOnly"
        />
      </div>

      <div class="pb-2">
        <button-primary-utp1
            data-bs-toggle="collapse" data-bs-target="#showAdditionalBlocks"
            aria-expanded="false" aria-controls="showAdditionalBlocks">
          Показать дополнительные блоки <i class="ps-1 bi bi-caret-down-fill"></i>
        </button-primary-utp1>
      </div>
      <div class="row collapse" id="showAdditionalBlocks">
        <label class="form-label" for="warningText">Предупреждение (НЕ злоупотребляйте):</label>
        <div class="col-sm-10 col-12 pb-2">
          <textarea
              class="form-control" id="warningText" rows="2"
              v-model="localNewMailingLetters.mailingLetters.warningText"
              placeholder="Введите текст предупреждения"
              type="text"
              minlength="1"
              maxlength="255"
              autocomplete="off"
              :disabled="readOnly"
              :readonly="readOnly"
          ></textarea>
        </div>
        <div class="col-auto pb-3">
          <input type="text" id="copyWarningText" readonly value="[#warn()]"
                 class="form-control-plaintext mailing-letter-code text-center small cursor-pointer"
                 @click="copyTextFunction('copyWarningText')">
        </div>
        <label class="form-label" for="informationTitle">Заголовок блока информации:</label>
        <div class="col-sm-10 col-12 pb-2">
          <input-utp1
              id="informationTitle"
              v-model:model-value="localNewMailingLetters.mailingLetters.informationTitle"
              placeholder="Введите заголовок блока информации"
              type="text"
              minlength="1"
              maxlength="255"
              autocomplete="off"
              :disabled="readOnly"
              :readonly="readOnly"
          />
        </div>
        <div class="d-none d-sm-block col-auto pb-2">
          <input type="text" id="copyInformation" readonly value="[#info()]"
                 class="form-control-plaintext mailing-letter-code text-center small cursor-pointer"
                 @click="copyTextFunction('copyInformation')">
        </div>
        <!--suppress XmlInvalidId -->
        <label class="form-label" for="informationText">Текст блока информации:</label>
        <div class="col-sm-10 col-12 pb-2 pb-sm-3">
          <markdown-wysiwyg
              :id="'informationText'"
              :rows="7"
              :links="localNewMailingLetters.mailingLetterLinks"
              :images="localNewMailingLetters.mailingLetterImages"
              :variables="variablesLetterText"
              v-model:model-value="localNewMailingLetters.mailingLetters.informationText"
              :disabled="readOnly"
              :readonly="readOnly"/>
        </div>
        <div class="d-block d-sm-none col-auto pb-2">
          <input type="text" id="copyInformationSm" readonly value="[#info()]"
                 class="form-control-plaintext mailing-letter-code text-center small cursor-pointer"
                 @click="copyTextFunction('copyInformationSm')">
        </div>
      </div>
    </div>
  </div>
  <!--suppress JSValidateTypes -->
  <uploadImageMailingModal
      :letter-image-id="letterImageId"
      :modal-id="'uploadImageMailingModal'"
      :form-id="'imageEmailModalForm'"
      :mailing-type="'SINGLE'"
      :init="initUploadImageMailingModal"
      @uploadImageMailing="updateMailingLettersImages"
  />
  <!--suppress JSValidateTypes -->
  <preview-email-letters-modal
      :id="'previewEmailLettersModal'"
      :emailLetterHtmlString="emailLetterHtmlString"
      :preloader="mailingSettingsLoader"
  />
  <apply-social
      @applySocial="applySocial"
  />
</template>

<!--suppress JSUnresolvedReference -->
<script>
import InputUtp1 from "../../UI/inputs/InputUtp1";
import ButtonPrimaryUtp1 from "../../UI/buttons/ButtonPrimaryUtp1";
import InputUrlUtp1 from "../../UI/inputs/InputUrlUtp1";
import ButtonSecondaryUtp1 from "../../UI/buttons/ButtonSecondaryUtp1";
import {onMounted, ref, watch} from "vue";
import UploadImageMailingModal from "../../UI/modals/UploadImageMailingModal";
import MarkdownWysiwyg from "../../UI/inputs/MarkdownWysiwyg";
import PreviewEmailLettersModal from "../../UI/modals/PreviewEmailLettersModal";
import useMailingSettings from "../../../hooks/rest/mailing/useMailingSettings";
import DoubleButtonUtp1 from "../../UI/buttons/DoubleButtonUtp1";
import ApplySocial from "../../UI/modals/ApplySocial";
import useApplySocial from "../../../hooks/functions/useApplySocial";

export default {
  name: "mailing-letter",
  components: {
    ApplySocial,
    DoubleButtonUtp1,
    PreviewEmailLettersModal,
    MarkdownWysiwyg,
    UploadImageMailingModal,
    ButtonSecondaryUtp1,
    InputUrlUtp1,
    ButtonPrimaryUtp1,
    InputUtp1
  },
  props: {
    newMailingLetters: {
      mailingLetters: {},
      mailingLetterLinks: [],
      mailingLetterImages: [],
      vkLetterLinks: [],
      vkLetterImages: [],
      emailBindings: [],
      vkBindings: []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.localNewMailingLetters = this.newMailingLetters
  },
  watch: {
    newMailingLetters() {
      this.localNewMailingLetters = this.newMailingLetters
    }
  },
  emits: ['update:newMailingLetters'],
  setup(props, {emit}) {
    const localNewMailingLetters = ref({
      mailingLetters: {},
      mailingLetterLinks: [],
      mailingLetterImages: [],
      vkLetterLinks: [],
      vkLetterImages: [],
      emailBindings: [],
      vkBindings: []
    })

    watch(localNewMailingLetters, (newVal) => {
      emit('update:newMailingLetters', newVal)
    })

    const initUploadImageMailingModal = ref(false)

    const variablesLetterText = [{
      name: 'Имя',
      value: '[#name()]'
    }, {
      name: 'Контакт',
      value: '[#contact()]'
    }, {
      name: 'Город',
      value: '[#city()]'
    }, {
      name: 'Страна',
      value: '[#country()]'
    }, {
      name: 'Дата рождения',
      value: '[#birthday()]'
    }, {
      name: 'Автор',
      value: '[#author()]'
    }]

    const copyTextFunction = (id) => {
      const copyText = document.getElementById(id);
      copyText.select();
      navigator.clipboard.writeText(copyText.value)
    }

    const addMailingLettersLinks = () => {
      localNewMailingLetters.value.mailingLetterLinks.push({
        letterLinkId: localNewMailingLetters.value.mailingLetterLinks.length,
        linkUrl: "",
        linkText: ""
      })
    }

    const deleteMailingLettersLinks = (letterLinkId) => {
      localNewMailingLetters.value.mailingLetterLinks.splice(letterLinkId, 1)
      localNewMailingLetters.value.mailingLetterLinks.forEach(item => {
        item.letterLinkId = localNewMailingLetters.value.mailingLetterLinks.indexOf(item)
      })
    }

    const addMailingLettersImages = () => {
      localNewMailingLetters.value.mailingLetterImages.push({
        letterImageId: localNewMailingLetters.value.mailingLetterImages.length,
        imageUrl: "",
        imageLink: ""
      })
    }

    const updateMailingLettersImages = (letterImageId, selectedImg) => {
      localNewMailingLetters.value.mailingLetterImages.forEach(item => {
        if (item.letterImageId === letterImageId) item.imageUrl = selectedImg
      })
      uploadImageMailingModalHide()
    }

    const deleteMailingLettersImages = (letterImageId) => {
      localNewMailingLetters.value.mailingLetterImages.splice(letterImageId, 1)
      localNewMailingLetters.value.mailingLetterImages.forEach(item => {
        item.letterImageId = localNewMailingLetters.value.mailingLetterImages.indexOf(item)
      })
    }

    const letterImageId = ref(null)

    const uploadImageMailingModal = ref(null)
    const previewEmailLettersModal = ref(null)

    const displayWidth = ref(document.querySelector('#app').offsetWidth)

    onMounted(() => {
      uploadImageMailingModal.value = new bootstrap.Modal('#uploadImageMailingModal', {})
      previewEmailLettersModal.value = new bootstrap.Modal('#previewEmailLettersModal', {})
      window.addEventListener('resize', updateWidth);
      displayWidth.value = document.querySelector('#app').offsetWidth
    })

    const updateWidth = () => {
      displayWidth.value = document.querySelector('#app').offsetWidth
    }

    const uploadImageMailingModalShow = (id) => {
      letterImageId.value = id
      initUploadImageMailingModal.value = true
      uploadImageMailingModal.value.show()
    }

    const uploadImageMailingModalHide = () => {
      letterImageId.value = null
      uploadImageMailingModal.value.hide()
    }

    const {
      mailingSettingsLoader,
      emailLetterHtmlString,
      previewEmailLetters
    } = useMailingSettings()

    const previewEmailLettersModalShow = () => {
      previewEmailLetters(localNewMailingLetters.value)
      previewEmailLettersModal.value.show()
    }

    const {applyVk} = useApplySocial()

    const applySocial = () => {
      const vkText = applyVk(localNewMailingLetters.value.mailingLetters)
      if (vkText) {
        localNewMailingLetters.value.mailingLetters.vkText = vkText.length > 3277 ? vkText.substring(0, 3277) : vkText
      }
      localNewMailingLetters.value.vkLetterLinks = []
      localNewMailingLetters.value.mailingLetterLinks.forEach(link => {
        localNewMailingLetters.value.vkLetterLinks.push(Object.assign({}, link))
      })
      localNewMailingLetters.value.mailingLetters.vkActionButtonUrl = localNewMailingLetters.value.mailingLetters.actionButtonUrl
      if (localNewMailingLetters.value.mailingLetters.actionButtonText) {
        localNewMailingLetters.value.mailingLetters.vkActionButtonText = localNewMailingLetters.value.mailingLetters.actionButtonText.length > 40 ?
            localNewMailingLetters.value.mailingLetters.actionButtonText.substring(0, 40) : localNewMailingLetters.value.mailingLetters.actionButtonText
      }
    }

    return {
      localNewMailingLetters,
      copyTextFunction,
      addMailingLettersLinks,
      deleteMailingLettersLinks,
      addMailingLettersImages,
      updateMailingLettersImages,
      deleteMailingLettersImages,
      uploadImageMailingModalShow,
      previewEmailLettersModalShow,
      letterImageId,
      initUploadImageMailingModal,
      variablesLetterText,
      displayWidth,
      mailingSettingsLoader,
      emailLetterHtmlString,
      applySocial
    }
  }
}
</script>

<style scoped>
.mx--125 {
  margin-right: -0.125rem !important;
  margin-left: -0.125rem !important;
}

.mailing-letter-code {
  width: 70px;
  color: #842029;
  background-color: #f8d7da;
}
</style>