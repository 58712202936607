<!--suppress HtmlUnknownTag -->
<template>

  <div class="d-none d-lg-block sidebar-utp1 pe-0 col-lg-2">
    <aside class="navbar sidebar-utp1-height sticky-top overflow-auto pe-4">
      <ul class="navbar-nav nav-pills">
        <div v-for="item in sidebar" class="text-truncate" style="max-width: 100%">
          <li v-if="!item.hr" class="nav-item">
            <a @click="$router.push(item.route)"
               :class="item.disabled ?
             'nav-link ps-3 d-flex disabled' :
             ((section === item.section) || (section === item.path)) ?
             'nav-link ps-3 d-flex active':
             'nav-link ps-3 d-flex'">
              <i :class="'me-2 bi ' + 'bi-'+item.icon"></i>
              {{ item.name }}
            </a>
          </li>
          <li v-else-if="!item.disabled" class="px-3">
            <hr>
          </li>
        </div>
      </ul>
    </aside>
  </div>

  <div class="d-lg-none d-block">
    <aside class="nav-scroller">
      <div class="navbar fixed-bottom bg-white">
        <nav class="navbar-nav" aria-label="Secondary navigation">
          <div v-for="item in sidebar">
            <div v-if="!item.hr">
              <a @click="$router.push(item.route)"
                 :class="item.disabled ? 'nav-link disabled ' : ((section === item.section) || (section === item.path)) ? 'nav-link active ' : 'nav-link '"
                 :title=item.name data-bs-toggle="tooltip" data-bs-placement="top">
                <i :class="'fs-2 icons bi ' + 'bi-'+item.icon"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </aside>
  </div>

</template>

<!--suppress JSUnusedLocalSymbols, ES6ShorthandObjectProperty -->
<script>
import {useRoute} from "vue-router";
import {onMounted, ref, watch} from "vue";

export default {
  name: "sidebar-utp1",
  setup() {
    const sidebar = [
      {
        disabled: false,
        hr: false,
        name: "Магазин",
        route: "/",
        section: "shop",
        path: "",
        icon: "cart3"
      },
      {
        disabled: false,
        hr: false,
        name: "Рассылка",
        route: "/mailing",
        section: "mailing",
        icon: "send"
      },
      {
        disabled: true,
        hr: false,
        name: "Сайт",
        route: "/suite",
        section: "suite",
        icon: "globe2"
      },
      {
        disabled: true,
        hr: false,
        name: "Обучение",
        route: "/education",
        section: "education",
        icon: "mortarboard"
      },
      {
        disabled: true,
        hr: false,
        name: "Маркетинг",
        route: "/marketing",
        section: "marketing",
        icon: "pie-chart"
      },
      {
        disabled: true,
        hr: false,
        name: "Аналитика",
        route: "/analytics",
        section: "analytics",
        icon: "bar-chart"
      },
      {
        disabled: true,
        hr: false,
        name: "Уведомления",
        route: "/notifications",
        section: "notifications",
        icon: "alarm"
      },
      {
        disabled: false,
        hr: true,
        name: "",
        route: "",
        section: "",
        icon: ""
      },
      {
        disabled: true,
        hr: false,
        name: "Сотрудники",
        route: "/employees",
        section: "employees",
        icon: "people"
      },
      {
        disabled: true,
        hr: false,
        name: "Поддержка",
        route: "/support",
        section: "support",
        icon: "headset"
      },
      {
        disabled: true,
        hr: false,
        name: "Партнёры",
        route: "/network-marketing",
        section: "network-marketing",
        icon: "diagram-3"
      },
      {
        disabled: false,
        hr: true,
        name: "",
        route: "",
        section: "",
        icon: ""
      },
      {
        disabled: true,
        hr: false,
        name: "Рекомендуем",
        route: "/recommended",
        section: "recommended",
        icon: "exclamation-triangle"
      },
      {
        disabled: true,
        hr: false,
        name: "Наш блог",
        route: "/blog",
        section: "blog",
        icon: "newspaper"
      }
    ]

    const route = useRoute()
    const path = () => {
      return route.path.substring(1).split('/')[0].toLowerCase()
    }
    const section = ref(path())
    watch(route, () => {
      section.value = path()
    })

    onMounted(() => {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
      const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
    })

    return {sidebar, section}
  }
}
</script>

<style scoped>
@import "../../assets/css/sidebar.css";
</style>