import {createRouter, createWebHistory} from 'vue-router'
import NotFound from "../views/NotFound";
import Home from "../views/Home";
import Security from "../views/settings/Security";
import Settings from "../views/settings/Settings";
import GroupsCategories from "../views/mailing/contacts/GroupsCategories";
import AddGroupsCategories from "../views/mailing/contacts/AddGroupsCategories";
import EditGroupsCategories from "../views/mailing/contacts/EditGroupsCategories";
import Groups from "../views/mailing/contacts/Groups";
import AddGroups from "../views/mailing/contacts/AddGroups";
import EditGroups from "../views/mailing/contacts/EditGroups";
import Contacts from "../views/mailing/contacts/Contacts";
import AddContact from "../views/mailing/contacts/AddContact";
import Contact from "../views/mailing/contacts/Contact";
import MailingEmailSettings from "../views/mailing/settings/MailingEmailSettings";
import ConfirmedEmailBinding from "../views/mailing/settings/ConfirmedEmailBinding";
import SingleMailings from "../views/mailing/single/SingleMailings";
import AddSingleMailing from "../views/mailing/single/AddSingleMailing";
import DuplicateSingleMailing from "../views/mailing/single/DuplicateSingleMailing";
import EditSingleMailing from "../views/mailing/single/EditSingleMailing";
import externalAuth from "../views/settings/ExternalAuth";
import Products from "../views/shop/products/Products";
import AddProduct from "../views/shop/products/AddProduct";
import EditProduct from "../views/shop/products/EditProduct";
import ProductsCategories from "../views/shop/products/ProductsCategories";
import AddProductsCategory from "../views/shop/products/AddProductsCategory";
import EditProductsCategory from "../views/shop/products/EditProductsCategory";
import CopyProduct from "../views/shop/products/CopyProduct";
import Invoices from "@/views/shop/invoices/Invoices.vue";
import AddInvoice from "@/views/shop/invoices/AddInvoice.vue";
import Invoice from "@/views/shop/invoices/Invoice.vue";
import ShopSettings from "@/views/shop/settings/ShopSettings.vue";
import AddShopSetting from "@/views/shop/settings/AddShopSetting.vue";
import ShopSetting from "@/views/shop/settings/ShopSetting.vue";

const routes = [
    {
        path: '/',
        name: 'Стартовая страница',
        component: Home
    },
    {
        path: '/settings',
        name: 'Настройки',
        component: Settings
    },
    {
        path: '/settings/security',
        name: 'Безопасность',
        component: Security
    },
    {
        path: '/settings/external-auth',
        name: 'Внешняя авторизация',
        component: externalAuth
    },
    {
        path: '/mailing',
        name: 'Разовая рассылка',
        component: SingleMailings
    },
    {
        path: '/mailing/single/add',
        name: 'Создать разовый выпуск',
        component: AddSingleMailing
    },
    {
        path: '/mailing/single/:id',
        name: 'Изменить разовый выпуск',
        component: EditSingleMailing
    },
    {
        path: '/mailing/single/add/:id',
        name: 'Дублировать разовый выпуск',
        component: DuplicateSingleMailing
    },

    {
        path: '/mailing/contacts',
        name: 'Контакты',
        component: Contacts
    },
    {
        path: '/mailing/contacts/add',
        name: 'Добавить контакт',
        component: AddContact
    },
    {
        path: '/mailing/contacts/:id',
        name: 'Редактировать контакт',
        component: Contact
    },
    {
        path: '/mailing/contacts/groups',
        name: 'Гроппы контактов',
        component: Groups
    },

    {
        path: '/mailing/contacts/groups/add',
        name: 'Добавить группу контактов',
        component: AddGroups
    },
    {
        path: '/mailing/contacts/groups/:id',
        name: 'Изменить группу контактов',
        component: EditGroups
    },
    {
        path: '/mailing/contacts/groups-categories',
        name: 'Категории групп',
        component: GroupsCategories
    },
    {
        path: '/mailing/contacts/groups-categories/add',
        name: 'Добавить категорию групп',
        component: AddGroupsCategories
    },
    {
        path: '/mailing/contacts/groups-categories/:id',
        name: 'Изменить категорию групп',
        component: EditGroupsCategories
    },
    {
        path: '/mailing/settings',
        name: 'Настройки рассылки',
        component: MailingEmailSettings
    },
    {
        path: '/mailing/settings/:code',
        name: 'Подтверждение e-mailа',
        component: ConfirmedEmailBinding
    },
    {
        path: '/shop/invoices',
        name: 'Счета',
        component: Invoices
    },
    {
        path: '/shop/invoices/add',
        name: 'Создать счет',
        component: AddInvoice
    },
    {
        path: '/shop/invoices/:id',
        name: 'Детали счета',
        component: Invoice
    },
    {
        path: '/shop/products',
        name: 'Продукты',
        component: Products
    },
    {
        path: '/shop/products/add',
        name: 'Добавить продукт',
        component: AddProduct
    },
    {
        path: '/shop/products/add/:id',
        name: 'Копировать продукт',
        component: CopyProduct
    },
    {
        path: '/shop/products/:id',
        name: 'Редактировать продукт',
        component: EditProduct
    },
    {
        path: '/shop/products/categories',
        name: 'Категории продуктов',
        component: ProductsCategories
    },
    {
        path: '/shop/products/categories/add',
        name: 'Добавить категорию продуктов',
        component: AddProductsCategory
    },
    {
        path: '/shop/products/categories/:id',
        name: 'Изменить категорию продуктов',
        component: EditProductsCategory
    },
    {
        path: '/shop/settings',
        name: 'Магазины',
        component: ShopSettings
    },
    {
        path: '/shop/settings/add',
        name: 'Добавить магазин',
        component: AddShopSetting
    },
    {
        path: '/shop/settings/:id',
        name: 'Настройки магазина',
        component: ShopSetting
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404 NotFound',
        component: NotFound,
    }
]


// noinspection JSCheckFunctionSignatures
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach((to) => {
    window.document.title = to.name ? to.name + ' | UTP1' : 'UTP1.com'
})

/*// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta)

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next()

    // Turn the meta tag definitions into actual elements in the head.
    // noinspection JSUnresolvedFunction
    nearestWithMeta.meta.map(tagDef => {
        const tag = document.createElement('meta')

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key])
        })

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '')

        return tag
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag))

    next()
})*/

export default router
