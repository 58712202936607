<template>
  <main>
    <preloader-main v-if="contactsLoader"/>
    <header-back-status-utp1
        :header="header"
        :status="contact.status"
    />

    <header-nav-utp1 :nav="nav"/>

    <div class="tab-content">

      <div class="tab-pane active" id="basic">
        <contact-basic :contact="contact"/>
      </div>

      <div class="tab-pane" id="groups">
        <contact-groups :contacts="contact.contacts" @updateContact="updateContact"/>
      </div>

    </div>

    <delete-object
        @deleteObject="deleteContacts"
    />

    <div class="pt-3 bottom-0 start-0">
      <button-secondary-utp1 :disabled="false" data-bs-toggle="modal" data-bs-target="#deleteObjectModal">
        Удалить контакт
      </button-secondary-utp1>
    </div>

  </main>
  <sidebar-right-utp1/>
</template>

<!--suppress JSUnresolvedVariable -->
<script>
import HeaderBackStatusUtp1 from "../../../components/UI/headers/HeaderBackStatusUtp1";
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import useContacts from "../../../hooks/rest/mailing/contacts/useContacts";
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";
import HeaderNavUtp1 from "../../../components/UI/headers/HeaderNavUtp1";
import ContactBasic from "../../../components/pages/mailing/contacts/ContactBasic";
import ContactGroups from "../../../components/pages/mailing/contacts/ContactGroups";
import ButtonSecondaryUtp1 from "../../../components/UI/buttons/ButtonSecondaryUtp1";
import router from "../../../router";
import DeleteObject from "../../../components/UI/modals/DeleteObject";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";

export default {
  name: "contact",
  components: {
    SidebarRightUtp1,
    DeleteObject,
    ButtonSecondaryUtp1,
    ContactGroups, ContactBasic, HeaderNavUtp1, PreloaderMain, HeaderBackStatusUtp1
  },
  setup() {
    const route = useRoute()
    const profileId = route.params.id

    const header = {
      title: "Детали контакта",
      buttonRedirect: "/mailing/contacts"
    }
    const nav = [{
      value: "basic",
      name: "Основное",
      active: true,
      disabled: false
    }, {
      value: "groups",
      name: "Группы",
      active: false,
      disabled: false
    }, {
      value: "mailing",
      name: "Рассылки",
      active: false,
      disabled: true
    }, {
      value: "clicks",
      name: "Клики",
      active: false,
      disabled: true
    }, {
      value: "orders",
      name: "Заказы",
      active: false,
      disabled: true
    }, {
      value: "education",
      name: "Обучение",
      active: false,
      disabled: true
    }, {
      value: "commissions",
      name: "Комиссии",
      active: false,
      disabled: true
    }]

    const deleteContacts = async () => {
      await deleteContact(profileId)
      await router.push('/mailing/contacts')
    }

    const updateContact = () => {
      getContact(profileId)
    }

    const {contactsLoader, contact, getContact, deleteContact} = useContacts()

    const popoverList = ref()

    onMounted(async () => {
      await getContact(profileId)
      const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
      popoverList.value = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl, {
        trigger: 'focus'
      }))
    })

    return {header, contact, contactsLoader, nav, deleteContacts, updateContact}
  }
}
</script>

<style scoped>

</style>