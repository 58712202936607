<template>
  <main>
    <preloader-main v-if="loaderProducts"/>
    <header-button-utp1
        :header="header"
        @buttonClick="$router.push('/shop/products/categories/add')"
    />
    <table class="table table-hover">
      <tbody>
      <tr v-for="item in productsCategories.productsCategories" @click="getProductsCategory(item.id)" class="cursor-pointer">
        <td>{{ item.name }}</td>
      </tr>
      </tbody>
    </table>
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import HeaderButtonUtp1 from "../../../components/UI/headers/HeaderButtonUtp1";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";
import useProducts from "../../../hooks/rest/shop/useProducts";
import {onMounted} from "vue";
import router from "../../../router";
export default {
  name: "products-categories",
  components: {SidebarRightUtp1, HeaderButtonUtp1, PreloaderMain},
  setup() {
    const { loaderProducts, getProductsCategories, productsCategories } = useProducts()

    const header = {
      title: "Категории продуктов",
      buttonTitle: "Добавить категорию"
    }

    const getProductsCategory = (id) => {
      router.push('/shop/products/categories/' + id)
    }

    onMounted(() => {
      getProductsCategories()
    })

    return {header, loaderProducts, productsCategories, getProductsCategory}
  }
}
</script>

<style scoped>

</style>