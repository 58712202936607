export const auth = {
    state: () => ({
        name: "",
        userId: null,
        login: "",
        auth: null,
        updateTokens: false,
        promise: null
    }),
    getters: {
        name(state) {
            return state.name
        }
    },
    mutations: {
        auth(state, basic) {
            state.name = basic.name
            state.userId = basic.id
            state.login = basic.login
            state.auth = true
        },
        updateName(state, name) {
            state.name = name
        },
        unAuth(state) {
            state.name = ""
            state.userId = null
            state.auth = false
        },
        updateTokens(state, promise) {
            state.updateTokens = true
            state.promise = promise
        },
        unUpdateTokens(state) {
            state.updateTokens = false
            state.promise = null
        }
    },
    actions: {},
    namespaced: true
}
