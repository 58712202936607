import axios from "axios";
import {ref} from 'vue';
import useTokens from "../../../useTokens";
import router from "../../../../router";

export default function useGroups() {

    const groupsLoader = ref(false)
    const groupsException = ref(null)
    const groupCategory = ref({
        id: null,
        name: "",
        vkGroupId: null
    })
    const groupsCategories = ref(null)
    const getAllGroup = ref({
        id: null,
        name: "",
        categoryId: null,
        categoryName: null
    })
    const getAllGroups = ref(null)
    const group = ref({
        id: null,
        name: "",
        categoryId: null,
        categories: [groupCategory]
    })
    const groupsList = ref({
        categories: [],
        groups: []
    })

    const getGroupsCategories = async () => {
        groupsException.value = null
        groupsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/contacts/groups/categories`, {headers: header})
                .then(response => {
                    groupsCategories.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getGroupsCategories()
                    }
                })
                .finally(() => groupsLoader.value = false)
        }
    }

    const addGroupsCategories = async (groupsCategories) => {
        groupsException.value = null
        groupsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.post(`https://api.utp1.com/contacts/groups/categories`,
                groupsCategories,
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await addGroupsCategories(groupsCategories)
                    }
                })
                .finally(() => groupsLoader.value = false)
        }
    }

    const getGroupsCategory = async (groupsCategoryId) => {
        groupsException.value = null
        groupsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/contacts/groups/categories/` + groupsCategoryId,
                {headers: header})
                .then(response => {
                    groupCategory.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getGroupsCategory(groupsCategoryId)
                    } else (await router.push('/mailing/contacts/groups-categories'))
                })
                .finally(() => groupsLoader.value = false)
        }
    }

    const updateGroupsCategories = async (groupsCategoryId) => {
        groupsException.value = null
        groupsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(`https://api.utp1.com/contacts/groups/categories/` + groupsCategoryId,
                groupCategory.value,
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateGroupsCategories(groupsCategoryId)
                    } else (await router.push('/mailing/contacts/groups-categories'))
                })
                .finally(() => groupsLoader.value = false)
        }
    }

    const deleteGroupsCategories = async (groupsCategoryId) => {
        groupsException.value = null
        groupsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.delete(`https://api.utp1.com/contacts/groups/categories/` + groupsCategoryId,
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await deleteGroupsCategories(groupsCategoryId)
                    } else (await router.push('/mailing/contacts/groups-categories'))
                })
                .finally(() => groupsLoader.value = false)
        }
    }

    const getGroups = async () => {
        groupsException.value = null
        groupsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/contacts/groups`, {headers: header})
                .then(response => {
                    getAllGroups.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getGroups()
                    }
                })
                .finally(() => groupsLoader.value = false)
        }
    }

    const getGroupsList = async () => {
        groupsException.value = null
        groupsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/contacts/groups/list`, {headers: header})
                .then(response => {
                    groupsList.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getGroupsList()
                    }
                })
                .finally(() => groupsLoader.value = false)
        }
    }

    const addGroups = async (groups) => {
        groupsException.value = null
        groupsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.post(`https://api.utp1.com/contacts/groups`,
                groups,
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await addGroups(groups)
                    }
                })
                .finally(() => groupsLoader.value = false)
        }
    }

    const getGroup = async (groupId) => {
        groupsException.value = null
        groupsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.get(`https://api.utp1.com/contacts/groups/` + groupId,
                {headers: header})
                .then(response => {
                    group.value = response.data
                })
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await getGroup(groupId)
                    } else (await router.push('/mailing/contacts/groups'))
                })
                .finally(() => groupsLoader.value = false)
        }
    }

    const updateGroups = async (groupId) => {
        groupsException.value = null
        groupsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.put(`https://api.utp1.com/contacts/groups/` + groupId,
                group.value,
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await updateGroups(groupId)
                    } else (await router.push('/mailing/contacts/groups'))
                })
                .finally(() => groupsLoader.value = false)
        }
    }

    const deleteGroups = async (groupId) => {
        groupsException.value = null
        groupsLoader.value = true
        const header = await authHeader()
        if (header) {
            await axios.delete(`https://api.utp1.com/contacts/groups/` + groupId,
                {headers: header})
                .then()
                .catch(async e => {
                    if (e.response.status === 401) {
                        await updateTokens()
                        await deleteGroups(groupId)
                    } else (await router.push('/mailing/contacts/groups'))
                })
                .finally(() => groupsLoader.value = false)
        }
    }

    const {authHeader, updateTokens} = useTokens()

    return {
        groupsLoader,
        groupsCategories,
        groupCategory,
        getAllGroup,
        getAllGroups,
        groupsList,
        group,
        getGroupsCategories,
        addGroupsCategories,
        getGroupsCategory,
        updateGroupsCategories,
        deleteGroupsCategories,
        getGroups,
        getGroupsList,
        addGroups,
        getGroup,
        updateGroups,
        deleteGroups
    }

}
