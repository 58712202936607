export default function useApplySocial() {

    const applyVk = (mailingLetters) => {
        const lightBoldRegex = new RegExp("\\*(.*?)\\*", "g")
        const paragraphRegex = new RegExp("^(?!" +
            "\\d{1,3}\\.\\.\\s.*$|" +
            "--\\s.*$|" +
            "---\\s?$)(.+$)", "gm")
        const boldRegex = new RegExp("\\*\\*(.*?)\\*\\*", "g")
        const italicRegex = new RegExp("__(.*?)__", "g")
        const strikethroughRegex = new RegExp("~~(.*?)~~", "g")
        const horizontalRuleRegex = new RegExp("^---$", "gm")

        const bulletListItemRegex = new RegExp("^(--)\\s(.*?)$", "gm")
        const orderOneListItemRegex = new RegExp("^1\\.\\.\\s(.*?)$", "gm")
        const orderTwoListItemRegex = new RegExp("^2\\.\\.\\s(.*?)$", "gm")
        const orderThreeListItemRegex = new RegExp("^3\\.\\.\\s(.*?)$", "gm")
        const orderFourListItemRegex = new RegExp("^4\\.\\.\\s(.*?)$", "gm")
        const orderFiveListItemRegex = new RegExp("^5\\.\\.\\s(.*?)$", "gm")
        const orderSixListItemRegex = new RegExp("^6\\.\\.\\s(.*?)$", "gm")
        const orderSevenListItemRegex = new RegExp("^7\\.\\.\\s(.*?)$", "gm")
        const orderEightListItemRegex = new RegExp("^8\\.\\.\\s(.*?)$", "gm")
        const orderNineListItemRegex = new RegExp("^9\\.\\.\\s(.*?)$", "gm")
        const orderListItemRegex = new RegExp("^(\\d{2,3})\\.\\.\\s(.*?)$", "gm")

        const warnRegex = new RegExp("(\\[#warn\\((.*)\\)])", "g")
        const infoRegex = new RegExp("(\\[#info\\((.*)\\)])", "g")
        const imageRegex = new RegExp("(\\[#imag\\d{1,3}\\((.*)\\)])", "g")

        const resultRegex = new RegExp("\n\n\n+", "g")

        const topic = mailingLetters.letterTopic ? mailingLetters.letterTopic + " " : ""
        const title = mailingLetters.letterTitle ? mailingLetters.letterTitle.replaceAll(lightBoldRegex, "$1") : ""
        const first = mailingLetters.letterTopic || mailingLetters.letterTitle ? topic + title + "\n\n" : ""

        const warn = mailingLetters.warningText ? "\n\n❗ " + mailingLetters.warningText + "\n\n" : null
        const infoTitle = mailingLetters.informationTitle ? "\n\n📍 " + mailingLetters.informationTitle + "\n\n" : null
        const infoText = mailingLetters.informationText ? "\n\n📎 " + mailingLetters.informationText
            .replaceAll(paragraphRegex, '\n$1\n')
            .replaceAll(boldRegex, "$1")
            .replaceAll(italicRegex, "$1")
            .replaceAll(strikethroughRegex, "$1")
            .replaceAll(horizontalRuleRegex, "-------------------------")
            .replaceAll(bulletListItemRegex, "✅ $2")
            .replaceAll(orderOneListItemRegex, "1️⃣ $1")
            .replaceAll(orderTwoListItemRegex, "2️⃣ $1")
            .replaceAll(orderThreeListItemRegex, "3️⃣ $1")
            .replaceAll(orderFourListItemRegex, "4️⃣ $1")
            .replaceAll(orderFiveListItemRegex, "5️⃣ $1")
            .replaceAll(orderSixListItemRegex, "6️⃣ $1")
            .replaceAll(orderSevenListItemRegex, "7️⃣ $1")
            .replaceAll(orderEightListItemRegex, "8️⃣ $1")
            .replaceAll(orderNineListItemRegex, "9️⃣ $1")
            .replaceAll(orderListItemRegex, "*️⃣ $2")
            .replaceAll(imageRegex, "$2")
            .replaceAll(warnRegex, warn ? warn : "\n\n$2\n\n")
            .trim() + "\n\n"
            : null
        const info = infoTitle ? infoText ? infoTitle + infoText : infoTitle : infoText ? infoText : null

        const text = mailingLetters.letterText ? mailingLetters.letterText
                .replaceAll(paragraphRegex, '\n$1\n')
                .replaceAll(boldRegex, "$1")
                .replaceAll(italicRegex, "$1")
                .replaceAll(strikethroughRegex, "$1")
                .replaceAll(horizontalRuleRegex, "-------------------------")
                .replaceAll(bulletListItemRegex, "✅ $2")
                .replaceAll(orderOneListItemRegex, "1️⃣ $1")
                .replaceAll(orderTwoListItemRegex, "2️⃣ $1")
                .replaceAll(orderThreeListItemRegex, "3️⃣ $1")
                .replaceAll(orderFourListItemRegex, "4️⃣ $1")
                .replaceAll(orderFiveListItemRegex, "5️⃣ $1")
                .replaceAll(orderSixListItemRegex, "6️⃣ $1")
                .replaceAll(orderSevenListItemRegex, "7️⃣ $1")
                .replaceAll(orderEightListItemRegex, "8️⃣ $1")
                .replaceAll(orderNineListItemRegex, "9️⃣ $1")
                .replaceAll(orderListItemRegex, "*️⃣ $2")
                .replaceAll(imageRegex, "$2")
                .replaceAll(warnRegex, warn ? warn : "\n\n$2\n\n")
                .replaceAll(infoRegex, info ? info : "\n\n$2\n\n")
            : ""

        const second = mailingLetters.actionText ? "\n\n📌 " + mailingLetters.actionText : ""

        return (first + text + second).replaceAll(resultRegex, "\n\n").trim()
    }

    return {applyVk}

}
