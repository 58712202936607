<template>

  <form id="group" @submit.prevent novalidate>
    <div class="float-sm-start text-center pb-2 pb-sm-0">
      Показывать по:
      <div class="btn-group" role="group">
        <input type="radio" class="btn-check" id="number10" :value="10" v-model="localNumber">
        <label class="btn btn-outline-secondary btn-sm" for="number10">10</label>

        <input type="radio" class="btn-check" id="number30" :value="30" v-model="localNumber">
        <label class="btn btn-outline-secondary btn-sm" for="number30">30</label>

        <input type="radio" class="btn-check" id="number100" :value="100" v-model="localNumber">
        <label class="btn btn-outline-secondary btn-sm" for="number100">100</label>
      </div>
      из {{ total }}
    </div>

    <div class="float-sm-end">
      <div class="btn-toolbar mb-3 justify-content-center">
        <div class="input-group">
          <input-utp1
              type="number"
              class="form-control bg-white form-control-sm w-pagination"
              v-model:model-value="newPage"
              placeholder="1"
              required
              min="1"
              :max="Math.ceil(total / localNumber) > 0 ? Math.ceil(total / localNumber) : 1"
              @keyup.enter="updatePage"
              @blur="updatePage"
          />
          <div class="input-group-text py-0">из
            {{ Math.ceil(total / localNumber) > 0 ? Math.ceil(total / localNumber) : 1 }}
          </div>
        </div>
        <div class="btn-group ms-2" role="group" aria-label="First group">
          <button type="button"
                  :class="newPage <= 1? 'btn btn-sm btn-secondary disabled' : 'btn btn-sm btn-secondary'"
                  @click="minusPage">
            <span>&laquo;</span></button>
          <button type="button" :class="newPage >= Math.ceil(total / localNumber) ?
                  'btn btn-sm btn-secondary disabled' : 'btn btn-sm btn-secondary '"
                  @click="plusPage">
            <span>&raquo;</span></button>
        </div>
      </div>
    </div>
  </form>

</template>

<script>
import InputUtp1 from "../inputs/InputUtp1";
import FormSearch from "../forms/FormSearch.vue";
import {ref, watch} from "vue";

export default {
  name: "pagination-utp1",
  components: {FormSearch, InputUtp1},
  props: {
    number: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    // noinspection JSCheckFunctionSignatures
    this.localNumber = parseInt(this.number)
    // noinspection JSCheckFunctionSignatures
    this.localPage = parseInt(this.page)
    // noinspection JSCheckFunctionSignatures
    this.newPage = parseInt(this.page)
  },
  watch: {
    total() {
      this.deleteCheckValidity()
    }
  },
  emits: ["update:number", "update:page"],
  setup(props, {emit}) {
    const localNumber = ref(10)
    const localPage = ref(1)
    const newPage = ref(1)

    watch(localNumber, async (newVal) => {
      await emit('update:number', newVal)
      localPage.value = props.page
      newPage.value = props.page
    })

    watch(newPage, () => {
      const form = document.querySelector('#group')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        form.classList.remove("was-validated")
      }
    })

    watch(localPage, (newVal) => {
      const form = document.querySelector('#group')
      if (form.checkValidity()) {
        // noinspection JSCheckFunctionSignatures
        emit('update:page', parseInt(newVal))
      }
    })

    const deleteCheckValidity = () => {
      const form = document.querySelector('#group')
      if ((newPage.value === 1) ||
          ((newPage.value < Math.ceil(props.total / localNumber.value)) && (newPage.value > 0))) {
        form.classList.remove("was-validated")
      }
    }

    const updatePage = () => {
      localPage.value = newPage.value
    }

    const minusPage = () => {
      newPage.value--
      updatePage()
    }

    const plusPage = () => {
      newPage.value++
      updatePage()
    }

    return {localNumber, localPage, newPage, updatePage, minusPage, plusPage, deleteCheckValidity}
  }
}
</script>

<style scoped>
.w-pagination {
  width: 4.5rem;
}
</style>