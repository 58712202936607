<template>
  <header class="pt-1 pb-2 mb-4 border-bottom clearfix">
    <div class="float-sm-start">
      <!--suppress JSUnresolvedReference -->
      <h1 class="h3 fw-light">
        {{ header.title }}
      </h1>
    </div>
    <div class="float-sm-end">
      <!--suppress JSUnresolvedReference -->
      <button-primary-utp1
          :disabled="false"
          @buttonClick="buttonClick">
        {{ header.buttonTitle }}
      </button-primary-utp1>
    </div>
  </header>
</template>

<script>
import ButtonPrimaryUtp1 from "../buttons/ButtonPrimaryUtp1";

export default {
  name: "header-button-utp1",
  components: {ButtonPrimaryUtp1},
  props: {
    header: Object
  },
  setup(_, {emit}) {
    const button = {
      buttonActive: true
    }
    const buttonClick = () => {
      emit("buttonClick")
    }
    return {button, buttonClick}
  }

}
</script>

<style scoped>

</style>