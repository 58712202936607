<template>
  <main>
    <entry-header-utp1>Error 404 NotFound</entry-header-utp1>
    <p class="h4 fw-light">
      Стрнаница не найдена
    </p>
    <div class="py-3 d-sm-block d-grid">
      <button type="button" @click="$router.push('/')" class="btn btn-primary">Вернуться на глувную</button>
    </div>
  </main>
</template>

<script>
import EntryHeaderUtp1 from "../components/UI/headers/EntryHeaderUtp1";
export default {
  name: "not-found",
  components: {EntryHeaderUtp1}
}
</script>

<style scoped>

</style>