<template>
  <preloader-main v-if="userLoader || mailingSettingsLoader"/>

  <div class="mb-3">
    <label for="author" class="form-label">
      Имя автора рассылки (для переменной в сообщениях)
    </label>
    <input-utp1
        id="author"
        placeholder="Имя для переменной"
        v-model:model-value="vkSettings.author"
        maxlength="255"/>
  </div>

  <div class="mb-3">
    <label for="authorContact" class="form-label">
      Идентификатор автора рассылки (для переменной в письмах)
    </label>
    <input-utp1
        type="email"
        id="authorContact"
        placeholder="Идентификатор для переменной"
        v-model:model-value="vkSettings.authorContact"
        maxlength="255"/>
    <label for="authorContact" class="form-text">Обязательно в одном из следующих форматов: id123 (для личных страниц) /
      club123 (для групп) / public123 (для пабликов) / login (для личных страниц и сообществ) - то, что идёт после
      vk.com/...</label>
  </div>

  <double-button-utp1
      :buttons="buttons"
      @buttonPrimaryClick="updateVkSetting"
      @buttonSecondaryClick="cancelChanges"
  />

  <div v-if="!userVk.authorise" class="mt-3">
    <button-primary-utp1 @buttonClick="vkOAuth">
      Авторизоваться ВКонтакте
    </button-primary-utp1>
  </div>
  <div v-else class="mt-3">
    <ol class="list-group">
      <li class="list-group-item bg-light">
        <div class="row-form">
          <div class="row">
            <div class="col">
              <div class="row-form">
                <div class="row">
                  <div class="col-auto">
                    <!--suppress JSUnresolvedVariable -->
                    <img :src="userVk.userVk.vkUserPhoto" alt="vkPhoto" class="status-card-utp1">
                  </div>
                  <div class="col my-auto text-truncate">
                    <!--suppress JSUnresolvedVariable -->
                    <span class="text-truncate">{{ userVk.userVk.vkUserName }}<br>
                      <!--suppress JSUnresolvedVariable -->
                        <span class="text-secondary">
                          id{{ userVk.userVk.vkUserId }}
                        </span>
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <!--suppress JSUnresolvedVariable -->
            <div class="col-auto my-auto">
              <button class="btn btn-danger" @click="deleteUsersVk">
                Выйти
              </button>
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="text-truncate text-center">
          <a class="card-link text-decoration-none" @click="getUserVk">
            <i class="bi bi-arrow-clockwise"></i> Обновить список групп</a>
        </div>
      </li>
      <!--suppress JSUnresolvedVariable -->
      <li v-for="item in userVk.userVk.vkGroups"
          class="list-group-item list-group-item-action">
        <div class="row-form">
          <div class="row">
            <div class="col text-truncate">
              <!--suppress JSUnresolvedVariable -->
              <img :src="item.vkGroupPhoto" class="rounded-5 me-3" alt="vkGroupPhoto" style="width: 38px;height: 38px">
              <!--suppress JSUnresolvedVariable -->
              <span class="text-truncate align-text-top">
                {{ item.vkGroupName }}
              </span>
            </div>
            <!--suppress JSUnresolvedVariable -->
            <div class="col-auto" v-if="item.closed === 'OPEN' && item.deactivated === null && item.signed === true">
              <!--suppress JSCheckFunctionSignatures -->
              <button class="btn btn-primary" :disabled="groupTokenValid(item.vkGroupId)"
                      @click="groupsAuth(item.vkGroupId)">
                Подключить
              </button>
            </div>
            <div v-else-if="item.closed === 'CLOSED'"
                 class="col-auto text-truncate text-end text-secondary my-auto"
                 style="font-size: 0.775em;">
              Закрытое сообщество
            </div>
            <div v-else-if="item.closed === 'PRIVATE'"
                 class="col-auto text-truncate text-end text-secondary my-auto"
                 style="font-size: 0.775em;">
              Частное сообщество
            </div>
            <div v-else-if="item.signed === false"
                 class="col-auto text-truncate text-end text-secondary my-auto"
                 style="font-size: 0.775em;">
              Вы не состоите<br> в сообществе
            </div>
            <div v-else-if="item.deactivated === 'DELETED'"
                 class="col-auto text-truncate text-end text-secondary my-auto"
                 style="font-size: 0.775em;">
              Сообщество удалено
            </div>
            <div v-else-if="item.deactivated === 'BANNED'"
                 class="col-auto text-truncate text-end text-secondary my-auto"
                 style="font-size: 0.775em;">
              Сообщество заблокировано
            </div>
          </div>
        </div>
      </li>
    </ol>
  </div>

  <label class="pt-2 form-text">
    Можно привязать только открытые сообщества, в которых Вы состоите и являетесь администратором.
    Максимальное количество - 25 групп.
    Так же должны быть произведены следующие настройки:
    <!--suppress HtmlUnknownTag -->
    <ul class="my-1">
      <li>Включены сообщения;</li>
      <li>Включены возможности ботов;</li>
      <li>Разрешено добавлять сообщество в чаты;</li>
      <li>Открытые или ограниченные фотографии.</li>
    </ul>
  </label>
  <label class="pb-4 text-secondary">
    <strong>Все настройки производятся автоматически</strong>, главное ничего не меняйте.
    Единственное, фотографии будут настроены как ограниченные - можете открыть, но не закрывайте!
  </label>
  <div v-for="item in vkBinding">
    <card-utp1
        card-id="vkUser"
        :image="{
          img: item.vkGroupPhoto,
          base64: false,
          round: true,
          redirect: null
        }"
        :badge="item.default ? {
          icon: 'bi bi-check-all',
          background: 'bg-green-dark'
        } : item.tokenValid ? {
          icon: 'bi bi-check',
          background: 'bg-green'
        } : {
          icon: 'bi bi-exclamation',
          background: 'bg-red'
        }"
        :dropdown-card-menu="cardHeader"
        @deleteCard="deleteCardModal(item.id)">
      <template v-slot:cardHeader>
        <div class="text-truncate">
          <!--suppress JSUnresolvedVariable -->
          <span class="text-primary fw-semibold">
                    {{ item.vkGroupName }}
                  </span>
        </div>
      </template>
      <div class="text-truncate">
        id{{ item.vkGroupId }}
      </div>
      <template v-slot:cardFooter>
        <!--suppress JSUnresolvedVariable -->
        <div v-if="!item.tokenValid" class="text-truncate">Повторно подключите сообщество!</div>
        <div v-else-if="item.default" class="text-truncate">По-умолчанию</div>
        <div v-else class="text-truncate">
          <!--suppress JSCheckFunctionSignatures -->
          <a class="card-link text-decoration-none" @click="updateVkBindingDefault(item.id)">Назначить
            по-умолчанию</a>
        </div>
      </template>
    </card-utp1>
  </div>

  <delete-object
      :id="deleteCardId"
      :confirmation="false"
      @deleteObject="deleteVkBinding"
  />

</template>

<!--suppress JSUnresolvedVariable -->
<script>
import ButtonPrimaryUtp1 from "../../../UI/buttons/ButtonPrimaryUtp1";
import {onMounted, ref} from "vue";
import ButtonSecondaryUtp1 from "../../../UI/buttons/ButtonSecondaryUtp1";
import VkAuth from "../../../system/widgets/VkAuth";
import useUser from "../../../../hooks/rest/settings/useUser";
import PreloaderMain from "../../../system/preloader/PreloaderMain";
import CardUtp1 from "../../../UI/cards/CardUtp1";
import useMailingSettings from "../../../../hooks/rest/mailing/useMailingSettings";
import InputUtp1 from "../../../UI/inputs/InputUtp1";
import DoubleButtonUtp1 from "../../../UI/buttons/DoubleButtonUtp1";
import DeleteObject from "../../../UI/modals/DeleteObject";

export default {
  name: "vk-binding",
  components: {
    DeleteObject,
    DoubleButtonUtp1,
    InputUtp1, CardUtp1, PreloaderMain, VkAuth, ButtonSecondaryUtp1, ButtonPrimaryUtp1
  },
  props: {
    vkSetting: {}
  },
  watch: {
    vkSetting() {
      this.cancelChanges()
    }
  },
  setup(props) {
    const cardHeader = [{
      dropdownName: "Удалить",
      dropdownPush: "",
      dropdownHr: false,
      dropdownDelete: true
    }]

    const vkSettings = ref({
      author: "",
      authorContact: ""
    })

    const buttons = {
      primaryTitle: "Сохранить",
      primaryDisabled: false,
      secondaryTitle: "Отменить",
      secondaryDisabled: false
    }

    const deleteCardId = ref(0)
    const deleteObjectModal = ref()

    const updateVkSetting = () => {
      const form = document.querySelector('#group-email-setting')
      if (!form.checkValidity()) {
        form.classList.add("was-validated")
      } else {
        updateVkSettings(vkSettings.value)
      }
    }

    const cancelChanges = () => {
      vkSettings.value.author = props.vkSetting.author
      vkSettings.value.authorContact = props.vkSetting.authorContact
    }

    const popup = ref()

    const vkOAuth = async () => {
      popup.value = await window.open(
          "https://oauth.vk.com/authorize?client_id=51528363" +
          "&redirect_uri=https://syst.utp1.com/vk-user/auth" +
          "&scope=4521984&display=popup&response_type=code",
          "_blank",
          "location,width=655,height=350," +
          "left=" + (screen.width > 655 ? (screen.width - 655) / 2 : 0) +
          ",top=" + (screen.height > 350 ? (screen.height - 350) / 2 : 0) +
          ",menubar=no,toolbar=no,location=no,status=no"
      )
    }

    window.authVk = function (code) {
      if (code !== "") {
        const vkCode = {
          code: code,
          redirectUrl: "https://syst.utp1.com/vk-user/auth"
        }
        addVkToken(vkCode)
        popup.value.close()
      } else {
        popup.value.close()
      }
    }

    const groupsAuth = async (groupId) => {
      popup.value = await window.open(
          "https://oauth.vk.com/authorize?client_id=51528363" +
          "&redirect_uri=https://syst.utp1.com/vk-group/auth" +
          "&group_ids=" + groupId +
          "&scope=266244&display=popup&response_type=code&&v=5.131&state=" + groupId,
          "_blank",
          "location,width=655,height=350," +
          "left=" + (screen.width > 655 ? (screen.width - 655) / 2 : 0) +
          ",top=" + (screen.height > 350 ? (screen.height - 350) / 2 : 0) +
          ",menubar=no,toolbar=no,location=no,status=no"
      )
    }

    const {
      mailingSettingsLoader,
      vkBinding,
      getVkBinding,
      addVkBinding,
      updateVkBindingDefault,
      deleteVkBinding,
      updateVkSettings
    } = useMailingSettings()

    window.groupVk = function (code) {
      if (code !== "") {
        const vkCode = {
          code: code,
          redirectUrl: "https://syst.utp1.com/vk-group/auth"
        }
        addVkBinding(vkCode)
        popup.value.close()
      } else {
        popup.value.close()
      }
    }

    window.closePopup = function () {
      popup.value.close()
    }

    const {userLoader, getUserVk, addVkToken, deleteUsersVk, userVk} = useUser()

    onMounted(async () => {
      await getVkBinding()
      await getUserVk()
      deleteObjectModal.value = new bootstrap.Modal('#deleteObjectModal', {})
    })

    const groupTokenValid = (vkGroupId) => {
      let groupsTokenValid = false
      if (vkBinding.value.length > 25) {
        groupsTokenValid = true
        vkBinding.value.forEach(item => {
          if (item.vkGroupId === vkGroupId && !item.tokenValid) {
            groupsTokenValid = false
          }
        })
      } else {
        vkBinding.value.forEach(item => {
          if (item.vkGroupId === vkGroupId && item.tokenValid) {
            groupsTokenValid = true
          }
        })
      }
      return groupsTokenValid
    }

    const deleteCardModal = (id) => {
      deleteCardId.value = id
      deleteObjectModal.value.show()
    }

    return {
      cardHeader,
      userLoader,
      mailingSettingsLoader,
      userVk,
      vkOAuth,
      deleteUsersVk,
      groupsAuth,
      vkBinding,
      updateVkBindingDefault,
      deleteVkBinding,
      groupTokenValid,
      getUserVk,
      buttons,
      vkSettings,
      cancelChanges,
      updateVkSetting,
      deleteCardModal,
      deleteCardId
    }
  }
}
</script>

<style scoped>

</style>