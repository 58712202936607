import {ref} from 'vue';

export default function useInvoiceError(primaryException = null) {

    const invoiceErrorMessage = ref(null)
    const invoiceException = ref(null)

    const invoiceError = (secondaryException) => {
        if (secondaryException !== null) {
            invoiceException.value = secondaryException
        } else if (primaryException !== null) {
            invoiceException.value = primaryException
        } else invoiceException.value = null

        if (invoiceException.value === "" || invoiceException.value === null) {
            invoiceErrorMessage.value = null
        } else {
            if (invoiceException.value === "No ...") invoiceErrorMessage.value = "Отсутствует ..."
            else if (invoiceException.value === "No mainProduct") invoiceErrorMessage.value = "Необходимо добавить основной продукт"

            else if (invoiceException.value === "Use identifier") invoiceErrorMessage.value = "Продукт с таким идентификатором уже есть"
            else if (invoiceException.value === "Use ...") invoiceErrorMessage.value = "Пользователь с таким ... уже есть"

            else if (invoiceException.value === "Invalid name") invoiceErrorMessage.value = "Некорректное имя"
            else if (invoiceException.value === "Invalid email") invoiceErrorMessage.value = "Некорректный e-mail"
            else if (invoiceException.value === "Invalid phone") invoiceErrorMessage.value = "Некорректный телефон"
            else if (invoiceException.value === "Invalid productId") invoiceErrorMessage.value = "Некорректный ID основного продукта"
            else if (invoiceException.value === "Invalid cost") invoiceErrorMessage.value = "Некорректная стоимость"
            else if (invoiceException.value === "Invalid productCost") invoiceErrorMessage.value = "Некорректная стоимость основного продукта"
            else if (invoiceException.value === "Invalid autoCost") invoiceErrorMessage.value = "Некорректный размер автоматического платежа"
            else if (invoiceException.value === "Invalid validity") invoiceErrorMessage.value = "Некорректный срок действия счёта"
            else if (invoiceException.value === "Invalid firstProlong") invoiceErrorMessage.value = "Некорректный первый период"
            else if (invoiceException.value === "Invalid periodProlong") invoiceErrorMessage.value = "Некорректный период автоматичского платежа"
            else if (invoiceException.value === "Invalid productsId") invoiceErrorMessage.value = "Некорректный ID дополнительного продукта"
            else if (invoiceException.value === "Invalid productsCost") invoiceErrorMessage.value = "Некорректная стоимость дополнительного продукта"
            else if (invoiceException.value === "Invalid ...") invoiceErrorMessage.value = "Некорректный ..."

            else if (invoiceException.value === "No access") invoiceErrorMessage.value = "К сожалению у Вас нет доступа"
            else if (invoiceException.value === "Timeout") invoiceErrorMessage.value = "Мы уже исправляем эту ошибку"
            else if (invoiceException.value === "Exception") invoiceErrorMessage.value = "Проверьте данные и попробуйте ещё раз"
            else invoiceErrorMessage.value = "Некорректные данные, проверьте и попробуйте ещё раз"
        }
    }

    return {
        invoiceErrorMessage,
        invoiceError
    }

}
