<template>
  <main>
    <preloader-main v-if="loaderProducts"/>
    <header-back-utp1
        :header="header"
    />
    <products-category
        v-model:products-category="productsCategory"
        :button="button"
        @buttonClick="updateProductsCategories"
        @buttonDeleteClick="deleteProductsCategories"
    />
  </main>
  <sidebar-right-utp1/>
</template>

<script>
import PreloaderMain from "../../../components/system/preloader/PreloaderMain";
import HeaderBackUtp1 from "../../../components/UI/headers/HeaderBackUtp1";
import ProductsCategory from "../../../components/pages/shop/products/ProductsCategory";
import SidebarRightUtp1 from "../../../components/system/SidebarRightUtp1";
import {onMounted} from "vue";
import router from "../../../router";
import useProducts from "../../../hooks/rest/shop/useProducts";
import {useRoute} from "vue-router";

export default {
  name: "edd-products-category",
  components: {SidebarRightUtp1, ProductsCategory, HeaderBackUtp1, PreloaderMain},
  setup() {
    const route = useRoute()
    const id = route.params.id

    const header = {
      title: "Изменить категорию групп",
      buttonRedirect: "/shop/products/categories"
    }

    const button = {
      buttonTitle: "Изменить",
      buttonDelete: true
    }

    const updateProductsCategories = async () => {
      await updateProductsCategory(productsCategory.value, id)
    }

    const deleteProductsCategories = async () => {
      await deleteProductsCategory(id)
      await router.push('/shop/products/categories')
    }

    const {
      getProductsCategory,
      updateProductsCategory,
      deleteProductsCategory,
      loaderProducts,
      productsCategory
    } = useProducts()

    onMounted(() => {
      getProductsCategory(id)
    })

    return {loaderProducts, header, button, productsCategory, updateProductsCategories, deleteProductsCategories}
  }
}
</script>

<style scoped>

</style>
