<template>
  <div class="pt-1 mb-3 d-block">
    <div class="card text-bg-light">
      <div class="card-header">Так будет выглядеть шаблон Вашего письма:</div>
      <div class="card-body p-0">

        <table
            :style="lettersSettings.backgroundColor ? 'background-color:' + lettersSettings.backgroundColor :
                'background-color:#ffffff'"
            style="width: 100%;"> <!--min-width:290px;-->
          <tbody>
          <tr>
            <td style="padding: 20px 5px;">
              <table style="width: 100%!important; max-width: 550px; margin: 0 auto; word-break: break-word;">
                <tbody>
                <tr>
                  <td style="background-color:#fff;border-radius:15px 15px 0 0;padding:15px 0 0">
                    <img v-if="lettersLogo" alt="logo"
                         :src="'data:image;base64,' + lettersLogo"
                         style="display:block;height:50px;margin:0 auto 15px;max-width:100%;width:auto">

                    <img v-if="lettersHeader"
                         alt="banner"
                         :src="'data:image;base64,' + lettersHeader"
                         style="width:100%!important;max-width:550px;display:block;vertical-align:top;height:auto;margin:5px auto 15px">
                  </td>
                </tr>

                <!-- Content -->
                <tr>
                  <td style="font-family:Arial,sans-serif;background-color:#fff;padding:5px 25px 10px;color:#333;font-size:17px;line-height:1.45">
                    <!--suppress JSUnresolvedReference -->
                    <p style="margin:17px 0">
                      {{ lettersSettings.defaultTextTemplate }}
                    </p>
                  </td>
                </tr>
                <!-- End Content -->

                <!--suppress JSUnresolvedReference -->
                <tr v-if="(lettersSettings.socialNetworksCall === null || lettersSettings.socialNetworksCall === '') &&
                     (lettersSettings.socialButtons.length === 0) &&
                     (lettersSettings.contactNotes === null || lettersSettings.contactNotes === '') &&
                     (lettersSettings.contact === null || lettersSettings.contact === '') &&
                     (lettersSettings.contactUrl === null || lettersSettings.contactUrl === '')">
                  <td style="padding:0 0 15px;border-radius:0 0 15px 15px;background-color:#fff">
                  </td>
                </tr>
                <tr v-else>
                  <td style="padding:25px 25px 20px;border-radius:0 0 15px 15px;border-top:1px solid #dddfe0;background-color:#fff;font-size:17px;font-family:Arial,sans-serif;text-align:center;color:#777">

                    <!--suppress JSUnresolvedReference -->
                    <div v-if="lettersSettings.socialNetworksCall" style="padding-bottom:18px">
                      {{ lettersSettings.socialNetworksCall }}
                    </div>

                    <!--suppress JSUnresolvedReference -->
                    <div v-if="lettersSettings.socialButtons.length > 0"
                         style="font-size:12px;line-height:100%;padding-bottom:18px">
                      <!--suppress JSUnresolvedReference -->
                      <a v-for="icon in lettersSettings.socialButtons"
                         :href="icon.url" target="_blank" style="margin:5px;display:inline-block">
                        <!--suppress JSUnresolvedReference -->
                        <img :src="'https://files.utp1.com/img/system/mailing/icons' +
                        (lettersSettings.socialButtonColor ? '/color' : '/no-color') +
                        (lettersSettings.socialButtonType === 'ROUND' ? '/ROUND/' :
                        lettersSettings.socialButtonType === 'SQUARE' ? '/SQUARE/' :
                        lettersSettings.socialButtonType === 'ICON' ? '/ICON/' : '/ROUND/') +
                        icon.socialNetwork + '.png'" style="display:block" alt="Icon">
                      </a>
                    </div>

                    <!--suppress JSUnresolvedReference -->
                    <div v-if="lettersSettings.contactNotes" style="font-size:12px">{{
                        lettersSettings.contactNotes
                      }}
                    </div>

                    <!--suppress JSUnresolvedReference -->
                    <div v-if="lettersSettings.contact || lettersSettings.contactUrl"
                         style="padding:10px 0;font-family:'Segoe UI','Helvetica Neue',Helvetica,Arial,sans-serif;font-size:24px;line-height:1;font-weight:300">
                      <!--suppress JSUnresolvedReference -->
                      <a v-if="lettersSettings.contactUrl" :style="'color:' + lettersSettings.color"
                         style="text-decoration:none"
                         :href="lettersSettings.contactUrl" target="_blank">
                        {{ lettersSettings.contact ? lettersSettings.contact : lettersSettings.contactUrl }}
                      </a><!--suppress JSUnresolvedReference --><span v-else>{{ lettersSettings.contact }}</span>

                    </div>

                  </td>
                </tr>
                </tbody>
              </table>

            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "letter-body",
  props: {
    lettersSettings: {
      type: Object,
      default: {}
    },
    lettersLogo: {
      default: null
    },
    lettersHeader: {
      default: null
    }
  }
}
</script>

<style scoped>

</style>